import React from "react";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";

const FilterModal = ({
  children,
  title,
  onReset,
  showButtons = true
}) => {
  const { t } = useTranslation();
  return (
    <>
      {/*<div*/}
      {/*  className="hidden lg:block fixed bg-black -mt-1 py-4 pb-4 -left-8 top-20 w-screen z-10 bg-opacity-50"*/}
      {/*  style={{height: "84vh"}}*/}
      {/*  onClick={onClose}*/}
      {/*/>*/}
      <div className="absolute -top-2 -left-16 lg:left-0 lg:top-0 z-20 bg-white mt-4 p-8  w-96 rounded-sm shadow-lg">
        {title && <div className="mb-4">{title}</div>}

        {children}
        {showButtons && (
          <>
            <div className="mt-6">
              <button
                className="leading-5 flex items-center m-auto py-3 px-4 text-sm "
                type="button"
                onClick={onReset}
              >
                <X size={14} className="mr-2" />
                {t("Clear")}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FilterModal;
