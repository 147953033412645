import React, {useEffect, useState} from 'react'
import { Modal } from 'semantic-ui-react'
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import RequestsServices from "../../../../services/RequestsServices";



function ListSingleActionModal ({ accept, requestUuid, handleRefresh, setModalOpen, handleModalClose }) {

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        setOpen(setModalOpen);
    }, [setModalOpen]);

    const handleStatusChange = () => {
        setLoading(true);
        RequestsServices.changeRequestStatus(requestUuid, {
            status: `${accept ? "accept" : "reject"}`
        })
            .then(() => {
                toast.success(`${t("The request status has been successfully changed")}`);
                handleRefresh(true);
            })
            .then(() => handleModalClose(false))
            .catch(() => toast.error(`${t("The request status could not be changed")}`))
            .finally(()=> setLoading(false))
    }

    return (
        <Modal
            open={open}
        >
            <Modal.Header style={{background: "rgba(249, 250, 251, 1", border: "none"}}>
                <div  className="flex justify-between">
                    <h3 className="text-xl font-bold">{`${t("Are you sure you want to change the request status to")}: ${accept ? `${t("Accepted")}` : `${t("Declined")}`}`}</h3>
                    <AiFillCloseCircle   onClick={() => handleModalClose(false)} className="text-primse-gray-font cursor-pointer w-8 h-8" />
                </div>
            </Modal.Header>
            <Modal.Content>
                <div>
                    <button
                        onClick={() => handleStatusChange()}
                        className="leading-5 mx-2 font-bold text-black uppercase bg-primse-green py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                    >
                        {t("Confirm")}
                    </button>
                    <button
                        onClick={() => handleModalClose(false)}
                        className="leading-5 mx-2 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                    >
                        {t("Cancel")}
                    </button>
                </div>

            </Modal.Content>
            {loading && <Loader/>}
        </Modal>
    )
}

export default ListSingleActionModal;