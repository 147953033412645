import { useStoreActions } from "easy-peasy";
import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";

import googleLogo from "./google.svg";
import appleLogo from "./apple.svg";

const Unauthorized = () => {
  const showLoginModal = useStoreActions((state) => state.auth.showLoginModal);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col justify-center items-center mt-8">
      <div className="my-8">
        <div>{t("You must log in to access the content")}</div>
      </div>
      <div>
        <Button onClick={showLoginModal}>{t("Log in")}</Button>
      </div>
      <div className="flex gap-x-4 mt-8">
        <a
          href="https://apps.apple.com/pl/app/primse-com-platforma/id1593392134"
          target="blank"
        >
          <img style={{ height: "40px" }} src={appleLogo} alt="Apple" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.primsemobile&amp;gl=PL"
          target="blank"
        >
          <img style={{ height: "40px" }} src={googleLogo} alt="Google" />
        </a>
      </div>
    </div>
  );
};

export default Unauthorized;
