import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Dropdown, Input } from "semantic-ui-react";
import { X } from "react-feather";
import Container from "../../components/Container";
import cs from "classnames";
import ReportsServices from "../../services/ReportsServices";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";

const Th = ({ children, rowspan, colspan, className }) => (
  <th
    className={cs(
      "px-1 text-center z-20 bg-white  border-primse-green",
      // "px-1 text-center z-20 bg-white border border-primse-green",
      className
    )}
    rowSpan={rowspan && rowspan}
    colSpan={colspan && colspan}
  >
    {children}
  </th>
);
const Td = ({ children, className }) => (
  <th
    className={cs("py-2 px-2 border-b overflow-auto font-normal  ", className)}
  >
    {children}
  </th>
);

const ReportDeveloper = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [reportsData, setReportsData] = useState([]);
  const [reportsDataTotal, setReportsDataTotal] = useState({});
  const [totalDevelopers, setTotalDevelopers] = useState([]);
  const [totalParentDevelopers, setTotalParentDevelopers] = useState([]);
  const [totalInvestments, setTotalInvestments] = useState([]);

  const [selectedParentDeveloper, setSelectedParentDeveloper] = useState("");
  const [selectedInvestment, setSelectedInvestment] = useState("");
  const [selectedDeveloper, setSelectedDeveloper] = useState("");
  const [selectedDataFrom, setSelectedDataFrom] = useState("");
  const [selectedDataTo, setSelectedDataTo] = useState("");

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    const formData = new FormData();

    const date = new Date();

    const firstDay = new Date(date.getFullYear(), date.getMonth(), 2)
      .toISOString("yyyy-MM-dd")
      .slice(0, 10);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1)
      .toISOString("yyyy-MM-dd")
      .slice(0, 10);

    setSelectedDataFrom(firstDay);
    setSelectedDataTo(lastDay);

    formData.append(["date_from"], firstDay);
    formData.append(["date_to"], lastDay);
    ReportsServices.reportDeveloper(null, formData).then((res) => {
      setData(res);
    });
  }, []);

  const setDataFn = (data) => {
    if (data?.investments_total?.length !== 0) {
      setReportsDataTotal(data?.investments_total);
    }
    const arr = [];

    for (const [key, value] of Object.entries(data?.investments)) {
      arr.push({
        id: key,
        investment_name: value.investment.investment_name
          ? value.investment.investment_name
          : "",
        developer_name: value.investment.developer_name
          ? value.investment.developer_name
          : "",
        parent_developer_name: value.investment.parent_developer_name
          ? value.investment.parent_developer_name
          : "",
        investment_created: value.investment.investment_created
          ? value.investment.investment_created
          : "",
        investment_price_list_updated: value.investment
          .investment_price_list_updated
          ? value.investment.investment_price_list_updated
          : "",

        investment_view: value.investment_view ? value.investment_view : 0,
        basket_count: value.basket_count ? value.basket_count : 0,
        briefcase_count: value.briefcase_count ? value.briefcase_count : 0,

        query_accepted: value.query_accepted ? value.query_accepted : 0,
        query_accepted_conditionally: value.query_accepted_conditionally
          ? value.query_accepted_conditionally
          : 0,
        query_awaiting: value.query_awaiting ? value.query_awaiting : 0,
        query_confirmed: value.query_confirmed ? value.query_confirmed : 0,
        query_count: value.query_count ? value.query_count : 0,
        query_expired: value.query_expired ? value.query_expired : 0,
        query_rejected: value.query_rejected ? value.query_rejected : 0,
        query_rejected_percent: value.query_rejected_percent
          ? value.query_rejected_percent
          : 0,

        transaction_open: value.transaction_open ? value.transaction_open : 0,
        transaction_settled: value.transaction_settled
          ? value.transaction_settled
          : 0,
        transaction_unsettled: value.transaction_unsettled
          ? value.transaction_unsettled
          : 0,
      });
    }

    setReportsData(arr);

    setLoaded(true);
  };

  useEffect(() => {
    setLoaded(false);
    if (data) {
      if (data?.investments_total?.length !== 0) {
        setReportsDataTotal(data?.investments_total);
      }
      const arr = [];

      const arrDevelopers = [];
      const arrParentDeveloper = [];
      const arrInvestments = [];

      for (const [key, value] of Object.entries(data?.investments)) {
        if (value?.investment?.developer_name) {
          arrDevelopers.push({
            key: value.investment.developer_id,
            value: value.investment.developer_id,
            text: value.investment.developer_name,
          });
        }
        if (value?.investment?.parent_developer_name) {
          arrParentDeveloper.push({
            key: value.investment.parent_developer_id,
            value: value.investment.parent_developer_id,
            text: value.investment.parent_developer_name,
          });
        }
        if (value?.investment?.investment_name) {
          arrInvestments.push({
            key: value.investment.investment_id,
            value: value.investment.investment_id,
            text: value.investment.investment_name,
          });
        }

        arr.push({
          id: key,
          investment_name: value.investment.investment_name
            ? value.investment.investment_name
            : "",
          developer_name: value.investment.developer_name
            ? value.investment.developer_name
            : "",
          parent_developer_name: value.investment.parent_developer_name
            ? value.investment.parent_developer_name
            : "",
          investment_created: value.investment.investment_created
            ? value.investment.investment_created
            : "",
          investment_price_list_updated: value.investment
            .investment_price_list_updated
            ? value.investment.investment_price_list_updated
            : "",

          investment_view: value.investment_view ? value.investment_view : 0,
          basket_count: value.basket_count ? value.basket_count : 0,
          briefcase_count: value.briefcase_count ? value.briefcase_count : 0,

          query_accepted: value.query_accepted ? value.query_accepted : 0,
          query_accepted_conditionally: value.query_accepted_conditionally
            ? value.query_accepted_conditionally
            : 0,
          query_awaiting: value.query_awaiting ? value.query_awaiting : 0,
          query_confirmed: value.query_confirmed ? value.query_confirmed : 0,
          query_count: value.query_count ? value.query_count : 0,
          query_expired: value.query_expired ? value.query_expired : 0,
          query_rejected: value.query_rejected ? value.query_rejected : 0,
          query_rejected_percent: value.query_rejected_percent
            ? value.query_rejected_percent
            : 0,

          transaction_open: value.transaction_open ? value.transaction_open : 0,
          transaction_settled: value.transaction_settled
            ? value.transaction_settled
            : 0,
          transaction_unsettled: value.transaction_unsettled
            ? value.transaction_unsettled
            : 0,
        });
      }

      const sortFunction = (a, b) => {
        if (a.text.toLowerCase() < b.text.toLowerCase()) {
          return -1;
        }
        if (a.text.toLowerCase() > b.text.toLowerCase()) {
          return 1;
        }
        return 0;
      };

      const setUniqe = (arr, value) => {
        return arr
          .filter(
            (thing, index, self) =>
              index === self.findIndex((t) => t[value] === thing[value])
          )
          .sort(sortFunction);
      };

      setReportsData(arr);
      setTotalDevelopers(setUniqe(arrDevelopers, "text"));
      setTotalParentDevelopers(setUniqe(arrParentDeveloper, "text"));
      setTotalInvestments(setUniqe(arrInvestments, "text"));

      setLoaded(true);
    }
  }, [data]);

  const handleClearSearch = () => {
    setSelectedInvestment("");
    setSelectedDeveloper("");
    setSelectedParentDeveloper("");
    setSelectedDataFrom("");
    setSelectedDataTo("");
    setLoaded(false);
    ReportsServices.reportDeveloper().then((res) => {
      setDataFn(res);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (selectedInvestment !== "") {
      formData.append(["investment"], selectedInvestment);
    }
    if (selectedDeveloper !== "") {
      formData.append(["developer"], selectedDeveloper);
    }
    if (selectedParentDeveloper !== "") {
      formData.append(["parent_developer"], selectedParentDeveloper);
    }
    if (selectedDataTo !== "") {
      formData.append(["date_to"], selectedDataTo);
    }
    if (selectedDataFrom !== "") {
      formData.append(["date_from"], selectedDataFrom);
    }
    setLoaded(false);
    ReportsServices.reportDeveloper(null, formData).then((res) => {
      setData(res);
    });
  };

  return (
    <main>
      {!loaded && (
        <div className="h-screen">
          <Loader />
        </div>
      )}
      <div style={{ minHeight: "calc(80vh + 1rem)" }}>
        <Container>
          <header className="sticky top-20 py-4 lg:pt-0 lg:pb-2 lg:top-20  z-20 bg-white w-screen lg:w-full">
            <form
              onSubmit={handleSubmit}
              className={cs(
                "flex flex-col items-center  absolute w-screen lg:w-full bg-white lg:static lg:justify-start lg:border-t-0 lg:py-0 transition-all transform  lg:transform -translate-y-0 duration-500"
                // { "-translate-y-mobileClientSearchHeight": !showMobileMenu }
              )}
            >
              <div className="w-full  flex flex-col lg:flex-row items-center lg:justify-start flex-wrap">
                <Input
                  className="w-56 mx-2 mb-2 h-12 lg:mt-4"
                  label={`${t("Post date")} ${t("from")}`}
                  labelPosition={"left corner"}
                  fluid={true}
                  type="date"
                  size={"small"}
                  onChange={(e, data) => setSelectedDataFrom(data.value)}
                  value={selectedDataFrom}
                />
                <Input
                  className="w-56 mx-2 mb-2 h-12 lg:mt-4"
                  label={`${t("Post date")} ${t("to")}`}
                  labelPosition={"left corner"}
                  fluid={true}
                  type="date"
                  size={"small"}
                  onChange={(e, data) => setSelectedDataTo(data.value)}
                  value={selectedDataTo}
                />
                <Dropdown
                  className="h-12 mx-2  mb-2 lg:mt-4"
                  placeholder={t("Parent developer")}
                  fluid={false}
                  search={true}
                  selection={true}
                  onChange={(e, data) => setSelectedParentDeveloper(data.value)}
                  options={totalParentDevelopers}
                  value={selectedParentDeveloper}
                  clearable
                  selectOnBlur={false}
                />

                <Dropdown
                  className="h-12 mx-2 my-2   lg:mt-4"
                  placeholder={t("Developer")}
                  fluid={false}
                  search={true}
                  selection={true}
                  onChange={(e, data) => setSelectedDeveloper(data.value)}
                  options={totalDevelopers}
                  value={selectedDeveloper}
                  clearable
                  selectOnBlur={false}
                />

                <div className="hidden lg:flex  mb-2 lg:mt-4 ">
                  <Dropdown
                    className="h-12 mx-2"
                    placeholder={t("Investment")}
                    fluid={false}
                    search={true}
                    selection={true}
                    onChange={(e, data) => setSelectedInvestment(data.value)}
                    options={totalInvestments}
                    value={selectedInvestment}
                    clearable
                    selectOnBlur={false}
                  />
                </div>
                <div className="mx-2 justify-center items-center">
                  <button
                    className="flex items-center py-3 px-4"
                    type="button"
                    onClick={handleClearSearch}
                  >
                    <X size={14} className="mr-2" />
                    {t("Clear")}
                  </button>
                </div>
                <div className="mx-2">
                  <button
                    className="font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                    type="submit"
                    // onClick={() => setShowMobileMenu(false)}
                  >
                    {t("Search")}
                  </button>
                </div>
              </div>
            </form>
          </header>
          <div className="hidden lg:flex w-full items-center justify-center lg:justify-start">
            <div className="mx-2 mb-4">
              <h3 className="text-xl font-bold">
                {t("Search results")}:{" "}
                <span className="text-primse-green">{reportsData?.length}</span>
              </h3>
            </div>
          </div>
          {loaded && reportsData?.length !== 0 && (
            <table
              className="w-full hidden text-center lg:block border-separate"
              style={{ borderSpacing: 0 }}
            >
              <thead
                className="uppercase w-full sticky top-40 pt-8 "
                // className="uppercase text-xs w-full"
                style={{ fontSize: "0.7rem", lineFeight: "0.75rem" }}
              >
                <tr className="">
                  {/* <Th rowspan="2" className="border-t border-r border-b border-l">id</Th> */}
                  <Th
                    rowspan="2"
                    className="border-t border-r border-b border-l"
                  >
                    id
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Investment")}
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Parent developer / developer")}
                  </Th>

                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Investment created")}
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Price list updated")}
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Investment views")}
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Basket")}
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Briefcase")}
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Offers generated")}
                  </Th>
                  <Th
                    colspan="6"
                    className="text-center border-t border-r border-b"
                  >
                    {t("Requests")}
                  </Th>
                  <Th
                    colspan="3"
                    className="text-center border-t border-r border-b"
                  >
                    {t("Transactions")}
                  </Th>
                </tr>
                <tr>
                  <Th className="border-r border-b"> {t("Accepted")}</Th>
                  <Th className="border-r border-b">
                    {t("Accepted conditionally")}
                  </Th>
                  <Th className="border-r border-b"> {t("Awaiting")}</Th>
                  <Th className="border-r border-b">{t("All")}</Th>
                  <Th className="border-r border-b">{t("Rejected")}</Th>
                  <Th className="border-r border-b">{t("Rejected")} (%)</Th>
                  <Th className="border-r border-b"> {t("Open")}</Th>
                  <Th className="border-r border-b">{t("Settled")}</Th>

                  <Th className="border-r border-b">{t("Unsettled (PLN)")}</Th>
                </tr>
              </thead>
              {/* <tbody className="text-sm"> */}
              <tbody
                className="w-full"
                style={{ fontSize: "0.70rem", lineFeight: "0.75rem" }}
              >
                <tr className=" border-b h-14 hover:bg-primse-light-green cursor-pointer ">
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td>{t("Total")}</Td>

                  <Td>
                    {reportsDataTotal.investment_view
                      ? reportsDataTotal.investment_view
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.basket_count
                      ? reportsDataTotal.basket_count
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.briefcase_count
                      ? reportsDataTotal.briefcase_count
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.query_confirmed
                      ? reportsDataTotal.query_confirmed
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.query_accepted
                      ? reportsDataTotal.query_accepted
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.query_accepted_conditionally
                      ? reportsDataTotal.query_accepted_conditionally
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.query_awaiting
                      ? reportsDataTotal.query_awaiting
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.query_count
                      ? reportsDataTotal.query_count
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.query_rejected
                      ? reportsDataTotal.query_rejected
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.query_rejected_percent
                      ? Number(reportsDataTotal.query_rejected_percent).toFixed(
                          2
                        )
                      : 0}{" "}
                    %
                  </Td>
                  <Td>
                    {reportsDataTotal.transaction_open
                      ? reportsDataTotal.transaction_open
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.transaction_settled
                      ? reportsDataTotal.transaction_settled
                      : 0}
                  </Td>

                  <Td>
                    {reportsDataTotal.transaction_unsettled === 0
                      ? "0.00"
                      : reportsDataTotal.transaction_unsettled}
                  </Td>
                </tr>

                {reportsData.map((data) => {
                  return (
                    <tr
                      key={data.id}
                      className="border-b h-14 hover:bg-primse-light-green cursor-pointer "
                    >
                      <Td>{data.id}</Td>
                      <Td>{data.investment_name}</Td>
                      <Td>
                        {data.parent_developer_name !== ""
                          ? data.parent_developer_name
                          : data.developer_name}
                      </Td>
                      <Td>{data.investment_created}</Td>
                      <Td>{data.investment_price_list_updated}</Td>
                      <Td>{data.investment_view}</Td>
                      <Td>{data.basket_count}</Td>
                      <Td>{data.briefcase_count}</Td>
                      <Td>{data.query_confirmed}</Td>

                      <Td>{data.query_accepted}</Td>
                      <Td>{data.query_accepted_conditionally}</Td>
                      <Td>{data.query_awaiting}</Td>
                      <Td>{data.query_count}</Td>
                      <Td>{data.query_rejected}</Td>
                      <Td>
                        {Number(data.query_rejected_percent).toFixed(2)} %
                      </Td>

                      <Td>{data.transaction_open}</Td>
                      <Td>{data.transaction_settled}</Td>
                      <Td>
                        {data.transaction_unsettled === 0
                          ? "0.00"
                          : data.transaction_unsettled}
                      </Td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Container>
      </div>
      <Footer />
    </main>
  );
};

export default ReportDeveloper;
