import styles from "./Loader.module.css";
import cs from "classnames";
const Loader = ({ position = "fixed", opacity = "bg-opacity-60" }) => {
  return (
    <div
      className={cs(
        "top-0 left-0 z-40  w-full h-full flex items-center justify-center bg-white ",
        position,
        opacity,
        styles.container
      )}
    >
      <div className={styles.ldsRing}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default Loader;
