import React, { useState } from "react";
import { AiFillCloseCircle } from "react-icons/all";
import { ChevronLeft, ChevronRight } from "react-feather";

import { editPrice } from "../../../../utils/numbers";

import cs from "classnames";
import RealestateItem from "../MapRealestateView/RealestateItem";

const MapRealestatesCluster = ({
  realestates,
  onClosedMarker,
  setActiveScrollMap,
}) => {
  const [selectInvestment, setSelectInvestment] = useState(0);

  return (
    <MapInvestment
      allInvestments={realestates}
      selectInvestment={selectInvestment}
      onClosedMarker={onClosedMarker}
      investmentData={realestates[selectInvestment]}
      setActiveScrollMap={setActiveScrollMap}
      setSelectInvestment={setSelectInvestment}
    />
  );
};

const MapInvestment = ({
  allInvestments,
  selectInvestment,
  onClosedMarker,
  setActiveScrollMap,
  investmentData,
  setSelectInvestment,
}) => {
  return (
    <div
      onMouseEnter={() => {
        setActiveScrollMap(false);
      }}
      onMouseLeave={() => {
        setActiveScrollMap(true);
      }}
      className={cs(
        "p-5 bg-white absolute bottom-11 -left-80 shadow-md z-10 ",
        allInvestments?.length === 1 ? "pt-5" : "pt-0"
      )}
    >
      {allInvestments?.length !== 1 && (
        <div className="flex justify-center items-center">
          <button
            className="leading-5 m-2 text-primse-green px-5 cursor-pointer m-0"
            onClick={() => {
              if (selectInvestment > 0) {
                setSelectInvestment((prev) => prev - 1);
              }
            }}
          >
            <ChevronLeft />
          </button>
          <p className="text-primse-green m-0 text-lg">
            <strong>
              {selectInvestment + 1} / {allInvestments?.length}
            </strong>
          </p>
          <button
            className="leading-5 m-2 text-primse-green px-5 cursor-pointer m-0"
            onClick={() => {
              if (selectInvestment < allInvestments?.length - 1) {
                setSelectInvestment((prev) => prev + 1);
              }
            }}
          >
            <ChevronRight />
          </button>
        </div>
      )}
      <div className="flex">
        <div className="absolute right-3 top-3" data-id="exitModal">
          <AiFillCloseCircle
            onClick={() => {
              setActiveScrollMap(true);
              onClosedMarker();
            }}
            className="text-primse-gray-font cursor-pointer w-6 h-6"
            data-id="exitModal"
          />
        </div>

        {investmentData && (
          <RealestateItem data={investmentData} editPrice={editPrice} />
        )}
      </div>
    </div>
  );
};

export default MapRealestatesCluster;
