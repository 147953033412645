import InvestmentsWrapper from "../InvestmentsWrapper";
import React from "react";

const MapInvestments = ({ investmentData, investmentUuid }) => {
  return (
    <div className="py-5 md:pr-5 ">
      <div className="  ">
        <InvestmentsWrapper
          {...investmentData}
          investmentUuid={investmentUuid}
        />
      </div>
    </div>
  );
};

export default MapInvestments;
