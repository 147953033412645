import axios from "../utils/axios";

const ReportsServices = {
  reportAgency: async (e, FormData) => {
    return await axios
      .post(`/report-agency`, FormData)
      .then((res) => res?.data?.payload);
  },
  reportDeveloper: async (e, FormData) => {
    return await axios
      .post(`/report-developer`, FormData)
      .then((res) => res?.data?.payload);
  },
};

export default ReportsServices;
