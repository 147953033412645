import Moment from "react-moment";
import { useStoreState } from "easy-peasy";

const Footer = () => {
  const { version } = useStoreState((state) => state.version);
  const currentYear = new Date().getFullYear();

  return (
    <div className="flex justify-between items-center sm:px-8 py-4 bg-primse-gray text-primse-light-gray ">
      <div className="px-2 text-sm">
        <p className="p-0 m-0">{currentYear} Primse.com</p>
      </div>
      {version && (
        <div className="px-2 text-xs ">
          <p className="p-0 m-0">
            v1.
            <Moment format="MM.DD">
              {new Date(version[0].date).toISOString().split("T")[0]}
            </Moment>
          </p>
          <p className="p-0 m-0">
            up {new Date(version[0].date).toLocaleDateString()}
          </p>
        </div>
      )}
    </div>
  );
};

export default Footer;
