import { useRef, useState } from "react";
import Supercluster from "supercluster";

import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { dequal } from "dequal";

const useSupercluster = ({ points, bounds, zoom, options }) => {
  const superclusterRef = useRef();
  const pointsRef = useRef();
  const [clusters, setClusters] = useState([]);
  const zoomInt = Math.round(zoom);

  useDeepCompareEffectNoCheck(() => {
    if (!superclusterRef.current || !dequal(pointsRef.current, points)) {
      superclusterRef.current = new Supercluster(options);
      superclusterRef.current.load(points);
    }

    if (bounds) {
      setClusters(superclusterRef.current.getClusters(bounds, zoomInt));
    }

    pointsRef.current = points;

    return () => {
      superclusterRef.current = null;
    };
  }, [points, bounds, zoomInt, options]);

  return { clusters, supercluster: superclusterRef.current };
};

export default useSupercluster;
