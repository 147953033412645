import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import UserServices from "../../../services/UserServices";
import Loader from "../../../components/Loader";


const EditUserModalHeader = () => {

    const { t } = useTranslation();
    const { data: profileData, isLoading } = useQuery(
        ["user"],
        UserServices.getUserProfile);

    return (
        <>
            {
                profileData && <div className="items-center font-bold text-xl">
                    {`${t("Account settings")}:`}
                    <div>{`${profileData.last_name} ${profileData.first_name}`}</div>
                </div>
            }
            {
                isLoading && <Loader />
            }
        </>
    )
}


export default EditUserModalHeader;



