import React, { useEffect, useState } from "react";
import { Form, Message, Modal, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import RequestsServices from "../../services/RequestsServices";
import { AiFillCloseCircle } from "react-icons/all";
import { toast } from "react-toastify";
import cs from "classnames";
import Loader from "../../components/Loader";

function UpdateRequestModal({
  request_uuid,
  term_uuid,
  text,
  setModalOpen,
  modalOpen,
  handleRefresh,
}) {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(
    "Fill in the required conditions to submit the request"
  );

  useEffect(() => {
    setOpen(modalOpen);
  }, [modalOpen]);

  const { t } = useTranslation();

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    if (!file) {
      toast.error(
        `${t("The file could not be uploaded")} ${t(
          "Fill in the required conditions to submit the request"
        )}`
      );
      setErrorMessage("Fill in the required conditions to submit the request");
      setError(true);

      setLoading(false);
      return;
    }

    formData.append("term_uuid", term_uuid);
    formData.append("term_file", file);

    RequestsServices.updateRequestDocument(request_uuid, formData)
      .then((res) => {
        if (res?.status === 200) {
          toast.success(`${t("The file has been uploaded successfully")}`);
          handleRefresh(true);
        } else if (res?.response?.status === 500) {
          if (
            res?.response?.data?.error_message ===
            "It is not possible to upload attachments to applications in which the developer has made a decision"
          ) {
            toast.error(
              `${t(
                "It is not possible to upload attachments to requests..."
              )}   `
            );
            setErrorMessage(
              "It is not possible to upload attachments to requests..."
            );
            setError(true);
          } else {
            toast.error(
              `${t("The file could not be uploaded")} ${t(
                `${res?.response?.data?.error_message}`
              )}`
            );
            setErrorMessage(
              "Fill in the required conditions to submit the request"
            );
            setError(true);
          }
        }
      })
      .then(() => setModalOpen(false))
      .catch((err) => {
        if (
          err?.response?.data?.error_message ===
          "It is not possible to upload attachments to requests in which the developer has made a decision"
        ) {
          toast.error(
            `${t("It is not possible to upload attachments to requests...")}   `
          );
          setErrorMessage(
            "It is not possible to upload attachments to requests..."
          );
          setError(true);
        } else {
          toast.error(
            `${t("The file could not be uploaded")} ${t(
              `${err?.response?.data?.error_message}`
            )}`
          );
          setErrorMessage(
            "Fill in the required conditions to submit the request"
          );
          setError(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal open={open}>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between">
          <h3 className="text-xl font-bold">{t("Update a request")}</h3>
          <AiFillCloseCircle
            onClick={() => setModalOpen(false)}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Table celled structured textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  {t("Required attachments")}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell colSpan="2">
                  {t("Description")}
                </Table.HeaderCell>
                <Table.HeaderCell className="w-44">
                  {t("Status")}
                </Table.HeaderCell>
                <Table.HeaderCell className="w-44">
                  {t("File")}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row error={error}>
                <Table.Cell colSpan="2">
                  <span className="font-bold lg:hidden">
                    {t("Description")}
                  </span>
                  {text}
                </Table.Cell>
                <Table.Cell className="w-44">
                  <span>{file ? file.name : `${t("No file")}`}</span>
                </Table.Cell>
                <Table.Cell className="w-44">
                  <Form.Field className="flex justify-center">
                    <label className="w-40 uppercase font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray">
                      {t("Choose")}
                      <input
                        type="file"
                        onChange={(event) => {
                          setFile(event.target.files[0]);
                          setError(false);
                        }}
                        hidden
                      />
                    </label>
                  </Form.Field>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <div className="p-4 flex justify-end">
            <button
              className="leading-5 mx-2 w-40 uppercase font-bold text-white bg-primse-yellow py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
              onClick={() => setModalOpen(false)}
            >
              {t("Close")}
            </button>
            <button
              disabled={error}
              onClick={(e) => onSubmit(e)}
              className={cs(
                "leading-5 mx-2 w-40 uppercase font-bold text-white py-3 px-5 rounded-sm",
                {
                  "bg-primse-green transition-all hover:bg-primse-gray": !error,
                },
                { "bg-primse-gray cursor-default": error }
              )}
            >
              {t("Send")}
            </button>
          </div>
          {error && <Message negative={true} content={t(errorMessage)} />}
        </Form>
        {loading && <Loader />}
      </Modal.Content>
    </Modal>
  );
}

export default UpdateRequestModal;
