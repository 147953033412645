import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import { useTranslation } from "react-i18next";
import BasketContainer from "./Basket";
import Loader from "../../components/Loader";
import { ArrowRight } from "react-feather";

const EmptyBasketComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className=" flex flex-col items-center mt-20">
      <div className="uppercase font-medium text-center pt-4">
        {t("There are no objects in the cart...")}
      </div>
      <button
        className="leading-5 w-46 m-4 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
        onClick={() => history.push(`/`)}
      >
        <div className="flex">
          {t("Go to the search engine")}
          <ArrowRight className="text-white ml-2 " size={20} />
        </div>
      </button>
    </div>
  );
};

const Basket = () => {
  const [data, setData] = useState(null);
  const { basketsList, basketsItems } = useStoreState((state) => state.baskets);
  const location = useLocation();

  const handleSetData = (basket_item_uuid) => {
    setData(null);

    if (basket_item_uuid) {
      setTimeout(() => {
        const currentBasket = basketsList.filter(
          (el) => el.uuid === location.pathname.slice(8)
        );
        const listItems = basketsItems[location.pathname.slice(8)]
          ?.filter((el) => el.uuid !== basket_item_uuid)
          ?.filter((el) => {
            if (
              !el?.realestate_uuid &&
              el?.investment_name === "Rynek wtórny"
            ) {
              return null;
            } else return el;
          });
        const currentBasketAndItems = currentBasket.map((el) => {
          return { ...el, basketItems: listItems };
        });
        if (currentBasketAndItems?.length > 0) {
          setData(currentBasketAndItems[0]);
        } else {
          setData({ basketItems: [] });
        }
      }, 300);
    } else {
      setTimeout(() => {
        const currentBasket = basketsList.filter(
          (el) => el.uuid === location.pathname.slice(8)
        );
        const listItems = basketsItems[location.pathname.slice(8)]?.filter(
          (el) => {
            if (
              !el?.realestate_uuid &&
              el?.investment_name === "Rynek wtórny"
            ) {
              return null;
            } else return el;
          }
        );
        const currentBasketAndItems = currentBasket.map((el) => {
          return { ...el, basketItems: listItems };
        });
        if (currentBasketAndItems?.length > 0) {
          setData(currentBasketAndItems[0]);
        } else {
          setData({ basketItems: [] });
        }
      }, 300);
    }
  };

  const handleRefresh = (basket_item_uuid) => {
    handleSetData(basket_item_uuid);
  };

  useEffect(() => {
    handleSetData();
  }, [location.key]); 
  if (!data) return <Loader />;

  if (data) {
    return data?.basketItems?.length > 0 ? (
      <BasketContainer
        currentBasket={data}
        EmptyBasketComponent={EmptyBasketComponent}
        handleRefresh={handleRefresh}
      />
    ) : (
      <EmptyBasketComponent />
    );
  }
};

export default Basket;
