import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import AddClient from "../../AddClient";
import BriefcaseSearch from "../BriefcaseSearch";
import cs from "classnames";
import { useStoreState } from "easy-peasy";

const BriefcaseModal = ({ selectDefaultModal }) => {

  const [newClient, setNewClient] = useState(false);
  const { t } = useTranslation();

    const userAgencyType = useStoreState((state) => state.auth.user?.agencyType);
  return (
    <>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between">
          <div className="flex">
            <button
              className={cs(
                "leading-5 m-2 text-xs lg:text-base uppercase font-bold text-white py-2 px-5 rounded-sm transition-all hover:bg-primse-gray",
                { "bg-primse-yellow": !newClient },
                { "bg-primse-green": newClient }
              )}
              onClick={() => setNewClient(false)}
            >
              {t("Search")}
            </button>
            {userAgencyType !== "Metrohouse office" && (
              <button
                className={cs(
                  "leading-5 m-2 text-xs lg:text-base uppercase font-bold text-white py-2 px-5 rounded-sm transition-all hover:bg-primse-gray",
                  { "bg-primse-yellow": newClient },
                  { "bg-primse-green": !newClient }
                )}
                onClick={() => setNewClient(true)}
              >
                {t("Add a client")}
              </button>
            )}
          </div>
          <AiFillCloseCircle
            onClick={() => selectDefaultModal(false)}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        {newClient ? (
          <AddClient
            selectDefaultModal={selectDefaultModal}
          />
        ) : (
          <BriefcaseSearch
            selectDefaultModal={selectDefaultModal}
          />
        )}
      </Modal.Content>
    </>
  );
};

export default BriefcaseModal;
