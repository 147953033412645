import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { editPrice } from "../../../../utils/numbers";

const PriceComponent = ({
  price,
  pricemkw,
  ask_for_price,
  is_in_promotion,
  promotion_price,
  r_uuid,
  i_uuid,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="bg-primse-green py-5 px-8 mb-8 rounded-sm text-white flex flex-col items-center ">
      <p className="text-4xl font-bold mb-0 pb-2 ">
        {price && ask_for_price !== "1" ? (
          `${editPrice(price)} PLN`
        ) : (
          <span
            className="cursor-pointer"
            onClick={() => {
              history.push(`/questions?r_uuid=${r_uuid}&i_uuid=${i_uuid}`);
            }}
          >
            {t("Ask about price")}
          </span>
        )}
      </p>
      {is_in_promotion === "1" && promotion_price && ask_for_price !== "1" && (
        <p className="text-3xl font-bold mb-0 pb-2 line-through">
          {editPrice(promotion_price)} PLN
        </p>
      )}
      <p className="text-base  ">
        {pricemkw && ask_for_price !== "1" && (
          <>
            {editPrice(pricemkw)} PLN / m<sup>2</sup>
          </>
        )}
      </p>
    </div>
  );
};

export default PriceComponent;
