import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";
import Loader from "../../../../../../components/Loader";

import { toast } from "react-toastify";
import QuestionsService from "../../../../../../services/QuestionsService";

const ReplyQuestionContent = ({
  subject_name,
  investment_name,
  realestate_name,
  reply: replyProp,
  type,
  uuid,
  closedModal,
  handleRefresh,
}) => {
  const [reply, setReply] = useState("");
  const [loading, setLoading] = useState(false);

  const [errorReply, setErrorReply] = useState(false);

  const { t } = useTranslation();

  const handleReply = (event) => {
    setErrorReply(false);
    let value = event.target.value;
    setReply(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (type === "reply" && reply !== "") {
      setLoading(true);

      QuestionsService.questionsReply({
        question_uuid: uuid,
        reply: reply,
      }).then((res) => {
        if (res.status === 201) {
          toast.success(t("Answered"));
          setLoading(false);
          closedModal();
          handleRefresh(true);
        } else {
          toast.error(t("Wystąpił błąd podczas odpowiedzi"));
          setLoading(false);
        }
      });
    } else {
      const errors = [];
      if (reply === "") {
        setErrorReply(true);
        errors.push("Reply");
      }
      toast.error(
        <div>
          {t("None of the fields can be empty")}: <br />
          {errors.map((el, index) => (
            <div key={index}>
              - {t(el)} <br />
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <>
      <Form className="flex items-center flex-wrap" onSubmit={handleSubmit}>
        <div className="px-2 w-full flex">
          <label className="font-bold p-0 mr-2 my-2">{t("Subject")}: </label>
          <p className="mx-0 my-2">
            {subject_name ? subject_name : t("No data")}
          </p>
        </div>
        <div className="px-2 w-full flex">
          <label className="font-bold p-0 mr-2 my-2">{t("Investment")}: </label>
          <p className="mx-0 my-2">
            {investment_name ? investment_name : t("No data")}
          </p>
        </div>
        <div className="px-2 w-full flex">
          <label className="font-bold p-0 mr-2 my-2">
            {t("Realestate name")}:
          </label>
          <p className="mx-0 my-2">
            {realestate_name ? realestate_name : t("No data")}
          </p>
        </div>
        {type === "reply" ? (
          <Form.TextArea
            label={t("Reply")}
            placeholder={t("Reply")}
            value={reply}
            className="w-full p-2"
            onChange={handleReply}
            error={errorReply}
          />
        ) : (
          <div className="px-2 w-full flex">
            <label className="font-bold p-0 mr-2 my-2">{t("Reply")}:</label>
            <p className="mx-0 my-2">{replyProp ? replyProp : t("No data")}</p>
          </div>
        )}
        {type === "reply" && (
          <div className="w-full flex justify-end">
            <button
              className="m-2 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
              type="submit"
            >
              {t("Send")}
            </button>
          </div>
        )}
        {loading && <Loader />}
      </Form>
    </>
  );
};

export default ReplyQuestionContent;
