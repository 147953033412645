import React, { useState } from "react";
import { Document, pdfjs, Page } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PDFViewer = ({ url }) => {
  const [numPages, setNumPages] = useState(0);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  function removeTextLayerOffset() {
    const textLayers = document.querySelector(".react-pdf__Page__textContent");
    if (textLayers) {
      textLayers.remove();
    }

    const canvasLayers = document.querySelectorAll(".react-pdf__Page__canvas");

    canvasLayers.forEach((layer) => {
      const { classList } = layer;

      classList.add("mx-auto");
    });
  }

  return (
    <div className="mx-auto h-full">
      <Document
        loading={
          <div className="h-64 text-center">
            <p className="text-2xl">Ładowanie...</p>
          </div>
        }
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <>
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              onLoadSuccess={removeTextLayerOffset}
            />
            <p className="text-center">
              Strona {index + 1}/{numPages}
            </p>
          </>
        ))}
      </Document>
    </div>
  );
};

export default PDFViewer;
