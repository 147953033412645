import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const LoadingPage = () => {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (location.search) {
      history.replace(`/${location.search}`);
    } else {
      history.replace("/");
    }
  }, [location.search]);
  return null;
};

export default LoadingPage;
