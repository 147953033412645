import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import AddClient from "../../../components/AddClient";
import cs from "classnames";
import SearchClient from "../SearchClient";

const ClientsModal = ({ handleRefresh }) => {
  const [open, setOpen] = useState(false);
  const [newClient, setNewClient] = useState(false);
  const { t } = useTranslation();
  function handleClose() {
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size={"large"}
      trigger={
        <button className="leading-5 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-3 px-10 rounded-sm transition-all hover:bg-primse-gray">
          {t("Add a client")}
        </button>
      }
    >
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between">
          <div className="flex">
            <button
              className={cs(
                "leading-5 m-2 text-xs lg:text-base uppercase font-bold text-white py-2 px-5 rounded-sm transition-all hover:bg-primse-gray",
                { "bg-primse-yellow": !newClient },
                { "bg-primse-green": newClient }
              )}
              onClick={() => setNewClient(false)}
            >
              {t("Search")}
            </button>
            <button
              className={cs(
                "leading-5 m-2 text-xs lg:text-base uppercase font-bold text-white py-2 px-5 rounded-sm transition-all hover:bg-primse-gray",
                { "bg-primse-yellow": newClient },
                { "bg-primse-green": !newClient }
              )}
              onClick={() => setNewClient(true)}
            >
              {t("Add a client")}
            </button>
          </div>
          <AiFillCloseCircle
            onClick={() => setOpen(false)}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        {newClient ? (
          <AddClient handleClose={handleClose} handleRefresh={handleRefresh} />
        ) : (
          <SearchClient handleClose={handleClose} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ClientsModal;
