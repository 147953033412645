import { Checkbox, Dropdown, Input, TextArea } from "semantic-ui-react";
import { Controller } from "react-hook-form";
import React from "react";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import { regexStringToNumberPrice } from "../../../../utils/numbers";

const Inputss = ({
  name,
  fluid,
  type,
  readOnly,
  value,
  onBlur,
  onChange,
  disabled,
  amount,
  handleAmount,
  reqex,
}) => {
  return (
    <input
      fluid={fluid}
      type={type}
      readOnly={readOnly}
      value={value}
      onBlur={(e) => {
        onBlur(e);
        if (amount) {
          onChange(regexStringToNumberPrice(e.target.value, true));
          setTimeout(() => {
            handleAmount(name);
          }, 200);
        }
        if (reqex) {
          onChange(regexStringToNumberPrice(e.target.value, true));
        }
      }}
      onChange={(e) => {
        onChange(e);
        if (amount) {
          // handleAmount(name);
        }
      }}
      disabled={disabled}
      className="w-56 mx-2 h-12"
      style={{ textAlign: `${amount && "right"}` }}
      onInput={(e) => {
        if (amount) {
          const newValue = regexStringToNumberPrice(e.target.value, false);

          e.target.value = newValue;
        }
        if (reqex) {
          const newValue = regexStringToNumberPrice(e.target.value, false);
          e.target.value = newValue;
        }
      }}
    />
  );
};

export const TransactionInput = ({
  control,
  name,
  label,
  type,
  defaultValue,
  disabled = false,
  amount,
  handleAmount,
  reqex,
  className,
  itemsCenter,
  classNameLabel,
  required,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={
        amount ? regexStringToNumberPrice(defaultValue) : defaultValue
      }
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <div
            className={cs(
              "flex justify-between my-2",
              {
                "w-96": label,
              },
              itemsCenter ? "items-center" : "items-baseline",
              className
            )}
          >
            {label && (
              <label
                className={cs(`${!disabled && "font-bold"}`, classNameLabel)}
              >
                {t(`${label}`)}
                {required && <span className="text-red-600">*</span>}
              </label>
            )}

            <Input
              className={cs("mx-2 h-12", amount ? "w-full" : "w-56 ")}
              fluid={false}
              type={type}
              readOnly={disabled}
              value={value}
              onBlur={onBlur}
              disabled={disabled}
              reqex={reqex}
            >
              <Inputss
                name={name}
                fluid={false}
                type={type}
                readOnly={disabled}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                disabled={disabled}
                amount={amount}
                handleAmount={handleAmount}
                reqex={reqex}
              />
            </Input>
          </div>
        );
      }}
    />
  );
};

export const TransactionDropdown = ({
  control,
  name,
  label,
  size,
  options,
  defaultValue,
  handleTax,
  titleTax,
  allowAdditionsProps = false,
  disabled,
  taxFactoring = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      defaultValue={defaultValue}
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <div
            className={cs(
              "flex items-center justify-between my-2",
              { "w-96": label },
              { "w-full": size === "small" }
            )}
          >
            {label && <label>{t(`${label}`)}</label>}
            <Dropdown
              disabled={disabled}
              className="mx-2 h-12"
              style={{ display: "flex", alignItems: "center" }}
              search={true}
              selection={true}
              fluid={size === "small"}
              size="small"
              selectOnBlur={false}
              value={value}
              onBlur={onBlur}
              allowAdditions={allowAdditionsProps ? true : false}
              additionLabel="Dodaj: "
              options={options}
              onChange={(e, data) => {
                onChange(data.value);
                if (handleTax) {
                  handleTax(titleTax, data.value, data);
                }
                if (name === "commission_has_factoring") {
                  taxFactoring(data.value);
                }
              }}
              noResultsMessage={t("Real estate not found")}
            />
          </div>
        );
      }}
    />
  );
};

export const TransactionTextArea = ({ control, name, label }) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <div
            className={cs("flex items-baseline justify-between my-2", {
              "w-96": label,
            })}
          >
            {label && <label>{t(`${label}`)}</label>}
            <TextArea
              style={{
                width: "14rem",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
          </div>
        );
      }}
    />
  );
};

export const TransactionCheckbox = ({
  control,
  name,
  label,
  defaultValue,
  handleCheckbox,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      defaultValue={defaultValue === "1"}
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <div className="flex items-baseline justify-between my-2">
            {label && <label>{t(`${label}`)}</label>}
            <Checkbox
              disabled={disabled}
              checked={value}
              value={value}
              onBlur={onBlur}
              onChange={(e, i) => {
                onChange(i.checked);
                handleCheckbox(name);
              }}
            />
          </div>
        );
      }}
    />
  );
};

export const TransactionInputFiles = ({ control, name, setSelectFile }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur } }) => {
        return (
          <label className="w-40 flex justify-center uppercase font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray cursor-pointer">
            {t("Choose")}
            <input
              type="file"
              accept="application/pdf"
              onChange={(e) => {
                onChange(e.target.files[0]);
                setSelectFile(e.target.files[0]);
              }}
              onBlur={onBlur}
              hidden
            />
          </label>
        );
      }}
    />
  );
};
