import React, { useState, useMemo } from "react";
import { Form, Message, Modal, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useStoreState } from "easy-peasy";
import Loader from "../../../components/Loader";
import cs from "classnames";
import RequestsServices from "../../../services/RequestsServices";
import DocumentsServices from "../../../services/DocumentsServices";
import { actions } from "../../../store";

function NewRequestModalContent({
  investment_uuid,
  clientUuid,
  developerName,
  investmentName,
  setModalOpen,
  handleRefresh,
}) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});
  const [requiredError, setRequiredError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Fill in the required conditions to submit the request"
  );

  const { t } = useTranslation();

  const { data, isLoading } = useQuery(
    `request-form-${investment_uuid}`,
    RequestsServices.getRequestForm(investment_uuid)
  );

  const { briefcaseClient } = useStoreState((state) => state.briefcase);

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    setLoading(true);

    formData.append("client", clientUuid);
    Object.entries(file)?.forEach(([fileName, fileContent]) => {
      formData.append(`${fileName}`, fileContent);
    });

    RequestsServices.sendRequestDocuments(investment_uuid, formData)
      .then((res) => {
        if (res?.response?.status === 500) {
          if (
            res?.response?.data?.error_message ===
            "Client has already been registered for the investment"
          ) {
            setErrorMessage(
              "Client has already been registered for the investment"
            );
            toast.error(
              `${t("Client has already been registered for the investment")}`
            );
            setLoading(false);
            setRequiredError(true);
            actions.modals.openRequestModal(false);
            setModalOpen(false);
          } else if (
            res?.response?.data?.error_message ===
            "To send query you must add the required files"
          ) {
            setErrorMessage(t("You must add all required files"));
            toast.error(`${t("You must add all required files")}`);
            setLoading(false);
            setRequiredError(true);
          } else {
            setErrorMessage(
              "Fill in the required conditions to submit the request"
            );
            toast.error(`${t("Request could not be sent")}`);
            setLoading(false);
            setRequiredError(true);
            actions.modals.openRequestModal(false);
            setModalOpen(false);
          }
        } else {
          toast.success(`${t("Request sent successfully")}`);
          setLoading(false);
          setLoading(false);
          handleRefresh(true);
          actions.modals.openRequestModal(false);
          setModalOpen(false);
        }
      })

      .catch((error) => {
        if (
          error?.response?.status === 500 &&
          error?.response?.data?.error_message ===
            "Client has already been registered for the investment"
        ) {
          setErrorMessage(
            "Client has already been registered for the investment"
          );
          toast.error(
            `${t("Client has already been registered for the investment")}`
          );
          setLoading(false);
          setRequiredError(true);
          actions.modals.openRequestModal(false);
        } else {
          setErrorMessage(
            "Fill in the required conditions to submit the request"
          );
          toast.error(`${t("Request could not be sent")}`);
          setLoading(false);
          setRequiredError(true);
          actions.modals.openRequestModal(false);
        }
      });
  };

  const requiredForm = useMemo(() => {
    let requiredForm = false;

    if (data && data.form) {
      // eslint-disable-next-line no-unused-vars
      for (const [value, label] of Object.entries(data.form.elements)) {
        if (label.required) requiredForm = true;
      }
    }

    return requiredForm;
  }, [data]);

  const getDeadlineDate = (name, data, fieldInfo) => {
    if (!name || !data || !fieldInfo) return "";
    const parseName = fieldInfo?.required ? "required" : "additional";
    const sliceName = name?.slice(10);
    const deadline = data?.payload?.term?.[parseName]?.[sliceName]?.deadline;

    if (deadline) return deadline;
    return "";
  };

  return (
    <Modal.Content
      style={{ maxHeight: "75vh", overflowY: "auto" }}
      className="overflow-overweight"
    >
      {briefcaseClient && (
        <h4 className="font-medium">
          {t("Client")} :{" "}
          <span className="font-bold">
            {briefcaseClient.first_name} {briefcaseClient.last_name}
          </span>
        </h4>
      )}

      {data?.form?.elements ? (
        <Form>
          <Table celled structured textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="2">
                  {t("Developer")}
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="2">
                  {t("Investment")}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell colSpan="2">{developerName}</Table.Cell>
                <Table.Cell colSpan="2">{investmentName}</Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  className="border-t border-primse-green"
                  colSpan="4"
                >
                  {t("Comments")}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Row>
              <Table.Cell colSpan="4">{data?.payload?.comment}</Table.Cell>
            </Table.Row>
          </Table>
          <Table celled structured textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  {t("Required attachments")}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell colSpan="2">
                  {t("Description")}
                </Table.HeaderCell>
                <Table.HeaderCell className="w-44">
                  {t("Status")}
                </Table.HeaderCell>
                <Table.HeaderCell className="w-44">
                  {t("File")}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!requiredForm && (
                <Table.Cell colSpan="4">
                  {t("No required attachments")}
                </Table.Cell>
              )}
              {data &&
                data.form &&
                Object.entries(data.form.elements).map(
                  ([filedName, fieldInfo]) =>
                    fieldInfo.required === true && (
                      <Table.Row
                        key={filedName}
                        error={
                          requiredError &&
                          !Object.keys(file).includes(filedName)
                        }
                      >
                        <Table.Cell colSpan="2">
                          <span className="font-bold lg:hidden">
                            {t("Description")}
                          </span>
                          <div className="flex flex-col items-center">
                            {fieldInfo.label}
                            {fieldInfo.attr.pattern.doc_uuid && (
                              <strong
                                onClick={() => {
                                  DocumentsServices.getDocument({
                                    document_uuid:
                                      fieldInfo.attr.pattern.doc_uuid,
                                    file_name: fieldInfo.attr.pattern.name,
                                  })
                                    .then(() =>
                                      toast.success(
                                        `${t("Document download started")}`
                                      )
                                    )
                                    .catch(() =>
                                      toast.error(
                                        `${t(
                                          "The document could not be downloaded"
                                        )}`
                                      )
                                    );
                                }}
                                className="cursor-pointer text-sm"
                              >
                                {`${t("Download model")}: ${
                                  fieldInfo.attr.pattern.name
                                }`}
                              </strong>
                            )}
                          </div>
                        </Table.Cell>
                        <Table.Cell className="w-44">
                          <span>
                            {file[filedName]?.name
                              ? file[filedName].name
                              : `${t("No file")}`}
                          </span>
                        </Table.Cell>
                        <Table.Cell className="w-44">
                          <Form.Field required>
                            <label className="w-40 uppercase font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray cursor-pointer">
                              {t("Choose")}
                              <input
                                type="file"
                                onChange={(event) => {
                                  setFile((prevState) => ({
                                    ...prevState,
                                    [filedName]: event.target.files[0],
                                  }));
                                  setRequiredError(false);
                                  toast.success(
                                    `${t(
                                      "The file has been added successfully"
                                    )}`
                                  );
                                }}
                                hidden
                              />
                            </label>
                          </Form.Field>
                        </Table.Cell>
                      </Table.Row>
                    )
                )}
            </Table.Body>
          </Table>
          <Table celled structured textAlign="center">
            <Table.Header className="border-primse-green">
              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  {t("Additional terms")}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>{t("Description")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Deadline")}</Table.HeaderCell>
                <Table.HeaderCell className="w-44">
                  {t("Status")}
                </Table.HeaderCell>
                <Table.HeaderCell className="w-44">
                  {t("File")}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data &&
                data.form &&
                Object.entries(data.form.elements).map(
                  ([fieldName, fieldInfo]) =>
                    fieldInfo.required === false && (
                      <Table.Row key={fieldName}>
                        <Table.Cell>
                          <div className="flex flex-col items-center">
                            {fieldInfo.label}
                            {fieldInfo.attr.pattern.doc_uuid && (
                              <strong
                                onClick={() => {
                                  DocumentsServices.getDocument({
                                    document_uuid:
                                      fieldInfo.attr.pattern.doc_uuid,
                                    file_name: fieldInfo.attr.pattern.name,
                                  })
                                    .then(() =>
                                      toast.success(
                                        `${t("Document download started")}`
                                      )
                                    )
                                    .catch(() =>
                                      toast.error(
                                        `${t(
                                          "The document could not be downloaded"
                                        )}`
                                      )
                                    );
                                }}
                                className="cursor-pointer text-sm"
                              >
                                {`${t("Download model")}: ${
                                  fieldInfo.attr.pattern.name
                                }`}
                              </strong>
                            )}
                          </div>
                        </Table.Cell>
                        <Table.Cell>
                          {getDeadlineDate(fieldName, data, fieldInfo)}
                        </Table.Cell>
                        <Table.Cell className="w-44">
                          <span>
                            {file[fieldName]?.name
                              ? file[fieldName].name
                              : `${t("No file")}`}
                          </span>
                        </Table.Cell>
                        <Table.Cell className="w-44">
                          <Form.Field>
                            <label className="w-40 uppercase font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray cursor-pointer">
                              {t("Choose")}
                              <input
                                type="file"
                                onChange={(event) => {
                                  setFile((prevState) => ({
                                    ...prevState,
                                    [fieldName]: event.target.files[0],
                                  }));
                                  toast.success(
                                    `${t(
                                      "The file has been added successfully"
                                    )}`
                                  );
                                }}
                                hidden
                              />
                            </label>
                          </Form.Field>
                        </Table.Cell>
                      </Table.Row>
                    )
                )}
            </Table.Body>
          </Table>
          <div className="p-4 flex justify-end">
            <button
              className="leading-5 mx-2 w-40 uppercase font-bold text-white bg-primse-yellow py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
              onClick={() => {
                setModalOpen(false);
                actions.modals.openRequestModal(false);
              }}
            >
              {t("Close")}
            </button>
            <button
              disabled={requiredError}
              onClick={(e) => onSubmit(e)}
              className={cs(
                "leading-5 mx-2 w-40 uppercase font-bold text-white py-3 px-5 rounded-sm",
                {
                  "bg-primse-green transition-all hover:bg-primse-gray": !requiredError,
                },
                { "bg-primse-gray cursor-default": requiredError }
              )}
            >
              {t("Send")}
            </button>
          </div>
          {requiredError && (
            <Message negative={true} content={t(errorMessage)} />
          )}
        </Form>
      ) : (
        <div>
          {developerName && investmentName && data?.payload?.comment ? (
            <div>
              <Form>
                <Table celled structured textAlign="center">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2">
                        {t("Developer")}
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan="2">
                        {t("Investment")}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell colSpan="2">{developerName}</Table.Cell>
                      <Table.Cell colSpan="2">{investmentName}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        className="border-t border-primse-green"
                        colSpan="4"
                      >
                        {t("Comments")}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Row>
                    <Table.Cell colSpan="4">
                      {data?.payload?.comment}
                    </Table.Cell>
                  </Table.Row>
                </Table>

                <Table celled structured textAlign="center">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="4">
                        {t("Required attachments")}
                      </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell colSpan="2">
                        {t("Description")}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="w-44">
                        {t("Status")}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="w-44">
                        {t("File")}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Cell colSpan="4">
                      {t("No required attachments")}
                    </Table.Cell>
                  </Table.Body>
                </Table>
                <Table celled structured textAlign="center">
                  <Table.Header className="border-primse-green">
                    <Table.Row>
                      <Table.HeaderCell colSpan="4">
                        {t("Additional terms")}
                      </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell>{t("Description")}</Table.HeaderCell>
                      <Table.HeaderCell>{t("Deadline")}</Table.HeaderCell>
                      <Table.HeaderCell className="w-44">
                        {t("Status")}
                      </Table.HeaderCell>
                      <Table.HeaderCell className="w-44">
                        {t("File")}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Cell colSpan="4">
                      {t("No additional attachments")}
                    </Table.Cell>
                  </Table.Body>
                </Table>
              </Form>
              <div className="p-4 flex justify-end">
                <button
                  className="leading-5 mx-2 w-40 uppercase font-bold text-white bg-primse-yellow py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
                  onClick={() => {
                    actions.modals.openRequestModal(false);
                    setModalOpen(false);
                  }}
                >
                  {t("Close")}
                </button>
                <button
                  disabled={requiredError}
                  onClick={(e) => onSubmit(e)}
                  className={cs(
                    "leading-5 mx-2 w-40 uppercase font-bold text-white py-3 px-5 rounded-sm",
                    {
                      "bg-primse-green transition-all hover:bg-primse-gray": !requiredError,
                    },
                    { "bg-primse-gray cursor-default": requiredError }
                  )}
                >
                  {t("Send")}
                </button>
              </div>
            </div>
          ) : (
            <>
              {t("There are no documents for a given investment")}
              <div className="p-4 flex justify-end">
                <button
                  className="leading-5 mx-2 w-40 uppercase font-bold text-white bg-primse-yellow py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
                  onClick={() => {
                    setModalOpen(false);
                    actions.modals.openRequestModal(false);
                  }}
                >
                  {t("Close")}
                </button>
                <button
                  disabled={requiredError}
                  onClick={(e) => onSubmit(e)}
                  className={cs(
                    "leading-5 mx-2 w-40 uppercase font-bold text-white py-3 px-5 rounded-sm",
                    {
                      "bg-primse-green transition-all hover:bg-primse-gray": !requiredError,
                    },
                    { "bg-primse-gray cursor-default": requiredError }
                  )}
                >
                  {t("Send")}
                </button>
              </div>
            </>
          )}
        </div>
      )}
      {(loading || isLoading) && <Loader />}
    </Modal.Content>
  );
}

export default NewRequestModalContent;
