import { Popup } from "semantic-ui-react";
import cs from "classnames";
import { useTranslation } from "react-i18next";

const PopupTrigger = (rest) => {
  return <PopupView {...rest} />;
};

const PopupView = ({
  children,
  openReplyModal,
  status_name,
  openDetailsModal,
  userPrimaryRole,
}) => {
  const { t } = useTranslation();

  return (
    <Popup trigger={children} flowing on="click" position={"bottom center"}>
      <div className={cs("bg-white  text-sm font-normal ")}>
        <div className="border-b text-center">
          <div
            className="p-3 cursor-pointer hover:text-primse-green"
            onClick={() => openDetailsModal(true)}
          >
            <strong>{t("Details")}</strong>
          </div>
        </div>
        {userPrimaryRole !== "Doradca" &&
          status_name === "Waiting for developer answer" && (
            <div className="border-b text-center">
              <div
                className="p-3 cursor-pointer hover:text-primse-green  "
                onClick={() => openReplyModal(true)}
              >
                <strong>{t("Reply")}</strong>
              </div>
            </div>
          )}
      </div>
    </Popup>
  );
};

export default PopupTrigger;
