import _axios from "axios";
import AuthService from "../services/AuthServices";
import * as Sentry from "@sentry/react";

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const axios = _axios.create({
  baseURL: API_URL,
});
axios.defaults.headers.common["Api-Key"] = API_KEY;
axios.defaults.headers.common["Content-Type"] = `application/json`;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.status === 403 &&
      error?.response?.data?.error_message === "Invalid Token" &&
      error?.response?.config?.url !== "/logout"
    ) {
      AuthService.logOut();
      return error;
    }
    Sentry.setExtra("error", error);
    Sentry.setExtra("response", error?.response);
    if (error?.response?.data) {
      Sentry.setExtra("data", error?.response?.data);
    }
    if (error?.response?.data?.error_message) {
      Sentry.setExtra("error_message", error?.response?.data?.error_message);
    }
    if (error?.response?.data?.form) {
      Sentry.setExtra("form", error?.response?.data?.form);
    }
    if (error?.response?.status === 410) {
      Sentry.captureMessage("INFO: Status 410 - GONE");
    }
    if (error?.response?.data?.error_message === "Not Found") {
      Sentry.captureMessage("INFO: Not Found");
    } else if (
      error?.response?.status === 403 &&
      error?.response?.data?.error_message === "Invalid Auth"
    ) {
      Sentry.captureMessage("INFO: Status 403 - Invalid Auth");
    } else if (
      error?.response?.status === 403 &&
      error?.response?.data?.error_message === "Invalid Token" &&
      error?.response?.config?.url !== "/logout"
    ) {
      Sentry.captureMessage("INFO: Status 403 - Invalid Token");
    } else if (
      error?.response?.status === 403 &&
      error?.response?.statusText === "Forbidden" &&
      error?.response?.config?.url !== "/logout"
    ) {
      Sentry.captureMessage("INFO: Status 403 - Forbidden");
    } else if (
      error?.response?.status === 403 &&
      error?.response?.config?.url === "/logout"
    ) {
      return error;
    } else if (error?.response?.data?.error_message) {
      Sentry.captureMessage(`INFO: ${error?.response?.data?.error_message}`);
    } else {
      Sentry.captureMessage("INFO: Custom Axios Global Error");
    }

    return error;
  }
);

export default axios;
