import cs from "classnames";

const Cluster = ({
  active,
  number,
  onClick,
  ActiveCluster,
  variant = "investment",
}) => {
  return (
    <>
      <div
        className={cs(
          "cursor-pointer bottom-5 right-5 rounded-full flex flex-col items-center justify-center transition-all duration-500 font-bold leading-none w-16 h-16 -ml-8 -mt-8 duration-300  hover:opacity-90",
          variant === "investment" ? "bg-primse-green" : "bg-primse-yellow"
        )}
        onClick={onClick}
      >
        <div
          className={cs(
            " text-center text-xl",
            variant === "investment" ? "text-white" : "text-gray-800"
          )}
        >
          <div>{number}</div>
        </div>
      </div>
      {active && ActiveCluster}
    </>
  );
};

export default Cluster;
