import RealestatesServices from "../../services/RealestatesServices";
import FlatListView from "./FlatListView";
import { useEffect, useState } from "react";
import { isArray } from "../../utils/functions";

const FlatList = ({ uuid }) => {
  const [data, setData] = useState({ payload: [] });
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const flatData = await RealestatesServices.getFlatList(
      uuid,
      { perPage: 999 },
      true
    )();
    if (isArray(flatData?.payload)) {
      setData({ payload: flatData?.payload });
    }
  };

  return (
    <>
      {data ? (
        <FlatListView flats={data.payload} />
      ) : (
        <div
          className="ui placeholder fluid mt-12"
          style={{ height: "50vh", display: "block" }}
        ></div>
      )}
    </>
  );
};

export default FlatList;
