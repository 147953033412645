import axios from "../utils/axios";

const RequestsServices = {
  getRequestForm: (investment_uuid) => async () => {
    return await axios
      .get(`/request-form/${investment_uuid}`)
      .then((res) => res.data);
  },
  getRequest: (uuid) => async () => {
    return await axios
      .get(`/request-view/${uuid}`)
      .then((res) => res.data.payload);
  },
  getRequests: async () => {
    return await axios
      .get("/request-index", {
        params: {
          perPage: -1,
        },
      })
      .then((res) => res.data.payload);
  },
  sendRequestDocuments: async (investment_uuid, formData) => {
    return await axios
      .post(`request-create/${investment_uuid}`, formData)
      .then((res) => res);
  },
  //TODO: przenieśćę logikę związaną z formatowaniem danych
  updateRequestDocument: async (request_uuid, formData) => {
    return await axios
      .post(`request-update/${request_uuid}`, formData)
      .then((res) => res);
  },
  changeRequestStatus: async (request_uuid, status) => {
    return await axios
      .post(`/request-change-status/${request_uuid}`, status)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  getRequestHistory: async (request_uuid) => {
    return await axios
      .get(`/request-history/${request_uuid}`)
      .then((res) => res);
  },
  getRequestTermHistory: async (request_uuid, term_uuid) => {
    return await axios
      .get(`/request-term-history/${request_uuid}/${term_uuid}`)
      .then((res) => res);
  },
};

export default RequestsServices;
