import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import RequestsServices from "../../../../services/RequestsServices";
import { AiFillCloseCircle } from "react-icons/all";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import cs from "classnames";
const MoreInfo = ({ data }) => {
  const { t } = useTranslation();
  return (
    <ul>
      <p className="font-bold text-xl mb-0">{t("Customer details")}:</p>
      {data?.data?.map(({ label, value, display = true }, index) => {
        if (
          value === null ||
          value === "null" ||
          value === "undefined" ||
          value === "" ||
          display === false
        ) {
          return null;
        } else {
          return (
            <li className="flex leading-8 " key={index}>
              <div className="overflow-ellipsis overflow-hidden whitespace-pre-line flex w-full">
                {label && !Array.isArray(value) && (
                  <strong className="mr-1 font-bold w-2/12 m-0">
                    {t(label)}:
                  </strong>
                )}
                {typeof value === "string" && !Array.isArray(value) && (
                  <span>{label === "Filing" ? value : t(value)}</span>
                )}
              </div>
            </li>
          );
        }
      })}
      <p className="font-bold text-xl mb-0 mt-4">{t("Agent details")}:</p>
      {data?.otherData?.map(({ label, value, display = true }, index) => {
        if (
          value === null ||
          value === "null" ||
          value === "undefined" ||
          value === "" ||
          display === false
        ) {
          return null;
        } else {
          return (
            <li className="flex leading-8 " key={index}>
              <div className="overflow-ellipsis overflow-hidden whitespace-pre-line flex w-full">
                {label && !Array.isArray(value) && (
                  <strong className="mr-1 font-bold w-2/12 m-0">
                    {t(label)}:
                  </strong>
                )}
                {typeof value === "string" && !Array.isArray(value) && (
                  <span>{label === "Filing" ? value : t(value)}</span>
                )}
              </div>
            </li>
          );
        }
      })}
    </ul>
  );
};

function ActionModalFromMail({
  requestUuid,
  handleRefresh,
  infoData,
  open,
  setOpen,
}) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const handleStatusChange = (statusProps = null, onlyComent = false) => {
    let status = statusProps;

    if (onlyComent && message?.replaceAll(" ", "").length === 0) {
      return toast.error('Musisz uzupełnić pole "Komentarz"');
    }
    setLoading(true);
    RequestsServices.changeRequestStatus(requestUuid, {
      status,
      comment: message,
    })
      .then(() => {
        toast.success(
          `${t("The request status has been successfully changed")}`
        );
        handleRefresh(true);
      })
      .then(() => setOpen(false))
      .catch(() =>
        toast.error(`${t("The request status could not be changed")}`)
      )
      .finally(() => setLoading(false));
  };

  return (
    <Modal open={open}>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between">
          <h3 className="text-xl font-bold">{t("Edit request")}</h3>
          <AiFillCloseCircle
            onClick={() => setOpen(false)}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>

      <Modal.Content>
        <MoreInfo data={infoData} />
        <div className="flex my-4 ui form">
          <p className="w-2/12 m-0 pr-24 font-bold">{t("Comment")}:</p>
          <div className="flex flex-col w-full  ">
            <textarea
              className="w-full"
              maxLength={256}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={t("Add comment")}
            />
            <p className="text-right text-sm pt-2">
              {t("Number of characters")}: {message?.length} / 256
            </p>
          </div>
        </div>

        <div className="flex justify-between lg:justify-end">
          <button
            onClick={() => handleStatusChange("accept")}
            className={cs(
              "leading-5 mx-2 font-bold text-white uppercase bg-primse-green py-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white px-8"
            )}
          >
            {t("Accept2")}
          </button>
          <button
            onClick={() => handleStatusChange("reject")}
            className={cs(
              "leading-5 mx-2 font-bold text-black uppercase bg-primse-yellow py-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white px-8"
            )}
          >
            {t("Decline")}
          </button>
          <button
            onClick={() => handleStatusChange(null, true)}
            className={cs(
              "leading-5 mx-2 font-bold text-black uppercase bg-primse-yellow py-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white px-8"
            )}
          >
            {t("Add comment without decision")}
          </button>
          <button
            onClick={() => setOpen(false)}
            className="leading-5 px-8 mx-2 font-bold text-black uppercase bg-primse-yellow py-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
          >
            {t("Cancel")}
          </button>
        </div>
      </Modal.Content>
      {loading && <Loader />}
    </Modal>
  );
}

export default ActionModalFromMail;
