import { useQuery } from "react-query";
import BriefcaseContentService from "../../../../../../services/BriefcaseContentService";
import { useTranslation } from "react-i18next";

const TriggerBriefcase = ({ briefcaseClient, type }) => {
  const { data, isLoading } = useQuery(
    ["briefcase", briefcaseClient.uuid],
    BriefcaseContentService.getBriefcaseContent(briefcaseClient.uuid)
  );
  const { t } = useTranslation();
  const sliceName = (a, b) => {
    const fName = a.slice(0, 1);
    const lName = b.slice(0, 1);

    return `${fName}${lName}`;
  };

  if (type === "number") {
    return (
      <>
        {!isLoading && (
          <div className="bg-primse-mid-gray text-white absolute right-4 top-5 rounded-full h-6 w-6 flex items-center justify-center">
            {data?.payload?.length}
          </div>
        )}
      </>
    );
  }
  if (type === "name") {
    return (
      <>
        {!isLoading && (
          <div className="flex flex-col justify-center items-center pt-2">
            <p className="text-white p-0 m-0 text-xs">{t("Briefcase")}:</p>
            <p className="text-white p-0 m-0 text-xs">
              {sliceName(briefcaseClient.first_name, briefcaseClient.last_name)}
            </p>
          </div>
        )}
      </>
    );
  }
};

export default TriggerBriefcase;
