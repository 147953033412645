import React, { useEffect, useState } from "react";
import { Input, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import UserServices from "../../services/UserServices";
import { Controller, useForm } from "react-hook-form";
import Button from "../../components/Button";
import { useHistory, useLocation } from "react-router-dom";

function ResetPassword() {
  const { t } = useTranslation();
  const { handleSubmit, control, watch } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [hash, setHash] = useState("");

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      !location?.search ||
      location?.search === "" ||
      location?.search?.slice(0, 5) !== "?hash"
    ) {
      history?.push("/");
    }
    setHash(location?.search?.slice(6));
  }, []);

  const onSubmit = (data) => {
    if (!data?.password || data?.password?.length < 6) {
      setError(true);
      return;
    }
    if (!data?.confirmPassword || data?.confirmPassword?.length < 6) {
      setError(true);
      return;
    }
    if (data?.confirmPassword !== data?.password) {
      setError(true);
      return;
    }

    setLoading(true);
    UserServices.createPassword({ ...data }, hash)
      .then((res) => {
        if (res?.status === 400 || res?.response?.status === 400) {
          if (
            res?.response?.data?.form?.error_messages?.currentPassword
              ?.msgPassword &&
            res.response.data.form.error_messages.currentPassword
              .msgPassword === "Incorrect password"
          ) {
            setConfirmPasswordError(true);
            toast.error(`${t("A wrong password was entered")}`);
          } else {
            setError(true);
            toast.error(
              `${t("Could not change password, an error has occurred")}`
            );
          }

          return res?.status || res?.response?.status;
        } else {
          toast.success(`${t("Password has been successfully updated")}`);
          history?.push("/");
          return res?.status || res?.response?.status;
        }
      })

      .catch((err) => {
        console.log(err);
        if (
          err?.response?.data?.form?.error_messages?.currentPassword
            ?.msgPassword &&
          err.response.data.form.error_messages.currentPassword.msgPassword ===
            "Incorrect password"
        ) {
          setConfirmPasswordError(true);
          toast.error(`${t("A wrong password was entered")}`);
        } else {
          setError(true);
          toast.error(
            `${t("Could not change password, an error has occurred")}`
          );
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="max-w-screen-md mx-auto">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col lg:flex-row items-center lg:justify-around mt-20 ">
          <div className="flex flex-col">
            <div className="p-2 flex flex-col">
              <label className="font-bold p-2">{t("New password")}:</label>
              <Controller
                name="password"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      size={"large"}
                      type="password"
                      error={
                        error &&
                        (watch().password === undefined ||
                          watch().password !== watch().confirmPassword)
                      }
                    />
                  );
                }}
              />
            </div>
            <div className="p-2 flex flex-col">
              <label className="font-bold p-2">{t("Confirm password")}:</label>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      size={"large"}
                      type="password"
                      error={
                        error &&
                        (watch().confirmPassword === undefined ||
                          watch().password !== watch().confirmPassword)
                      }
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="p-2 mt-2  text-center">
          <Button type="submit" className="px2">
            {t("Save")}
          </Button>
        </div>
      </form>
      {loading && <Loader />}
      {error && watch().password !== watch().confirmPassword && (
        <Message
          negative={true}
          content={t("The new password and confirm password fields must match")}
        />
      )}
      {error &&
        error &&
        (watch().password === undefined ||
          watch().confirmPassword === undefined ||
          watch().currentPassword === undefined) && (
          <Message
            negative={true}
            content={t("None of the fields can be empty")}
          />
        )}
      {confirmPasswordError && (
        <Message
          negative={true}
          content={t("Please enter the correct password")}
        />
      )}
      {error &&
        (watch().password?.length < 6 ||
          watch().currentPassword?.length < 6 ||
          watch().confirmPassword?.length < 6) && (
          <Message
            negative={true}
            content={t("Password must contain at least 6 characters")}
          />
        )}
    </div>
  );
}

export default ResetPassword;
