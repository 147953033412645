import { CheckSquare, Square, XSquare } from "react-feather";
import i18n from "../i18n";

export const applicationStatusData = (status) => {
  let result = {};
  const statusArr = [
    { value: "Accepted", label: `${i18n.t("Accepted")}` },
    { value: "Awaiting", label: `${i18n.t("Awaiting")}` },
    {
      value: "Accepted conditionally",
      label: `${i18n.t("Accepted conditionally")}`,
    },
    {
      value: "Accepted duplicate",
      label: `${i18n.t("Accepted duplicate")}`,
    },
    { value: "Rejected", label: `${i18n.t("Rejected")}` },
    { value: "Expired", label: `${i18n.t("Expired")}` },
  ];
  statusArr.map((e) => {
    if (e.value === status) {
      result = e;
    }
  });
  return result.label;
};
export const applicationStatusIcon = (status) => {
  let result;
  switch (status) {
    case "Accepted":
      result = (
        <div className="mr-2.5 text-primse-green">
          <CheckSquare />{" "}
        </div>
      );
      break;
    case "Awaiting":
      result = (
        <div className="mr-2.5 text-yellow-400">
          <Square />{" "}
        </div>
      );
      break;
    case "Rejected":
      result = (
        <div className="mr-2.5 text-red-400">
          <XSquare />{" "}
        </div>
      );
      break;
    case "Expired":
      result = (
        <div className="mr-2.5 text-red-400">
          <XSquare />{" "}
        </div>
      );
      break;
    case "Accepted conditionally":
      result = (
        <div className="mr-2.5 text-yellow-400">
          <CheckSquare />{" "}
        </div>
      );
      break;
    case "Accepted duplicate":
      result = (
        <div className="mr-2.5 text-yellow-400">
          <CheckSquare />{" "}
        </div>
      );
      break;

    default:
      break;
  }

  return result;
};

export const colorStatus = (status) => {
  let result;

  switch (status) {
    case "Accepted":
      result = "text-primse-green";
      break;
    case "Awaiting":
      result = "text-yellow-400";
      break;
    case "Rejected":
      result = "text-red-400";
      break;
    case "Accepted conditionally":
      result = "text-yellow-400";
      break;
    case "Accepted duplicate":
      result = "text-yellow-400";
      break;

    default:
      break;
  }

  return result;
};

export const transactionStatusData = (status, type = "label") => {
  let result = {};
  const statusArr = [
    {
      value: 1,
      label: `${i18n.t("Open transaction")}`,
      color: "text-yellow-400",
    },
    {
      value: 2,
      label: `${i18n.t("Primse approval required")}`,
      color: "text-yellow-400",
    },
    {
      value: 3,
      label: `${i18n.t("Developer approval required")}`,
      color: "text-yellow-400",
    },
    {
      value: 4,
      label: `${i18n.t("Adjustment required")}`,
      color: "text-yellow-400",
    },
    {
      value: 5,
      label: `${i18n.t("Primse invoice issue")}`,
      color: "text-yellow-400",
    },
    {
      value: 6,
      label: `${i18n.t("Primse invoice due")}`,
      color: "text-yellow-400",
    },
    {
      value: 7,
      label: `${i18n.t("Conditions confirmation")}`,
      color: "text-yellow-400",
    },
    {
      value: 8,
      label: `${i18n.t("Agent invoice issue")}`,
      color: "text-yellow-400",
    },
    {
      value: 9,
      label: `${i18n.t("Agent invoice due")}`,
      color: "text-yellow-400",
    },
    {
      value: 10,
      label: `${i18n.t("Settled2")}`,
      color: "text-primse-green",
    },
    {
      value: 11,
      label: `${i18n.t("Canceled")}`,
      color: "text-red-400",
    },
    {
      value: 12,
      label: `${i18n.t("Deleted")}`,
      color: "text-red-400",
    },
    {
      value: 13,
      label: `${i18n.t("Developer approval")}`,
      color: "text-yellow-400",
    },
    {
      value: 14,
      label: `${i18n.t("Suspended2")}`,
      color: "text-red-400",
    },
  ];
  statusArr.map((e) => {
    if (e.value === status * 1) {
      result = e;
    }
  });
  if (type === "label") {
    return result.label;
  } else if (type === "color") {
    return result.color;
  } else {
    return result.label;
  }
};

export const transactionStatusIcon = (status) => {
  let result;
  switch (status) {
    case "Open":
      result = (
        <div className="mr-2.5 text-yellow-400">
          <CheckSquare />{" "}
        </div>
      );
      break;
    case "For acceptance":
      result = (
        <div className="mr-2.5 text-yellow-400">
          <Square />{" "}
        </div>
      );
      break;
    case "Accepted":
      result = (
        <div className="mr-2.5 text-yellow-400">
          <Square />{" "}
        </div>
      );
      break;
    case "For correction":
      result = (
        <div className="mr-2.5 text-yellow-400">
          <Square />{" "}
        </div>
      );
      break;
    case "For issued":
      result = (
        <div className="mr-2.5 text-yellow-400">
          <Square />{" "}
        </div>
      );
      break;
    case "Issued":
      result = (
        <div className="mr-2.5 text-primse-green">
          <Square />{" "}
        </div>
      );
      break;
    case "Settled":
      result = (
        <div className="mr-2.5 text-primse-green">
          <Square />{" "}
        </div>
      );
      break;
    case "Canceled":
      result = (
        <div className="mr-2.5 text-red-400">
          <XSquare />{" "}
        </div>
      );
      break;
    case "Deleted":
      result = (
        <div className="mr-2.5 text-red-400">
          <XSquare />{" "}
        </div>
      );
      break;

    default:
      break;
  }

  return result;
};
