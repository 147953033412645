import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form, Message, Modal, TextArea } from "semantic-ui-react";
import { useQuery } from "react-query";
import { AiFillCloseCircle } from "react-icons/all";
import { toast } from "react-toastify";
import cs from "classnames";

import DocumentsServices from "../../../../services/DocumentsServices";

import Button from "../../../../components/Button";

const OfferInvestment = ({ uuid, visibleMobile = false }) => {
  const { t } = useTranslation();

  const { data: templatesData } = useQuery(
    "templates",
    DocumentsServices.getPDFTemplates
  );

  const templates = useMemo(() => {
    if (!templatesData) return [];
    const templatesObjectSettings = {};

    for (const [key, value] of Object.entries(templatesData.settings)) {
      if (key.slice(-10) !== "_max_items") {
        if (key.search("sm_realestates") !== -1) {
          templatesObjectSettings[key] = {
            uuid: value,
            type: "Secondary Market Realestates",
          };
        } else {
          templatesObjectSettings[key] = {
            uuid: value,
            type:
              key.search("realestates") !== -1 ? "Realestates" : "Investments",
          };
        }
      }
    }
    return templatesObjectSettings;
  }, [t, templatesData]);
  const createPDFrealestates = async (uuid, templateUuid, text) => {
    const id = toast.success(t("Generating an offer"), {
      autoClose: false,
    });

    try {
      const formData = new FormData();

      formData.append("investmentUuids[]", uuid);
      formData.append("templateUuid", templateUuid);
      formData.append("custom_text", text);
      return await DocumentsServices.createPdf(formData)
        .then(() =>
          toast.update(id, {
            render: `${t("Generated offers, download will start soon.")}`,
            type: "success",
            isLoading: false,
            autoClose: true,
          })
        )
        .catch(() =>
          toast.update(id, {
            render: `${t("Error, unable to generate offers.")}`,
            type: "error",
            isLoading: false,
            autoClose: true,
          })
        );
    } catch (error) {
      toast.update(id, {
        render: ``,
        type: "error",
        isLoading: false,
        autoClose: true,
      });
    }
  };

  return (
    <OfferComponent
      uuid={uuid}
      templates={templates}
      createPDFrealestates={createPDFrealestates}
      visibleMobile={visibleMobile}
    />
  );
};

const OfferComponent = ({
  uuid,
  templates,
  createPDFrealestates,
  visibleMobile,
}) => {
  const [opinionModalOpen, setOpinionModalOpen] = useState(false);
  const [customText, setCustomText] = useState("");
  const [template, setTemplate] = useState(null);
  const { t } = useTranslation();

  const handleGenerateButton = () => {
    setTemplate(templates["template_offer_investments"]);
    setOpinionModalOpen(true);
  };

  const generateWithOpinion = () => {
    setOpinionModalOpen(false);
    createPDFrealestates(uuid, template.uuid, customText);
    setCustomText("");
  };

  return (
    <div>
      <button
        className={cs(
          "uppercase rounded-sm bg-primse-green",
          visibleMobile
            ? "text-base font-bold text-white  py-4 px-5 mt-3 rounded-sm w-full"
            : "leading-5 py-5 mr-5 px-8 transition-all hover:bg-primse-gray"
        )}
        onClick={() => {
          handleGenerateButton();
        }}
      >
        <span className="text-white font-bold whitespace-nowrap">
          {t("Generate an offer")}
        </span>
      </button>
      <MyOpinionModal
        setOpinionModalOpen={setOpinionModalOpen}
        opinionModalOpen={opinionModalOpen}
        customText={customText}
        setCustomText={setCustomText}
        generateWithOpinion={generateWithOpinion}
      />
    </div>
  );
};

function MyOpinionModal({
  setOpinionModalOpen,
  opinionModalOpen,
  customText,
  setCustomText,
  generateWithOpinion,
}) {
  const { t } = useTranslation();

  const onSubmit = () => {
    generateWithOpinion();
  };

  return (
    <>
      <Modal open={opinionModalOpen}>
        <Modal.Header
          style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
        >
          <div className="flex justify-between">
            <h3 className="text-xl font-bold">
              {t("In my opinion - a comment")}
            </h3>
            <AiFillCloseCircle
              onClick={() => setOpinionModalOpen(false)}
              className="text-primse-gray-font cursor-pointer w-8 h-8"
            />
          </div>
        </Modal.Header>

        <Modal.Content>
          <div className="p-2">
            <Form onSubmit={onSubmit}>
              <div className="p-2">
                <TextArea
                  maxLength="350"
                  value={customText}
                  onChange={(e) => setCustomText(e.target.value)}
                />
                <Message warning={true} visible={true} size="small">
                  {t("The maximum length of a comment is 350 characters")}
                </Message>
              </div>
              <div className="p-2 flex justify-end">
                <Button type="submit">{t("Generate")}</Button>
              </div>
            </Form>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
}

export default OfferInvestment;
