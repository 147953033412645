import Moment from "react-moment";

const RequestItemMobile = ({ data, handleSelectClient }) => {
  return (
    <div
      onClick={() => handleSelectClient(data)}
      className="py-3 border-b flex flex-wrap"
    >
      <div className="flex w-full">
        <div className="w-10/12 font-bold text-lg">
          {`${data.first_name} ${data.last_name}`}
        </div>
      </div>
      <div>
        <div>
          <span className="font-bold">Utworzony:</span>{" "}
          <Moment format="DD.MM.YYYY">{data.created}</Moment>
        </div>
        <div>
          <span className="font-bold">Handlowiec:</span>{" "}
          {data.source_of_origin_name}
        </div>
        <div>
          <span className="font-bold">Telefon:</span> +48 123 456 789
        </div>
        <div>
          <span className="font-bold">E-mail:</span> email@email.pl
        </div>
        <div>
          <span className="font-bold">Status:</span> {data.status_name}
        </div>
        <div>
          <span className="font-bold">Zgłoszenia:</span> 5
        </div>
        <div>
          <span className="font-bold">Uwagi:</span> -
        </div>
      </div>
    </div>
  );
};

export default RequestItemMobile;
