import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { Checkbox, Dropdown } from "semantic-ui-react";
import FilterModal from "./FilterModal";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import styles from "./SearchComponents.module.css";

export const FLOORS_NUM = 10;

const Floor = ({ children, active, selected, fifth, sixth, ...props }) => (
  <li
    className={cs(
      {
        "bg-primse-green": !!active || !!selected,
        "text-white": !!active || !!selected,
        "opacity-80 ": !!active && !selected,
        "bg-opacity-40 ": !!selected,
        "border rounded-md": !!sixth,
        "rounded-md": !!fifth,
      },
      "w-1/5 text-center py-4 border-r border-t border-b first:border hover:bg-primse-green hover:text-white hover:opacity-100 transition-all cursor-pointer"
    )}
    {...props}
  >
    {children}
  </li>
);

const AdvancedDropdown = ({ control, setValue, completion_quarters }) => {
  const { t } = useTranslation();

  const containerRef = useRef();

  const onRoomCLicked = (selectedValues, value) => {
    if (selectedValues.includes(value)) {
      return selectedValues.filter((s) => s !== value);
    } else {
      const values = [...selectedValues, value].sort();
      if (values?.length === 1) {
        return [value];
      } else {
        return [values[0], values[values?.length - 1]];
      }
    }
  };

  const setDeliveryOptions = () => {
    if (!completion_quarters) return null;

    const options = [];

    function compare(a, b) {
      if (b.label === "Oddane do użytku") return 1;
      const arrA = a.label.split(" ");
      const arrB = b.label.split(" ");

      if (arrA[0] < arrB[0]) {
        if (arrA[2] < arrB[2] || arrA[2] === arrB[2]) return 1;
        if (arrA[2] > arrB[2]) return -1;
      }
      if (arrA[0] > arrB[0]) {
        if (arrA[2] > arrB[2] || arrA[2] === arrB[2]) return -1;
        if (arrA[2] < arrB[2]) return 1;
      }

      return 1;
    }

    const convertQuarter = (q) => {
      switch (q) {
        case "1":
          return "I";
        case "2":
          return "II";
        case "3":
          return "III";
        case "4":
          return "IV";

        default:
          return q;
      }
    };

    const quartersNew = completion_quarters.map((item) => {
      return {
        label: `${convertQuarter(`${item.quarter}`)} ${t("Quarter")} ${
          item.year
        }`,
      };
    });

    quartersNew
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort(compare)
      .map((o) => {
        options.push({
          text: o.label,
          value: o.label,
          key: o.label,
        });
      });

    return options;
  };

  return (
    <FilterModal
      title={`${t("Advanced")}`}
      onReset={() => {
        setValue("basement", false);
        setValue("floors", []);
        setValue("storage", false);
        setValue("closed", false);
        setValue("delivery", [""]);
        setValue("balcony", false);
        setValue("terrace", false);
        setValue("green_terrace", false);
        setValue("has_parking_space", false);
      }}
    >
      <div className="flex flex-col">
        <div className="my-2">
          <Controller
            control={control}
            name="floors"
            render={({ field: { onChange, value: selectedValues } }) => {
              return (
                <div ref={containerRef}>
                  <div className="my-2">
                    <label>{t("Floor")}</label>
                  </div>
                  <ul className={cs(styles.roomsContainer, "flex flex-wrap")}>
                    {Array(FLOORS_NUM)
                      .fill()
                      .map((_, index) => {
                        const value = index;
                        return (
                          <Floor
                            key={index}
                            onClick={() =>
                              onChange(onRoomCLicked(selectedValues, value))
                            }
                            active={selectedValues.includes(value)}
                            selected={
                              selectedValues?.length !== 0 &&
                              value > selectedValues[0] &&
                              value < selectedValues[selectedValues?.length - 1]
                            }
                            sixth={index === 5}
                            fifth={index === 4}
                          >
                            {value}
                            {index === FLOORS_NUM - 1 && "+"}
                          </Floor>
                        );
                      })}
                  </ul>
                </div>
              );
            }}
          />
        </div>
        <div className="my-2">
          <div className="my-2">
            <label>{t("Delivery date")}</label>
          </div>

          <Controller
            control={control}
            name="delivery"
            render={({ field: { onChange, value: values } }) => {
              return (
                <Dropdown
                  className="mx-2 h-12"
                  style={{ display: "flex", alignItems: "center" }}
                  search={true}
                  selection={true}
                  size={"small"}
                  placeholder={`${t("Quarter")}`}
                  value={values[0]}
                  onChange={(e, data) => {
                    onChange([data.value]);
                  }}
                  options={setDeliveryOptions()}
                  selectOnBlur={false}
                />
              );
            }}
          />
        </div>
        <div className="my-2">
          <Controller
            control={control}
            name="basement"
            render={({ field: { onChange, value: values } }) => {
              return (
                <Checkbox
                  label={t("Basement and storage")}
                  toggle
                  checked={values?.length === 0 ? false : values}
                  onChange={(_, e) => {
                    onChange(e.checked);
                  }}
                />
              );
            }}
          />
        </div>
        <div className="my-2">
          <Controller
            control={control}
            name="balcony"
            render={({ field: { onChange, value: values } }) => {
              return (
                <Checkbox
                  checked={values?.length === 0 ? false : values}
                  toggle
                  label={t("Balcony")}
                  onChange={(_, e) => {
                    onChange(e.checked);
                  }}
                />
              );
            }}
          />
        </div>
        <div className="my-2">
          <Controller
            control={control}
            name="terrace"
            render={({ field: { onChange, value: values } }) => {
              return (
                <Checkbox
                  checked={values?.length === 0 ? false : values}
                  toggle
                  label={t("Terrace")}
                  onChange={(_, e) => {
                    onChange(e.checked);
                  }}
                />
              );
            }}
          />
        </div>
        <div className="my-2">
          <Controller
            control={control}
            name="green_terrace"
            render={({ field: { onChange, value: values } }) => {
              return (
                <Checkbox
                  checked={values?.length === 0 ? false : values}
                  toggle
                  label={t("garden")}
                  onChange={(_, e) => {
                    onChange(e.checked);
                  }}
                />
              );
            }}
          />
        </div>
        <div className="my-2">
          <Controller
            control={control}
            name="has_parking_space"
            render={({ field: { onChange, value: values } }) => {
              return (
                <Checkbox
                  checked={values?.length === 0 ? false : values}
                  toggle
                  label={t("Parking space")}
                  onChange={(_, e) => {
                    onChange(e.checked);
                  }}
                />
              );
            }}
          />
        </div>
        <div className="my-2">
          <Controller
            control={control}
            name="closed"
            render={({ field: { onChange, value: values } }) => {
              return (
                <Checkbox
                  label={t("Guarded area")}
                  toggle
                  checked={values?.length === 0 ? false : values}
                  onChange={(_, e) => {
                    onChange(e.checked);
                  }}
                />
              );
            }}
          />
        </div>
        {/*    <div className="my-2">*/}
        {/*<Controller*/}
        {/*    control={control}*/}
        {/*    name="storage"*/}
        {/*    render={({ field: { onChange, value: values } }) => {*/}
        {/*        return (*/}
        {/*            <Checkbox*/}
        {/*                checked={values?.length === 0 ? false : values}*/}
        {/*                toggle*/}
        {/*                label={t("Storage")}*/}
        {/*                onChange={(_, e) => {*/}
        {/*                    onChange(e.checked);*/}
        {/*                }}*/}
        {/*            />*/}
        {/*        );*/}
        {/*    }}*/}
        {/*/>*/}
        {/*    </div>*/}
      </div>
    </FilterModal>
  );
};

export default AdvancedDropdown;
