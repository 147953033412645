import Gallery from "../../../../components/Gallery";
import { useMemo } from "react";
import { useStoreState } from "easy-peasy";

const GelleryRealestate = ({
  data_secondary = null,
  images,
  setShowModalMap,
  static_map,
  investmentData = null,
}) => {
  const { mode } = useStoreState((state) => state.mode);

  const presentationImageTypes = ["Plan", "Photos"];
  const normalImageTypes = ["Card", "Plan", "Photos"];

  const imageMap = useMemo(() => {
    const arrImage = [];
    images?.forEach((el) => {
      if (el?.extension !== ".pdf") {
        if (
          mode === "presentation" &&
          presentationImageTypes?.includes(el.type)
        ) {
          arrImage.push(el);
        }
        if (mode !== "presentation" && normalImageTypes?.includes(el.type)) {
          arrImage.push(el);
        }
      }
    });

    if (investmentData && investmentData?.pictures) {
      investmentData?.pictures?.forEach((el) => arrImage?.push(el));
    }

    return arrImage;
  }, [images, investmentData?.pictures, mode]);

  if (!investmentData) return null;

  return (
    <Gallery
      data_secondary={data_secondary}
      data={investmentData}
      images={imageMap}
      setShowModalMap={setShowModalMap}
      static_map={static_map}
    />
  );
};

export default GelleryRealestate;
