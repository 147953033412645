import React, { useEffect, useRef } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import AuthService from "../../services/AuthServices";
import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";
import Modal from "../../components/Modal";
import AuthServices from "../../services/AuthServices";
import { useHistory, useLocation } from "react-router-dom";
import { actions } from "../../store";
import useQueryParams from "../../hooks/useQueryParams";

const ModalLogin = () => {
  const { t } = useTranslation();

  const isOpen = useStoreState((state) => state.auth.isLoginModalOpen);
  const handleClose = useStoreActions((actions) => actions.auth.hideLoginModal);
  const location = useLocation();
  const { mutate: login, isLoading } = useMutation(AuthService.logIn, {
    onSuccess: () => {
      if (location?.pathname === "/") {
        history.push("/news");
      }
      handleClose();
    },
    onError: () => toast.error(t("Incorrect data")),
  });
  const { handleSubmit, control } = useForm();

  const history = useHistory();

  const toastIdAutoLogin = useRef(null);

  const query = useQueryParams();
  useEffect(async () => {
    if (location.pathname.slice(1, 6).toString() === "oauth") {
      const allquery = query.getAll();

      if (location.hash) {
        allquery.hash = location.hash;
      }

      if (allquery?.token) {
        allquery.access_token = allquery.token;
        delete allquery.token;
      }

      toast.warning(`${t("Logging in, please wait")}`);

      const formData = new FormData();

      for (const [key, value] of Object.entries(allquery)) {
        formData.append(key, value);
      }

      await AuthService.logOut();
      await AuthServices.addNewUser(formData)
        .then(
          ({
            // realestateType,
            city,
            areaFrom,
            roomCountFrom,
            roomCountTo,
            priceTo,
            defaultCities,
            clientUuid,
            navigateToBriefcase,
          }) => {
            const filters = {};

            toast.success(`${t("Successful login, welcome to Primse.com")}`);
            const locations = [];
            if (city && city?.length !== 0 && city[0] !== "") {
              locations.push(...city);
            }
            if (locations?.length === 0 && defaultCities?.length > 0) {
              locations?.push(...defaultCities.map(({ name }) => name));
            }

            if (locations?.length > 0) {
              filters.location = locations?.map((name) => name);
            }
            if (areaFrom && areaFrom?.length !== "") {
              filters.area = [`${parseFloat(areaFrom)}`, ""];
            }
            if (priceTo && priceTo?.length !== "") {
              filters.price = ["", `${parseFloat(priceTo)}`];
            }

            let roomsFrom = "0";
            let roomsTo = "5";

            if (roomCountFrom && roomCountFrom?.length !== "") {
              roomsFrom = roomCountFrom;
            }
            if (roomCountTo && roomCountTo?.length !== "") {
              roomsTo = roomCountTo;
            }

            filters.rooms = [roomsFrom, roomsTo];

            const filtersLength = Object.keys(filters)?.length;

            if (filtersLength > 0) {
              actions.filters.setSearchFilters({
                ...filters,
              });
            }

            if (navigateToBriefcase && clientUuid && clientUuid !== "") {
              return history.push(`/client/${clientUuid}`);
            }

            return history.push("/news");

            // history.push(
            //   `/loader?${
            //     realestateType === "flat" ||
            //     realestateType === "Flat" ||
            //     realestateType === "Mieszkanie"
            //       ? `type=apartment&`
            //       : ""
            //   }${
            //     realestateType === "house" ||
            //     realestateType === "House" ||
            //     realestateType === "Dom"
            //       ? `type=house&`
            //       : ""
            //   }${
            //     realestateType === "office" ||
            //     realestateType === "Office" ||
            //     realestateType === "Lokal usługowy" ||
            //     realestateType === "Shop"
            //       ? `type=service&`
            //       : ""
            //   }${
            //     locations?.length > 0 ? `location=${locations.join(",")}&` : ""
            //   }${areaFrom ? `area=${areaFrom},0&` : ""}${
            //     roomCountFrom || roomCountTo
            //       ? `rooms=${roomsFrom},${roomsTo}&`
            //       : ""
            //   }${priceTo ? `price=0,${priceTo}&` : ""}`
            // );
          }
        )
        .then(() => handleClose)
        .catch((err) => {
          if (err?.message === "Missing required client data") {
            toast.error(`${t("No customer data completed")}`);
          } else {
            toast.error(`${t("An error occurred, login failed")}`);
          }
        });
    }
  }, []);
  useEffect(async () => {
    if (location.pathname.slice(0, 16).toString() === "/auth/developer/") {
      let token = location.pathname.slice(16).toString();
      if (location.hash) {
        token = token + location.hash;
      }

      const params = new URLSearchParams(location?.search);
      const page = params.get("page");
      const query_uuid = params.get("query_uuid");
      const action = params.get("action");
      toastIdAutoLogin.current = toast(
        `${t("The request is loading, please wait")}`,
        {
          autoClose: false,
          type: toast.TYPE.WARNING,
        }
      );

      await AuthService.logOut();
      await AuthServices.logWithRequest(token)
        .then(() => {
          setTimeout(() => {
            toast.update(toastIdAutoLogin.current, {
              render: `${t("Request data successfully downloaded")}`,
              type: toast.TYPE.SUCCESS,
              autoClose: 5000,
            });
          }, 1000);

          if (page) {
            let link = `/${page}`;
            if (query_uuid) link += `/${query_uuid}`;
            if (action) link += `?action=${action}`;

            history.push(link);
          } else history.push("/requests");
        })
        .then(() => handleClose)
        .catch(() =>
          setTimeout(() => {
            toast.update(toastIdAutoLogin.current, {
              render: `${t("The request data could not be retrieved")}`,
              type: toast.TYPE.ERROR,
              autoClose: 5000,
            });
          }, 1000)
        );
    }
    // if (location.pathname.toString() === "/transactions") {
    //   let token = location.search.slice(7).toString();

    //   toast.warning(`${t("Logging in, please wait")}`);
    //   AuthServices.logWithRequest(token)
    //     .then(() => {
    //       toast.success(`${t("Successful login, welcome to Primse.com")}`);
    //       history.push("/transactions");
    //     })
    //     .then(() => handleClose)
    //     .catch(() => toast.error(`${t("An error occurred, login failed")}`));
    // }
  }, []);

  const onSubmit = ({ username, password }) => {
    login({ username, password });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        //   handleClose={() => setShowModal(null)}
      >
        <div className="p-4 w-96">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col">
              <div className="p-2 flex justify-center">
                <Controller
                  name="username"
                  control={control}
                  defaultValue=""
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        size={"large"}
                        label={t("E-mail")}
                        labelPosition="left corner"
                        placeholder={t("E-mail")}
                      />
                    );
                  }}
                />
              </div>
              <div className="p-2 mt-4 flex justify-center">
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        size={"large"}
                        label={t("Password")}
                        labelPosition="left corner"
                        placeholder={t("Password")}
                        type="password"
                      />
                    );
                  }}
                />
              </div>
              <div className="p-2 2 mt-4 text-center">
                <Button type="submit" className="px2">
                  {t("Log in")}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
      {isLoading && <Loader />}
    </>
  );
};
ModalLogin.propTypes = {};
ModalLogin.defaultProps = {};

export default ModalLogin;
