import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import NewsServices from "../../services/NewsServices";
import Loader from "../../components/Loader";
import NewsDetailsView from "./NewsDetailsView";

const NewsDetails = () => {
  const { uuid } = useParams();
  const { data, isLoading } = useQuery(
    ["news-details", uuid],
    NewsServices.getNewsDetails(uuid)
  );

  return (
    <main className="mt-4">
      {isLoading && <Loader />}
      <div>{data && <NewsDetailsView newsDetails={data} />}</div>
    </main>
  );
};

// Reports.propTypes = {};

// Reports.defaultProps = {};

export default NewsDetails;
