import axios from "../utils/axios";

const NewsServices = {
  getNews: async (params) => {
    return await axios
      .get("/news-index", {
        params,
      })
      .then((res) => res?.data?.payload);
  },
  getNewsDetails: (news_uuid) => async () => {
    return await axios.get(`/news-view/${news_uuid}`).then((res) => res.data);
  },
  downloadFile: async (id, name) => {
    return await axios
      .get(`/news-download-file/${id}`, { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();

        return { success: true };
      })
      .catch((err) => {
        console.log(err);
        return { success: false };
      });
  },
};

export default NewsServices;
