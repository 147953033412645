import React, { useState } from "react";
import { Modal } from "semantic-ui-react";

import { AiFillCloseCircle } from "react-icons/all";
import RequestUserSearch from "../RequestUserSearch";

import cs from "classnames";
import { useTranslation } from "react-i18next";

const RequestUserComponent = ({ uuid, setClientUuid, classNameButton }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      size={"large"}
      trigger={
        <div>
          <button
            className={cs(
              "leading-5 uppercase bg-primse-green py-5 px-8 rounded-sm transition-all hover:bg-primse-gray",
              "hidden lg:flex lg:justify-center lg:py-5 lg:px-5 items-center",
              classNameButton
            )}
            onClick={() => {}}
          >
            <span className="text-white font-bold whitespace-nowrap">
              {t("Report a customer for this investment")}
            </span>
          </button>
        </div>
      }
    >
      <>
        <Modal.Header
          style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
        >
          <div className="flex justify-between">
            <div className="flex">{t("Choose a client")}</div>
            <AiFillCloseCircle
              onClick={() => setOpen(false)}
              className="text-primse-gray-font cursor-pointer w-8 h-8"
            />
          </div>
        </Modal.Header>
        <Modal.Content>
          <RequestUserSearch
            setOpen={setOpen}
            uuid={uuid}
            setClientUuid={setClientUuid}
          />
        </Modal.Content>
      </>
    </Modal>
  );
};

export default RequestUserComponent;
