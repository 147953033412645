import React from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import BasketsService from "../../../services/BasketsService";
import { useStoreState } from "easy-peasy";
import { toast } from "react-toastify";

const DeleteBasket = ({ selectDefaultModal }) => {
  const { t } = useTranslation();

  const { currentBasketUuid } = useStoreState((state) => state.baskets);
  const { currentBasket } = useStoreState((state) => state.baskets);

  const handleSubmit = (e) => {
    e.preventDefault();
    BasketsService.removeBasket({
      basket_uuid: currentBasketUuid,
    })
      .then(async (res) => {
        if (res.response && res.response.status === 410) {
          await BasketsService.getBaskets().then(() =>
            toast.success(`${t("The cart has been successfully deleted")}`)
          );
          selectDefaultModal();
        } else toast.error(`${t("The cart could not be deleted")}`);
      })
      .catch((err) => {
        if (err.response && err.response.status === 410) {
          BasketsService.getBaskets().then(() =>
            toast.success(`${t("The cart has been successfully deleted")}`)
          );
          selectDefaultModal();
        } else toast.error(`${t("The cart could not be deleted")}`);
      });
  };
  return (
    <>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between items-center">
          <h3 className="font-bold mb-0">{t("Delete cart")}</h3>
          <AiFillCloseCircle
            onClick={() => {
              selectDefaultModal();
            }}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          {t(`Are you sure you want to delete the cart`)}{" "}
          <span className="font-bold">{t(currentBasket.name)}?</span>
        </div>
      </Modal.Content>
      <Modal.Content>
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <div className="flex">
            <button
              className="leading-5 mr-4 text-xs lg:text-base uppercase font-bold text-white bg-primse-yellow py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
              type="submit"
            >
              {t("Delete cart")}
            </button>
            <button
              className="leading-5 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
              onClick={(e) => {
                e.preventDefault();
                selectDefaultModal();
              }}
            >
              {t("Cancel")}
            </button>
          </div>
        </form>
      </Modal.Content>
    </>
  );
};

export default DeleteBasket;
