import { useState, useEffect } from "react";
import { useStoreState } from "easy-peasy";
import { ChevronDown, ChevronUp } from "react-feather";
import { searchUtils } from "../../../../utils/searchUtils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import axios from "../../../../utils/axios";
import { actions } from "../../../../store";
import { toast } from "react-toastify";

const BasketModalSelect = () => {
  const [openModal, setOpenModal] = useState(false);
  const [basketsListMap, setBasketsListMap] = useState(null);
  const { basketsList, currentBasket } = useStoreState(
    (state) => state.baskets
  );

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const arr = basketsList.map(({ name, uuid }) => ({
      name,
      uuid,
      checked: currentBasket.uuid === uuid ? true : false,
    }));

    arr.sort((a, b) => {
      return a.name < b.name ? -1 : 1;
    });
    setBasketsListMap(arr);
  }, [basketsList]);

  const toastInfo = (uuidProps) => {
    const filterData = basketsList.filter(({ uuid }) => uuid === uuidProps);
    const sumData =
      filterData[0].count_investments * 1 + filterData[0].count_realestates * 1;
    if (sumData !== 0) {
      toast.success(`${t("The basket has been changed")}`);
    }
  };

  const handleChangeBasket = (uuid) => {
    axios.get("/basket-index").then((res) => {
      actions.baskets.setAllBaskets(res.data.payload);
    });
    searchUtils(basketsList, uuid);
    toastInfo(uuid);
    history.push(`/basket/${uuid}`);
  };

  return (
    <div className="absolute top-5 left-5 bg-white p-4 ring-2 ring-primse-green rounded w-auto z-10">
      <div
        onClick={() => setOpenModal((prev) => !prev)}
        className="flex justify-between cursor-pointer"
      >
        <p className="text-base p-0 m-0 pr-4">
          {t("Selected basket")}: {`${t(currentBasket.name)}`}{" "}
        </p>
        {!openModal ? (
          <ChevronDown className=" text-primse-green" />
        ) : (
          <ChevronUp className=" text-primse-green" />
        )}
      </div>
      {openModal && (
        <div>
          {basketsListMap ? (
            <>
              {basketsListMap.map(({ name, uuid, checked }) => (
                <div
                  key={uuid}
                  onClick={() => handleChangeBasket(uuid)}
                  className="flex cursor-pointer items-center mt-3 "
                >
                  <div className="p-0 w-3 h-3 ring-1 ring-primse-green flex justify-center items-center text-primse-green rounded-sm">
                    {checked && (
                      <div className="w-3/4 h-3/4 bg-primse-green "></div>
                    )}
                  </div>
                  <p className="px-4 py-0 text-sm">{t(name)}</p>
                </div>
              ))}
            </>
          ) : (
            <p>Lista koszyków jest pusta</p>
          )}
        </div>
      )}
    </div>
  );
};

export default BasketModalSelect;
