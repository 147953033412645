import Moment from "react-moment";
import { useTranslation } from "react-i18next";

const QuestionsItemMobile = ({
  data: {
    date_created,
    developer_name,
    investment_name,
    realestate_name,
    status_name,
    subject_name,
    reply,
  },
}) => {
  const { t } = useTranslation();

  return (
    <div className="py-3 border-b flex flex-wrap">
      <div className="flex w-full">
        <div className="w-10/12 font-bold text-lg">
          <span className="font-bold">{t("Subject")}:</span> {`${subject_name}`}
        </div>
      </div>
      <div>
        <div>
          <span className="font-bold">{t("Created")}:</span>{" "}
          <Moment format="DD.MM.YYYY">{date_created}</Moment>
        </div>
        <div>
          <span className="font-bold">Status:</span> {t(status_name)}
        </div>
        <div>
          <span className="font-bold">{t("Developer")}:</span> {developer_name}
        </div>
        <div>
          <span className="font-bold">{t("Investment")}:</span>{" "}
          {investment_name ? investment_name : t("No data")}
        </div>
        <div>
          <span className="font-bold">{t("Realestate name")}:</span>{" "}
          {realestate_name ? realestate_name : t("No data")}
        </div>
        <div>
          <span className="font-bold">{t("Reply")}:</span>{" "}
          {reply ? reply : t("No data")}
        </div>
      </div>
    </div>
  );
};

export default QuestionsItemMobile;
