import React from "react"
import i18n from "../../i18n";
import cs from "classnames";
import {Flag} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import Container from "../Container";

const LanguageChange = () => {

    const { t } = useTranslation();

    return <main className="py-0">

        <Container style={{ minHeight: "calc(80vh + 1rem)" }}>
        <div>
        <div className="border-b">
            <div className="p-3">
                <strong>{t("Select language")}</strong>
            </div>
            <div className="p-3 py-4 flex">
                <div
                    className="pr-4 cursor-pointer hover:text-primse-green"
                    onClick={() => i18n.changeLanguage("en")}
                >
                    <div
                        className={cs("flex items-center", {
                            "font-bold": i18n.language === "en",
                        })}
                    >
                        <Flag name="uk" /> {t("English")}
                    </div>
                </div>
                <div
                    className="cursor-pointer hover:text-primse-green"
                    onClick={() => i18n.changeLanguage("pl")}
                >
                    <div
                        className={cs("flex items-center", {
                            "font-bold": i18n.language === "pl",
                        })}
                    >
                        <Flag name="pl" /> {t("Polish")}
                    </div>
                </div>
            </div>
        </div>
    </div>
        </Container>
    </main>
}

export default LanguageChange