import React, { useEffect, useState } from "react";
import { Form, Message, Modal, TextArea } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";

import Loader from "../../components/Loader";
import Button from "../../components/Button";

function MyOpinionModal({
  setOpinionModalOpen,
  opinionModalOpen,
  createPDFrealestates,
  realestateList,
  template: { uuid, type },
  investmentsList,
  realestateSecondaryMarketList,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [customText, setCustomText] = useState("");
  useEffect(() => {
    if (!opinionModalOpen) {
      setCustomText("");
    }
  }, [opinionModalOpen]);
  useEffect(() => {
    if (
      opinionModalOpen &&
      investmentsList?.length > 1 &&
      Object.keys(realestateList)?.length === 0 &&
      Object.keys(realestateSecondaryMarketList)?.length === 0
    ) {
      onSubmit();
      // setOpinionModalOpen(false);
    } else {
      setOpen(opinionModalOpen);
    }
  }, [opinionModalOpen]);

  const { t } = useTranslation();

  const onSubmit = () => {
    setLoading(true);
    switch (true) {
      case type === "Secondary Market Realestates":
        createPDFrealestates(realestateSecondaryMarketList, uuid, customText)
          .then(() => {
            setOpinionModalOpen(false);
          })
          .finally(() => setLoading(false));
        break;
      case type === "Realestates":
        createPDFrealestates(realestateList, uuid, customText)
          .then(() => {
            setOpinionModalOpen(false);
          })
          .finally(() => setLoading(false));
        break;
      case type === "Investments":
        createPDFrealestates(realestateList, uuid, customText, investmentsList)
          .then(() => {
            setOpinionModalOpen(false);
          })
          .finally(() => setLoading(false));
        break;
    }
  };

  return (
    <>
      <Modal open={open}>
        <Modal.Header
          style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
        >
          <div className="flex justify-between">
            <h3 className="text-xl font-bold">
              {t("In my opinion - a comment")}
            </h3>
            <AiFillCloseCircle
              onClick={() => setOpinionModalOpen(false)}
              className="text-primse-gray-font cursor-pointer w-8 h-8"
            />
          </div>
        </Modal.Header>

        <Modal.Content>
          <div className="p-2">
            <Form onSubmit={onSubmit}>
              <div className="p-2">
                <TextArea
                  maxLength="350"
                  value={customText}
                  onChange={(e) => setCustomText(e.target.value)}
                />
                <Message warning={true} visible={true} size="small">
                  {t("The maximum length of a comment is 350 characters")}
                </Message>
              </div>
              <div className="p-2 flex justify-end">
                <Button type="submit">{t("Generate")}</Button>
              </div>
            </Form>
          </div>
        </Modal.Content>
      </Modal>
      {loading && <Loader />}
    </>
  );
}

export default MyOpinionModal;
