import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";
import QuestionsView from "./QuestionsView";
import QuestionsService from "../../services/QuestionsService";

const Questions = () => {
  const [refresh, setRefresh] = useState(false);
  const [questionsList, setQuestionsList] = useState(null);

  const { data } = useQuery("questions", QuestionsService.getQuestions, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      if (data.status === 200) {
        setQuestionsList(data.data);
      } else {
        setQuestionsList({ payload: [] });
      }
    }
  }, [data]);

  useEffect(() => {
    if (refresh === true) {
      setQuestionsList(null);
      QuestionsService.getQuestions()
        .then((res) => {
          if (res.status === 200) {
            setQuestionsList(res.data);
          } else {
            setQuestionsList({ payload: [] });
          }
        })
        .finally(() => setRefresh(false));
    }
  }, [refresh]);

  const handleRefresh = (value) => {
    setRefresh(value);
  };

  return (
    <main className="py-0">
      {questionsList !== null && !refresh && (
        <QuestionsView data={questionsList} handleRefresh={handleRefresh} />
      )}
      {(questionsList === null && <Loader />) || (refresh && <Loader />)}
    </main>
  );
};

export default Questions;
