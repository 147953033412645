import axios from "../utils/axios";

const ClientsService = {
  getClients: async (propsParams) => {
    return await axios
      .get("/client-index", {
        params: {
          perPage: 10000,
          ...propsParams,
        },
      })
      .then((res) => {
        return res;
      });
  },

  getClient: (contact_uuid) => async () => {
    return await axios.get(`/client-view/${contact_uuid}`).then((res) => {
      if (res?.status === 200 && res?.data?.payload) {
        return res.data.payload;
      } else return null;
    });
  },

  addClient: async ({
    first_name,
    last_name,
    work_phone,
    work_email,
    description,
  }) => {
    return await axios.post("/client-create", {
      first_name: `${first_name}`,
      last_name: `${last_name}`,
      work_phone: `${work_phone}`,
      work_email: `${work_email}`,
      description: `${description}`,
    });
  },

  updateClient: async ({
    client_uuid,
    first_name,
    last_name,
    work_phone,
    work_email,
    description,
  }) => {
    return await axios
      .post(`/client-update/${client_uuid}`, {
        first_name: `${first_name}`,
        last_name: `${last_name}`,
        work_phone: `${work_phone}`,
        work_email: `${work_email}`,
        description: `${description}`,
      })
      .then(() => {
        // console.log(res)
      });
  },

  getSearchedClient: async ({
    last_name,
    email,
    phone,
    assigned_to,
    created_from,
    created_to,
  }) => {
    return await axios.get("/client-index", {
      params: {
        perPage: 1000,
        last_name: `${last_name}`,
        email: `${email}`,
        phone: `${phone}`,
        assigned_to: `${assigned_to}`,
        created_from: `${created_from}`,
        created_to: `${created_to}`,
      },
    });
  },

  getForm: async () => {
    return await axios.get("/client-search-form").then((res) => res.data);
  },

  deleteClient: async (uuid) => {
    return await axios.post(`/client-delete/${uuid}`).then((res) => res);
  },
};

export default ClientsService;
