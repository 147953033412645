import { action, computed } from "easy-peasy";

const basketStore = {
    currentBasketUuid: null,
    baskets: {},
    basketsItems: {},
    basketModalOpen: false,
    basketInfoPopup: false,
    setBasketModal: action((state, setOpen) => {
        state.basketModalOpen = setOpen;
    }),
    setBasketInfoPopup: action((state, setOpen) => {
        state.basketInfoPopup = setOpen;
    }),
    setCurrentBasket: action((state, uuid) => {
        state.currentBasketUuid = uuid;
    }),
    setAllBaskets: action((state, basketsList) => {
        state.baskets = basketsList.reduce((memo, next)=> {
            memo[next.uuid] = next;
            return memo;
        }, {});
    }),
    setBasketItems: action((state, { basketId, basketItemsList }) => {
        state.basketsItems = {
            ...state.basketsItems,
            [basketId]: basketItemsList
        };

    }),
    setOneBasket: action((state, basket) => {
        state.baskets = {
            ...state.baskets,
            [basket.uuid]: basket
        };
    }),
    // currentBasket: computed((state) => state.baskets[state.currentBasketUuid]),
    currentBasket: computed((state) => ({
        ...state.baskets[state.currentBasketUuid],
        basketItems: state.basketsItems[state.currentBasketUuid] || []
    }))
    ,
    basketsList: computed((state) => Object.values(state.baskets)),
    updateStoreBasket: action((state, { uuid, name }) => {
        state.baskets[uuid] = {...state.baskets[uuid], name};
    }),
    cleanStoreBasket: action((state) => {
        state.baskets = {};
    }),
    deleteStoreBasket: action((state, { uuid }) => {
        delete state.baskets[uuid];
        delete state.baskets.currentBasket;
    }),
    deleteItemFromBasket: action((state, uuid ) => {
        state.basketsItems[state.currentBasketUuid]
            .splice(state.basketsItems[state.currentBasketUuid]
                .findIndex(basketItem => basketItem.uuid === uuid), 1);
    })
};

export default basketStore;