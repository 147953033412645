import { useQuery } from "react-query";
import { Modal } from "semantic-ui-react";
import Loader from "../../Loader";
import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import BasketsService from "../../../services/BasketsService";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { actions } from "../../../store";
import { ArrowRight } from "react-feather";
import { editPrice } from "../../../utils/numbers";
import cs from "classnames";

import DetailsInvestments from "../../DetailsInvestments";

const MyCartContent = ({ display }) => {
  const history = useHistory();
  const { t } = useTranslation();

  function handleInvestmentDetails(link) {
    history.push(`/investment/${link}`);
    actions.baskets.setBasketModal(false);
  }

  function handleRealestateDetails(link) {
    history.push(`/realestate/${link}`);
    actions.baskets.setBasketModal(false);
  }

  function handleGoToSearch() {
    history.push(`/`);
    actions.baskets.setBasketModal(false);
  }

  const [refresh, setRefresh] = useState(false);

  const { currentBasket, currentBasketUuid } = useStoreState(
    (state) => state.baskets
  );

  const userAgencyType = useStoreState((state) => state.auth.user?.agencyType);
  const { isLoading } = useQuery(
    ["basket", currentBasketUuid],
    BasketsService.getBasket(currentBasketUuid)
  );

  const handleDelete = (basket_item_uuid, type) => {
    setRefresh(true);
    BasketsService.removeItemFromBasket({
      basket_item_uuid: basket_item_uuid,
    }).then((res) => {
      if (res.response.status === 410) {
        actions.baskets.deleteItemFromBasket(basket_item_uuid);
        switch (type) {
          case "flat":
            toast.success(
              `${t("Apartment from the cart has been successfully deleted")}`
            );
            break;
          case "investment":
            toast.success(
              `${t("Investment from the cart has been successfully deleted")}`
            );
            break;
        }
        setRefresh(false);
      } else {
        setRefresh(false);
        toast.error(`${t("Item could not be deleted")}`);
      }
    });
  };

  const sortInvestment = useMemo(() => {
    const mapInvestment = currentBasket.basketItems.filter(
      ({ is_secondary_market }) => !is_secondary_market
    );

    const arrItems = [];

    mapInvestment?.forEach((el) => {
      const filter = arrItems.filter(
        ({ investment_id }) => investment_id === el.investment_id
      );
      if (filter?.length === 0) {
        arrItems.push({
          investment_uuid: el.investment_uuid,
          investment_id: el.investment_id,
          investment_name: el.investment_name,
          investment_city: el.investment_city,
          investment_district: el.investment_district,
          investment_status: el.investment_status,

          items: [],
        });
      }
    });

    mapInvestment?.forEach((el) => {
      const filter = arrItems
        .map(({ investment_id }) => {
          if (investment_id === el.investment_id) {
            return el;
          } else return null;
        })
        .filter((el) => el !== null);

      function checkIndex(element) {
        return element.investment_id === el.investment_id;
      }
      const index = arrItems.findIndex(checkIndex);

      arrItems[index].items = [...arrItems[index].items, { ...filter }];
    });

    return arrItems;
  }, [currentBasket]);

  // const emptyBasketAgencyUser = useMemo(() => {
  //   if (!currentBasket.basketItems) return false;
  //
  //   if (
  //     currentBasket.basketItems?.length > 0 &&
  //     userAgencyType === "Metrohouse office"
  //   ) {
  //     let count = 0;
  //
  //     currentBasket.basketItems?.forEach((el) => {
  //       if (el.investment_name === "Rynek wtórny") {
  //         count++;
  //       }
  //     });
  //
  //     return count !== 0 && count === currentBasket.basketItems?.length;
  //   }
  // }, [currentBasket, display]);

  const sortInvestmentSecondatyMarket = useMemo(() => {
    const mapSecondaryMarket = currentBasket.basketItems.filter(
      ({ is_secondary_market }) => is_secondary_market
    );
    return mapSecondaryMarket;
  }, [currentBasket]);

  // const uuidSecondatyMarket = useMemo(() => {
  //   const mapSecondaryMarket = currentBasket.basketItems.filter(
  //     ({ is_secondary_market }) => is_secondary_market
  //   );
  //
  //   if (mapSecondaryMarket?.length !== 0) {
  //     return mapSecondaryMarket[0].investment_uuid;
  //   } else return null;
  // }, [currentBasket]);

  return (
    <Modal.Content
      style={{
        paddingTop: "0",
        height: "400px",
        overflow: "auto",
        display: display ? "block" : "none",
      }}
    >
      {refresh && <Loader />}
      {currentBasket.basketItems?.length === 0 && (
        <div className="flex flex-col items-center">
          <div className="uppercase font-medium text-center pt-4">
            {t("There are no objects in the cart...")}
          </div>
          <button
            className="leading-5 w-46 m-4 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
            onClick={() => handleGoToSearch()}
          >
            <div className="flex">
              {t("Go to the search engine")}
              <ArrowRight className="text-white ml-2 " size={20} />
            </div>
          </button>
        </div>
      )}
      {currentBasket.basketItems?.length === 1 &&
        !currentBasket.basketItems[0].realestate_uuid &&
        currentBasket.basketItems[0].investment_name === "Rynek wtórny" && (
          <div className="flex flex-col items-center">
            <div className="uppercase font-medium text-center pt-4">
              {t("There are no objects in the cart...")}
            </div>
            <button
              className="leading-5 w-46 m-4 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
              onClick={() => handleGoToSearch()}
            >
              <div className="flex">
                {t("Go to the search engine")}
                <ArrowRight className="text-white ml-2 " size={20} />
              </div>
            </button>
          </div>
        )}

      {!refresh &&
        sortInvestment &&
        sortInvestment.map((item) => (
          <div key={`${item.investment_id}`}>
            <div className="border-b p-2">
              <div className="flex justify-between">
                {item.investment_status === "On sale" ? (
                  <div
                    className="font-bold hover:text-primse-green cursor-pointer"
                    onClick={() =>
                      handleInvestmentDetails(item.investment_uuid)
                    }
                  >
                    {item.investment_name}
                    {item.investment_city && `, ${item.investment_city}`}
                    {item.investment_district &&
                      `, ${item.investment_district}`}
                  </div>
                ) : (
                  <div className="font-bold  ">
                    {item.investment_name}
                    {item.investment_city && `, ${item.investment_city}`}
                    {item.investment_district &&
                      `, ${item.investment_district}`}
                  </div>
                )}
                {item.items?.length === 1 && !item.items[0][0].realestate_name && (
                  <button
                    className="leading-5 w-46 mb-1 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-1 px-5 rounded-sm transition-all hover:bg-primse-gray"
                    onClick={() => {
                      handleDelete(item.items[0][0].uuid, "investment");
                    }}
                  >
                    {t("Delete from basket")}
                  </button>
                )}
              </div>

              <div className="text-sm text-gray-500">
                {item.investment_name}
              </div>
            </div>
            {item.items.map(
              (element) =>
                element[0].realestate_name && (
                  <div>
                    <div className="border-b p-2">
                      <div className="ml-4 text-gray-500  flex justify-between">
                        <div
                          className={cs(
                            element?.[0]?.realestate_uuid
                              ? "hover:text-primse-green cursor-pointer"
                              : ""
                          )}
                          onClick={() => {
                            if (element?.[0]?.realestate_uuid) {
                              handleRealestateDetails(
                                element?.[0]?.realestate_uuid
                              );
                            }
                          }}
                        >
                          {element[0].realestate_name
                            ? element[0].realestate_name
                            : null}
                        </div>
                        <button
                          className="leading-5 w-46 mb-1 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-1 px-5 rounded-sm transition-all hover:bg-primse-gray"
                          onClick={() => {
                            handleDelete(element[0].uuid, "flat");
                          }}
                        >
                          {t("Delete from basket")}
                        </button>
                      </div>
                      <div className="ml-4 text-gray-500 flex justify-between">
                        <div className="flex">
                          <div>
                            <span className="font-semibold">
                              {t("Price")}:{" "}
                            </span>
                            <span>
                              {element[0]?.realestate_price &&
                              element[0]?.ask_for_price !== "1" ? (
                                `${editPrice(element[0]?.realestate_price)} PLN`
                              ) : (
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    history.push(
                                      `/questions?r_uuid=${element[0]?.realestate_uuid}&i_uuid=${element[0]?.investment_uuid}`
                                    );
                                    actions.baskets.setBasketModal(false);
                                  }}
                                >
                                  {t("Ask about price")}
                                </span>
                              )}{" "}
                            </span>
                          </div>
                          <div className="ml-4">
                            <span className="font-semibold">
                              {" "}
                              {t("Rooms")}:{" "}
                            </span>
                            <span>
                              {element[0].realestate_rooms &&
                                element[0].realestate_rooms}
                            </span>
                          </div>
                          <div className="ml-4">
                            <span className="font-semibold">
                              {" "}
                              {t("Area")}:{" "}
                            </span>
                            <span>
                              {element[0].realestate_area &&
                                element[0].realestate_area}{" "}
                              m<sup>2</sup>
                            </span>
                          </div>
                        </div>
                        <div>
                          <DetailsInvestments
                            PDFLink={element[0].realestate_pdf}
                            PDFName={element[0].realestate_name}
                            planLink={element[0].realestate_plan}
                            uuid={element[0].uuid}
                            pathname={history.location.pathname}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        ))}

      {!refresh &&
        userAgencyType !== "Metrohouse office" &&
        sortInvestmentSecondatyMarket?.length > 1 && (
          <div>
            <div className="border-b p-2 flex justify-between">
              <div className="font-bold  w-full">
                <div className="flex justify-between">
                  <div>{t("Secondary market")} </div>
                  {/* <div className=" flex justify-between">
                  {sortInvestmentSecondatyMarket?.length === 1 &&
                    !sortInvestmentSecondatyMarket[0].realestate_name && (
                      <button
                        className="w-46 mb-1 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-1 px-5 rounded-sm transition-all hover:bg-primse-gray"
                        onClick={() => {
                          handleDelete(
                            sortInvestmentSecondatyMarket[0].uuid,
                            "investment"
                          );
                        }}
                      >
                        {t("Delete from basket")}
                      </button>
                    )}
                </div>{" "} */}
                </div>
                <div className="text-sm text-gray-500 font-normal">
                  {t("Secondary market")}
                </div>
              </div>
            </div>

            {sortInvestmentSecondatyMarket.map(
              ({
                realestate_name,
                realestate_price,
                realestate_uuid,
                uuid,
                realestate_plan,
                realestate_pdf,
                realestate_rooms,
                realestate_area,
              }) => {
                if (realestate_name) {
                  return (
                    <div key={uuid} className="border-b p-2">
                      <div className="ml-4 text-gray-500 flex justify-between ">
                        <div
                          className={cs(
                            realestate_uuid
                              ? "hover:text-primse-green cursor-pointer"
                              : ""
                          )}
                          onClick={() => {
                            if (realestate_uuid) {
                              handleRealestateDetails(realestate_uuid);
                            }
                          }}
                        >
                          {realestate_name}
                        </div>
                        <button
                          className="leading-5 w-46 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-1 px-5 rounded-sm transition-all hover:bg-primse-gray"
                          onClick={() => {
                            handleDelete(uuid, "flat");
                          }}
                        >
                          {t("Delete from basket")}
                        </button>
                      </div>
                      <div className="ml-4 text-gray-500 flex justify-between ">
                        <div className="flex">
                          <div>
                            <span className="font-semibold">
                              {t("Price")}:{" "}
                            </span>
                            <span>
                              {realestate_price
                                ? `${editPrice(realestate_price)} PLN`
                                : null}{" "}
                            </span>
                          </div>
                          <div className="ml-4">
                            <span className="font-semibold">
                              {" "}
                              {t("Rooms")}:{" "}
                            </span>
                            <span>{realestate_rooms && realestate_rooms}</span>
                          </div>
                          <div className="ml-4">
                            <span className="font-semibold">
                              {" "}
                              {t("Area")}:{" "}
                            </span>
                            <span>
                              {realestate_area && realestate_area} m<sup>2</sup>
                            </span>
                          </div>
                        </div>

                        <div>
                          <DetailsInvestments
                            PDFLink={realestate_pdf}
                            PDFName={realestate_name}
                            planLink={realestate_plan}
                            uuid={uuid}
                            pathname={history.location.pathname}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }
              }
            )}
          </div>
        )}

      {isLoading && <Loader />}
    </Modal.Content>
  );
};
export default MyCartContent;
