import { action, computed } from "easy-peasy";

const authStore = {
  user: null,
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setUserDefaultCities: action((state, payload) => {
    state.user.defaultCities = payload;
  }),
  isLoginModalOpen: false,
  showLoginModal: action((state) => {
    state.isLoginModalOpen = true;
  }),
  hideLoginModal: action((state) => {
    state.isLoginModalOpen = false;
  }),
  isLoggedIn: computed((state) => !!state.user),
};

export default authStore;
