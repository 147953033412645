import { action } from "easy-peasy";

const modalsStore = {
  editUserModal: false,
  warningModal: false,
  requestModal: false,
  problemModal: false,
  problemModalStatus: "",

  openEditUserModal: action((state, payload) => {
    state.editUserModal = payload;
  }),
  openWarningModal: action((state, payload) => {
    state.warningModal = payload;
  }),
  openRequestModal: action((state, payload) => {
    state.requestModal = payload;
  }),
  openProblemModal: action((state, payload) => {
    state.problemModal = payload;
  }),
  setProblemModalStatus: action((state, payload) => {
    state.problemModalStatus = payload;
  }),
};

export default modalsStore;
