import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox } from "semantic-ui-react";
import FilterModal from "./FilterModal";
import { useTranslation } from "react-i18next";

const MarketDropdown = ({ control }) => {
  const { t } = useTranslation();

  const getValues = (checked, values, key) => {
    if (checked) {
      return [...values, key];
    } else {
      return values.filter((v) => v !== key);
    }
  };

  return (
    <Controller
      control={control}
      name="market"
      render={({ field: { onChange, value: values } }) => {
        return (
          <FilterModal
            title={t("Market type")}
            onReset={() => {
              onChange([]);
            }}
          >
            <ul>
              <li className="mb-2">
                <Checkbox
                  label={t("Primary market")}
                  checked={values.includes("Primary market")}
                  onChange={(_, e) => {
                    onChange(getValues(e.checked, values, "Primary market"));
                  }}
                />
              </li>
              <li className="mb-2">
                <Checkbox
                  label={t("Secondary market")}
                  checked={values.includes("Secondary market")}
                  onChange={(_, e) => {
                    onChange(getValues(e.checked, values, "Secondary market"));
                  }}
                />
              </li>
            </ul>
          </FilterModal>
        );
      }}
    />
  );
};

export default MarketDropdown;
