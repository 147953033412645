import { useTranslation } from "react-i18next";
import { actions } from "../../../../store";
import { BriefCasePlusWhite } from "../../../../components/Icons";

const ButtonAddToBriefcase = ({ type = "icon", uuid, name, investment }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    actions.briefcase.setBriefcaseAddMobile({
      uuid,
      name,
      investment,
    });
    actions.briefcase.setBriefcaseModalSelectClient(true);
    actions.briefcase.setBriefcaseModal(true);
  };

  return (
    <>
      {type === "text" ? (
        <div onClick={handleClick} className="md:hidden my-3">
          <button className="text-base uppercase font-bold text-white bg-primse-green py-4 px-5 rounded-sm w-full">
            {t("Add to client briefcase")}
          </button>
        </div>
      ) : (
        <div onClick={handleClick} className="md:hidden">
          <div className="bg-primse-green ml-3 rounded-sm flex justify-center items-center cursor-pointer transition-all duration-300 hover:opacity-80 w-16 h-16">
            <div className="w-12">
              <BriefCasePlusWhite />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ButtonAddToBriefcase;
