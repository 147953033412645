import React, { useEffect, useMemo, useState } from "react";
import Container from "../../../components/Container";
import { Mail, Phone } from "react-feather";
import { TiUser } from "react-icons/all";
import cs from "classnames";
import { Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import BreadcrumbExample from "../../../components/Breadcrumb";
import NewsSection from "../../../components/NewsSection";
import ButtonNavLink from "../../../components/ButtonNavLink";
import { RequestDocumentTable } from "../RequestDocumentTable";
import SingleActionModal from "../SingleActionModal";
import Moment from "react-moment";
import { useStoreState } from "easy-peasy";
import {
  applicationStatusIcon,
  applicationStatusData,
  colorStatus,
} from "../../../utils/statusUtils";
import PresentationTable from "../PresentationTable";
import { daysLeft } from "../../../utils/numbers";
import { useHistory } from "react-router-dom";
import RequestsServices from "../../../services/RequestsServices";

import useUserRole from "../../../hooks/useUserRole";
import ActionModalFromMail from "../Modals/ActionModalFromMail";

const RequestView = ({
  applicationData: {
    client_name,
    user_name,
    date,
    status,
    developer_name,
    investment_name,
    client_uuid,
    client_phone,
    client_email,
    expire,
    status_date,
    terms: { query_comment, required, additional },
    uuid,
    confirmation_document_created,
    confirmation_document_name,
    confirmation_document_uuid,
    decision_deadline,
    investment_uuid,
    client_name_first,
    client_name_last,
    id,
    developer_id,
    client_id,
    investment_id,
    agreement_garage_price_gross,
    agreement_garage_tax_type_id,
    agreement_cell_price_gross,
    agreement_cell_tax_type_id,
    commission_standard,
    agreement_factoring,
  },
  requestUuid,
  handleRefresh,
  requestHistory,
  infoData,
}) => {
  const PresentationCardText =
    "Wymagana karta prezentacji/Presentation card required";
  const { t } = useTranslation();
  const history = useHistory();
  const userRole = useStoreState((state) => state.auth.user?.role);
  const userPrimaryRole = useStoreState(
    (state) => state.auth.user?.primaryRole
  );
  const [requiredTermItems, setRequiredTermItems] = useState([]);
  const [additionalTermItems, setAdditionalTermItems] = useState([]);
  const { isDeveloper } = useUserRole();

  const [openModalFromMail, setOpenModalFromMail] = useState(false);
  const employment_type = useStoreState(
    (state) => state.auth.user?.employment?.employment_type
  );
  useEffect(() => {
    if (history?.location?.search) {
      const params = new URLSearchParams(history?.location?.search);

      const action = params.get("action");

      if (
        action === "accept" ||
        action === "reject" ||
        action === "add-comment"
      ) {
        setOpenModalFromMail(true);
        if (history?.location?.pathname) {
          history.replace(history?.location?.pathname);
        }
      }
    }
  }, [history?.location?.search]);

  const userPermmisions = (type) => {
    if (userPrimaryRole === "Admin") return true;
    if (userPrimaryRole === "Top Admin") return true;
    if (type === "Agency" && userRole === "Agency") return true;
    else if (type === "Developer" && userRole === "Deweloper") return true;
    else return false;
  };

  const BREADCRUMB_DATA = useMemo(() => {
    return [
      {
        name: "Main page",
        linkPage: "/",
        uuid: `${requestUuid}`,
      },
      {
        name: `Requests`,
        linkPage: `requests`,
        uuid: `${requestUuid}`,
      },
    ];
  }, []);

  useEffect(() => {
    if (required && Array.isArray(required)) {
      const findRequiredText = required?.find(
        (el) => el?.text === PresentationCardText
      );
      if (findRequiredText && findRequiredText?.doc_uuid) {
        const getAllTerms = async (term_uuid) => {
          try {
            await RequestsServices.getRequestTermHistory(uuid, term_uuid).then(
              (res) => {
                if (res?.status === 200) {
                  setRequiredTermItems(res?.data?.payload);
                }
              }
            );
          } catch (error) {
            console.log(error);
          }
        };
        getAllTerms(findRequiredText.term_uuid);
      }
    }

    if (additional && Array.isArray(additional)) {
      const findAdditionalText = additional?.find(
        (el) => el?.text === PresentationCardText
      );
      if (findAdditionalText && findAdditionalText?.doc_uuid) {
        const getAllTerms = async (term_uuid) => {
          try {
            await RequestsServices.getRequestTermHistory(uuid, term_uuid).then(
              (res) => {
                if (res?.status === 200) {
                  setAdditionalTermItems(res?.data?.payload);
                }
              }
            );
          } catch (error) {
            console.log(error);
          }
        };
        getAllTerms(findAdditionalText.term_uuid);
      }
    }
  }, [required, additional]);

  return (
    <main className="mt-4">
      <div style={{ minHeight: "80vh" }}>
        <BreadcrumbExample
          pages={BREADCRUMB_DATA}
          returnPage={"Requests"}
          current={[client_name].filter((value) => value).join(" ")}
        />
        <Container>
          <header className="border items-center justify-between flex">
            <div className="w-full flex flex-col lg:flex-row lg:justify-between">
              <div>
                <div className="pt-4 px-2 flex   justify-between w-full items-center">
                  <div className="mx-2 flex  flex-col md:flex-row items-start md:items-end justify-between">
                    <div className="mx-4 flex   justify-between items-center">
                      <div className="ml-0 mr-2 md:mx-2 text-primse-green">
                        <TiUser size={20} />
                      </div>
                      {userRole !== "Deweloper" ? (
                        <ButtonNavLink
                          uuid={client_uuid}
                          link={`/client/${client_uuid}`}
                          name={`${client_name}`}
                        />
                      ) : (
                        client_name
                      )}
                    </div>
                    <div className="mx-4 flex   justify-between items-center">
                      <div className="ml-0 mr-2 md:mx-2 text-primse-green">
                        <Phone size={20} />
                      </div>
                      {client_phone}
                    </div>
                    <div className="mx-4 flex  justify-between items-center my-3 md:my-0">
                      <div className="ml-0 mr-2 md:mx-2 text-primse-green">
                        <Mail size={20} />
                      </div>
                      {client_email}
                    </div>
                  </div>
                </div>

                <div className="pt-2 px-2 pb-2 flex  justify-between w-full items-center">
                  <div className="mx-2 flex flex-col md:flex-row items-start md:items-center justify-between">
                    <div className="my-2 md:my-0 mx-4 md:mx-6 flex   justify-between items-center">
                      {t("Applicant")}:{" "}
                      <span className="font-bold mx-2">{user_name}</span>
                    </div>
                    <div className="my-2 md:my-0 mx-4 flex justify-between items-center">
                      {t("Filing date")}:{" "}
                      <span className="font-bold mx-2">
                        <Moment format="YYYY-MM-DD, HH:mm:ss">{date}</Moment>
                      </span>
                    </div>
                    <div className="my-2 md:my-0 mx-4 flex items-center">
                      {t("Report status")}:
                      <div className="my-2 md:my-0 ml-2 flex items-center">
                        {applicationStatusIcon(status)}
                        <div
                          className={cs(
                            "font-bold mx-2 flex flex-col",
                            colorStatus(status)
                          )}
                        >
                          <span>{`${applicationStatusData(status)} ${t(
                            "from"
                          )}`}</span>
                          <Moment
                            format="YYYY-MM-DD, HH:mm:ss"
                            className="whitespace-nowrap"
                          >
                            {status_date}
                          </Moment>
                        </div>
                      </div>
                    </div>
                    {decision_deadline && (
                      <div className="my-2 md:my-0 mx-4 flex items-center">
                        {t("Developer decision by")}:
                        <div
                          className={cs(
                            "my-2 md:my-0 ml-2 flex flex-col items-center font-bold",
                            {
                              "text-yellow-400":
                                decision_deadline &&
                                daysLeft(decision_deadline) === 1,
                            },
                            {
                              "text-red-400":
                                decision_deadline &&
                                daysLeft(decision_deadline) < 1,
                            }
                          )}
                        >
                          {`${decision_deadline}, ${t("Left")}:`}
                          <div>
                            {daysLeft(decision_deadline) > 0
                              ? `${daysLeft(decision_deadline)} ${t("Days")}`
                              : `0 ${t("Days")}`}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {(status === "Accepted" ||
                  status === "Accepted conditionally") &&
                  (employment_type === "Agency" ||
                    userPrimaryRole === "Admin" ||
                    userPrimaryRole === "Top Admin") && (
                    <div className="px-10 pb-4 w-max">
                      <PresentationTable
                        handleRefresh={handleRefresh}
                        request_uuid={uuid}
                        confirmation_document_created={
                          confirmation_document_created
                        }
                        confirmation_document_name={confirmation_document_name}
                        confirmation_document_uuid={confirmation_document_uuid}
                        client_id={client_id}
                        investment_id={investment_id}
                      />
                    </div>
                  )}
              </div>
              {!infoData?.investment?.promoting_agency_offer ? (
                <div className="flex flex-col justify-evenly ">
                  {applicationStatusData(status) === "Zaakceptowane" &&
                    userPermmisions("Agency") && (
                      // userPermmisions("ADMIN") && (
                      <div className="flex justify-center items-center mx-6">
                        <button
                          onClick={() =>
                            history.push("/transaction/create", {
                              data: {
                                investment_uuid,
                                investment_name,
                                developer_name,
                                client_first_name: client_name_first,
                                client_last_name: client_name_last,
                                id,
                                client_phone,
                                client_email,
                                developer_id,
                                client_id,
                                investment_id,
                                parking_price_gross: agreement_garage_price_gross
                                  ? parseFloat(
                                      agreement_garage_price_gross
                                    ).toFixed(2)
                                  : "0.00",
                                parking_extra_1_price_gross: agreement_garage_price_gross
                                  ? parseFloat(
                                      agreement_garage_price_gross
                                    ).toFixed(2)
                                  : "0.00",
                                parking_extra_2_price_gross: agreement_garage_price_gross
                                  ? parseFloat(
                                      agreement_garage_price_gross
                                    ).toFixed(2)
                                  : "0.00",
                                parking_tax_type_id: agreement_garage_tax_type_id
                                  ? agreement_garage_tax_type_id
                                  : "1",
                                parking_extra_1_tax_type_id: agreement_garage_tax_type_id
                                  ? agreement_garage_tax_type_id
                                  : "1",
                                parking_extra_2_tax_type_id: agreement_garage_tax_type_id
                                  ? agreement_garage_tax_type_id
                                  : "1",
                                storage_price_gross: agreement_cell_price_gross
                                  ? parseFloat(
                                      agreement_cell_price_gross
                                    ).toFixed(2)
                                  : "0.00",
                                storage_extra_1_price_gross: agreement_cell_price_gross
                                  ? parseFloat(
                                      agreement_cell_price_gross
                                    ).toFixed(2)
                                  : "0.00",
                                storage_extra_2_price_gross: agreement_cell_price_gross
                                  ? parseFloat(
                                      agreement_cell_price_gross
                                    ).toFixed(2)
                                  : "0.00",
                                storage_tax_type_id: agreement_cell_tax_type_id
                                  ? agreement_cell_tax_type_id
                                  : "1",
                                storage_extra_1_tax_type_id: agreement_cell_tax_type_id
                                  ? agreement_cell_tax_type_id
                                  : "1",
                                storage_extra_2_tax_type_id: agreement_cell_tax_type_id
                                  ? agreement_cell_tax_type_id
                                  : "1",
                                commission_total_percentage: commission_standard
                                  ? parseFloat(commission_standard)
                                  : "0",
                                agreement_factoring: agreement_factoring,

                                agreement_factoring_commission: "0",

                                agreement_commission: "0",
                              },
                            })
                          }
                          className="leading-5 w-56 mx-2 font-bold text-white uppercase bg-primse-green py-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                        >
                          {t("Otwórz transakcję")}
                        </button>
                      </div>
                    )}
                  <div className="flex lg:items-center flex-col justify-center">
                    {applicationStatusData(status) === "Oczekujące" &&
                      isDeveloper() && (
                        <div>
                          <div className="flex justify-between lg:justify-end lg:items-center ">
                            <div className="my-2">
                              <SingleActionModal
                                accept={true}
                                requestUuid={requestUuid}
                                handleRefresh={handleRefresh}
                                infoData={infoData}
                                confirmButtonText={"Accept2"}
                              />
                            </div>
                            <div className="my-2">
                              <SingleActionModal
                                accept={false}
                                requestUuid={requestUuid}
                                handleRefresh={handleRefresh}
                                infoData={infoData}
                                confirmButtonText={"Decline"}
                              />
                            </div>
                          </div>
                          <ActionModalFromMail
                            requestUuid={requestUuid}
                            handleRefresh={handleRefresh}
                            infoData={infoData}
                            open={openModalFromMail}
                            setOpen={setOpenModalFromMail}
                          />
                        </div>
                      )}
                    {applicationStatusData(status) === "Awaiting" &&
                      isDeveloper() && (
                        <div className="flex justify-between lg:justify-end lg:items-center ">
                          <div className="my-2">
                            <SingleActionModal
                              accept={true}
                              requestUuid={requestUuid}
                              handleRefresh={handleRefresh}
                              infoData={infoData}
                              confirmButtonText={"Accept2"}
                            />
                          </div>
                          <div className="my-2">
                            <SingleActionModal
                              accept={false}
                              requestUuid={requestUuid}
                              handleRefresh={handleRefresh}
                              infoData={infoData}
                              confirmButtonText={"Decline"}
                            />
                          </div>
                          <ActionModalFromMail
                            requestUuid={requestUuid}
                            handleRefresh={handleRefresh}
                            infoData={infoData}
                            open={openModalFromMail}
                            setOpen={setOpenModalFromMail}
                          />
                        </div>
                      )}
                    {isDeveloper() && (
                      <div className="flex justify-between mb-2 w-full  lg:justify-end lg:items-center mb-2">
                        <SingleActionModal
                          customTrigger={{
                            style: `leading-5 ${
                              applicationStatusData(status) === "Awaiting" ||
                              applicationStatusData(status) === "Oczekujące"
                                ? "w-full px-6"
                                : "w-56 px-2 mr-8"
                            } mx-2 font-bold text-white uppercase bg-primse-green py-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white`,
                            text:
                              applicationStatusData(status) === "Awaiting" ||
                              applicationStatusData(status) === "Oczekujące"
                                ? t("Add comment without decision")
                                : t("Add comment"),
                          }}
                          customTitle={t("Add a comment to request")}
                          requestUuid={requestUuid}
                          handleRefresh={handleRefresh}
                          infoData={infoData}
                          confirmButtonText={
                            applicationStatusData(status) === "Awaiting" ||
                            applicationStatusData(status) === "Oczekujące"
                              ? t("Add a comment without making a decision")
                              : t("Add comment")
                          }
                          onlyComent
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </header>

          {!infoData?.investment?.promoting_agency_offer ? (
            <main className="mt-t">
              <div className="border p-2">
                <h3 className="border-b border-primse-green uppercase p-2  text-xl font-bold mb-2">
                  {t("Request conditions")}
                </h3>
                <div className="mx-2 ">
                  <h3 className="text-xl font-bold">
                    {t("Valid until")}:{" "}
                    <span className="text-primse-green ml-2">
                      {expire && expire !== "1970-01-01" && expire}
                      {expire && expire === "1970-01-01" && ` ${t("No data")}`}
                      {!expire && ` ${t("No data")}`}
                    </span>
                  </h3>
                </div>
                <Table celled structured textAlign="center">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{ textTransform: "uppercase" }}>
                        {t("Developer")}
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ textTransform: "uppercase" }}>
                        {t("Investment")}
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ textTransform: "uppercase" }}>
                        {t("Required attachments")}
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ textTransform: "uppercase" }}>
                        {t("Additional terms")}
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ textTransform: "uppercase" }}>
                        {t("Additional information")}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{developer_name}</Table.Cell>
                      <Table.Cell>{investment_name}</Table.Cell>
                      <Table.Cell>
                        {required &&
                        required !== null &&
                        required?.length !== 0 ? (
                          <Table celled={true} structured textAlign="center">
                            <Table.Body>
                              {required.map((row) => {
                                return (
                                  <RequestDocumentTable
                                    PresentationCardText={PresentationCardText}
                                    termsItems={requiredTermItems}
                                    key={row.term_uuid}
                                    required={true}
                                    requestUuid={requestUuid}
                                    rowData={row}
                                    handleRefresh={handleRefresh}
                                    requestStatus={status}
                                  />
                                );
                              })}
                            </Table.Body>
                          </Table>
                        ) : (
                          t("The request does not require any attachments")
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Table celled structured textAlign="center">
                          <Table.Body>
                            {additional.map((row) => (
                              <RequestDocumentTable
                                required={false}
                                PresentationCardText={PresentationCardText}
                                termsItems={additionalTermItems}
                                requestUuid={requestUuid}
                                rowData={row}
                                key={row.term_uuid}
                                handleRefresh={handleRefresh}
                                requestStatus={status}
                              />
                            ))}
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                      <Table.Cell>{query_comment}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </main>
          ) : null}
          <div className="w-7/12">
            <div className="p-2">
              <h3 className="  uppercase p-2  text-xl font-bold mb-2">
                {t("Change history")}
              </h3>

              {requestHistory?.length !== 0 && (
                <Table
                  celled
                  structured
                  textAlign="center"
                  size="small"
                  className="w-7/12"
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        style={{
                          textTransform: "uppercase",
                          fontSize: "0.9em",
                        }}
                      >
                        {t("Operation type")}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{
                          textTransform: "uppercase",
                          fontSize: "0.9em",
                        }}
                      >
                        {t("Previous value")}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{
                          textTransform: "uppercase",
                          fontSize: "0.9em",
                        }}
                      >
                        {t("New value")}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{
                          textTransform: "uppercase",
                          fontSize: "0.9em",
                        }}
                      >
                        {t("User")}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{
                          textTransform: "uppercase",
                          fontSize: "0.9em",
                        }}
                      >
                        {t("Created")}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{
                          textTransform: "uppercase",
                          fontSize: "0.9em",
                        }}
                      >
                        {t("Comment")}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {requestHistory.map(
                      (
                        { created, label, user, old_value, new_value, comment },
                        index
                      ) => (
                        <Table.Row
                          className=" hover:bg-primse-light-green w-7/12"
                          key={`${created}_${user}_${old_value}_${new_value}_${label}_${index}_`}
                        >
                          <Table.Cell
                            className="w-1/12"
                            style={{ fontSize: "0.9em" }}
                          >
                            {t(label)}
                          </Table.Cell>
                          <Table.Cell
                            className="w-1/12"
                            style={{ fontSize: "0.9em" }}
                          >
                            {t(old_value)}
                          </Table.Cell>
                          <Table.Cell
                            className="w-1/12"
                            style={{ fontSize: "0.9em" }}
                          >
                            {t(new_value)}
                          </Table.Cell>
                          <Table.Cell
                            className="w-1/12"
                            style={{ fontSize: "0.9em" }}
                          >
                            {user}
                          </Table.Cell>
                          <Table.Cell
                            className="w-1/12"
                            style={{ fontSize: "0.9em" }}
                          >
                            {created}
                          </Table.Cell>
                          <Table.Cell
                            className="break-all w-6/12"
                            style={{ fontSize: "0.9em" }}
                          >
                            {comment}
                          </Table.Cell>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
              )}
            </div>
          </div>
        </Container>
      </div>
      <NewsSection />
    </main>
  );
};

// Reports.propTypes = { };

// Reports.defaultProps = { };

export default RequestView;
