import React, { useEffect, useState } from "react";
import Container from "../../components/Container";
import { useForm, Controller } from "react-hook-form";
import { Dropdown, Input, Pagination } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { X } from "react-feather";
import { useQuery } from "react-query";
import TransactionsServices from "../../services/TransactionsServices";
import {
  clearDuplicatesFromArray,
  handleSortDataDown,
  Td,
} from "../../utils/searchUtils";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import {
  transactionStatusData,
  transactionStatusIcon,
} from "../../utils/statusUtils";

import cs from "classnames";
import { editPrice } from "../../utils/numbers";
import Loader from "../../components/Loader";

const Transactions = () => {
  const { data, isLoading } = useQuery(
    "transactions",
    TransactionsServices.getTransactions,
    {
      refetchOnWindowFocus: false,
    }
  );

  const history = useHistory();

  const [sorted, setSorted] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [pagination, setPagination] = useState({
    data: data ? data : [],
    offset: 0,
    numberPerPage: 100,
    pageCount: 1,
    currentData: [],
  });

  function handleInvoiceDetails(link) {
    history.push(`/transaction/${link}`);
  }

  const handlePageClick = (event, { activePage }) => {
    setActivePage(activePage);
    const offset = (activePage - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      pageCount: Math.ceil(prevState.data?.length / prevState.numberPerPage),
      currentData: prevState.data.slice(
        pagination.offset,
        pagination.offset + pagination.numberPerPage
      ),
    }));
  }, [pagination.data, pagination.numberPerPage, pagination.offset]);

  useEffect(() => {
    if (!sorted) {
      handleSortDataDown(data, setPagination);
    }
  }, [data]);

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      invoiceNumber: "",
      invoiceStatus: "",
      createdFrom: "",
      createdTo: "",
      createdByUser: "",
    },
  });
  const { t } = useTranslation();

  const createdByUserOptions = () => {
    const createdByUserOptionsArr = [];
    data?.forEach(({ created_by, created_by_name }) => {
      createdByUserOptionsArr.push({
        value: created_by,
        text: created_by_name,
      });
    });

    return clearDuplicatesFromArray(createdByUserOptionsArr);
  };

  const onSubmit = (data) => {
    const result = pagination.data
      .filter(({ invoice_number }) => {
        if (data.invoiceNumber === "") {
          return true;
        } else if (
          invoice_number &&
          invoice_number.includes(data.invoiceNumber)
        ) {
          return true;
        } else return false;
      })
      .filter(({ status_id }) => {
        if (data.invoiceStatus === "") {
          return true;
        } else if (status_id && status_id === data.invoiceStatus) {
          return true;
        } else return false;
      })
      .filter(({ created_at }) => {
        if (data.createdFrom === "") {
          return true;
        } else if (created_at && created_at > data.createdFrom) {
          return true;
        } else return false;
      })
      .filter(({ created_at }) => {
        if (data.createdTo === "") {
          return true;
        } else if (created_at && created_at < data.createdTo) {
          return true;
        } else return false;
      })
      .filter(({ created_by }) => {
        if (data.createdByUser === "") {
          return true;
        } else if (created_by && created_by === data.createdByUser) {
          return true;
        } else return false;
      });

    handleSortDataDown(result, setPagination);
    setSorted(true);
  };

  return (
    <main className="py-0">
      {isLoading && <Loader />}
      {!isLoading && (
        <Container style={{ minHeight: "calc(80vh + 1rem)" }}>
          <header className="sticky top-20 py-4 lg:pt-4 lg:pb-0 lg:top-20  z-20 bg-white w-screen lg:w-full">
            <form
              className="flex justify-between"
              onSubmit={handleSubmit((data) => onSubmit(data))}
            >
              <div className="flex justify-evenly">
                <Controller
                  control={control}
                  name="createdFrom"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label={t("Created from")}
                        className="w-56 mx-2 h-12"
                        labelPosition={"left corner"}
                        size={"small"}
                        value={value}
                        onChange={onChange}
                        type="date"
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="createdTo"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Input
                        label={t("Created to")}
                        className="w-56 mx-2 h-12"
                        labelPosition={"left corner"}
                        type="date"
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />

                <Controller
                  control={control}
                  name="createdByUser"
                  render={({ field: { onChange, onBlur, value } }) => {
                    return (
                      <Dropdown
                        className="mx-2 h-12"
                        style={{ display: "flex", alignItems: "center" }}
                        search={true}
                        selection={true}
                        size={"small"}
                        selectOnBlur={false}
                        placeholder={t("Created by")}
                        fluid={false}
                        type="text"
                        value={value}
                        onBlur={onBlur}
                        options={createdByUserOptions()}
                        onChange={(e, data) => {
                          onChange(data.value);
                        }}
                      />
                    );
                  }}
                />
              </div>
              <div className="flex">
                <div className="mx-2">
                  <button
                    className="flex items-center py-3 px-4"
                    onClick={() => {
                      reset();
                      handleSortDataDown(data, setPagination);
                    }}
                    type="button"
                  >
                    <X size={14} className="mr-2" />
                    {t("Clear")}
                  </button>
                </div>
                <div className="mx-2">
                  <button
                    className="font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                    type="submit"
                  >
                    {t("Search")}
                  </button>
                </div>
              </div>
            </form>
          </header>
          <div className="py-2 md:mx-2 md:my-0">
            <h3 className="text-xl font-bold">
              {t("Search results")}:{" "}
              <span className="text-primse-green">
                {pagination.currentData?.length}
              </span>
            </h3>
          </div>
          <table className="w-full hidden lg:table">
            <thead className="bg-white font-bold uppercase z-20">
              <tr className="border-b h-14  text-xs">
                <th className="px-2 text-left sticky top-36 bg-white z-20  ">
                  {t("Id")}
                </th>

                <th className="px-2 text-left sticky top-36 bg-white ">
                  {t("Client")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white ">
                  {t("Investment")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white">
                  {t("Developer")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white ">
                  {t("Agency")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white ">
                  {t("Price")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white ">
                  {t("Status")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white ">
                  {t("Created")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white ">
                  {t("Created by")}
                </th>
                {/* <th className="px-2 text-left sticky top-36 bg-white ">
                {t("Actions")}
              </th> */}
              </tr>
            </thead>
            <tbody className="text-sm">
              {pagination.currentData?.map(
                ({
                  id,
                  client_first_name,
                  client_last_name,
                  developer_name,
                  investment_name,
                  agency_name,
                  total_price_gross,
                  status_id,
                  created_at,
                  created_by_name,
                  uuid,
                }) => (
                  <tr
                    key={id}
                    className="border-b h-14 hover:bg-primse-light-green cursor-pointer"
                    onClick={() => {
                      handleInvoiceDetails(uuid);
                    }}
                  >
                    <Td>{id}</Td>
                    <Td>
                      {client_first_name} {client_last_name}
                    </Td>
                    <Td>{investment_name}</Td>
                    <Td>{developer_name}</Td>
                    <Td>{agency_name}</Td>
                    <Td>
                      {total_price_gross
                        ? `${editPrice(total_price_gross)} PLN`
                        : 0}
                    </Td>
                    <Td>
                      <div className="my-2 md:my-0 ml-2 flex items-center">
                        {transactionStatusIcon(status_id)}
                        <div
                          className={cs(
                            "font-bold mx-2 flex flex-col",
                            transactionStatusData(status_id, "color")
                          )}
                        >
                          <span>{`${transactionStatusData(status_id)}`}</span>
                        </div>
                      </div>
                    </Td>
                    <Td>
                      <Moment format="DD.MM.YYYY">{created_at}</Moment>
                    </Td>
                    <Td>{created_by_name}</Td>
                    {/* <Td>Akcje</Td> */}
                  </tr>
                )
              )}
            </tbody>
          </table>
          {pagination.data?.length > 100 && (
            <div className="py-4">
              <Pagination
                activePage={activePage}
                totalPages={pagination.pageCount}
                onPageChange={handlePageClick}
              />
            </div>
          )}
        </Container>
      )}
    </main>
  );
};

export default Transactions;
