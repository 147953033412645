import React, { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import NewRequestModalContent from "./NewRequestModalContent";
import { actions } from "../../store";
function NewRequestModal({
  investment_uuid,
  clientUuid,
  developerName,
  investmentName,
  setModalOpen,
  modalOpen,
  handleRefresh,
}) {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setOpen(modalOpen);
  }, [modalOpen]);

  return (
    <Modal open={open}>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between">
          <h3 className="text-xl font-bold">{t("Create a request")}</h3>
          <AiFillCloseCircle
            onClick={() => {
              actions.modals.openRequestModal(false);
              setModalOpen(false);
            }}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      <NewRequestModalContent
        setModalOpen={setModalOpen}
        investment_uuid={investment_uuid}
        clientUuid={clientUuid}
        developerName={developerName}
        investmentName={investmentName}
        handleRefresh={handleRefresh}
      />
    </Modal>
  );
}

export default NewRequestModal;
