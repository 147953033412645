const CartWithPlusIcon = ({ custom_color, className}) =>
    (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 33.92 29.01"
            className={className}

        >
            <defs>
                <style>
                    .cls-111{`{fill: ${custom_color};}`}.cls-112
                    {`{fill: ${custom_color};stroke: #7eba27;stroke-linejoin:round;}`}
                </style>
            </defs>
            <g id="Warstwa_2" data-name="Warstwa 2">
                <g id="bold">
                    <path
                        className="cls-111"
                        d="M8.81,21h14.4a.79.79,0,0,0,.76-.58L27.16,9.31a.79.79,0,0,0-.77-1H7L6.41,5.73a.8.8,0,0,0-.78-.62H.8a.8.8,0,0,0-.8.8.79.79,0,0,0,.8.79H5l2.88,13a2.39,2.39,0,0,0,.94,4.58h14.4a.8.8,0,0,0,0-1.6H8.81A.79.79,0,0,1,8,21.84.8.8,0,0,1,8.81,21Z"
                    />
                    <path
                        className="cls-111"
                        d="M8,26.62a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,8,26.62Z"
                    />
                    <path
                        className="cls-111"
                        d="M19.22,26.62a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,19.22,26.62Z"
                    />
                    <polygon
                        className="cls-112"
                        points="33.42 5.93 27.99 5.93 27.99 0.5 22.47 0.5 22.47 5.93 17.03 5.93 17.03 11.45 22.47 11.45 22.47 16.89 27.99 16.89 27.99 11.45 33.42 11.45 33.42 5.93"
                    />
                </g>
            </g>
        </svg>
    )

export default CartWithPlusIcon;