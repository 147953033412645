import { queryClient } from "../App";
import { actions } from "../store";
import axios from "../utils/axios";
import ClientsService from "./ClientsService";
import * as Sentry from "@sentry/react";
import { sendErrorSentry } from "../utils/functions";

const AuthService = {
  logIn: async ({ username, password }) => {
    const credentials = btoa(`${username}:${password}`);
    return await axios
      .post("/auth", null, {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      })
      .then(async (res) => {
        actions.filters.cleanAllFilters();
        return await res.data;
      })
      .then(
        async ({
          token,
          user: {
            last_name,
            first_name,
            employment,
            primary_role,
            default_cities,
          },
        }) => {
          localStorage.setItem("token", token);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

          actions.filters.setSearchFilters({
            location: default_cities?.map(({ name }) => name),
          });
          actions.auth.setUser({
            username: `${last_name} ${first_name}`,
            token,

            role:
              employment && employment[0] && employment[0].employment_type
                ? `${employment[0].employment_type}`
                : "other",
            agencyType: `${employment[0].agency_type}`,
            primaryRole: `${primary_role}`,
            defaultCities: default_cities,
            employment: employment && employment?.length !== 0 && employment[0],
          });
          queryClient.resetQueries();

          Sentry.configureScope(function (scope) {
            scope.setTag("my-tag", "Login-modal");
            scope.setUser({
              username: `${last_name} ${first_name}`,
              primaryRole: `${primary_role}`,
              agencyType: `${employment[0].agency_type}`,
              employmentType: `${employment[0].employment_type}`,
              officeName: `${employment[0].office_name}`,
            });
          });

          return token;
        }
      );
  },
  logOut: async () => {
    const token = localStorage.getItem("token");
    if (!token) return null;
    return await axios
      .post("/logout", null)
      .then(() => {
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
        queryClient.clear();
        actions.auth.setUser(null);
        actions.filters.cleanAllFilters();
        Sentry.configureScope((scope) => scope.setUser(null));
      })
      .catch(() => {
        Promise.reject();
      });
  },

  getUserInfo: async () => {
    actions.isLoading.setIsLoading(true);
    const token = localStorage.getItem("token");

    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .get("/user-view")
      .then((res) => {
        if (res.error_code === "invalid_token") {
          throw new Error("Invalid token");
        } else return res.data.payload;
      })
      .then(
        ({
          last_name,
          first_name,
          employment,
          primary_role,
          default_cities,
        }) => {
          actions.filters.setSearchFilters({
            location: default_cities?.map(({ name }) => name),
          });
          actions.auth.setUser({
            username: `${last_name} ${first_name}`,
            token,
            role:
              employment && employment[0] && employment[0].employment_type
                ? `${employment[0].employment_type}`
                : "other",
            agencyType: `${employment[0].agency_type}`,
            primaryRole: `${primary_role}`,
            defaultCities: default_cities,
            employment: employment && employment?.length !== 0 && employment[0],
          });
          queryClient.resetQueries();
          actions.isLoading.setIsLoading(false);

          Sentry.configureScope(function (scope) {
            scope.setTag("my-tag", "Login-auto");
            scope.setUser({
              username: `${last_name} ${first_name}`,
              primaryRole: `${primary_role}`,
              agencyType: `${employment[0].agency_type}`,
              employmentType: `${employment[0].employment_type}`,
              officeName: `${employment[0].office_name}`,
            });
          });
        }
      )
      .catch(() => {
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
        queryClient.clear();
        actions.auth.setUser(null);
        actions.isLoading.setIsLoading(false);
      });
  },
  addNewUser: async (formData) => {
    let searchCriteriaData = {};
    let client = "";
    let navigateToBriefcase = false;

    return await axios
      .post("/auth/user", formData)
      .then(async (res) => {
        return await res.data;
      })
      .then(
        async ({
          token,
          clientUuid,
          searchCriteria,
          user: {
            last_name,
            first_name,
            employment,
            default_cities,
            primary_role,
          },
          briefcase,
        }) => {
          sendErrorSentry("LOGIN MH AuthServices:addNewUser", {
            token: token ? token : "BRAK DANYCH",
            clientUuid: clientUuid ? clientUuid : "BRAK DANYCH",
            searchCriteria: searchCriteria ? searchCriteria : "BRAK DANYCH",
            last_name: last_name ? last_name : "BRAK DANYCH",
            first_name: first_name ? first_name : "BRAK DANYCH",
            employment: employment ? employment : "BRAK DANYCH",
            default_cities: default_cities ? default_cities : "BRAK DANYCH",
            primary_role: primary_role ? primary_role : "BRAK DANYCH",
            date: new Date(),
          });

          localStorage.setItem("token", token);
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

          actions.auth.setUser({
            username: `${last_name} ${first_name}`,
            token,
            role:
              employment && employment[0] && employment[0].employment_type
                ? `${employment[0].employment_type}`
                : "other",
            agencyType: `${employment?.[0]?.agency_type}`,
            primaryRole: `${primary_role}`,
            defaultCities: default_cities,
            employment: employment && employment?.length !== 0 && employment[0],
          });
          if (clientUuid) {
            client = clientUuid;
          }

          searchCriteriaData = { defaultCities: default_cities };

          if (searchCriteria) {
            searchCriteriaData = { ...searchCriteriaData, ...searchCriteria };
          } else {
            searchCriteriaData = {
              areaFrom: "",
              city: [""],
              priceTo: "",
              realestateType: "flat",
              roomCountFrom: "",
              roomCountTo: "",
              defaultCities: default_cities,
            };
          }
          Sentry.configureScope(function (scope) {
            scope.setTag("my-tag", "Login-new-user-MH");
            scope.setUser({
              username: `${last_name} ${first_name}`,
              primaryRole: `${primary_role}`,
              agencyType: `${employment[0]?.agency_type}`,
              employmentType: `${employment[0]?.employment_type}`,
              officeName: `${employment[0]?.office_name}`,
            });
          });

          if (
            briefcase?.investments?.length > 0 ||
            briefcase?.realestates?.length > 0
          ) {
            navigateToBriefcase = true;
          }

          await queryClient.resetQueries();
          return searchCriteriaData;
        }
      )

      .then((searchCriteriaData) => {
        if (client !== "") {
          ClientsService.getClient(client)().then((res) =>
            actions.briefcase.setBriefcaseClient(res.client)
          );
        }
        return searchCriteriaData;
      })
      .then((searchCriteriaData) => {
        return {
          ...searchCriteriaData,
          clientUuid: client,
          navigateToBriefcase,
        };
      });
  },
  logWithRequest: async (requestToken) => {
    let requestUuid = "";

    return await axios
      .get(`/auth/developer/${requestToken}`)
      .then(async (res) => {
        return await res.data;
      })
      .then(
        async ({
          token,
          query,
          user: {
            last_name,
            first_name,
            employment,
            default_cities,
            primary_role,
          },
        }) => {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          localStorage.setItem("token", token);
          requestUuid = query;

          actions.filters.setSearchFilters({
            location: default_cities?.map(({ name }) => name),
          });
          actions.auth.setUser({
            username: `${last_name} ${first_name}`,
            token,
            role:
              employment && employment[0] && employment[0].employment_type
                ? `${employment[0].employment_type}`
                : "other",
            agencyType: `${employment[0].agency_type}`,
            primaryRole: `${primary_role}`,
            defaultCities: default_cities,
            employment: employment && employment?.length !== 0 && employment[0],
          });
          Sentry.configureScope(function (scope) {
            scope.setTag("my-tag", "Login-developer");
            scope.setUser({
              username: `${last_name} ${first_name}`,
              primaryRole: `${primary_role}`,
              agencyType: `${employment[0].agency_type}`,
              employmentType: `${employment[0].employment_type}`,
              officeName: `${employment[0].office_name}`,
            });
          });

          queryClient.resetQueries();
          return token;
        }
      )
      .then(() => requestUuid);
  },
};

export default AuthService;
