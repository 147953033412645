import { actions } from "../store";
import { toast } from "react-toastify";
import i18n from "../i18n";
import BasketsService from "../services/BasketsService";
import BriefcaseContentService from "../services/BriefcaseContentService";

export const addToBasket = (e, uuid, currentBasketUuid, name = "") => {
  actions.isLoading.setIsLoading(true);
  e.stopPropagation();
  BasketsService.addRealestateToBasket({
    basket_uuid: currentBasketUuid,
    realestate_uuid: uuid,
  })
    .then((res) => {
      if (res?.status === 201) {
        toast.success(
          `${i18n.t("The real estate")} ${name} ${i18n.t(
            "has been successfully added to the cart3"
          )}`
        );
      } else if (res?.response?.status === 400) {
        if (res?.response?.data?.isDuplicated) {
          toast.error(`${i18n.t("The real estate is already in the cart")}`);
        } else {
          toast.error(`${i18n.t("The real estate could not be added")}`);
        }
      } else {
        toast.error(`${i18n.t("The real estate could not be added")}`);
      }
    })
    .catch((err) => {
      if (err?.response?.data.isDuplicated) {
        toast.error(`${i18n.t("The real estate is already in the cart")}`);
      } else {
        toast.error(`${i18n.t("The real estate could not be added")}`);
      }
    })
    .finally(() => actions.isLoading.setIsLoading(false));
};

export const addInvestmentToBasket = (
  basket_uuid,
  investment_uuid,
  investment_name = " "
) => {
  BasketsService.addToBasket({
    basket_uuid: basket_uuid,
    investment_uuid: investment_uuid,
  })
    .then((res) => {
      if (res?.status === 201) {
        toast.success(
          `${i18n.t("The investment")} ${investment_name} ${i18n.t(
            "has been successfully added to the cart"
          )}`
        );
      } else if (res?.response?.status === 400) {
        if (res?.response?.data.isDuplicated) {
          toast.error(`${i18n.t("The investment is already in the cart")}`);
        } else {
          toast.error(`${i18n.t("The investment could not be added")}`);
        }
      } else {
        toast.error(`${i18n.t("The real estate could not be added")}`);
      }
    })
    .catch((err) => {
      if (err?.response?.data.isDuplicated) {
        toast.error(`${i18n.t("The investment is already in the cart")}`);
      } else {
        toast.error(`${i18n.t("The investment could not be added")}`);
      }
    })
    .finally(() => actions.isLoading.setIsLoading(false));
};

export const addFlatToBriefcase = (object_uuid, flat_name, briefcaseClient) => {
  actions.isLoading.setIsLoading(true);
  if (briefcaseClient) {
    BriefcaseContentService.addRealestate({
      contact: briefcaseClient.uuid,
      object_uuid: object_uuid,
      object_type: "realestate",
    })
      .then((res) => {
        if (res?.status === 200) {
          toast.success(
            `${i18n.t("The real estate")} ${flat_name} ${i18n.t(
              "has been successfully added to the briefcase2"
            )}`
          );
        } else if (res?.response?.status === 400) {
          if (res?.response?.data.isDuplicated) {
            toast.error(
              `${i18n.t("The real estate is already in the briefcase")}`
            );
          } else {
            toast.error(
              `${i18n.t("The real estate could not be added to briefcase")}`
            );
          }
        } else {
          toast.error(
            `${i18n.t("The real estate could not be added to briefcase")}`
          );
        }
      })
      .catch((err) => {
        if (err?.response?.data.isDuplicated) {
          toast.error(
            `${i18n.t("The real estate is already in the briefcase")}`
          );
        } else
          toast.error(
            `${i18n.t("The real estate could not be added to briefcase")}`
          );
      })
      .finally(() => actions.isLoading.setIsLoading(false));
  } else {
    actions.isLoading.setIsLoading(false);
    toast.warning(
      <div className="text-base">
        {i18n.t("Choose client briefcase")}
        <button
          className="leading-5 my-2 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
          onClick={() => {
            actions.briefcase.setBriefcaseModalSelectClient(true);
            actions.briefcase.setBriefcaseModal(true);
          }}
        >
          {i18n.t("Choose briefcase")}
        </button>
      </div>
    );
  }
};
