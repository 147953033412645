import React from "react";
import { useLocation } from "react-router-dom";

function useQueryParams() {
  const { search } = useLocation();

  const getAll = React.useCallback(() => {
    const params = new URLSearchParams(search);
    const result = {};
    for (const [key, value] of params) {
      result[key] = value;
    }
    return result;
  }, [search]);

  const get = React.useCallback(
    (key) => {
      const params = new URLSearchParams(search);
      return params.get(key);
    },
    [search]
  );

  const convertStringParamsToObject = (string, toArray = true) => {
    const params = new URLSearchParams(string);
    const result = {};
    for (const [key, value] of params) {
      if (toArray) {
        result[key] = value.split(",");
      } else {
        result[key] = value;
      }
    }
    return result;
  };

  return { getAll, get, convertStringParamsToObject };
}

export default useQueryParams;
