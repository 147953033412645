import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";
import ClientsService from "../../services/ClientsService";
import Loader from "../Loader";
import axios from "../../utils/axios";
import { actions } from "../../store";
import { toast } from "react-toastify";

const AddClient = ({ selectDefaultModal, handleRefresh = () => {} }) => {
  const [clientFirstName, setClientFirstName] = useState("");
  const [clientLastName, setClientLastName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientComments, setClientComments] = useState("");
  const [loading, setLoading] = useState(false);
  const [formFirstNameError, setFormFirstNameError] = useState(false);
  const [formLastNameError, setFormLastNameError] = useState(false);
  const [formEmailError, setFormEmailError] = useState(false);
  const [formPhoneError, setFormPhoneError] = useState(false);

  const { t } = useTranslation();

  const handleClientFirstName = (event) => {
    setFormFirstNameError(false);
    let value = event.target.value;
    setClientFirstName(value);
  };

  const handleClientLastName = (event) => {
    setFormLastNameError(false);
    let value = event.target.value;
    setClientLastName(value);
  };

  const handleClientEmail = (event) => {
    setFormEmailError(false);
    let value = event.target.value;
    setClientEmail(value);
  };

  const handleClientPhone = (event) => {
    setFormPhoneError(false);
    let value = event.target.value;
    setClientPhone(value);
  };

  const handleClientComments = (event) => {
    let value = event.target.value;
    setClientComments(value);
  };

  // const clearForm = () => {
  //     setClientFirstName("");
  //     setClientLastName("");
  //     setClientEmail("");
  //     setClientPhone("");
  //     setClientComments("");
  // }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      clientFirstName !== "" &&
      clientLastName !== "" &&
      clientEmail !== "" &&
      clientPhone !== ""
    ) {
      setLoading(true);

      ClientsService.addClient({
        first_name: clientFirstName,
        last_name: clientLastName,
        work_phone: clientPhone,
        work_email: clientEmail,
        description: clientComments,
      })
        .then((res) => {
          if (res?.status === 201) {
            toast.success(`${t("Customer added successfully")}`);

            handleRefresh(true);
            return axios
              .get(`/client-view/${res.data.payload.uuid}`)
              .then((res) => {
                actions.briefcase.setBriefcaseClient(res.data.payload.client);
                selectDefaultModal();
              });
          } else {
            if (
              res?.response?.data?.form?.error_messages?.assigned_to?.notInArray
            ) {
              toast.error(
                `${t(
                  "You do not have the appropriate permissions to add a client"
                )}`
              );
            }
            if (res?.response?.data?.form?.error_messages?.last_name?.failed) {
              setFormLastNameError(true);
              toast.error(
                `${t("An error occurred while adding a client")}: ${t(
                  "One of the fields - First Name or Last Name - must be completed"
                )}`
              );
            }
            if (
              res?.response?.data?.form?.error_messages?.work_email?.recordFound
            ) {
              toast.error(
                `${t(
                  "The customer with the given e-mail address already exists"
                )}`
              );
            }
            if (
              res?.response?.data?.form?.error_messages?.work_email?.isEmpty
            ) {
              setFormEmailError(true);
              toast.error(`${t("The e-mail field can not be empty")}`);
            }
            if (
              res?.response?.data?.form?.error_messages?.work_email
                ?.emailAddressInvalid
            ) {
              setFormEmailError(true);
              toast.error(`${t("Incorrect e-mail address")}`);
            }
            if (
              res?.response?.data?.form?.error_messages?.work_phone?.isEmpty
            ) {
              setFormEmailError(true);
              toast.error(`${t("The e-mail field can not be empty")}`);
            }
            if (res?.response?.data?.form?.errors?.work_phone) {
              setFormPhoneError(true);
              toast.error(`${t("Incorrect phone number")}`);
            }
            if (
              res?.response?.data?.form?.error_messages?.first_name &&
              res?.response?.data?.form?.error_messages?.first_name[0] ===
                "Klient jest już obsługiwany przez innego użytkownika z Twojej firmy"
            ) {
              setFormFirstNameError(true);
              setFormLastNameError(true);
              setFormEmailError(true);
              setFormPhoneError(true);
              toast.error(
                `${t(`${res.response.data.form.error_messages.first_name}`)}`
              );
            } else if (
              res?.response?.data?.form?.error_messages?.first_name &&
              res?.response?.data?.form?.error_messages?.first_name[0] ===
                "Taki klient jest już na twojej liście klientów"
            ) {
              setFormFirstNameError(true);
              setFormLastNameError(true);
              setFormEmailError(true);
              setFormPhoneError(true);
              toast.error(
                `${t(`${res.response.data.form.error_messages.first_name}`)}`
              );
            } else {
              if (res?.response?.data) {
                toast.error(`${t("An error occurred while adding a client")}`);
              }
            }

            setLoading(false);
          }
        })
        .catch((err) => {
          if (
            err?.response?.data?.form?.error_messages?.assigned_to?.notInArray
          ) {
            toast.error(
              `${t(
                "You do not have the appropriate permissions to add a client"
              )}`
            );
          }
          if (err?.response?.data?.form?.error_messages?.last_name?.failed) {
            setFormLastNameError(true);
            toast.error(
              `${t("An error occurred while adding a client")}: ${t(
                "One of the fields - First Name or Last Name - must be completed"
              )}`
            );
          }
          if (
            err?.response?.data?.form?.error_messages?.work_email?.recordFound
          ) {
            toast.error(
              `${t(
                "The customer with the given e-mail address already exists"
              )}`
            );
          }
          if (err?.response?.data?.form?.error_messages?.work_email?.isEmpty) {
            setFormEmailError(true);
            toast.error(`${t("The e-mail field can not be empty")}`);
          }
          if (
            err?.response?.data?.form?.error_messages?.first_name &&
            err?.response?.data?.form?.error_messages?.first_name[0] ===
              "Klient jest już obsługiwany przez innego użytkownika z Twojej firmy"
          ) {
            setFormFirstNameError(true);
            setFormLastNameError(true);
            setFormEmailError(true);
            setFormPhoneError(true);
            toast.error(
              `${t(`${err.response.data.form.error_messages.first_name}`)}`
            );
          } else if (
            err?.response?.data?.form?.error_messages?.first_name &&
            err?.response?.data?.form?.error_messages?.first_name[0] ===
              "Taki klient jest już na twojej liście klientów"
          ) {
            setFormFirstNameError(true);
            setFormLastNameError(true);
            setFormEmailError(true);
            setFormPhoneError(true);
            toast.error(
              `${t(`${err.response.data.form.error_messages.first_name}`)}`
            );
          } else {
            if (err?.response?.data) {
              toast.error(`${t("An error occurred while adding a client")}`);
            }
          }

          setLoading(false);
        });
    } else {
      const errors = [];
      if (clientFirstName === "") {
        setFormFirstNameError(true);
        errors.push("First name");
      }
      if (clientLastName === "") {
        setFormLastNameError(true);
        errors.push("Last name");
      }
      if (clientEmail === "") {
        setFormEmailError(true);
        errors.push("Email");
      }
      if (clientPhone === "") {
        setFormPhoneError(true);
        errors.push("Phone");
      }
      toast.error(
        <div>
          {t("None of the fields can be empty")}: <br />
          {errors.map((el, index) => (
            <div key={index}>
              - {t(el)} <br />
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <>
      <div style={{ background: "rgba(249, 250, 251, 1", border: "none" }}>
        <div className="flex justify-between">
          <h3 className="text-xl font-bold">{t("Add a client")}</h3>
        </div>
      </div>
      <div style={{ paddingTop: "0" }}>
        <Form className="flex items-center flex-wrap" onSubmit={handleSubmit}>
          <div className="w-1/2 flex flex-col">
            <label className="font-bold px-2 pt-2">
              {t("First name")} <span className="text-red-500">*</span>
            </label>
            <Form.Input
              className="p-2"
              onChange={handleClientFirstName}
              value={clientFirstName}
              error={formFirstNameError}
            >
              <input placeholder={t("First name")} />
            </Form.Input>
          </div>
          <div className="w-1/2 flex flex-col">
            <label className="font-bold px-2 pt-2">
              {t("Last name")} <span className="text-red-500">*</span>
            </label>
            <Form.Input
              className="p-2"
              onChange={handleClientLastName}
              value={clientLastName}
              error={formLastNameError}
            >
              <input placeholder={t("Last name")} />
            </Form.Input>
          </div>
          <div className="w-1/2 flex flex-col">
            <label className="font-bold px-2 pt-2">
              {t("E-mail")} <span className="text-red-500">*</span>{" "}
            </label>
            <Form.Input
              className="p-2"
              onChange={handleClientEmail}
              value={clientEmail}
              error={formEmailError}
            >
              <input placeholder={t("E-mail")} />
            </Form.Input>
          </div>
          <div className="w-1/2 flex flex-col">
            <label className="font-bold px-2 pt-2">
              {t("Phone number")} <span className="text-red-500">*</span>
            </label>
            <Form.Input
              className="p-2"
              onChange={handleClientPhone}
              value={clientPhone}
              error={formPhoneError}
            >
              <input placeholder={t("Phone number")} />
            </Form.Input>
          </div>
          <Form.TextArea
            label={t("Comments")}
            placeholder={t("Comments")}
            value={clientComments}
            className="w-full p-2"
            onChange={handleClientComments}
          />
          <div className="w-full flex justify-end">
            <button
              className="leading-5 m-2 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
              type="submit"
            >
              {t("Add a client")}
            </button>
          </div>
          {loading && <Loader />}
        </Form>
      </div>
    </>
  );
};

export default AddClient;
