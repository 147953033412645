import cs from "classnames";
import styles from "./Marker.module.css";
import { useState } from "react";

import FlatHover from "../FlatHover";
import { parseThousands } from "../../../../utils/numbers";

const Marker = ({
  active,
  onClick,
  markerInformation,
  ActiveMarker,
  variant = "investment",
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div
      className={cs("cursor-default", styles.marker)}
      onClick={onClick}
      onMouseEnter={() => {
        setTooltipOpen(true);
      }}
      onMouseLeave={() => setTooltipOpen(false)}
    >
      {!active && tooltipOpen && (
        <div className="">
          <FlatHover {...markerInformation} />
        </div>
      )}

      <div
        className={cs(
          "rounded-full flex flex-col items-center justify-center transition-all duration-500 hover:opacity-90 cursor-pointer font-bold leading-none w-16 h-16 -ml-8 -mt-8",
          variant === "investment"
            ? "bg-primse-green text-white"
            : "bg-primse-yellow text-gray-800"
        )}
      >
        <div className="text-center">
          {variant === "investment" ? (
            <>
              <div>{parseThousands(markerInformation.price_from)}</div>
              <div>-</div>
              <div>{parseThousands(markerInformation.price_to)}</div>
            </>
          ) : (
            <div>{parseThousands(markerInformation.price)}</div>
          )}
        </div>
      </div>
      {/*TODO: move marker / active marker to marker from search!*/}
      {active && ActiveMarker}
    </div>
  );
};

export default Marker;
