import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { useStoreState } from "easy-peasy";
import GoogleMapReact from "google-map-react";

import MapContextMenu from "../../containers/Search/components/MapContextMenu";

import BasketModalSelect from "./components/BasketModalSelect";
import BriefcasePanel from "./components/BriefcasePanel";
import SelectMarket from "./components/SelectMarket";

import Cluster from "./components/Cluster/";
import MapControls from "./components/MapControls";
import Marker from "./components/Marker/";
import PineMarker from "./components/PineMarker";
import BasketPanel from "./components/BasketPanel/BasketPanel";

import useSupercluster from "./hooks/useSupercluster";
import useAdjustedClusters from "./hooks/useAdjustedClusters";

import { isArray } from "../../utils/functions";
import { centerToMarkers } from "./helpers";

const MapGoogle = ({
  displayElements = {},
  selectedMarkets = [],
  setSelectedMarkets = () => {},

  defaultCenter,
  defaultZoom = 6.5,

  markersInvestment = [],
  markersRealestate = [],

  ActiveMarker = null,
  ActiveMarkerNew = null,
  ActiveCluster = null,

  pineComponent = null,
}) => {
  const optionsDisplayElements = {
    selectMarket: false,
    basketsList: false,
    basketPanel: false,
    briefcasePanel: false,
    ...displayElements,
  };

  const mapRef = useRef();
  const [activeMarker, setActiveMarker] = useState(null);
  const [activeDoubleMarker, setActiveDoubleMarker] = useState(null);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [url, setUrl] = useState("");
  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const { mode } = useStoreState((state) => state.mode);

  const [bounds, setBounds] = useState([
    11.800706999999989,
    50.417508398403555,
    25.86320699999999,
    54.35037272235667,
  ]);
  const [zoom, setZoom] = useState(defaultZoom);
  const [maxZoomMap, setMaxZoomMap] = useState(14);

  const [scrollActive, setScrollActive] = useState(true);

  const pointsInvestments = useMemo(() => {
    if (!isArray(markersInvestment)) return [];
    return markersInvestment.map((flat) => ({
      type: "Feature",
      properties: { cluster: false, data: flat },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(flat.location.lng),
          parseFloat(flat.location.lat),
        ],
      },
    }));
  }, [markersInvestment]);

  const pointsRealestate = useMemo(() => {
    if (!isArray(markersRealestate)) return [];
    return markersRealestate?.map((flat) => ({
      type: "Feature",
      properties: { cluster: false, data: flat },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(flat.location.lng),
          parseFloat(flat.location.lat),
        ],
      },
    }));
  }, [markersRealestate]);

  const {
    clusters: clustersInvestments,
    supercluster: superclusterInvestments,
  } = useSupercluster({
    points: pointsInvestments,
    bounds,
    zoom,
    options: { radius: 150, maxZoom: maxZoomMap },
  });

  const {
    clusters: clusterRealestates,
    supercluster: superclusterRealestates,
  } = useSupercluster({
    points: pointsRealestate,
    bounds,
    zoom,
    options: { radius: 300, maxZoom: maxZoomMap },
  });

  useEffect(() => {
    if (mapRef.current && window.google.maps) {
      centerToMarkers(
        mapRef,
        [...markersInvestment, ...markersRealestate],
        defaultCenter,
        defaultZoom
      );
    }

    // eslint-disable-next-line
  }, [markersInvestment, markersRealestate]);

  useEffect(() => {
    if (mode === "presentation") {
      setMaxZoomMap(14);
    } else {
      setMaxZoomMap(16);
    }
  }, [mode]);

  useEffect(() => {
    const map = mapRef.current;
    const marker = [...markersInvestment, ...markersRealestate].find(
      (m) => m.uuid === activeMarker
    );

    if (map && marker) {
      let ne = map.getBounds().getNorthEast();
      let sw = map.getBounds().getSouthWest();
      const bounds = new window.google.maps.LatLngBounds();

      let currentPos = (ne.lat() - sw.lat()) / 4;

      bounds.extend(
        new window.google.maps.LatLng({
          lng: parseFloat(marker.location.lng),
          lat: parseFloat(marker.location.lat) + currentPos + currentPos * 0.4,
        })
      );

      map.fitBounds(bounds);

      map.setZoom(zoom);
    }
    // eslint-disable-next-line
  }, [activeMarker]);

  const [
    adjustedClustersInvestments,
    adjustedClustersRealestates,
  ] = useAdjustedClusters({
    clusters1: clustersInvestments,
    clusters2: clusterRealestates,
    markerSize: 56,
    zoom: zoom,
  });

  const handleMapChange = useCallback(
    ({ zoom, bounds: newBounds }) => {
      setZoom(zoom);

      if (
        newBounds &&
        (newBounds?.nw?.lng !== bounds[0] ||
          newBounds?.se?.lat !== bounds[1] ||
          newBounds?.se?.lng !== bounds[2] ||
          newBounds?.nw?.lat !== bounds[3])
      ) {
        setBounds([
          newBounds?.nw?.lng,
          newBounds?.se?.lat,
          newBounds?.se?.lng,
          newBounds?.nw?.lat,
        ]);
      }
    },
    [bounds]
  );

  return (
    <div className="h-full w-full relative">
      <GoogleMapReact
        options={{
          clickableIcons: false,
          disableDefaultUI: true,
          maxZoom: maxZoomMap,
          minZoom: 7,
          scrollwheel: scrollActive,
        }}
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          maps.event.addListener(map, "mousemove", (event) => {
            if (!event.domEvent.target.dataset) {
              if (showContextMenu) {
                setShowContextMenu(false);
              }
              return;
            }

            if (event.domEvent.target.dataset.link !== undefined) {
              setContextMenuPosition({
                x: event.domEvent.x,
                y: event.domEvent.y,
              });
              setUrl(event.domEvent.target.dataset.link);
              setShowContextMenu(true);

              return "";
            } else {
              if (showContextMenu) {
                setShowContextMenu(false);
              }
              return;
            }
          });

          mapRef.current = map;
          centerToMarkers(
            mapRef,
            [...markersInvestment, ...markersRealestate],
            defaultCenter,
            defaultZoom
          );
        }}
        onChange={handleMapChange}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
      >
        {adjustedClustersInvestments?.map((cluster, index) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount,
          } = cluster.properties;
          if (isCluster) {
            return (
              <Cluster
                lat={latitude}
                lng={longitude}
                key={index}
                number={pointCount}
                onClick={(e) => {
                  if (zoom === maxZoomMap) {
                    const selectMarkers = superclusterInvestments
                      ?.getChildren(cluster.id)
                      ?.map((el) => el?.properties?.data);

                    setActiveDoubleMarker(selectMarkers);

                    setActiveMarker(
                      e.target.dataset.id === "exitModal" ||
                        e.target.parentElement.dataset.id === "exitModal"
                        ? null
                        : selectMarkers[0].uuid
                    );
                  } else {
                    const expansionZoom = Math.min(
                      superclusterInvestments.getClusterExpansionZoom(
                        cluster.id
                      ),
                      20
                    );

                    if (
                      expansionZoom - zoom > 3 &&
                      expansionZoom < maxZoomMap
                    ) {
                      const zoomStatus = expansionZoom - zoom;

                      mapRef?.current?.setZoom(expansionZoom - zoomStatus / 2);
                      setTimeout(() => {
                        mapRef?.current?.setZoom(expansionZoom);
                      }, 600);
                    } else if (expansionZoom > maxZoomMap) {
                      mapRef?.current?.setZoom(14);
                      setTimeout(() => {
                        mapRef?.current?.setZoom(16);
                      }, 900);
                    } else {
                      mapRef?.current?.setZoom(expansionZoom);
                    }
                    const map = mapRef?.current;
                    const bounds = new window.google.maps.LatLngBounds();

                    bounds.extend(
                      new window.google.maps.LatLng(latitude, longitude)
                    );

                    map.setCenter(bounds.getCenter());
                  }
                }}
                active={
                  ActiveMarker &&
                  activeDoubleMarker &&
                  activeMarker === activeDoubleMarker[0].uuid
                }
                ActiveCluster={
                  ActiveMarker &&
                  activeDoubleMarker &&
                  activeMarker === activeDoubleMarker[0].uuid &&
                  ActiveCluster(
                    activeDoubleMarker,
                    () => {
                      setActiveMarker(null);
                    },
                    (status) => {
                      setScrollActive(status);
                    }
                  )
                }
              />
            );
          }

          if (pineComponent) {
            return (
              <PineMarker
                key={cluster.properties.data.uuid}
                lat={latitude}
                lng={longitude}
              />
            );
          } else {
            return (
              <Marker
                key={cluster.properties.data.uuid}
                lat={latitude}
                lng={longitude}
                active={activeMarker === cluster.properties.data.uuid}
                onClick={(e) => {
                  setActiveMarker(
                    e.target.dataset.id === "exitModal" ||
                      e.target.parentElement.dataset.id === "exitModal"
                      ? null
                      : cluster.properties.data.uuid
                  );
                }}
                markerInformation={cluster.properties.data}
                ActiveMarker={
                  ActiveMarker &&
                  activeMarker === cluster.properties.data.uuid &&
                  ActiveMarker(
                    cluster.properties.data,
                    () => {
                      setActiveMarker(null);
                    },
                    (status) => {
                      setScrollActive(status);
                    }
                  )
                }
              />
            );
          }
        })}
        {isArray(markersRealestate) &&
          markersRealestate?.length > 0 &&
          adjustedClustersRealestates?.map((cluster, index) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount,
            } = cluster.properties;

            if (isCluster && cluster?.id) {
              const clusterData = superclusterRealestates
                ?.getChildren(cluster?.id)
                ?.map((el) => el?.properties?.data)
                ?.filter((el) => el);

              return (
                <Cluster
                  key={index}
                  lat={latitude}
                  lng={longitude}
                  variant="realestate"
                  number={pointCount}
                  onClick={(e) => {
                    if (zoom === maxZoomMap) {
                      const selectMarkers = superclusterRealestates
                        ?.getChildren(cluster.id)
                        ?.map((el) => el?.properties?.data);

                      setActiveDoubleMarker(selectMarkers);

                      setActiveMarker(
                        e.target.dataset.id === "exitModal" ||
                          e.target.parentElement.dataset.id === "exitModal"
                          ? null
                          : selectMarkers[0].uuid
                      );
                    } else {
                      const expansionZoom = Math.min(
                        superclusterRealestates.getClusterExpansionZoom(
                          cluster.id
                        ),
                        20
                      );

                      if (
                        expansionZoom - zoom > 3 &&
                        expansionZoom < maxZoomMap
                      ) {
                        const zoomStatus = expansionZoom - zoom;

                        mapRef?.current?.setZoom(
                          expansionZoom - zoomStatus / 2
                        );
                        setTimeout(() => {
                          mapRef?.current?.setZoom(expansionZoom);
                        }, 600);
                      } else if (expansionZoom > maxZoomMap) {
                        mapRef?.current?.setZoom(maxZoomMap);
                      } else {
                        mapRef?.current?.setZoom(expansionZoom);
                      }
                      const map = mapRef?.current;
                      const bounds = new window.google.maps.LatLngBounds();

                      bounds.extend(
                        new window.google.maps.LatLng(latitude, longitude)
                      );

                      map.setCenter(bounds.getCenter());
                    }
                  }}
                  active={
                    clusterData?.[0]?.uuid === activeDoubleMarker?.[0]?.uuid
                  }
                  ActiveCluster={
                    ActiveMarker &&
                    activeDoubleMarker &&
                    activeMarker === activeDoubleMarker[0].uuid &&
                    ActiveCluster(
                      activeDoubleMarker,
                      () => {
                        setActiveMarker(null);
                      },
                      (status) => {
                        setScrollActive(status);
                      },
                      false
                    )
                  }
                />
              );
            }

            return (
              <Marker
                key={cluster.properties.data.uuid}
                lat={latitude}
                lng={longitude}
                variant="realestate"
                active={activeMarker === cluster.properties.data.uuid}
                onClick={(e) => {
                  setActiveMarker(
                    e.target.dataset.id === "exitModal" ||
                      e.target.parentElement.dataset.id === "exitModal"
                      ? null
                      : cluster.properties.data.uuid
                  );
                }}
                markerInformation={cluster.properties.data}
                ActiveMarker={
                  ActiveMarker &&
                  activeMarker === cluster.properties.data.uuid &&
                  ActiveMarkerNew(
                    { ...cluster?.properties?.data, variant: "realestate" },
                    () => {
                      setActiveMarker(null);
                    },
                    (status) => {
                      setScrollActive(status);
                    }
                  )
                }
              />
            );
          })}
      </GoogleMapReact>

      <MapControls
        handlePlusClicked={() =>
          mapRef.current.setZoom(mapRef.current.getZoom() + 1)
        }
        handleMinusClicked={() =>
          mapRef.current.setZoom(mapRef.current.getZoom() - 1)
        }
      />

      {optionsDisplayElements.selectMarket && (
        <SelectMarket
          selectedMarkets={selectedMarkets}
          setSelectedMarkets={setSelectedMarkets}
        />
      )}
      {optionsDisplayElements.basketsList && <BasketModalSelect />}
      {optionsDisplayElements.basketPanel && <BasketPanel />}
      {optionsDisplayElements.briefcasePanel && <BriefcasePanel />}

      {showContextMenu ? (
        <MapContextMenu
          url={url}
          setShowContextMenu={setShowContextMenu}
          contextMenuPosition={contextMenuPosition}
        />
      ) : null}
    </div>
  );
};

export default MapGoogle;
