import { useEffect, useMemo, useState, useRef } from "react";
import { X } from "react-feather";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import Container from "../../components/Container";
import Loader from "../../components/Loader";
import NewsSection from "../../components/NewsSection";

import MarketDropdown from "../Search/components/Flat/SearchComponents/MarketDropdown";
import AreaDropdown from "../Search/components/Flat/SearchComponents/AreaDropdown";
import DistrictDropdown from "../Search/components/Flat/SearchComponents/DistrictDropdown";
import PriceDropdown from "../Search/components/Flat/SearchComponents/PriceDropdown";
import RoomsDropdown, {
  ROOMS_NUM,
} from "../Search/components/Flat/SearchComponents/RoomsDropdown";
import TypeDropdown from "../Search/components/Flat/SearchComponents/TypeDropdown";
import {
  DefaultValueInput,
  DefaultValueInputText,
  DistrictValueInput,
} from "../Search/components/Flat/SearchComponents/ValueInputs";
import AdvancedDropdown from "../Search/components/Flat/SearchComponents/AdvancedDropdown";
import LocationsServices from "../../services/LocationsServies";
import {
  convertValuesToURL,
  DEFAULTVALUES,
  parseURLtoFormValues,
} from "../Search/searchUtils";
import { useStoreActions, useStoreState } from "easy-peasy";

import FlatList from "./components/FlatList";
import { Pagination, Dropdown } from "semantic-ui-react";
import { isArray } from "../../utils/functions";
import useUserRole from "../../hooks/useUserRole";

const Realestates = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const ref = useRef(null);

  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(0);

  const [resetQuery, setResetQuery] = useState(false);
  const { handleSubmit, watch, control, setValue, getValues } = useForm({
    defaultValues: parseURLtoFormValues(location.search),
  });
  const { canSeeSecondaryMarket, isAdmin, isMetrohouse } = useUserRole();

  const [onWheelMouse, setOnWheelMouse] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [formValues, setFormValues] = useState({
    area: ["", ""],
    market: ["Primary market"],
    location: [],
    price: ["", ""],
    rooms: [],
    type: ["apartment", "house"],
    order: ["price,asc"],
    basement: ["false"],
    balcony: ["false"],
    terrace: ["false"],
    green_terrace: ["false"],
    has_parking_space: ["false"],
    closed: ["false"],
  });

  const [locationsData, setLocationsData] = useState([]);

  const [offsetTopTable, setOffsetTopTable] = useState(0);
  const completion_quarters = useStoreState(
    (state) => state.dictionaries.dictionariesList.completion_quarters
  );
  const searchFilters = useStoreState((state) => state.filters.searchFilters);
  const setSearchFilters = useStoreActions(
    (state) => state.filters.setSearchFilters
  );

  useEffect(() => {
    if (searchFilters) {
      history.push(`/realestates?${convertValuesToURL(searchFilters)}`);
      for (const [key, value] of Object.entries(searchFilters)) {
        setValue(key, value);
      }
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      setOffsetTopTable(ref.current.offsetTop);
    }
  }, [ref]);

  const [itemsInPagination, setItemsInPagination] = useState(0);

  useEffect(() => {
    setOnWheelMouse(false);
  }, [onWheelMouse]);

  const values = watch();

  const { isLoading: appIsLoading } = useStoreState((state) => state.isLoading);

  const setAllPageCount = (items, itemsPerPage) => {
    const numberOfPages = Math.ceil(items / itemsPerPage);
    if (items === 0 && itemsPerPage === 0) {
      setCountPage(10);
    } else {
      setCountPage(numberOfPages);
    }
  };
  const handleReset = () => {
    if (resetQuery === false) setResetQuery(!resetQuery);
    Object.entries(DEFAULTVALUES)?.forEach(([key, value]) => {
      setValue(key, value);
    });
    history.push(`/realestates/?${convertValuesToURL(DEFAULTVALUES)}`);
    setSearchFilters(DEFAULTVALUES);
    setLoaded(false);
    const formValues = parseURLtoFormValues(location.search);
    setFormValues(formValues);
    setLoaded(true);
  };

  const handleSetQuery = (value) => {
    setResetQuery(value);
  };

  const handleButtonSecondaryMarket = () => {
    Object.entries(DEFAULTVALUES)?.forEach(([key, value]) => {
      setValue(key, value);
    });

    setValue("market", ["Secondary market"]);
  };
  const setPageController = (selectPage) => {
    setPage(selectPage);
    setTimeout(() => setLoaded(true), 0);
  };

  const handleSortAsc = (arr) => {
    return arr.sort((a, b) => (a.value > b.value ? 1 : -1));
  };
  const cities = useMemo(() => {
    if (!locationsData) return [];
    const result = locationsData.map(
      ({
        city_name,
        voivodeship_name,
        quarter_name,
        type,
        voivodeship_id,
        city_id,
        quarter_id,
      }) => {
        switch (type) {
          case "voivodeship":
            return {
              value: voivodeship_name.toLowerCase(),
              name: voivodeship_name,
              description: `Całe województwo`,
              id: `v:${voivodeship_id}`,
              type: type,
            };
          case "city":
            return {
              value: city_name.toLowerCase(),
              name: city_name,
              description: `${voivodeship_name}`,
              id: `c:${city_id}`,
              type: type,
            };
          case "quarter":
            return {
              value: quarter_name.toLowerCase(),
              name: quarter_name,
              description: city_name,
              id: `q:${quarter_id}`,
              type: type,
            };
        }
      }
    );
    const voivodeships = handleSortAsc(
      result?.filter((el) => el.type === "voivodeship")
    );
    const citys = handleSortAsc(result?.filter((el) => el.type === "city"));
    const quarters = handleSortAsc(
      result?.filter((el) => el.type === "quarter")
    );

    return [...citys, ...quarters, ...voivodeships];
  }, [locationsData]);

  useEffect(() => {
    const formValues = parseURLtoFormValues(location.search);
    setFormValues(formValues);
    getLocations();
    setTimeout(() => {
      setLoaded(true);
    }, 200);
  }, [location.search]);

  const getLocations = async () => {
    const getLocationsDataFromApi = await LocationsServices.getLocations(
      values.type,
      values.market,
      true
    )();
    if (
      isArray(getLocationsDataFromApi) &&
      getLocationsDataFromApi?.length > 0
    ) {
      setLocationsData(getLocationsDataFromApi);
    }
  };

  const onSubmit = (values) => {
    history.push(`/realestates/?${convertValuesToURL(values)}`);
    setSearchFilters(values);
    setLoaded(false);

    setTimeout(() => {
      setLoaded(true);
    }, 200);
    return values;
  };

  const getTypePlaceholder = () => {
    const value = values.type;
    if (value?.length === 0) return t("Type");

    if (isArray(value) && value?.length > 1) {
      return `${t("Selected")}: (${value?.length})`;
    }

    return Object.values(value)
      .map((v) => t(v))
      .join(",");
  };
  const getMarketPlaceholder = () => {
    const value = values.market;
    if (value?.length === 0) return t("Select a market type");
    return Object.values(value)
      .map((v) => t(v))
      .join(",");
  };

  const getAreaPlaceholder = () => {
    const value = values.area;

    if (value[0] === "" && value[1] === "") return t("Area");

    return `${value[0] ? `${t("from")} ${value[0]}` : ""}${
      value[1] ? ` ${t("to")} ${value[1]}` : ""
    }`;
  };

  const getRoomsPlaceholder = () => {
    const value = values.rooms;

    if (value?.length === 0) return t("Rooms number");
    if (value?.length === 1) {
      return `${t("Number of rooms")}: ${value[0]}`;
    }
    const max = value[1] === ROOMS_NUM ? `${value[1]}+` : value[1];
    return `${t("Number of rooms")}: ${value[0]} - ${max}`;
  };

  const getPricePlaceholder = () => {
    const value = values.price;
    if (value[0] === "" && value[1] === "") return t("Price");

    return `${value[0] ? `${t("from")} ${value[0]}PLN` : ""}${
      value[1] ? ` ${t("to")} ${value[1]}PLN` : ""
    }`;
  };
  const getAdvancedPlaceholder = () => {
    return t("Advanced search");
  };

  const handleOrderSearch = () => {
    const values = getValues();
    history.push(`/realestates/?${convertValuesToURL(values)}`);
    setLoaded(true);
  };

  const sortRealestates = (order) => {
    setLoaded(false);
    setValue("order", [order]);
    setTimeout(() => handleOrderSearch(order), 0);
  };

  const optionsSort = [
    {
      key: `${t("Price")} ${t("ascending")}`,
      text: `${t("Price")} ${t("ascending")}`,
      value: "price,asc",
    },
    {
      key: `${t("Price")} ${t("descending")}`,
      text: `${t("Price")} ${t("descending")}`,
      value: "price,desc",
    },
    {
      key: `${t("Price")} ${t("per m2")} ${t("ascending")}`,
      text: `${t("Price")} ${t("per m2")} ${t("ascending")}`,
      value: "pricemkw,asc",
    },
    {
      key: `${t("Price")} ${t("per m2")} ${t("descending")}`,
      text: `${t("Price")} ${t("per m2")} ${t("descending")}`,
      value: "pricemkw,desc",
    },
    {
      key: `${t("Area")} ${t("ascending")}`,
      text: `${t("Area")} ${t("ascending")}`,
      value: "area,asc",
    },
    {
      key: `${t("Area")} ${t("descending")}`,
      text: `${t("Area")} ${t("descending")}`,
      value: "area,desc",
    },
  ];

  const canRenderSecondaryMarket = () => {
    if (isAdmin()) return true;

    if (isMetrohouse()) return false;
    if (canSeeSecondaryMarket()) return true;

    return false;
  };

  return (
    <main>
      {appIsLoading && <Loader />}
      <Container className="h-full flex flex-col justify-between px-4 xl:px-0">
        <header className="  sticky top-20  z-20 bg-white w-screen lg:w-full ">
          <form
            className="items-center -ml-2 hidden lg:flex flex-wrap"
            onSubmit={handleSubmit(onSubmit, () => {})}
          >
            <div className="mx-2 w-72 pt-4">
              <DefaultValueInputText
                placeholder={t("Name investment, real estate, developer")}
                control={control}
              />
            </div>
            <div className="mx-2  pt-4">
              <DefaultValueInput
                placeholder={getTypePlaceholder}
                control={control}
                Modal={TypeDropdown}
              />
            </div>
            {canRenderSecondaryMarket() && (
              <div className="mx-2  pt-4">
                <DefaultValueInput
                  placeholder={getMarketPlaceholder}
                  control={control}
                  Modal={MarketDropdown}
                />
              </div>
            )}
            <div className="mx-2  pt-4">
              <DistrictValueInput
                placeholder={getTypePlaceholder}
                control={control}
                cities={cities}
                Modal={DistrictDropdown}
                resetQuery={resetQuery}
                handleSetQuery={handleSetQuery}
                watch={watch}
              />
            </div>
            <div className="mx-2  pt-4">
              <DefaultValueInput
                placeholder={getAreaPlaceholder}
                control={control}
                Modal={AreaDropdown}
              />
            </div>
            <div className="mx-2  pt-4">
              <DefaultValueInput
                placeholder={getRoomsPlaceholder}
                control={control}
                Modal={RoomsDropdown}
              />
            </div>
            <div className="mx-2  pt-4">
              <DefaultValueInput
                placeholder={getPricePlaceholder}
                control={control}
                Modal={PriceDropdown}
              />
            </div>
            <div className="mx-2  pt-4 hidden lg:block">
              <DefaultValueInput
                placeholder={getAdvancedPlaceholder}
                control={control}
                Modal={AdvancedDropdown}
                setValue={setValue}
                completion_quarters={completion_quarters}
              />
            </div>
            <div className="mx-2 pt-4">
              <button
                className="leading-5 flex items-center m-auto py-3 px-4 text-sm"
                type="button"
                onClick={handleReset}
              >
                <X size={14} className="mr-2" />
                {t("Clear")}
              </button>
            </div>
            <div className="mx-2 pt-4">
              <button
                className="leading-5 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                type="submit"
                onClick={() => {
                  setLoaded(false);
                  setPage(1);
                }}
              >
                {t("Search")}
              </button>
            </div>
          </form>
        </header>
        <div
          ref={ref}
          className="py-2 items-center justify-between hidden lg:flex"
        >
          <div className="md:mx-2 md:my-0">
            <h3 className="text-xl font-bold">
              {t("Search results")}:{" "}
              <span className="text-primse-green">{itemsInPagination}</span>
            </h3>
          </div>
          <div className="flex items-center -ml-2">
            <div className="my-2 mx-2 md:my-0 flex  items-center hidden lg:inline-flex">
              <div>
                <p className="mx-2 text-xs lg:text-base">{t("Sort by")}: </p>
              </div>
              <div className="text-xs lg:text-base my-2 md:mx-2 md:my-0">
                <Dropdown
                  className="border-primse-green h-12"
                  style={{ display: "flex", alignItems: "center", zIndex: 9 }}
                  placeholder={t("Choose")}
                  fluid={false}
                  selection={true}
                  defaultValue={"price,asc"}
                  value={`${formValues?.order?.[0]},${formValues?.order?.[1]}`}
                  options={optionsSort}
                  onChange={(event, data) => sortRealestates(data.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="py-4 flex justify-center md:justify-start">
          <Pagination
            activePage={page}
            defaultActivePage={1}
            totalPages={countPage}
            boundaryRange={window.innerWidth < 500 ? 0 : 1}
            siblingRange={window.innerWidth < 500 ? 0 : 1}
            onPageChange={(event, data) => {
              setLoaded(false);
              setPageController(data.activePage);
              window.scrollTo(0, 0);
            }}
          />
        </div>
        <div style={{ minHeight: "80vh" }}>
          {loaded ? (
            <FlatList
              handleButtonSecondaryMarket={handleButtonSecondaryMarket}
              formValues={formValues}
              page={page}
              setAllPageCount={setAllPageCount}
              sortRealestates={sortRealestates}
              setItemsInPagination={setItemsInPagination}
              offsetTopTable={offsetTopTable}
              canRenderSecondaryMarket={canRenderSecondaryMarket}
            />
          ) : (
            <Loader />
          )}
        </div>
        <div className="py-4 flex justify-center md:justify-start">
          <Pagination
            activePage={page}
            defaultActivePage={1}
            totalPages={countPage}
            boundaryRange={window.innerWidth < 500 ? 0 : 1}
            siblingRange={window.innerWidth < 500 ? 0 : 1}
            onPageChange={(event, data) => {
              setLoaded(false);
              setPageController(data.activePage);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      </Container>
      <NewsSection />
    </main>
  );
};

export default Realestates;
