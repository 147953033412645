import axios from "../utils/axios";

const ProblemService = {
  problemSend: async (formData) => {
    return await axios
      .post(`/problem-send`, formData)
      .then((res) => res.data.payload);
  },
};

export default ProblemService;
