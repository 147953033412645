import Moment from "react-moment";
import { actions } from "../../../../store";
import { useStoreState } from "easy-peasy";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import BriefcaseContentService from "../../../../services/BriefcaseContentService";

const BriefcaseSearchItemMobile = ({ data, selectDefaultModal }) => {
  const { t } = useTranslation();

  const { briefcaseAddMobile } = useStoreState((state) => state.briefcase);

  const addInvestmentToBriefcase = (
    client_uuid,
    object_uuid,
    investment_name,
    investment
  ) => {
    BriefcaseContentService.addRealestate({
      contact: client_uuid,
      object_uuid: object_uuid,
      object_type: `${investment ? "investment" : "realestate"}`,
    })
      .then(() => {
        toast.success(
          `${t("The investment")} ${investment_name} ${t(
            "has been successfully added to the briefcase"
          )}`
        );
        actions.briefcase.setBriefcaseAddMobile(null);
        actions.briefcase.setBriefcaseModal(false);
        actions.briefcase.setBriefcaseModalSelectClient(false);
      })
      .catch((err) => {
        if (err?.response.data?.isDuplicated) {
          toast.error(`${t("The investment is already in the briefcase")}`);
        } else
          toast.error(`${t("The investment could not be added to briefcase")}`);
      });
  };

  function handleBriefcaseChange(data) {
    actions.briefcase.setBriefcaseClient(data);

    if (briefcaseAddMobile) {
      addInvestmentToBriefcase(
        data.uuid,
        briefcaseAddMobile.uuid,
        briefcaseAddMobile.name,
        briefcaseAddMobile.investment
      );
    } else {
      selectDefaultModal();
    }
  }

  return (
    <div
      onClick={() => handleBriefcaseChange(data)}
      className="py-3 border-b flex flex-wrap"
    >
      <div className="flex w-full">
        <div className="w-10/12 font-bold text-lg">
          {`${data.first_name} ${data.last_name}`}
        </div>
      </div>
      <div>
        <div>
          <span className="font-bold">Utworzony:</span>{" "}
          <Moment format="DD.MM.YYYY">{data.created}</Moment>
        </div>
        <div>
          <span className="font-bold">Handlowiec:</span>{" "}
          {data.source_of_origin_name}
        </div>
        <div>
          <span className="font-bold">Telefon:</span> +48 123 456 789
        </div>
        <div>
          <span className="font-bold">E-mail:</span> email@email.pl
        </div>
        <div>
          <span className="font-bold">Status:</span> {data.status_name}
        </div>
        <div>
          <span className="font-bold">Zgłoszenia:</span> 5
        </div>
        <div>
          <span className="font-bold">Uwagi:</span> -
        </div>
      </div>
    </div>
  );
};

// ClientsItemMobile.propTypes = {};

// ClientsItemMobile.defaultProps = {};

export default BriefcaseSearchItemMobile;
