import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useQuery } from "react-query";
import RealestatesServices from "../../../../services/RealestatesServices";
import FlatListView from "./FlatListView";
import {
  parseURLtoFormValues,
  priceFilterRealestate,
  roomsFilterRealestate,
  areaFilterRealestate,
  floorsFilterRealestate,
} from "../../../Search/searchUtils";

const FlatList = ({ uuid, sortType, setSortType }) => {
  const location = useLocation();

  const [selectRealestates, setSelectRealestates] = useState([]);
  const [loadedData, setLoadedData] = useState(false);

  const { data } = useQuery(
    `flats-${uuid}`,
    RealestatesServices.getFlatList(uuid, { perPage: 999 }, true)
  );
  const filterInvestments = (formValues) => {
    if (!data) return null;

    setLoadedData(false);
    const filterData = data.payload
      .filter(areaFilterRealestate(formValues))
      .filter(roomsFilterRealestate(formValues))
      .filter(priceFilterRealestate(formValues))
      .filter(floorsFilterRealestate(formValues));

    setSelectRealestates(filterData);

    setLoadedData(true);
  };

  useEffect(() => {
    const formValues = parseURLtoFormValues(location.search);
    filterInvestments(formValues);
  }, [data, location.search]);

  if (!loadedData) {
    return (
      <div className="ui placeholder fluid " style={{ height: "100%" }}></div>
    );
  }

  if (loadedData) {
    return (
      <FlatListView
        flats={selectRealestates}
        sortType={sortType}
        setSortType={setSortType}
      />
    );
  }
};

export default FlatList;
