import { useState, useEffect } from "react";

import FlatHover from "../../../../components/MapGoogle/components/FlatHover";
import { parseThousands } from "../../../../utils/numbers";
import MapInvestment from "../../../Search/components/MapInvestment";
import MapInvestmentsCluster from "../../../Search/components/MapInvestmentsCluster";
import buildingImg from "../../../../components/MapGoogle/assets/building@2x.png";
import { useLocation } from "react-router";

import Loader from "../../../../components/Loader";
import MapRealestateView from "../../../Search/components/MapRealestateView";
import MapGoogle from "../../../../components/MapGoogle";
import MapRealestatesCluster from "../../../Search/components/MapRealestatesCluster";

const MapBasket = ({ currentBasket, markersRealestate = [] }) => {
  const [markers, setMarkers] = useState([]);
  const [loadedDataFetch, setLoadedDataFetch] = useState(false);

  const location = useLocation();

  const filterInvestments = (data) => {
    if (!data) return null;

    const uniqueArray = data.filter(
      (v, i, a) =>
        a.findIndex((t) => t.investment_uuid === v.investment_uuid) === i
    );

    setMarkers(
      uniqueArray
        .filter(
          ({ investment_lat, investment_lng, is_secondary_market }) =>
            investment_lat && investment_lng && !is_secondary_market
        )
        .map((investment) => ({
          uuid: investment.investment_uuid,
          name: investment.investment_name,
          price_from: Number(investment.investment_price_from),
          price_to: Number(investment.investment_price_to),
          area_from: investment.investment_area_from,
          area_to: investment.investment_area_to,
          rooms_from: investment.investment_rooms_from,
          rooms_to: investment.investment_rooms_to,
          pictures: [{ thumbnail: investment.investment_pictures[0] }],
          location: {
            lat: investment.investment_lat,
            lng: investment.investment_lng,
          },
          developer_name: investment.developer_name,
          number_of_offers: investment.number_of_offers,
          completion_date: investment.investment_completion_date,
        }))
    );

    setLoadedDataFetch(true);
  };
  useEffect(() => {
    filterInvestments(currentBasket.basketItems);
  }, [location.pathname]);

  return (
    <>
      {!loadedDataFetch && <Loader />}
      <MapGoogle
        displayElements={{
          briefcasePanel: true,
          basketsList: true,
        }}
        markersInvestment={markers}
        markersRealestate={markersRealestate?.filter(
          (r) => r?.location?.lng && r?.location?.lat
        )}
        defaultCenter={{ lat: 51.9283628, lng: 19.6355535 }}
        TooltipEl={FlatHover}
        MarkerContent={({ price_from, price_to }) => {
          return (
            <>
              <img src={buildingImg} width={20} height={20} alt="icon" />
              <div className="mt-2 text-center">
                <div>{parseThousands(price_from)}</div>
                <div>-</div>
                <div>{parseThousands(price_to)}</div>
              </div>
            </>
          );
        }}
        ActiveMarkerNew={(
          data,
          variant,
          onClosedMarker,
          setActiveScrollMap
        ) => (
          <div>
            {variant === "investment" ? (
              <MapInvestment
                data={data}
                onClosedMarker={onClosedMarker}
                setActiveScrollMap={setActiveScrollMap}
              />
            ) : (
              <MapRealestateView
                data={data}
                onClosedMarker={onClosedMarker}
                setActiveScrollMap={setActiveScrollMap}
              />
            )}
          </div>
        )}
        ActiveMarker={(
          {
            uuid,
            name,
            price_from,
            price_to,
            area_from,
            area_to,
            rooms_from,
            rooms_to,
            number_of_offers,
            city,
            pictures,
            address_street,
            developer_name,
            completion_date,
            variant = "investment",
          },
          onClosedMarker,
          setActiveScrollMap
        ) => (
          <div>
            {variant === "investment" ? (
              <MapInvestment
                uuid={uuid}
                name={name}
                price_from={price_from}
                price_to={price_to}
                area_from={area_from}
                area_to={area_to}
                rooms_from={rooms_from}
                rooms_to={rooms_to}
                number_of_offers={number_of_offers}
                city={city}
                pictures={pictures}
                address_street={address_street}
                onClosedMarker={onClosedMarker}
                setActiveScrollMap={setActiveScrollMap}
                developer_name={developer_name}
                completion_date={completion_date}
              />
            ) : (
              <MapRealestateView
                uuid={uuid}
                name={name}
                pictures={pictures}
                onClosedMarker={onClosedMarker}
                setActiveScrollMap={setActiveScrollMap}
                developer_name={developer_name}
              />
            )}
          </div>
        )}
        ActiveCluster={(
          investments,
          onClosedMarker,
          setActiveScrollMap,
          isInvestments = true
        ) => (
          <>
            {isInvestments ? (
              <MapInvestmentsCluster
                investments={investments}
                onClosedMarker={onClosedMarker}
                setActiveScrollMap={setActiveScrollMap}
              />
            ) : (
              <MapRealestatesCluster
                realestates={investments}
                onClosedMarker={onClosedMarker}
                setActiveScrollMap={setActiveScrollMap}
              />
            )}
          </>
        )}
      />
    </>
  );
};

export default MapBasket;
