import * as Sentry from "@sentry/react";

export const sendErrorSentry = (title = "ERROR", error = {}, extras = []) => {
  try {
    Sentry.setExtra("Error", error);

    extras?.forEach((el) => {
      if (el?.error && el?.error !== "") {
        Sentry.setExtra(`${el?.name}`, el?.error);
      }
    });

    Sentry.captureMessage("INFO: " + title);
  } catch (error) {
    console.log("sentrySetError ERROR ", error);
  }
};

export function isArray(v) {
  return v instanceof Array;
}
