import Container from "../../components/Container";
import { useQuery } from "react-query";
import NewsServices from "../../services/NewsServices";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import NewsSection from "../../components/NewsSection";
import cs from "classnames";
import { useMemo, useState } from "react";
import DictionariesServices from "../../services/DictionariesServices";
import SimpleLoader from "../../components/Loader/SimpleLoader";
import { isArray } from "../../utils/functions";

const SingleNews = ({ news }) => {
  const { t } = useTranslation();

  const filesInGroupType = useMemo(() => {
    const newFiles = news?.files.reduce((acc, file) => {
      const { type } = file;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(file);
      return acc;
    }, {});

    let mainImage = null;

    // Banners / Images
    if (newFiles?.Banner?.length > 0) {
      mainImage = newFiles?.Banner[0];
    } else if (newFiles?.Image?.length > 0) {
      mainImage = newFiles?.Image[0];
    }

    return { ...newFiles, mainImage };
  }, [news?.files]);

  return (
    <article className=" group border-primse-green border-2 rounded flex flex-col justify-between shadow-none hover:shadow-xl shadow-black transition-shadow duration-500 ease-in-out ">
      <div>
        {filesInGroupType?.mainImage && (
          <div className="relative">
            <Link to={`/news-info/${news.uuid}`}>
              <div className="bg-gradient-to-b from-transparent via-transparent to-gray-800 w-full h-60  absolute top-0 transition-all duration-500 ease-in-out"></div>
              <div className="w-full bg-black h-60 opacity-10 group-hover:opacity-0 absolute top-0 transition-all duration-500 ease-in-out"></div>
              <img
                src={`${process.env.REACT_APP_IMAGES_URL}${filesInGroupType?.mainImage?.url}`}
                alt={filesInGroupType?.mainImage?.name}
                className="object-cover h-60 w-full object-cover  "
              />
              {news?.text_on_label && (
                <div className="absolute top-4 right-4 rounded-full bg-primse-green px-4 py-1">
                  <p className="text-sm font-extrabold uppercase text-white ">
                    {news.text_on_label}
                  </p>
                </div>
              )}
            </Link>
          </div>
        )}
        <div className="rounded-full bg-gray-800 relative left-6 -top-10 w-max p-2">
          <img className="w-12" src="/logo-primse-favicon.png" />
        </div>
        <div className="p-6 pt-0" style={{ marginTop: "-10px" }}>
          <Link to={`/news-info/${news?.uuid}`}>
            <p className="my-1 font-bold text-xl text-gray-600 ">
              {news?.subject}
            </p>
          </Link>

          <p className="my-6 text-gray-500 ">{news?.short_text}</p>
        </div>
      </div>

      <div className="">
        <Link to={`/news-info/${news?.uuid}`}>
          <p className="text-sm font-extrabold uppercase mt-4 pt-4 px-6">
            {t("Read more")}
          </p>
        </Link>
        <p className="text-sm border-t border-gray-200 text-gray-400 mt-4 py-2 px-6">
          {news?.active_date}
        </p>
      </div>
    </article>
  );
};

const SingleBestNews = ({ news }) => {
  const { t } = useTranslation();

  const filesInGroupType = useMemo(() => {
    const newFiles = news?.files.reduce((acc, file) => {
      const { type } = file;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(file);
      return acc;
    }, {});

    let mainImage = null;

    // Banners / Images
    if (newFiles?.Banner?.length > 0) {
      mainImage = newFiles?.Banner[0];
    } else if (newFiles?.Image?.length > 0) {
      mainImage = newFiles?.Image[0];
    }

    return { ...newFiles, mainImage };
  }, [news?.files]);

  return (
    <article className="mb-16 flex flex-col justify-between ">
      <div>
        {filesInGroupType?.mainImage && (
          <div>
            <Link to={`/news-info/${news.uuid}`}>
              <img
                src={`${process.env.REACT_APP_IMAGES_URL}${filesInGroupType?.mainImage?.url}`}
                alt={filesInGroupType?.mainImage?.name}
                className="object-cover h-60 w-full object-cover  "
              />
            </Link>
          </div>
        )}

        <div className="py-6">
          <Link to={`/news-info/${news?.uuid}`}>
            <p className="my-1   text-xl text-gray-600 ">{news?.subject}</p>
          </Link>
        </div>
      </div>

      <div className="">
        <Link to={`/news-info/${news?.uuid}`}>
          <p className="text-sm font-extrabold">{t("Read more")}</p>
        </Link>
      </div>
    </article>
  );
};

const News = () => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [news, setNews] = useState([]);

  const [loadingNews, setLoadingNews] = useState(true);

  const { t } = useTranslation();

  const { data: dictionariesList } = useQuery(
    "dictionaries",
    DictionariesServices.getDictionaries
  );

  const { data: newsPopular, isLoading: isLoadingNewsPopular } = useQuery(
    "newsPopular",
    () =>
      NewsServices.getNews({
        order: "active_date,desc",
        perPage: 2,
        is_popular: 1,
      })
  );

  const fetchNews = async (category_id) => {
    setLoadingNews(true);
    const res = await NewsServices.getNews({
      order: "active_date,desc",
      perPage: -1,
      category_id: [category_id],
    });

    if (isArray(res)) {
      setNews(res);
    }

    setLoadingNews(false);
  };

  const handleChangeCategory = (e) => {
    setActiveCategory(e);
    fetchNews(e);
  };

  const popularNews = useMemo(() => {
    if (!news && !newsPopular) return [];
    if (newsPopular?.length === 0 && news?.length === 0) return [];

    if (newsPopular?.length > 0) return newsPopular;
    if (newsPopular?.length === 0 && news?.length > 0) return news?.slice(0, 2);

    return [];
  }, [news, newsPopular]);

  const categoriesList = useMemo(() => {
    if (!dictionariesList?.information_category) return [];
    const arr = [];
    Object.entries(dictionariesList?.information_category)?.forEach(
      ([key, value]) => {
        arr?.push({ key, value });
      }
    );

    const findMainCategory = arr?.find(
      (el) => el?.value === "Nowości w ofertach"
    );

    if (findMainCategory) {
      handleChangeCategory(findMainCategory?.key);
    } else if (arr?.length > 0) {
      handleChangeCategory(arr[0]?.key);
    }

    return arr;
  }, [dictionariesList?.information_category]);

  return (
    <main className="mt-4 px-4">
      {isLoadingNewsPopular && <Loader />}
      <div
        style={{
          minHeight: "80vh",
          maxWidth: "1200px",
          margin: "0 auto",
          marginBottom: "10px",
        }}
      >
        <Container>
          <div className="pb-2 pt-6 border-b border-primse-green mb-8  w-max pr-10">
            <h2 className="text-4xl font-bold text-grey-600">{t("News")}</h2>
          </div>
          <div className="mb-4 flex flex-col md:flex-row gap-6 font-bold pb-4">
            {categoriesList?.map((category) => {
              return (
                <div
                  key={category?.key}
                  className={cs(
                    "cursor-pointer hover:text-primse-green transition-all w-max",
                    category?.key === activeCategory
                      ? "text-primse-green border-b border-primse-green"
                      : ""
                  )}
                  onClick={() => {
                    handleChangeCategory(category?.key);
                  }}
                >
                  {category?.value}
                </div>
              );
            })}
          </div>

          <div className="flex">
            {loadingNews ? (
              <SimpleLoader />
            ) : (
              <>
                {news?.length > 0 && (
                  <div
                    className={cs(
                      " w-full xl:w-9/12 grid-cols-1 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-8 xl:mr-16",
                      news?.length === 2 && "h-10"
                    )}
                  >
                    {news.map((news) => (
                      <SingleNews news={news} key={`${news.uuid}_ALL_NEWS`} />
                    ))}
                  </div>
                )}
              </>
            )}

            {popularNews?.length > 0 && (
              <aside className="hidden xl:block xl:w-3/12  flex flex-col">
                <p className="text-4xl font-bold">{t("Most popular")}:</p>
                {popularNews.map((news) => (
                  <SingleBestNews
                    news={news}
                    key={`${news.uuid}_POPULAR_NEWS`}
                  />
                ))}
              </aside>
            )}
          </div>
        </Container>
      </div>
      <NewsSection />
    </main>
  );
};

export default News;
