import PopupTrigger from "./components/PopupTrigger";
import ControlerEditModal from "./components/ControlerEditModal";
import { Menu } from "react-feather";

const PopupClients = ({
  openDetailsModal,
  setOpenDetailsModal,
  openReplyModal,
  setOpenReplyModal,

  ...rest
}) => {
  const closedModal = () => {
    setOpenReplyModal(false);
    setOpenDetailsModal(false);
  };

  return (
    <>
      <div>
        {openReplyModal && (
          <ControlerEditModal
            closedModal={closedModal}
            type="reply"
            {...rest}
          />
        )}
        {openDetailsModal && (
          <ControlerEditModal
            closedModal={closedModal}
            type="details"
            {...rest}
          />
        )}

        {!openReplyModal && !openDetailsModal && (
          <PopupTrigger
            openReplyModal={setOpenReplyModal}
            openDetailsModal={setOpenDetailsModal}
            {...rest}
          >
            <Menu className="mx-2 text-primse-green cursor-pointer" size={24} />
          </PopupTrigger>
        )}
      </div>
    </>
  );
};

export default PopupClients;
