import React, { useMemo } from "react";
import { MapPin } from "react-feather";
import { useTranslation } from "react-i18next";
import Badge from "../../../../../components/Badge";
import FilterModal from "./FilterModal";
import { truncateString } from "../../../searchUtils";

const DistrictDropdown = ({
  options = [],
  query,
  onOptionSelect = () => {
    //  console.log("selected place: ", place)
  },
  onOptionRemove,
  values,
  watch,
}) => {
  const { t } = useTranslation();

  const typeSelect = useMemo(() => {
    if (watch()?.type?.length > 0) {
      return true;
    } else {
      return false;
    }
  }, [watch()?.type]);

  return (
    <FilterModal title={null} showButtons={false}>
      <div className="-m-3 max-h-96 overflow-auto">
        <ul className="mb-4">
          {!typeSelect && <div>{t("Choose property type")}</div>}
          {typeSelect && options?.length < 1 && (
            <div>{t("No results found")}</div>
          )}

          {values.map((option) => (
            <li className="mb-2" key={option}>
              <Badge
                onRemove={() => {
                  onOptionRemove(option);
                }}
                Icon={<MapPin size={14} />}
              >
                {truncateString(
                  option.charAt(0).toUpperCase() + option.slice(1),
                  20
                )}
              </Badge>
            </li>
          ))}
        </ul>
        <ul className="">
          {options
            .filter((o) => !values.includes(o.value))
            .filter((o) =>
              query !== ""
                ? o.value.indexOf(query) !== -1 ||
                  o.description.toLowerCase().indexOf(query.toLowerCase()) !==
                    -1
                : true
            )
            .map((place) => (
              <li
                className="border-b py-3 px-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  onOptionSelect(place);
                }}
                key={place.id}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <div className="text-base font-medium">{place.name}</div>
                    {place.description && (
                      <div className="text-xs text-gray-400">
                        {place.description}
                      </div>
                    )}
                  </div>
                  {place.offers && (
                    <div className="text-xs text-gray-400">{`${place.offers} ofert`}</div>
                  )}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </FilterModal>
  );
};
export default DistrictDropdown;
