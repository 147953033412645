import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import PlanModal from "../PlanModal";

import DocumentsServices from "../../services/DocumentsServices";
import TrackingServices from "../../services/TrackingServices";

const DetailsInvestments = ({
  PDFLink,
  PDFName,
  planLink,
  uuid,
  pathname,
  classNameProps,
}) => {
  const { t } = useTranslation();

  const trackingPDF = (url, nameRealestate, uuidRealestate, success) => {
    TrackingServices.trackingAdd({
      url: url,
      name: `Realestate - download PDF`,
      description: `Lokal: ${nameRealestate}, uuid Lokalu: ${uuidRealestate}, status pobierania: ${
        success ? "pobrano" : "błąd podczas pobierania"
      }`,
    });
  };

  return (
    <>
      {PDFLink && (
        <p
          onClick={() => {
            DocumentsServices.getPdf({
              document_uuid: PDFLink,
              file_name: PDFName,
            })
              .then(() => {
                // toast.success(`${t("Document download started")}`);
                trackingPDF(pathname, PDFName, uuid, true);
              })
              .catch(() => {
                toast.error(`${t("The document could not be downloaded")}`);
                trackingPDF(pathname, PDFName, uuid, false);
              });
          }}
          className="inline text-primse-green cursor-pointer"
        >
          PDF
        </p>
      )}
      {planLink && PDFLink && ` / `}

      {planLink && (
        <>
          <PlanModal
            link={`${process.env.REACT_APP_IMAGES_URL}${planLink}`}
            name="Plan"
            url={pathname}
            nameTracking="Realestate - show plan"
            description={`Lokal: ${PDFName}, uuid Lokalu: ${uuid}`}
            classNameProps={classNameProps}
          />
        </>
      )}
    </>
  );
};

export default DetailsInvestments;
