import { action } from "easy-peasy";

const loadingStore = {
    isLoading: false,
    setIsLoading: action((state, payload) => {
        state.isLoading = payload;
    }),
}


export default loadingStore;