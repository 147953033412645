import { useQuery } from "react-query";
import InvestmentsService from "../../../../services/InvestmentsService";
import Investment from "../../../../components/Investment";
import Loader from "../../../../components/Loader";
import { BriefCasePlus } from "../../../../components/Icons";
import RealestatesServices from "../../../../services/RealestatesServices";
import { useStoreState } from "easy-peasy";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import { parseURLtoFormValues } from "../../searchUtils";
import { editPrice } from "../../../../utils/numbers";
import { useLocation } from "react-router";
import { ChevronLeft, ChevronRight } from "react-feather";

import { Popup } from "semantic-ui-react";
import {
  addToBasket,
  addFlatToBriefcase,
} from "../../../../utils/customerInteraction";
import ButtonNavLink from "../../../../components/ButtonNavLink";
import cs from "classnames";

const MapInvestmentsCluster = ({
  investments,
  onClosedMarker,
  setActiveScrollMap,
}) => {
  const [selectInvestment, setSelectInvestment] = useState(0);

  const location = useLocation();
  const formValues = parseURLtoFormValues(location.search);

  const { data: investmentData, isLoading: investmentLoading } = useQuery(
    `investment-${investments[selectInvestment].uuid}`,
    InvestmentsService.getInvestment(investments[selectInvestment].uuid)
  );

  const { data: flatsData, isLoading: flatsLoading } = useQuery(
    `flats-${investments[selectInvestment].uuid}`,
    RealestatesServices.getFlatList(investments[selectInvestment].uuid, {
      roomsFrom: formValues.rooms[0],
      roomsTo: formValues.rooms[1],
      priceFrom: formValues.price[0],
      priceTo: formValues.price[1],
      areaFrom: formValues.area[0],
      areaTo: formValues.area[1],
    })
  );

  return (
    <MapInvestment
      allInvestments={investments}
      selectInvestment={selectInvestment}
      uuid={investments[selectInvestment].uuid}
      onClosedMarker={onClosedMarker}
      setActiveScrollMap={setActiveScrollMap}
      investmentData={investmentData}
      investmentLoading={investmentLoading}
      flatsData={flatsData}
      flatsLoading={flatsLoading}
      setSelectInvestment={setSelectInvestment}
    />
  );
};

const MapInvestment = ({
  allInvestments,
  selectInvestment,
  onClosedMarker,
  setActiveScrollMap,
  investmentData,
  investmentLoading,
  flatsData,
  flatsLoading,
  setSelectInvestment,
}) => {
  const { t } = useTranslation();
  const [loading] = useState(false);

  const { briefcaseClient } = useStoreState((state) => state.briefcase);
  const { currentBasketUuid } = useStoreState((state) => state.baskets);

  const isLoading = investmentLoading || flatsLoading || loading;

  return (
    <div
      onMouseEnter={() => {
        setActiveScrollMap(false);
      }}
      onMouseLeave={() => {
        setActiveScrollMap(true);
      }}
      className={cs(
        "p-5 bg-white absolute bottom-11 -left-80 shadow-md z-10 ",
        allInvestments?.length === 1 ? "pt-5" : "pt-0"
      )}
    >
      {investmentLoading ||
        (!investmentData && (
          <Loader position="absolute" opacity="bg-opacity-100" />
        ))}
      {allInvestments?.length !== 1 && (
        <div className="flex justify-center items-center">
          <button
            className="leading-5 m-2 text-primse-green px-5 cursor-pointer m-0"
            onClick={() => {
              if (selectInvestment > 0) {
                setSelectInvestment((prev) => prev - 1);
              }
            }}
          >
            <ChevronLeft />
          </button>
          <p className="text-primse-green m-0 text-lg">
            <strong>
              {selectInvestment + 1} / {allInvestments?.length}
            </strong>
          </p>
          <button
            className="leading-5 m-2 text-primse-green px-5 cursor-pointer m-0"
            onClick={() => {
              if (selectInvestment < allInvestments?.length - 1) {
                setSelectInvestment((prev) => prev + 1);
              }
            }}
          >
            <ChevronRight />
          </button>
        </div>
      )}
      <div className="flex">
        <div className="absolute right-3 top-3" data-id="exitModal">
          <AiFillCloseCircle
            onClick={() => {
              setActiveScrollMap(true);
              onClosedMarker();
            }}
            className="text-primse-gray-font cursor-pointer w-6 h-6"
            data-id="exitModal"
          />
        </div>

        {investmentData && (
          <Investment
            {...investmentData}
            editPrice={editPrice}
            investmentData={investmentData}
          />
        )}
      </div>
      <div className="-mt-7">
        <div className="mt-7 max-h-40 overflow-auto">
          <table className="w-full text-sm">
            {isLoading && (
              <div className="h-40 relative">
                <Loader position="absolute" />
              </div>
            )}
            {!isLoading && (
              <tbody className="text-primse-gray-font text-sm">
                {flatsData?.payload.map((flat, index) => (
                  <tr
                    key={`${flat.uuid}${index}`}
                    className="transition-all duration-300 hover:bg-primse-light-green cursor-pointer"
                  >
                    <td className="py-2 pl-2 border-b">
                      <ButtonNavLink
                        uuid={flat.uuid}
                        link={`/realestate/${flat.uuid}`}
                        name={`${flat.name}`}
                      />
                    </td>
                    <td className="py-2 border-b">
                      <strong>{editPrice(flat.price)} PLN</strong>{" "}
                      {flat.pricemkw && (
                        <span className="opacity-50">
                          ({flat.pricemkw} PLN/m2)
                        </span>
                      )}
                    </td>
                    <td className="py-2 border-b">
                      {flat.area && (
                        <>
                          {flat.area} m<sup>2</sup>
                        </>
                      )}
                    </td>

                    <td className="py-2 border-b">
                      {flat.rooms &&
                        `${flat.rooms} ${
                          flat.rooms > 1
                            ? t("Number-rooms").toLowerCase()
                            : t("Room").toLowerCase()
                        }`}
                    </td>

                    <td className="py-2 border-b">
                      {" "}
                      {flat.floor &&
                        `${
                          flat.floor < 1
                            ? t("Ground floor").toLowerCase()
                            : flat.floor + " " + t("Floor").toLowerCase()
                        }`}
                    </td>
                    <td className="py-2 border-b">
                      <div className="duration-300 hover:opacity-80">
                        <Popup
                          trigger={
                            <div className="cursor-pointer text-white flex items-center justify-center duration-300 hover:opacity-90">
                              <div className="w-8 ">
                                <BriefCasePlus className="text-primse-green " />
                              </div>
                            </div>
                          }
                          flowing
                          on="click"
                          position="right center"
                        >
                          <div className="bg-white  text-sm font-normal">
                            <div className="flex flex-col">
                              <button
                                className="leading-5 my-2 font-bold text-black uppercase bg-primse-green py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                                onClick={(e) => {
                                  addToBasket(
                                    e,
                                    flat.uuid,
                                    currentBasketUuid,
                                    flat.name
                                  );
                                }}
                              >
                                {t("Add to basket")}
                              </button>
                              <button
                                className="leading-5 my-2 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                                onClick={() => {
                                  addFlatToBriefcase(
                                    flat.uuid,
                                    flat.name,
                                    briefcaseClient
                                  );
                                }}
                              >
                                {t("Add to client briefcase")}
                              </button>
                            </div>
                          </div>
                        </Popup>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default MapInvestmentsCluster;
