import React from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import ReplyQuestionContent from "../ReplyQuestionContent";

const ControlerEditModal = ({ closedModal, ...rest }) => {
  const { t } = useTranslation();
  return (
    <Modal open="true" onClose={() => closedModal()}>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between items-center">
          <div>{t("Question")}</div>
          <AiFillCloseCircle
            onClick={() => {
              closedModal();
            }}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>

      <Modal.Content>
        <ReplyQuestionContent {...rest} closedModal={closedModal} />
      </Modal.Content>
    </Modal>
  );
};

export default ControlerEditModal;
