import { Popup } from "semantic-ui-react";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { useStoreState } from "easy-peasy";

const PopupTrigger = (rest) => {
  return <PopupView {...rest} />;
};

const PopupView = ({ children, openEditModal, openDeleteModal, status }) => {
  const { t } = useTranslation();

  const userAgencyType = useStoreState((state) => state.auth.user?.agencyType);
  const userRole = useStoreState((state) => state.auth.user?.role);

  return (
    <Popup trigger={children} flowing on="click" position={"bottom center"}>
      <div className={cs("bg-white  text-sm font-normal ")}>
        {userAgencyType !== "Metrohouse office" && (
          <div className="border-b">
            <div
              className="p-3 cursor-pointer hover:text-primse-green  "
              onClick={() => openEditModal(true)}
            >
              <strong>{t("Edit customer details")}</strong>
            </div>
          </div>
        )}
        {status === "New" && userRole === "Agency" && (
          <div className="border-b text-center">
            <div
              className="p-3 cursor-pointer hover:text-primse-green  "
              onClick={() => openDeleteModal(true)}
            >
              <strong>{t("Delete client")} </strong>
            </div>
          </div>
        )}
      </div>
    </Popup>
  );
};

export default PopupTrigger;
