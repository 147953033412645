import axios from "../utils/axios";

const TrackingServices = {
  trackingAdd: async ({ name, url, description = "", identifier = null }) => {
    return await axios.post(`/tracking-add`, {
      name: name,
      identifier: identifier,
      context: {
        url: url,
        opis: description !== "" ? description : null,
      },
    });
  },
};

export default TrackingServices;
