import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumb, Icon } from "semantic-ui-react";
import "./style.css";
import { useStoreActions } from "easy-peasy";
import useQueryParams from "../../hooks/useQueryParams";

import { DEFAULTVALUES } from "../../containers/Search/searchUtils";

const BreadcrumbExample = ({
  pages = [{ name: null, linkPage: null, uuid: null }],
  current = null,
  returnPage,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const setSearchFilters = useStoreActions(
    (actions) => actions.filters.setSearchFilters
  );

  const { convertStringParamsToObject } = useQueryParams();

  const convertText = (text) => {
    return text.toLowerCase();
  };

  const goBack = () => {
    history.goBack();
  };

  const PagesMap = pages.map(({ name, linkPage, uuid }, index) => {
    if (name === null || name === "null") return null;
    if (index === 0) {
      return (
        <Breadcrumb.Section
          className="text-black-important "
          key={name + index + uuid}
          as={Link}
          to={`/`}
          link
        >
          {t(name)}
        </Breadcrumb.Section>
      );
    } else {
      return (
        <div key={name + index + uuid}>
          <Breadcrumb.Divider />
          <Breadcrumb.Section
            className="text-black-important "
            onClick={(e) => {
              e.preventDefault();
              if (!linkPage) history.push("/");

              if (linkPage.startsWith("?")) {
                const convertParams = convertStringParamsToObject(linkPage);
                const newParams = { ...DEFAULTVALUES, ...convertParams };

                setSearchFilters(newParams);
                history.push("/");
              } else {
                history.push(`/${linkPage}`);
              }
            }}
            as={Link}
            to={`/${linkPage}`}
            link
          >
            {t(name)}
          </Breadcrumb.Section>
        </div>
      );
    }
  });

  const AddDevider = (PagesMap) => {
    if (PagesMap?.length !== 0) {
      return <Breadcrumb.Divider />;
    }
  };

  return (
    <div className="mx-8 mb-4 flex">
      <div>
        <Breadcrumb>
          <Icon name="left angle" className="breadcump-angle " />
          {returnPage === "/" ? (
            <Breadcrumb.Section
              className="text-black-important "
              onClick={(e) => {
                e.preventDefault();
                goBack();
              }}
              link
            >
              {t("Return")}
            </Breadcrumb.Section>
          ) : (
            <Breadcrumb.Section
              className="text-black-important "
              as={Link}
              to={`/${convertText(returnPage)}`}
              link
            >
              {t(returnPage)}
            </Breadcrumb.Section>
          )}
        </Breadcrumb>
      </div>
      <div className="hidden md:block">
        <Breadcrumb className="px-8 ">
          <div className="flex">
            {PagesMap}
            {current !== null ? AddDevider(PagesMap) : null}
            {current !== null ? (
              <Breadcrumb.Section active>{current}</Breadcrumb.Section>
            ) : null}
          </div>
        </Breadcrumb>
      </div>
    </div>
  );
};

export default BreadcrumbExample;
