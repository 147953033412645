import propTypes from "prop-types";
import logoImg from "./logoPsmall.jpg";

const Logo = ({ width, height }) => (
  <img src={logoImg} width={width} height={height}  alt="primse logo"/>
);

Logo.propTypes = {
  width: propTypes.number,
  height: propTypes.number,
};

Logo.defaultProps = {
  width: 160,
  height: 40,
};

export default Logo;
