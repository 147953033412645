import PropTypes from "prop-types";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import DocumentsServices from "../../../../services/DocumentsServices";
import { toast } from "react-toastify";
const Td = ({ children }) => (
  <td className="py-3 md:py-6 px-5 border-b">{children}</td>
);

const Documents = ({ files }) => {
  const { t } = useTranslation();
  if (files === null) return null;

  function formatSizeUnits(bytes) {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + " MB";
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024000).toFixed(2) + " MB";
    } else {
      bytes = t("No data");
    }
    return bytes;
  }

  const getTypeName = (type, name) => {
    if (!type) return name || t("File");

    if (type === "Plan") return `${t("Apartment plan")} JPG`;
    if (type === "Card") return `${t("Apartment card")} PDF`;
    return name || t("File");
  };

  return (
    <div className="w-full pt-4 md:py-4">
      <div className="flex items-center justify-center md:justify-between">
        <h4 className="text-xl font-bold  md:text-left">{t("Documents")}</h4>
      </div>
      <div className="mt-11 ">
        <table className="border w-full ">
          <thead className="font-bold hidden md:table-header-group">
            <tr>
              <Td>{t("Document name")}</Td>
              <Td>{t("Size")}</Td>
              <Td>{t("Date added")}</Td>
              <Td></Td>
            </tr>
          </thead>
          <tbody>
            {files
              ?.filter((el) => {
                if (el?.type === "Card" || el?.type === "Plan") return el;
                return null;
              })
              .map(({ name, creation_date, id, extension, filesize, type }) => (
                <tr
                  className="px2 mb-4 md:mb-0  text-center flex flex-col md:table-row md:text-left   "
                  key={id}
                >
                  <Td>{getTypeName(type, name)}</Td>
                  <Td>{formatSizeUnits(filesize)}</Td>
                  <Td>
                    <Moment format="YYYY.MM.DD">{creation_date}</Moment>
                  </Td>
                  <Td>
                    <div className="text-center">
                      <button
                        onClick={() => {
                          DocumentsServices.getFile({
                            document_id: id,
                            file_name: name,
                            extension: extension,
                          })
                            .then(() =>
                              toast.success(`${t("Document download started")}`)
                            )
                            .catch(() =>
                              toast.error(
                                `${t("The document could not be downloaded")}`
                              )
                            );
                        }}
                        className="leading-5 text-white bg-primse-green px-6 py-2 font-bold rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                      >
                        {t("Download")}
                      </button>
                    </div>
                  </Td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

Documents.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      creation_date: PropTypes.string,
      file: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
};
Documents.defaultProps = {
  files: [],
};

export default Documents;
