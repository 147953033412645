import { useEffect, useState } from "react";

const useAdjustedClusters = ({
  clusters1,
  clusters2,
  zoom,
  markerSize = 56,
}) => {
  const [adjustedClusters1, setAdjustedClusters1] = useState(clusters1 || []);
  const [adjustedClusters2, setAdjustedClusters2] = useState(clusters2 || []);

  const adjustCoords = (cluster1, cluster2, scaleDelta) => {
    const dx =
      cluster1.geometry.coordinates[0] - cluster2.geometry.coordinates[0];
    const dy =
      cluster1.geometry.coordinates[1] - cluster2.geometry.coordinates[1];
    const distance = Math.sqrt(dx * dx + dy * dy);
    const moveBy = Math.max(0, (scaleDelta - distance) / 2);

    if (distance < scaleDelta) {
      cluster1.geometry.coordinates[0] -= moveBy;
      cluster1.geometry.coordinates[1] -= moveBy;
      cluster2.geometry.coordinates[0] += moveBy;
      cluster2.geometry.coordinates[1] += moveBy;
    }
  };

  useEffect(() => {
    if (!Array.isArray(clusters1) || !Array.isArray(clusters2)) {
      console.warn("Provided clusters must be arrays.");
      return;
    }

    const scaleDelta = markerSize / Math.pow(2, zoom);

    let newClusters1 = JSON.parse(JSON.stringify(clusters1));
    let newClusters2 = JSON.parse(JSON.stringify(clusters2));

    newClusters1.forEach((cluster1) => {
      newClusters2.forEach((cluster2) => {
        adjustCoords(cluster1, cluster2, scaleDelta);
      });
    });

    // Verify again after adjusting
    newClusters1.forEach((cluster1) => {
      newClusters2.forEach((cluster2) => {
        adjustCoords(cluster1, cluster2, scaleDelta);
      });
    });

    setAdjustedClusters1(newClusters1);
    setAdjustedClusters2(newClusters2);
  }, [clusters1, clusters2, zoom, markerSize]);

  return [adjustedClusters1, adjustedClusters2];
};

export default useAdjustedClusters;
