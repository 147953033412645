import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { Image, Modal } from "semantic-ui-react";
import Loader from "../../../../../components/Loader";
import PDFViewer from "../../../../../components/PDFViewer";
import DocumentsServices from "../../../../../services/DocumentsServices";

const ShowPresentation = ({
  setShowPresentation,
  showPresentation,
  confirmation_document_uuid,
  downloadFile: downloadPresentation,
}) => {
  const { t } = useTranslation();
  const [type, setType] = useState(null);
  const [url, setURL] = useState(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (showPresentation) {
      downloadFile();
    }

    if (showPresentation) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      setType(null);
      setURL(null);
      setLoaded(false);
      document.body.style.overflow = "unset";
    };
  }, [showPresentation]);

  const downloadFile = async () => {
    setLoaded(false);
    await DocumentsServices.showDocument({
      document_uuid: confirmation_document_uuid,
    })
      .then((res) => {
        setURL(res?.url);
        setType(res?.extension);
      })
      .catch(() => toast.error(`${t("The document could not be downloaded")}`));

    setLoaded(true);
  };

  const GetComponent = ({ type }) => {
    if (!type) return null;
    switch (type) {
      case "pdf":
        return <Modal.Content>{url && <PDFViewer url={url} />}</Modal.Content>;
      case "jpg":
        return (
          <Modal.Content image className="responsive">
            <Image size="fullscreen" src={url} wrapped />
          </Modal.Content>
        );
      case "png":
        return (
          <Modal.Content image className="responsive">
            <Image size="fullscreen" src={url} wrapped />
          </Modal.Content>
        );

      default:
        return (
          <div className="flex flex-col items-center justify-center py-24">
            <p className="text-2xl">{t("Unable to preview")}</p>
            <button
              onClick={() => {
                setShowPresentation(null);
                downloadPresentation();
              }}
              className="leading-5 w-56 mx-2 my-6 font-bold text-white uppercase bg-primse-green py-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
            >
              {t("Download")}
            </button>
          </div>
        );
    }
  };

  return (
    <Modal open={showPresentation ? true : false}>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between">
          <h3 className="text-xl font-bold">{t("Preview")}</h3>
          <AiFillCloseCircle
            onClick={() => setShowPresentation(null)}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      {!loaded && (
        <Modal.Content className="responsive h-96">
          <Loader />
        </Modal.Content>
      )}
      {loaded && <GetComponent type={type} />}
    </Modal>
  );
};

export default ShowPresentation;
