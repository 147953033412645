import propTypes from "prop-types";
import cs from "classnames";

const Button = ({ children, className, Icon, ...props }) => {
  return (
    <button
      className={cs(
        "leading-5 uppercase bg-primse-green py-5 px-8 rounded-sm transition-all hover:bg-primse-gray",
        className
      )}
      {...props}
    >
      <div className="w-6 mx-4">
     {Icon && <Icon/>}
      </div>
      <span className="text-white font-bold whitespace-nowrap"  >{children}</span>
    </button>

  );
};

Button.propTypes = {
  Icon: propTypes.any,
  children: propTypes.oneOfType([propTypes.string, propTypes.element]),
  onClick: propTypes.func,
  className: propTypes.string,
};

Button.defaultProps = {
  Icon: null,
  children: "Sample button",
  onClick: null,
  className: null,
};

export default Button;
