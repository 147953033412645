import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { CartWhiteMap } from "../../../../components/Icons";
import { useStoreState } from "easy-peasy";
import PopupTrigger from "./components/PopupTrigger";
import SelectModal from "./components/SelectModal";

const BasketPanel = () => {
  const { currentBasket } = useStoreState((state) => state.baskets);

  return currentBasket ? <BasketPanelButton /> : null;
};

const BasketPanelButton = () => {
  const [openSelectBasket, setOpenSelectBasket] = useState(false);

  const { uuid } = useParams();

  const { basketModalOpen, currentBasket } = useStoreState(
    (state) => state.baskets
  );

  const currentItemsInBasket = useMemo(() => {
    const filterFunction = (el) => {
      if (el.is_secondary_market) {
        if (el.realestate_name) return el;
      } else return el;
    };

    if (currentBasket.basketItems) {
      return currentBasket.basketItems.filter(filterFunction)?.length;
    } else return 0;
  }, [currentBasket.basketItems]);

  useEffect(() => {}, [currentBasket.basketItems]);

  const closedAllModal = () => {
    setOpenSelectBasket(false);
  };

  return (
    <div>
      {openSelectBasket && (
        <SelectModal
          openBasket={openSelectBasket}
          setOpenBasket={closedAllModal}
        />
      )}

      {!basketModalOpen && !openSelectBasket && (
        <>
          <PopupTrigger
            uuid={uuid}
            type="map-basket"
            setOpenSelectBasket={setOpenSelectBasket}
            basketName={currentBasket.name}
            aftermarket={false}
          >
            <div className="bg-primse-green absolute bottom-8 right-8 rounded-full h-24 w-24 flex items-center justify-center cursor-pointer">
              <div className="bg-primse-mid-gray text-white absolute right-4 top-5 rounded-full h-6 w-6 flex items-center justify-center">
                {currentItemsInBasket}
              </div>
              <div className="w-12">
                <CartWhiteMap />
              </div>
            </div>
          </PopupTrigger>
        </>
      )}
    </div>
  );
};

export default BasketPanel;
