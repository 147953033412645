const validatePasswordLength = (pass) => {
  return pass?.length >= 12;
};

const validatePasswordNumber = (pass) => {
  const regex = /^(?=.*\d)/gm;
  return regex.test(pass);
};

const validatePasswordBigLetter = (pass) => {
  const regex = /^(?=.*[A-Z])/gm;
  return regex.test(pass);
};

const validatePasswordSmallLetter = (pass) => {
  const regex = /^(?=.*[a-z])/gm;
  return regex.test(pass);
};

export const validatePassword = (pass) => {
  if (!validatePasswordLength(pass)) {
    return {
      success: false,
      message: "Password must contain at least 12 characters",
    };
  }
  if (!validatePasswordNumber(pass)) {
    return {
      success: false,
      message: "Password must contain at least one number",
    };
  }
  if (!validatePasswordBigLetter(pass)) {
    return {
      success: false,
      message: "Password must contain at least one capital letter",
    };
  }
  if (!validatePasswordSmallLetter(pass)) {
    return {
      success: false,
      message: "Password must contain at least one small letter",
    };
  }
  return { success: true, message: "Password is valid" };
};
