import React from "react";
import useUserRole from "../../../../hooks/useUserRole";

const SelectMarket = ({
  selectedMarkets = [],
  setSelectedMarkets = () => {},
}) => {
  const { isAdmin, isMetrohouse, canSeeSecondaryMarket } = useUserRole();

  const handleClick = (name) => {
    setSelectedMarkets((prev) =>
      prev.map((market) =>
        market.name === name ? { ...market, checked: !market?.checked } : market
      )
    );
  };

  const canRenderElement = () => {
    if (isAdmin()) return true;
    if (isMetrohouse()) return false;
    if (canSeeSecondaryMarket()) return true;

    return false;
  };

  if (!canRenderElement()) return null;

  return (
    <div className=" absolute top-5 right-3 flex flex-col gap-3 bg-white p-4 ring-2 ring-primse-green rounded w-auto z-10">
      {selectedMarkets?.map(({ name, checked }) => (
        <div
          key={name}
          onClick={() => handleClick(name)}
          className="flex cursor-pointer items-center"
        >
          <div className="p-0 w-3 h-3 ring-1 ring-primse-green flex justify-center items-center text-primse-green rounded-sm">
            {checked && <div className="w-3/4 h-3/4 bg-primse-green "></div>}
          </div>
          <p className="px-4 py-0 text-sm">{name}</p>
        </div>
      ))}
    </div>
  );
};

export default SelectMarket;
