import React, { useEffect, useState } from "react";
import { Form, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import RequestsServices from "../../../services/RequestsServices";
import { toast } from "react-toastify";

function SingleInvestmentForm({
  investment_uuid,
  developerName,
  investmentName,
  setFilesArr,
  requiredError,
  handleError,
  setRequiredFiles,
}) {
  const [file, setFile] = useState({});
  const [showRequired, setShowRequired] = useState(false);
  const [showAdditional, setShowAdditional] = useState(false);
  const { t } = useTranslation();

  const { data } = useQuery(
    [`request-form`, investment_uuid],
    RequestsServices.getRequestForm(investment_uuid)
  );

  const checkIfThereAreAnyRequired = () => {
    if (data?.form?.elements)
      Object.entries(data.form.elements)?.forEach((form) => {
        if (form[1].required === true) {
          setShowRequired(true);
          setRequiredFiles((prev) => [
            ...prev,
            { uuid: investment_uuid, formName: form[0] },
          ]);
        }
      });
    else return null;
  };

  const checkIfThereAreAnyAdditional = () => {
    if (data?.form?.elements)
      Object.entries(data.form.elements)?.forEach((form) => {
        if (form[1].required === false) setShowAdditional(true);
      });
    else return null;
  };

  useEffect(() => {
    checkIfThereAreAnyRequired();
    checkIfThereAreAnyAdditional();
  }, [data]);

  useEffect(() => {
    setFilesArr((prevState) => ({
      ...prevState,
      [investment_uuid]: file,
    }));
  }, [file]);

  const getDeadlineDate = (name, data, fieldInfo) => {
    if (!name || !data || !fieldInfo) return "";
    const parseName = fieldInfo?.required ? "required" : "additional";
    const sliceName = name?.slice(10);
    const deadline = data?.payload?.term?.[parseName]?.[sliceName]?.deadline;

    if (deadline) return deadline;
    return "";
  };

  return (
    <Form>
      <Table
        celled
        structured
        textAlign="center"
        style={{ marginBottom: "2rem" }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">{t("Developer")}</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">{t("Investment")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan="2">{developerName}</Table.Cell>
            <Table.Cell colSpan="2">{investmentName}</Table.Cell>
          </Table.Row>
        </Table.Body>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              className="border-t border-primse-green"
              colSpan="4"
            >
              {t("Comments")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Row>
          <Table.Cell colSpan="4">{data?.payload?.comment}</Table.Cell>
        </Table.Row>
        {showRequired && (
          <Table.Header style={{ borderTop: "1px solid #7eba27" }}>
            <Table.Row>
              <Table.HeaderCell colSpan="4">
                {t("Required attachments")}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell colSpan="2">
                {t("Description")}
              </Table.HeaderCell>
              <Table.HeaderCell className="w-44">
                {t("Status")}
              </Table.HeaderCell>
              <Table.HeaderCell className="w-44">{t("File")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        )}
        {showRequired && (
          <Table.Body>
            {Object.entries(data?.form?.elements).map(
              ([filedName, fieldInfo]) =>
                fieldInfo.required === true && (
                  <Table.Row
                    key={fieldInfo.label}
                    error={
                      requiredError && !Object.keys(file).includes(filedName)
                    }
                  >
                    <Table.Cell colSpan="2">
                      <span className="font-bold lg:hidden">
                        {t("Description")}
                      </span>
                      {fieldInfo.label}
                    </Table.Cell>
                    <Table.Cell className="w-44">
                      <span>
                        {file[filedName]?.name
                          ? file[filedName].name
                          : `${t("No file")}`}
                      </span>
                    </Table.Cell>
                    <Table.Cell className="w-44">
                      <Form.Field required>
                        <label className="w-40 uppercase font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray">
                          {t("Choose")}
                          <input
                            type="file"
                            onChange={(event) => {
                              setFile((prevState) => ({
                                ...prevState,
                                [filedName]: event.target.files[0],
                              }));
                              handleError();
                              toast.success(
                                `${t("The file has been added successfully")}`
                              );
                            }}
                            hidden
                          />
                        </label>
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                )
            )}
          </Table.Body>
        )}
        {showAdditional && (
          <Table.Header className="border-primse-green">
            <Table.Row style={{ borderTop: "1px solid #7eba27" }}>
              <Table.HeaderCell colSpan="4">
                {t("Additional terms")}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell>{t("Description")}</Table.HeaderCell>
              <Table.HeaderCell>{t("Deadline")}</Table.HeaderCell>
              <Table.HeaderCell className="w-44">
                {t("Status")}
              </Table.HeaderCell>
              <Table.HeaderCell className="w-44">{t("File")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
        )}
        {showAdditional && (
          <Table.Body>
            {Object.entries(data?.form?.elements).map(
              ([filedName, fieldInfo]) =>
                fieldInfo.required === false && (
                  <Table.Row key={fieldInfo.label}>
                    <Table.Cell>{fieldInfo.label}</Table.Cell>
                    <Table.Cell>
                      {getDeadlineDate(filedName, data, fieldInfo)}
                    </Table.Cell>
                    <Table.Cell className="w-44">
                      <span>
                        {file[filedName]?.name
                          ? file[filedName].name
                          : `${t("No file")}`}
                      </span>
                    </Table.Cell>
                    <Table.Cell className="w-44">
                      <Form.Field>
                        <label className="w-40 uppercase font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray">
                          {t("Choose")}

                          <input
                            type="file"
                            onChange={(event) => {
                              setFile((prevState) => ({
                                ...prevState,
                                [filedName]: event.target.files[0],
                              }));
                              toast.success(
                                `${t("The file has been added successfully")}`
                              );
                            }}
                            hidden
                          />
                        </label>
                      </Form.Field>
                    </Table.Cell>
                  </Table.Row>
                )
            )}
          </Table.Body>
        )}
      </Table>
    </Form>
  );
}

export default SingleInvestmentForm;
