import React, { useEffect } from "react";
import { useQuery } from "react-query";
import MyCardViewComponent from "./MyCardViewComponent";
import BasketsService from "../../services/BasketsService";
import { actions } from "../../store";
import { useStoreState } from "easy-peasy";
import * as Sentry from "@sentry/react";

const MyCart = () => {
  const currentBasket = useStoreState((state) => state.baskets.currentBasket);
  const agencyType = useStoreState((state) => state.auth.user?.agencyType);
  const { data, isLoading } = useQuery("baskets", BasketsService.getBaskets);

  useEffect(() => {
    if (data && data[0]?.uuid && !currentBasket.uuid) {
      actions.baskets.setCurrentBasket(data[0].uuid);
    }
  }, [data, currentBasket]);

  useEffect(() => {
    if (data && isLoading && agencyType === "Metrohouse office") {
      Sentry.setExtra("currentBasket", currentBasket);
      Sentry.setExtra("All Basket from query", data);
      Sentry.captureMessage("INFO: MyCart - Current Basket MH");
    }
  }, [isLoading]);

  return <>{!isLoading && <MyCardViewComponent />}</>;
};

export default MyCart;
