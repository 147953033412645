import React, { useEffect, useState } from "react";
import { Message, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import RequestsServices from "../../services/RequestsServices";
import { AiFillCloseCircle } from "react-icons/all";
import { toast } from "react-toastify";
import { useStoreState } from "easy-peasy";
import Loader from "../../components/Loader";
import cs from "classnames";
import InvestmentsList from "./InvestmentsList";

function NewRequestMultiInvestmentModal({
  clientUuid,
  setModalOpen,
  modalOpen,
  investmentsData,
  handleRefresh,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filesArr, setFilesArr] = useState({});
  const [requiredError, setRequiredError] = useState(false);
  const [investmentsListModal, setInvestmentsListModal] = useState([]);
  const [sending, setSending] = useState(false);
  const [requiredFiles, setRequiredFiles] = useState([]);
  const { t } = useTranslation();

  const { briefcaseClient } = useStoreState((state) => state.briefcase);

  useEffect(() => {
    setOpen(modalOpen);
  }, [modalOpen]);

  const handleError = () => {
    console.log("error");
    setRequiredError(false);
  };

  useEffect(() => {
    if (!sending) {
      setInvestmentsListModal(() =>
        // eslint-disable-next-line no-unused-vars
        Object.entries(investmentsData).map(([a, investmentInfo]) => {
          return {
            investmentUuid: investmentInfo?.investment_uuid,
            investmentInfo,
          };
        })
      );
    }
  }, [investmentsData]);

  const sendFiles = (e) => {
    e.preventDefault();

    let count = 0;

    requiredFiles?.forEach((file) => {
      if (filesArr[file?.uuid] && !filesArr?.[file?.uuid]?.[file.formName]) {
        count += 1;
      }
    });

    if (count > 0)
      return toast.error(
        `${t("Request could not be sent")} ${t(
          `Fill in the required conditions to submit the request`
        )}`
      );

    const newInvestmentsList = [];

    Promise.all(
      Object.entries(filesArr).map(([uuid, fileArr]) => {
        setLoading(true);
        // setSending(true);
        const formData = new FormData();
        formData.append("client", clientUuid);
        Object.entries(fileArr)?.forEach(([termsName, file]) => {
          formData.append(`${termsName}`, file);
        });
        return RequestsServices.sendRequestDocuments(uuid, formData)
          .then((res) => {
            if (res?.response?.status === 500) {
              toast.error(
                `${t("Request could not be sent")} ${t(
                  `${res?.response?.data?.error_message}`
                )}`
              );
            } else {
              toast.success(`${t("Request sent successfully")}`);
            }
          })
          .catch((err) => {
            newInvestmentsList.push(
              investmentsListModal.find((x) => {
                if (x.investmentUuid === uuid) return x;
              })
            );

            toast.error(
              `${t("Request could not be sent")} ${t(
                `${err.response.data.error_message}`
              )}`
            );
            setRequiredError(true);
            throw err;
          });
      })
    )
      .then(() => {
        handleRefresh(true);
        setModalOpen(false);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        // setInvestmentsListModal(newInvestmentsList);
        setLoading(false);
        setSending(false);
      });
  };

  // if (sending) return <Loader />;

  return (
    <Modal open={open}>
      {investmentsListModal && (
        <>
          <Modal.Header
            style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
          >
            <div className="flex justify-between">
              <h3 className="text-xl font-bold">{t("Create a request")}</h3>
              <AiFillCloseCircle
                onClick={() => setModalOpen(false)}
                className="text-primse-gray-font cursor-pointer w-8 h-8"
              />
            </div>
          </Modal.Header>
          <Modal.Content>
            {briefcaseClient && (
              <h4 className="font-medium">
                {t("Client")} :{" "}
                <span className="font-bold">
                  {briefcaseClient.first_name} {briefcaseClient.last_name}
                </span>
              </h4>
            )}
          </Modal.Content>
          <Modal.Content
            style={{ maxHeight: "75vh", overflowY: "auto" }}
            className="overflow-overweight"
          >
            <InvestmentsList
              investmentsListModal={investmentsListModal}
              filesArr={filesArr}
              setFilesArr={setFilesArr}
              requiredError={requiredError}
              handleError={handleError}
              setRequiredFiles={setRequiredFiles}
            />
            <div className="p-4 flex justify-end">
              <button
                className="leading-5 mx-2 w-40 uppercase font-bold text-white bg-primse-yellow py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
                onClick={() => setModalOpen(false)}
              >
                {t("Close")}
              </button>
              <button
                disabled={requiredError}
                onClick={(e) => sendFiles(e)}
                className={cs(
                  "leading-5 mx-2 w-40 uppercase font-bold text-white py-3 px-5 rounded-sm",
                  {
                    "bg-primse-green transition-all hover:bg-primse-gray": !requiredError,
                  },
                  { "bg-primse-gray cursor-default": requiredError }
                )}
              >
                {t("Send")}
              </button>
            </div>
            {requiredError && (
              <Message
                negative={true}
                content={t(
                  "Fill in the required conditions to submit the request"
                )}
              />
            )}
          </Modal.Content>
        </>
      )}
      {loading && <Loader />}
    </Modal>
  );
}

export default NewRequestMultiInvestmentModal;
