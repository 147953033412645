export const checkType = (type) => {
  let flatType = "";
  const typeArr = [
    {
      Label: "apartment",
      Value: "flat",
    },
    {
      Label: "House",
      Value: "house",
    },
    {
      Label: "Apartament",
      Value: "flat",
    },
    {
      Label: "Garage",
      Value: "Garaż",
    },
    {
      Label: "Parking place",
      Value: "Miejsce parkingowe",
    },
    {
      Label: "Storage room",
      Value: "Komórka lokatorska",
    },
    {
      Label: "Share.",
      Value: "Udział",
    },
    {
      Label: "Parking place ground",
      Value: "Miejsce parkingowe naziemne",
    },
    {
      Label: "Parking place underground",
      Value: "Miejsce parkingowe podziemne",
    },
    {
      Label: "Commercial",
      Value: "Commercial",
    },
    {
      Label: "Office Space",
      Value: "Office Space",
    },
    {
      Label: "Plot",
      Value: "plot",
    },
    {
      Label: "Investment apartment",
      Value: "Investment apartment",
    },
  ];

  typeArr?.forEach((el) => {
    if (type === el.Label) {
      flatType = el.Value;
    }
  });
  return flatType;
};
