import React, { useState, useEffect } from "react";
import { Form, Message, Modal, Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import { useStoreState } from "easy-peasy";
import { actions } from "../../store";
import cs from "classnames";
import { toast } from "react-toastify";
import ProblemService from "../../services/ProblemService";
import Loader from "../../components/Loader";

const ProblemModal = () => {
  const { t } = useTranslation();

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState("Problem techniczny");
  const [file, setFile] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { problemModal, problemModalStatus } = useStoreState(
    (state) => state.modals
  );

  useEffect(() => {
    if (problemModalStatus === "investment") {
      setCategory("Zapytaj o inwestycję");
    } else {
      setCategory("Problem techniczny");
    }
  }, [problemModalStatus, problemModal]);

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    setLoading(true);
    formData.append("category", `${category.toLocaleLowerCase()}`);
    formData.append("subject", title);
    formData.append("message", message);
    formData.append("filename", file);
    if (problemModalStatus === "investment") {
      formData.append("url", window.location.href);
    }

    ProblemService.problemSend(formData)
      .then(() => {
        toast.success(`${t("The form has been sent")}`);
        resetData();
        setLoading(false);
      })
      .catch(() => {
        toast.error(`${t("Failed to send the form")}`);
        setError(true);
        setLoading(false);
      });
  };

  const setTitleInput = (e) => {
    setError(false);
    setTitle(e.target.value);
  };

  const resetData = () => {
    setError(false);
    setTitle("");
    setMessage("");
    setCategory("Problem techniczny");
    setFile(null);
    actions.modals.openProblemModal(false);
  };

  return (
    <>
      {problemModal && (
        <Modal open={problemModal} size="small">
          <Modal.Header
            style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
          >
            <div className="flex justify-between">
              <h3 className="text-xl font-bold">{t("Ask Primse")}</h3>
              <AiFillCloseCircle
                onClick={() => resetData()}
                className="text-primse-gray-font cursor-pointer w-8 h-8"
              />
            </div>
          </Modal.Header>
          <Modal.Content>
            <Form>
              <div>
                <div className="flex my-4 ">
                  <p className="w-4/12 m-0">
                    {t("Category")} <span className="text-red-500">*</span>
                  </p>

                  <Dropdown
                    className="border-primse-green h-12 w-full"
                    fluid={false}
                    selection={true}
                    defaultValue={category}
                    options={[
                      {
                        key: `${t("Ask about the investment")}`,
                        text: `${t("Ask about the investment")}`,
                        value: "Zapytaj o inwestycję",
                      },
                      {
                        key: `${t("Technical problem")}`,
                        text: `${t("Technical problem")}`,
                        value: "Problem techniczny",
                      },
                      {
                        key: `${t("Out of date data")}`,
                        text: `${t("Out of date data")}`,
                        value: "Nieaktualne dane",
                      },
                      {
                        key: `${t("Idea")}`,
                        text: `${t("Idea")}`,
                        value: "Pomysł",
                      },
                    ]}
                    onChange={(event, data) => setCategory(data.value)}
                  />
                </div>
                <div className="flex my-4 ">
                  <p className="w-4/12 m-0">
                    {t("Topic")} <span className="text-red-500">*</span>
                  </p>

                  <input value={title} onChange={(e) => setTitleInput(e)} />
                </div>
                <div className="flex my-4">
                  <p className="w-4/12 m-0">{t("Message")}</p>

                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="flex my-4">
                  <p className="w-4/12 m-0 ">{t("File")}</p>

                  <div className="w-full m-0 flex justify-between items-center">
                    <label className="text-center w-40 uppercase font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray cursor-pointer">
                      {t("Choose")}
                      <input
                        type="file"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                          toast.success(
                            `${t("The file has been added successfully")}`
                          );
                        }}
                        hidden
                      />
                    </label>
                    <p className=" m-0 ml-4 text-right">{file && file.name}</p>
                  </div>
                </div>
              </div>
              <div className="  flex justify-end">
                <button
                  className="mx-2 w-40 uppercase font-bold text-white bg-primse-yellow py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
                  onClick={() => resetData()}
                >
                  {t("Close")}
                </button>
                <button
                  disabled={error}
                  onClick={(e) => onSubmit(e)}
                  className={cs(
                    "mx-2 w-40 uppercase font-bold text-white py-3 px-5 rounded-sm",
                    {
                      "bg-primse-green transition-all hover:bg-primse-gray": !error,
                    },
                    { "bg-primse-gray cursor-default": error }
                  )}
                >
                  {t("Send")}
                </button>
              </div>
              {error && (
                <Message
                  negative={true}
                  content={t(
                    "Fill in the required conditions to submit the form"
                  )}
                />
              )}
            </Form>
            {loading && <Loader />}
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

export default ProblemModal;
