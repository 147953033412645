import React from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all"; 

function WarningModal({ removeRealestate, open = false, setOpen = () => {}}) {
 
 
  const { t } = useTranslation();

  const handleRemove = () => {
    removeRealestate();
  };

  return (
    <Modal
      open={open}
      size="mini"
      onClose={() => setOpen(false)}
    >
      <Modal.Header
        style={{
          background: "rgba(249, 250, 251, 1",
          border: "none",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <div className="flex justify-end">
          <AiFillCloseCircle
            onClick={() => setOpen(false)}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <span className="font-bold">
            {t(
              "Are you sure you want to remove the premises from the briefcase"
            )}
            ?
          </span>
          <div className="p-4 pt-6 flex justify-center">
            <button
              className="mx-2 w-40 uppercase font-bold text-white bg-primse-yellow py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
              onClick={() => setOpen(false)}
            >
              {t("Close")}
            </button>
            <button
              className="mx-2 w-40 uppercase font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
              onClick={() => handleRemove()}
            >
              {t("Delete")}
            </button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default WarningModal;
