import { Switch, Route } from "react-router";
import Clients from "../containers/Clients";
// import Home from "../containers/Home";
import Investment from "../containers/Investemnt";
import investments from "../containers/investments";
import News from "../containers/News";
import Offers from "../containers/Offers";
import ReportAgency from "../containers/ReportAgency";
import ReportDeveloper from "../containers/ReportDeveloper";
import Search from "../containers/Search";
import PrivateRoute from "./components/PrivateRoute";
import Client from "../containers/Client";
import NewsDetails from "../containers/NewsDetails";
import Requests from "../containers/Requests";
import Request from "../containers/Request";

import Realestate from "../containers/Realestate";
import Realestates from "../containers/Realestates";
import Basket from "../containers/Basket";
import LogoutComponent from "../containers/Logout";
import Transactions from "../containers/Transactions";
import Transaction from "../containers/Transaction";
import LanguageChange from "../components/LanguageChange";
import Questions from "../containers/Questions";
import LoadingPage from "./LoadingPage";
import ResetPassword from "../containers/ResetPassword";

const Routing = () => {
  return (
    <Switch>
      {/* <Route path="/home" component={Home} /> */}
      <PrivateRoute path="/offers" component={Offers} />
      <PrivateRoute path="/clients" component={Clients} />
      <PrivateRoute path="/client/:uuid" component={Client} />
      <PrivateRoute path="/requests" component={Requests} />
      <PrivateRoute path="/request/:uuid" component={Request} />
      <PrivateRoute path="/investment/:uuid" component={Investment} />
      <PrivateRoute path="/investments" component={investments} />
      <PrivateRoute path="/realestate/:uuid" component={Realestate} />
      <PrivateRoute path="/realestates" component={Realestates} />
      <PrivateRoute path="/news" component={News} />
      <PrivateRoute path="/news-info/:uuid" component={NewsDetails} />
      <PrivateRoute path="/report/agency" component={ReportAgency} />
      <PrivateRoute path="/report/developer" component={ReportDeveloper} />
      <PrivateRoute path="/basket/:uuid" component={Basket} />
      <PrivateRoute path="/logout" component={LogoutComponent} />
      <PrivateRoute path="/transactions" component={Transactions} />
      <PrivateRoute path="/transaction/:uuid" component={Transaction} />
      <PrivateRoute path="/language-change" component={LanguageChange} />
      <PrivateRoute path="/questions" component={Questions} />
      <PrivateRoute path="/loader" component={LoadingPage} />
      <PrivateRoute exact path="/" component={Search} />

      <Route path="/password-reset" component={ResetPassword} />
    </Switch>
  );
};

export default Routing;
