import { useState, useMemo, useRef, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import "./style.css";
import useWindowSize from "./useWindowSize";
import Ring from "./Ring";

const Timeline = ({ dataInvestment }) => {
  const [activeScroll, setActiveScroll] = useState(false);
  const [heightRings, setHeightRings] = useState(0);
  const [widthTimeline, setWidthTimeline] = useState(0);
  const size = useWindowSize();

  const ref = useRef(null);
  const refButtonLeft = useRef(null);
  const refButtonRight = useRef(null);

  const MemoDataInvestment = useMemo(() => {
    const sort = dataInvestment?.sort((a, b) => {
      if (a.date_end && b.date_end) return a.date_end < b.date_end ? -1 : 1;
      return a.planned_date_end < b.planned_date_end ? -1 : 1;
    });
    return sort;
  }, [dataInvestment]);

  useEffect(() => {
    if (!ref?.current) return;
    setWidthTimeline(ref?.current?.clientWidth);
    if (ref?.current?.clientWidth === ref?.current?.scrollWidth) {
      setActiveScroll(false);
    } else setActiveScroll(true);
  }, [size]);

  if (!dataInvestment) return null;

  return (
    <div className="timeline py-2 pt-8 relative">
      {activeScroll && (
        <button
          className="buttonTimeline z-10 text-white bg-primse-green p-2 hover:opacity-80 duration-100 absolute"
          ref={refButtonLeft}
          style={{ bottom: `${heightRings * 2.3}px` }}
          onClick={() =>
            (ref.current.scrollLeft = ref.current.scrollLeft - 430)
          }
        >
          <ChevronLeft />
        </button>
      )}
      <div
        ref={ref}
        className="containerTimeline md:flex md:justify-evenly relative top-1/2 overflow-hidden text-center whitespace-nowrap"
      >
        {MemoDataInvestment.map(
          ({ name, planned_date_end, date_end }, index) => (
            <Ring
              key={index + planned_date_end}
              widthTimeline={widthTimeline - 202}
              name={name}
              planned_date_end={planned_date_end}
              date_end={date_end}
              index={index}
              dataInvestment={dataInvestment}
              setHeightRings={setHeightRings}
              size={size}
            />
          )
        )}
      </div>
      {activeScroll && (
        <button
          className="buttonTimeline z-10 text-white bg-primse-green p-2 hover:opacity-80 duration-100 absolute"
          ref={refButtonRight}
          style={{
            bottom: `${heightRings * 2.3}px`,
            marginRight: "20px",
            right: "0",
          }}
          onClick={() =>
            (ref.current.scrollLeft = ref.current.scrollLeft + 430)
          }
        >
          <ChevronRight />
        </button>
      )}
    </div>
  );
};

export default Timeline;
