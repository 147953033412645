import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import FilterModal from "./FilterModal";
import { useTranslation } from "react-i18next";

const PriceDropdown = ({ control }) => {
  const { t } = useTranslation();
  const refLeftInput = useRef(null);
  const refRightInput = useRef(null);
  return (
    <Controller
      control={control}
      name="price"
      render={({ field: { onChange, value } }) => {
        const regex = /^\d*[.]?\d*$/;

        const convertTextToPrice = (text) => {
          if (!text || text === "") return text;

          const parse = parseFloat(text)
            .toFixed(1)
            .replace(/\d(?=(\d{3})+\.)/g, "$& ")
            .slice(0, -2);

          return parse;
        };

        return (
          <FilterModal
            title={t("Price")}
            onReset={() => {
              onChange(["", ""]);
            }}
          >
            <div className="flex items-center">
              <div
                className="w-full flex items-center pl-3 rounded-sm ring-1 ring-primse-green"
                onClick={() => {
                  refLeftInput?.current?.focus();
                }}
              >
                <input
                  ref={refLeftInput}
                  className="w-full outline-none input-dropdown-showArray bg-transparent"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("from")}
                  onChange={(e) => {
                    const newValue = e?.target?.value.replaceAll(" ", "");

                    if (regex.test(newValue)) {
                      const newNumber = convertTextToPrice(newValue);
                      onChange([newNumber, value[1]]);
                    }
                  }}
                  value={value[0]}
                />
                <div>
                  <div
                    className="p-3 pb-1 hover:bg-gray-300 transition-all cursor-pointer"
                    onClick={() => {
                      const newValue = value[0]?.replaceAll(" ", "") || "0";
                      if (regex.test(newValue)) {
                        const addFn = parseFloat(newValue) + 5000;
                        const newNumber = convertTextToPrice(addFn);
                        onChange([newNumber, value[1]]);
                      }
                    }}
                  >
                    <div className="triangleUp " />
                  </div>
                  <div
                    className="p-3 pt-1 hover:bg-gray-300 transition-all cursor-pointer"
                    onClick={() => {
                      const newValue = value[0]?.replaceAll(" ", "") || "0";
                      if (regex.test(newValue)) {
                        const removeFn = parseFloat(newValue) - 5000;
                        if (removeFn < 1) onChange(["0", value[1]]);
                        else {
                          const newNumber = convertTextToPrice(removeFn);
                          onChange([newNumber, value[1]]);
                        }
                      }
                    }}
                  >
                    <div className="triangleDown " />
                  </div>
                </div>
              </div>
              <span className="mx-4">-</span>
              <div
                className="w-full flex items-center rounded-sm ring-1 ring-primse-green"
                onClick={() => {
                  refRightInput?.current?.focus();
                }}
              >
                <input
                  ref={refRightInput}
                  className="w-full outline-none pl-3 input-dropdown-showArray bg-transparent"
                  type="text"
                  inputMode="numeric"
                  placeholder={t("to")}
                  onChange={(e) => {
                    const newValue = e?.target?.value.replaceAll(" ", "");

                    if (regex.test(newValue)) {
                      const newNumber = convertTextToPrice(newValue);
                      onChange([value[0], newNumber]);
                    }
                  }}
                  value={value[1]}
                />
                <div>
                  <div
                    className="p-3 pb-1 hover:bg-gray-300 transition-all cursor-pointer"
                    onClick={() => {
                      const newValue = value[1]?.replaceAll(" ", "") || "0";

                      if (regex.test(newValue)) {
                        const addFn = parseFloat(newValue) + 5000;
                        const newNumber = convertTextToPrice(addFn);
                        onChange([value[0], newNumber]);
                      }
                    }}
                  >
                    <div className="triangleUp " />
                  </div>
                  <div
                    className="p-3 pt-1 hover:bg-gray-300 transition-all cursor-pointer"
                    onClick={() => {
                      const newValue = value[1]?.replaceAll(" ", "") || "0";
                      if (regex.test(newValue)) {
                        const removeFn = parseFloat(newValue) - 5000;
                        if (removeFn < 1) onChange([value[0], "0"]);
                        else {
                          const newNumber = convertTextToPrice(removeFn);
                          onChange([value[0], newNumber]);
                        }
                      }
                    }}
                  >
                    <div className="triangleDown " />
                  </div>
                </div>
              </div>

              {/* <Input
                placeholder="Do"
                min="0"
                // step="5000"
                type="number"
                fluid
                value={value[1]}
                className="flex-1"
                onChange={(e) => editLastNumber(e)}
                onKeyPress={(e) => {
                  preventNegative(e);
                  preventFloat(e);
                }}
              /> */}
            </div>
          </FilterModal>
        );
      }}
    />
  );
};

export default PriceDropdown;
