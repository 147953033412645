import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Container from "../../components/Container";
import Loader from "../../components/Loader";
import InvestmentsService from "../../services/InvestmentsService";

const Offers = () => {
  const { data, isLoading } = useQuery(
    "investments",
    InvestmentsService.getInvestments
  );


  return (
    <main className="mt-12">
      <div>
        <Container>
          <header className="flex items-end justify-between">
            <div>
              <h2 className="text-3xl font-bold">Offers</h2>
              <ul>
                {data && data.map((i) => (
                  <li key={i.id}>
                    <Link to={`/investment/${i.uuid}`}>{i.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </header>
        </Container>
      </div>
      {isLoading && <Loader />}
    </main>
  );
};

// Offers.propTypes = {};

// Offers.defaultProps = {};

export default Offers;
