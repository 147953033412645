import React from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import { useHistory } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import BriefcaseContent from "../BriefcaseContent";
import BriefcaseModal from "../BriefcaseModal";
import { ArrowRight } from "react-feather";

const BriefcaseView = ({
  selectModal,
  selectDefaultModal,
  setOpenFirstModal,
}) => {
  const { briefcaseClient } = useStoreState((state) => state.briefcase);

  const history = useHistory();

  function handleClientDetails(link) {
    history.push(`/client/${link}`);
  }

  function handleLink() {}

  const { t } = useTranslation();

  return (
    <>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between items-center">
          <div className="flex flex-col md:flex-row items-start md:items-center">
            <div
            // className="hover:text-primse-green cursor-pointer"
            // onClick={() => handleClientDetails(briefcaseClient?.uuid)}
            >
              {t("Briefcase")}: {briefcaseClient?.first_name}{" "}
              {briefcaseClient?.last_name}
            </div>
            <div>
              {briefcaseClient !== null && (
                <button
                  onClick={() => {
                    setOpenFirstModal(false);
                    handleClientDetails(briefcaseClient?.uuid);
                  }}
                  className="leading-5 ml-0 md:ml-4 my-2 md:my-0 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-3 px-10 rounded-sm transition-all hover:bg-primse-gray"
                >
                  <div className="flex items-center">
                    {t("See the customer's briefcase page")}
                    <ArrowRight className="text-white ml-2 " size={20} />
                  </div>
                </button>
              )}
              <button
                onClick={() =>
                  selectModal(
                    <BriefcaseModal selectDefaultModal={selectDefaultModal} />
                  )
                }
                className="leading-5 ml-0 md:ml-4 my-1 md:my-0 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-3 px-10 rounded-sm transition-all hover:bg-primse-gray"
              >
                {briefcaseClient
                  ? `${t("Switch briefcase")}`
                  : `${t("Choose briefcase")}`}
              </button>
            </div>
          </div>
          <AiFillCloseCircle
            onClick={() => setOpenFirstModal(false)}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      <BriefcaseContent
        clientName={`${briefcaseClient?.first_name} ${briefcaseClient?.last_name}`}
        uuid={briefcaseClient?.uuid}
        handleLink={handleLink}
        selectModal={selectModal}
        selectDefaultModal={selectDefaultModal}
      />
    </>
  );
};

export default BriefcaseView;
