import { useRef, useEffect, useMemo } from "react";
import { Check } from "react-feather";

const Ring = ({
  name,
  index,
  planned_date_end,
  date_end,
  setHeightRings,
  dataInvestment,
  widthTimeline,
  size,
}) => {
  const refComponent = useRef(null);
  const refRing = useRef(null);
  const refRingContent = useRef(null);
  const refLineRight = useRef(null);
  const refLineLeft = useRef(null);

  const isEnd = useMemo(() => {
    if (date_end) return true;
    else if (new Date(planned_date_end) < new Date()) return true;
    else return false;
  }, [date_end, planned_date_end]);

  useEffect(() => {
    const dateNow = new Date();
    const mapEl = dataInvestment.filter(
      ({ planned_date_end, date_end }) =>
        new Date(date_end ? date_end : planned_date_end) < dateNow
    );

    if (mapEl?.length === 0 && index === 0) {
      refRing.current.classList.add("bg-primse-green");
    }
  }, [dataInvestment, index, widthTimeline]);

  useEffect(() => {}, [planned_date_end, date_end]);

  useEffect(() => {
    refRingContent.current.style.top = `-${
      refRingContent.current.offsetHeight * 1.2
    }px`;

    refRingContent.current.style.transform = `translateX(-${
      refRingContent.current.offsetWidth / 2 - refRing.current.offsetWidth / 2
    }px)`;

    refLineRight.current.style.bottom = `${
      refRing.current.offsetHeight / 2.5
    }px`;

    refLineRight.current.style.width =
      size.width > 768
        ? `${widthTimeline / dataInvestment?.length / 2}px`
        : `${200}px`;
    refLineLeft.current.style.bottom = `${
      refRing.current.offsetHeight / 2.5
    }px`;

    refLineLeft.current.style.width =
      size.width > 768
        ? `${widthTimeline / dataInvestment?.length / 2}px`
        : `${200}px`;

    refLineRight.current.style.left = `${refRing.current.offsetWidth}px`;

    refLineLeft.current.style.left =
      size.width > 768
        ? `-${widthTimeline / dataInvestment?.length / 2}px`
        : `-${200}px`;

    refComponent.current.style.marginLeft = size.width < 768 ? `200px` : `0px`;
    refComponent.current.style.marginRight = size.width < 768 ? `200px` : `0px`;

    if (isEnd) {
      refRing.current.classList.add("ring-4");
      refRing.current.classList.add("ring-primse-green");
      refRing.current.classList.add("bg-primse-green");
    } else {
      refRing.current.classList.add("ring-4");
      refRing.current.classList.add("ring-primse-light-green");
    }

    refLineRight.current.style.backgroundColor = isEnd ? "#7eba27" : "#f2f8e9";
    refLineLeft.current.style.backgroundColor = isEnd ? "#7eba27" : "#f2f8e9";

    if (index === 0) setHeightRings(refRing.current.offsetHeight);
  }, [
    dataInvestment?.length,
    planned_date_end,
    index,
    setHeightRings,
    widthTimeline,
  ]);

  return (
    <div ref={refComponent} className="inline-block relative md:mx-0">
      <div ref={refLineLeft} className="line absolute"></div>
      <div ref={refRing} className="ring-ring rounded-full relative">
        {isEnd && (
          <Check
            color="white"
            className="text-white absolute bottom-0 right-0 arrowDown"
          />
        )}

        <div ref={refRingContent} className="absolute text-center left-0">
          <p className="whitespace-normal mb-1 font-bold text-base uppercase">
            {" "}
            {name}
          </p>
          <p className="text-base">{planned_date_end}</p>
        </div>
      </div>
      <div ref={refLineRight} className="line absolute"></div>
    </div>
  );
};

export default Ring;
