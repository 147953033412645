import axios from "../utils/axios";

const PresentationService = {
  presentationCreate: async (formData, request_uuid) => {
    return await axios
      .post(`/presentation-create/${request_uuid}`, formData)
      .then((res) => res.data.payload);
  },
  presentationRemove: async (request_uuid) => {
    return await axios
      .post(`/presentation-remove/${request_uuid}`)
      .then((res) => res.data.payload);
  },
};

export default PresentationService;
