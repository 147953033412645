import { action } from "easy-peasy";

const investmentsStoreData = {
  currentInvestmentsList: {},

  setCurrentInvestmentsList: action((state, investmentList) => {
    state.currentInvestmentsList = investmentList.reduce((memo, next) => {
      memo[next.uuid] = next;
      return memo;
    }, {});
  }),
  getInvestmentData: action((state, uuid) => {
    return state.currentInvestmentsList[uuid];
  }),
};

export default investmentsStoreData;
