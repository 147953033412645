import axios from "../utils/axios";

const DictionariesServices = {
  getDictionaries: async () => {
    return await axios
      .get(`/dictionary-index`)
      .then((res) => res?.data?.payload);
  },
};

export default DictionariesServices;
