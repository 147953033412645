import React, { useState } from "react";
import { Input, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import { actions } from "../../../store";
import BasketsService from "../../../services/BasketsService";
import { useStoreState } from "easy-peasy";
import { toast } from "react-toastify";

const EditBasket = ({ selectDefaultModal }) => {
  const { t } = useTranslation();

  const [name, setName] = useState("");

  const handleBasketName = (event, data) => {
    let value = data.value;
    setName(value);
  };

  const { currentBasketUuid } = useStoreState((state) => state.baskets);

  const handleSubmit = (e) => {
    e.preventDefault();
    BasketsService.updateBasket({
      basket_uuid: currentBasketUuid,
      name: name,
    })
      .then((res) => {
        BasketsService.getBaskets().then(() =>
          actions.baskets.updateStoreBasket({ uuid: res.uuid, name })
        );
        toast.success(`${t("The cart has been renamed successfully")}`);
        selectDefaultModal();
      })
      .catch(() => {
        toast.error(
          `${t("Something went wrong, the name could not be changed")}`
        );
      });
  };

  return (
    <>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between items-center">
          <h3 className="font-bold mb-0">{t("Edit cart")}</h3>
          <AiFillCloseCircle
            onClick={() => {
              selectDefaultModal();
            }}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <Input
            placeholder={t("New cart name")}
            className="my-2 w-52"
            label={t("Minimum 3 characters")}
            labelPosition={"left corner"}
            fluid={false}
            type="text"
            size={"large"}
            onChange={handleBasketName}
          />
          <div className="flex mt-6">
            <button
              className="leading-5 mr-4 text-xs lg:text-base uppercase font-bold text-white bg-primse-yellow py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
              type="submit"
            >
              {t("Change cart name")}
            </button>
            <button
              className="leading-5 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
              onClick={(e) => {
                e.preventDefault();
                selectDefaultModal();
              }}
            >
              {t("Cancel")}
            </button>
          </div>
        </form>
      </Modal.Content>
    </>
  );
};

export default EditBasket;
