import axios from "../utils/axios";

const BriefcaseContentService = {
  getBriefcaseContent: (client_uuid) => async () => {
    if (!client_uuid) return;

    return await axios.get(`/briefcase-index/${client_uuid}`).then((res) => {
      return res.data;
    });
  },

  addRealestate: async ({ contact, object_uuid, object_type }) => {
    return await axios.post("/briefcase-add", {
      contact: `${contact}`,
      object_uuid: `${object_uuid}`,
      object_type: `${object_type}`,
    });
  },

  removeRealestate: async ({ item_uuid }) => {
    return await axios.post(`/briefcase-remove/${item_uuid}`, {
      item_uuid: `${item_uuid}`,
    });
  },
};

export default BriefcaseContentService;
