import React, { useEffect, useState } from "react";
import { Form, Modal, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import { toast } from "react-toastify";
import DocumentsServices from "../../../../services/DocumentsServices";

function HistoryTermsModal({ setModalOpen, modalOpen, items }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(modalOpen);
  }, [modalOpen]);

  const { t } = useTranslation();

  return (
    <Modal open={open}>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between">
          <h3 className="text-xl font-bold">{t("Change history")}</h3>
          <AiFillCloseCircle
            onClick={() => setModalOpen(false)}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Table celled structured textAlign="center">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  {t("Attachments")}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell colSpan="2">{t("Name")}</Table.HeaderCell>
                <Table.HeaderCell className="w-44">
                  {t("User")}
                </Table.HeaderCell>
                <Table.HeaderCell className="w-44">
                  {t("Action")}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items?.map(
                ({ id, filename, first_name, last_name, document_uuid }) => {
                  return (
                    <Table.Row key={id}>
                      <Table.Cell colSpan="2">{filename}</Table.Cell>
                      <Table.Cell className="w-44">
                        <span>
                          {[first_name, last_name].filter((el) => el).join(" ")}
                        </span>
                      </Table.Cell>
                      <Table.Cell className="w-44">
                        <Form.Field className="flex justify-center">
                          <label
                            onClick={() => {
                              DocumentsServices.getDocument({
                                document_uuid: document_uuid,
                                file_name: filename,
                              })
                                .then(() => {
                                  toast.success(
                                    `${t("Document download started")}`
                                  );
                                })
                                .catch(() =>
                                  toast.error(
                                    `${t(
                                      "The document could not be downloaded"
                                    )}`
                                  )
                                );
                            }}
                            className="w-40 uppercase font-bold bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray cursor-pointer"
                          >
                            {t("Download")}
                          </label>
                        </Form.Field>
                      </Table.Cell>
                    </Table.Row>
                  );
                }
              )}
            </Table.Body>
          </Table>
          <div className="p-4 flex justify-end">
            <button
              className="leading-5 mx-2 w-40 uppercase font-bold text-white bg-primse-yellow py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
              onClick={() => setModalOpen(false)}
            >
              {t("Close2")}
            </button>
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  );
}

export default HistoryTermsModal;
