import { Modal } from "semantic-ui-react";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import Loader from "../../Loader";
import { useHistory } from "react-router-dom";
import BriefcaseContentService from "../../../services/BriefcaseContentService";
import { useTranslation } from "react-i18next";
import BriefcaseModal from "../BriefcaseModal";
import { actions } from "../../../store";
import DetailsInvestments from "../../DetailsInvestments";
import { editPrice } from "../../../utils/numbers";
import { useStoreState } from "easy-peasy";
import cs from "classnames";
import { ArrowRight } from "react-feather";
const BriefcaseContent = ({
  uuid,
  handleLink,
  selectModal,
  selectDefaultModal,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const { mode } = useStoreState((state) => state.mode);
  const userAgencyType = useStoreState((state) => state.auth.user?.agencyType);

  function handleInvestmentDetails(link) {
    history.push(`/investment/${link}`);
    actions.briefcase.setBriefcaseModal(false);
    handleLink(false);
  }

  const { data, isLoading } = useQuery(
    ["briefcase", uuid],
    BriefcaseContentService.getBriefcaseContent(uuid)
  );

  const emptyBriefcaseAgencyUser = useMemo(() => {
    if (!data) return false;

    if (data.payload?.length > 0 && userAgencyType === "Metrohouse office") {
      let count = 0;

      data.payload?.forEach((el) => {
        if (el.offer_kind_id === "2") {
          count++;
        }
      });

      return count !== 0 && count === data.payload?.length;
    }
  }, [data]);

  const emptyBriefcaseSecondaryMarket = useMemo(() => {
    if (
      data &&
      data.payload?.length === 1 &&
      data.payload[0].offer_kind_id === "2" &&
      data.payload[0].realestates?.length === 0
    )
      return true;
    else return false;
  }, [data]);

  const tableMap = (numberKind) => {
    return (
      <div>
        {data?.payload.map(
          ({
            investment_name,
            investment_uuid,
            developer_name,
            realestates,
            offer_kind_id,
            investment_status,
          }) => (
            <>
              {offer_kind_id === numberKind && numberKind === "1" && (
                <div key={investment_uuid}>
                  <div className="border-b p-2">
                    {investment_name !== "Rynek wtórny" ? (
                      <div
                        className={cs(
                          "font-bold ",
                          investment_status === "On sale" &&
                            "hover:text-primse-green cursor-pointer"
                        )}
                        onClick={() => {
                          if (investment_status === "On sale") {
                            handleInvestmentDetails(investment_uuid);
                          }
                        }}
                      >
                        {investment_name}
                      </div>
                    ) : (
                      <div className="font-bold ">{investment_name}</div>
                    )}
                    {mode !== "presentation" ? (
                      <div className="text-sm text-gray-500">
                        {developer_name}
                      </div>
                    ) : null}
                  </div>

                  {realestates.map(
                    ({
                      name,
                      price,
                      id,
                      item_uuid,
                      plan_url,
                      pdf,
                      rooms,
                      area,
                      ask_for_price,
                    }) => (
                      <div
                        key={`briefcaseContentRealestates-${id}`}
                        className="border-b p-2"
                      >
                        <div className="ml-4 text-gray-500">{name}</div>
                        <div className="ml-4 text-gray-500 flex justify-between">
                          <div className="flex">
                            <div>
                              <span className="font-semibold">
                                {t("Price")}:{" "}
                              </span>
                              <span>
                                {price && ask_for_price !== "1" ? (
                                  `${
                                    price
                                      ? `${editPrice(price)} PLN`
                                      : t("No data")
                                  }`
                                ) : (
                                  <span
                                    className="cursor-pointer"
                                    onClick={() => {
                                      history.push(
                                        `/questions?r_uuid=${id}&i_uuid=${investment_uuid}`
                                      );

                                      actions.briefcase.setBriefcaseModal(
                                        false
                                      );
                                    }}
                                  >
                                    {t("Ask about price")}
                                  </span>
                                )}
                              </span>
                            </div>
                            <div className="ml-4">
                              <span className="font-semibold">
                                {" "}
                                {t("Rooms")}:{" "}
                              </span>
                              <span>{rooms && rooms}</span>
                            </div>
                            <div className="ml-4">
                              <span className="font-semibold">
                                {" "}
                                {t("Area")}:{" "}
                              </span>
                              <span>
                                {area && area} m<sup>2</sup>
                              </span>
                            </div>
                          </div>
                          <div>
                            <DetailsInvestments
                              PDFLink={pdf}
                              PDFName={name}
                              planLink={plan_url}
                              uuid={item_uuid}
                              pathname={history.location.pathname}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              )}
              {offer_kind_id === numberKind &&
                numberKind === "2" &&
                realestates?.length !== 0 && (
                  <div key={investment_uuid}>
                    <div className="border-b p-2">
                      {investment_name !== "Rynek wtórny" ? (
                        <div
                          className={cs(
                            "font-bold ",
                            investment_status === "On sale" &&
                              "hover:text-primse-green cursor-pointer"
                          )}
                          onClick={() => {
                            if (investment_status === "On sale") {
                              handleInvestmentDetails(investment_uuid);
                            }
                          }}
                        >
                          {investment_name}
                        </div>
                      ) : (
                        <div className="font-bold ">{investment_name}</div>
                      )}

                      <div className="text-sm text-gray-500">
                        {developer_name}
                      </div>
                    </div>

                    {realestates.map(
                      ({
                        name,
                        price,
                        id,
                        item_uuid,
                        plan_url,
                        pdf,
                        rooms,
                        area,
                      }) => (
                        <div
                          key={`briefcaseContentRealestates-${id}`}
                          className="border-b p-2"
                        >
                          <div className="ml-4 text-gray-500">{name}</div>
                          <div className="ml-4 text-gray-500 flex justify-between">
                            <div className="flex">
                              <div>
                                <span className="font-semibold">
                                  {t("Price")}:{" "}
                                </span>
                                <span>
                                  {price ? `${editPrice(price)} PLN` : null}{" "}
                                </span>
                              </div>
                              <div className="ml-4">
                                <span className="font-semibold">
                                  {" "}
                                  {t("Rooms")}:{" "}
                                </span>
                                <span>{rooms && rooms}</span>
                              </div>
                              <div className="ml-4">
                                <span className="font-semibold">
                                  {" "}
                                  {t("Area")}:{" "}
                                </span>
                                <span>
                                  {area && area} m<sup>2</sup>
                                </span>
                              </div>
                            </div>
                            <div>
                              <DetailsInvestments
                                PDFLink={pdf}
                                PDFName={name}
                                planLink={plan_url}
                                uuid={item_uuid}
                                pathname={history.location.pathname}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
            </>
          )
        )}
      </div>
    );
  };

  return (
    <Modal.Content
      style={{ paddingTop: "0", height: "300px", overflow: "auto" }}
    >
      <>
        {!data && (
          <div className="uppercase font-medium text-center h-full flex justify-center align-center items-center">
            <div className="flex flex-col items-center">
              {t("No briefcase has been selected...")}
              <button
                onClick={() =>
                  selectModal(
                    <BriefcaseModal selectDefaultModal={selectDefaultModal} />
                  )
                }
                className="max-w-max ml-4 mt-4 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-3 px-10 rounded-sm transition-all hover:bg-primse-gray"
              >
                {t("Choose briefcase")}
              </button>
            </div>
          </div>
        )}

        {data?.payload?.length < 1 ||
        emptyBriefcaseAgencyUser ||
        emptyBriefcaseSecondaryMarket ? (
          <div className="uppercase font-medium text-center h-full flex justify-center align-center items-center">
            <div className="flex flex-col items-center">
              {t("There are no objects in the briefcase...")}
              <button
                className="w-46 m-4 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
                onClick={() => {
                  actions.briefcase.setBriefcaseModal(false);
                  history.push(`/`);
                }}
              >
                <div className="flex">
                  {t("Go to the search engine")}
                  <ArrowRight className="text-white ml-2 " size={20} />
                </div>
              </button>
            </div>
          </div>
        ) : (
          <div>
            {data && (
              <>
                {tableMap("1")}
                {userAgencyType !== "Metrohouse office" && tableMap("2")}
              </>
            )}
          </div>
        )}
        {isLoading && <Loader />}
      </>
    </Modal.Content>
  );
};

export default BriefcaseContent;
