import { action } from "easy-peasy";

const versionStore = {
  version: null,
  setVersion: action((state, payload) => {
    state.version = payload;
  }),
};

export default versionStore;
