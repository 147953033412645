import React, { useState } from "react";
import { Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { X } from "react-feather";
import { BiSearchAlt2, FaSquare } from "react-icons/all";
import ClientsItemMobile from "../ClientsItemMobile";
import { useHistory } from "react-router-dom";
import ClientsService from "../../../services/ClientsService";
import cs from "classnames";

const Td = ({ children, className }) => (
  <td className={cs("py-3 px-4 border-b", className)}>{children}</td>
);

const SearchClient = ({ handleClose }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [searchedSurname, setSearchedSurname] = useState("");
  const [searchedMobile, setSearchedMobile] = useState("");
  const [searchedEmail, setSearchedEmail] = useState("");

  const { t } = useTranslation();
  const history = useHistory();

  function handleClientDetails(link) {
    history.push(`/client/${link}`);
    handleClose();
  }

  const handleSearchSurname = (event) => {
    let value = event.target.value;
    setSearchedSurname(value);
  };

  const handleSearchMobile = (event) => {
    let value = event.target.value;
    setSearchedMobile(value);
  };

  const handleSearchEmail = (event) => {
    let value = event.target.value;
    setSearchedEmail(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    ClientsService.getSearchedClient({
      last_name: searchedSurname,
      email: searchedEmail,
      phone: searchedMobile,
      assigned_to: "",
      created_from: "",
      created_to: "",
    })
      .then((res) => {
        setFilteredData(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClearSearch = () => {
    setSearchedSurname("");
    setSearchedMobile("");
    setSearchedEmail("");
    setFilteredData([]);
  };

  return (
    <>
      <div>
        <form
          className="flex flex-col lg:flex-row justify-start items-center"
          onSubmit={handleSubmit}
        >
          <Input
            placeholder={t("Client last name")}
            className="m-2"
            label={t("Minimum 3 characters")}
            value={searchedSurname}
            labelPosition={"left corner"}
            fluid={false}
            type="text"
            size={"large"}
            onChange={handleSearchSurname}
            error={0 < searchedSurname?.length && searchedSurname?.length < 3}
          />
          <Input
            placeholder={t("Phone number")}
            className="m-2"
            value={searchedMobile}
            label={t("Minimum 6 digits")}
            labelPosition={"left corner"}
            fluid={false}
            type="text"
            size={"large"}
            onChange={handleSearchMobile}
            error={0 < searchedMobile?.length && searchedMobile?.length < 6}
          />
          <Input
            placeholder={t("E-mail")}
            className="m-2"
            value={searchedEmail}
            label={t("Minimum 6 characters")}
            labelPosition={"left corner"}
            fluid={false}
            type="text"
            size={"large"}
            onChange={handleSearchEmail}
            error={0 < searchedEmail?.length && searchedEmail?.length < 6}
          />
          <button
            className="leading-5 flex items-center py-3 px-4 text-sm"
            type="button"
            onClick={handleClearSearch}
          >
            <X size={14} className="mr-2" />
            {t("Clear")}
          </button>

          <button
            className="leading-5 font-bold text-white bg-primse-green uppercase py-3 px-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
            type="submit"
            disabled={
              searchedSurname?.length < 3 &&
              searchedMobile?.length < 6 &&
              searchedEmail?.length < 6
            }
          >
            <BiSearchAlt2 />
          </button>
        </form>
      </div>
      <div style={{ paddingTop: "0" }}>
        {filteredData?.length < 1 ? (
          <div
            className="
                    uppercase
                    font-medium
                    text-center
                    "
          >
            {t("Please complete at least...")}
          </div>
        ) : (
          <>
            <div className="p-4 mt-6 border rounded-xl w-full flex flex-col lg:hidden">
              {filteredData?.map((el) => (
                <ClientsItemMobile
                  key={el.uuid}
                  data={{
                    last_name: el.last_name,
                    first_name: el.first_name,
                    source_of_origin_name: el.source_of_origin_name,
                    created: el.created,
                    status_name: el.status_name,
                    uuid: el.uuid,
                  }}
                />
              ))}
            </div>
            <table className="w-full hidden text-left lg:block">
              <thead className="font-bold uppercase text-xs flex w-full">
                <tr className="flex w-full">
                  <th className="py-4 px-4 border-b w-1/12">ID</th>
                  <th className="py-4 px-4 border-b w-2/12">
                    {t("Creation date")}
                  </th>
                  <th className="py-4 px-4 border-b w-2/12">{t("Client")}</th>
                  <th className="py-4 px-4 border-b w-1/12">{t("City")}</th>
                  <th className="py-4 px-4 border-b w-1/12">{t("Phone")}</th>
                  <th className="py-4 px-4 border-b w-3/12">{t("E-mail")}</th>
                  <th className="py-4 px-4 border-b w-2/12"></th>
                </tr>
              </thead>
              <tbody
                className={cs(
                  "text-sm flex flex-col items-center overflow-y-scroll w-full",
                  filteredData?.length < 8 ? "justify-start" : "justify-between"
                )}
                style={{ height: "50vh", display: "block" }}
              >
                {filteredData.map((el) => (
                  <tr
                    onClick={() => handleClientDetails(el.uuid)}
                    key={el.uuid}
                    className="flex w-full hover:bg-primse-light-green"
                  >
                    <Td className="w-1/12">{el.id}</Td>
                    <Td className="w-2/12">{el.created}</Td>
                    <Td className="w-2/12">
                      {`${el.last_name} ${el.first_name}`}
                      {el.status === "1" && (
                        <div className="flex items-center">
                          <FaSquare className="text-primse-green pr-2" />
                          {t("StatusNew")}
                        </div>
                      )}
                      {el.status === "2" && (
                        <div className="flex items-center">
                          <FaSquare className="text-primse-green pr-2" />
                          {t("Interested")}
                        </div>
                      )}
                      {el.status === "3" && (
                        <div className="flex items-center">
                          <FaSquare className="text-primse-green pr-2" />
                          {t(`${el.status_name}`)}
                        </div>
                      )}
                      {el.status === "4" && (
                        <div className="flex items-center">
                          <FaSquare className="text-primse-green pr-2" />
                          {t(`${el.status_name}`)}
                        </div>
                      )}
                      {el.status === "6" && (
                        <div className="flex items-center">
                          <FaSquare className="text-primse-yellow pr-2" />
                          {t("StatusAgreement")}
                        </div>
                      )}
                      {el.status === "5" && (
                        <div className="flex items-center">
                          <FaSquare className="text-primse-yellow pr-2" />
                          {t("StatusInterested")}
                        </div>
                      )}
                    </Td>
                    <Td className="w-1/12">Warszawa</Td>
                    <Td className="w-1/12">{el.work_phone}</Td>
                    <Td className="w-3/12">{el.work_email}</Td>
                    <Td className="w-2/12">
                      <div className="text-primse-green flex justify-center">
                        <button className="leading-5 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray">
                          {t("Choose")}
                        </button>
                      </div>
                    </Td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
};

export default SearchClient;
