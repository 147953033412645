export const DEFAULTVALUES = {
  text: [],
  type: ["apartment", "house", "office space", "plot", "investment_apartment"],
  market: [],
  location: [],
  area: ["", ""],
  rooms: [],
  price: ["", ""],
  basement: [],
  floors: [],
  delivery: [""],
  closed: [],
  balcony: [],
  terrace: [],
  green_terrace: [],
  has_parking_space: [],
  order: [""],
};

export function parseURLtoFormValues(URL) {
  const params = new URLSearchParams(URL);
  const _page = params.get("page")?.split(",");
  const _text = params.get("text")?.split(",");
  const _type = params.get("type")?.split(",");
  const _market = params.get("market")?.split(",");
  const _location = params.get("location")?.split(",");
  const _area = params.get("area")?.split(",");
  const _rooms = params.get("rooms")?.split(",");
  const _price = params.get("price")?.replaceAll("%20", " ")?.split(",");
  const _floors = params.get("floors")?.split(",");
  const _delivery = params.get("delivery")?.split(",");
  const _basement = params.get("basement")?.split(",");
  const _closed = params.get("closed")?.split(",");
  const _order = params.get("order")?.split(",");
  const _balcony = params.get("balcony")?.split(",");
  const _terrace = params.get("terrace")?.split(",");
  const _green_terrace = params.get("green_terrace")?.split(",");
  const _has_parking_space = params.get("has_parking_space")?.split(",");
  return {
    page: _page ? _page : null,
    text: _text ? _text : DEFAULTVALUES.text,
    type: _type ? _type : DEFAULTVALUES.type,
    market: _market ? _market : DEFAULTVALUES.market,
    location: _location ? _location : DEFAULTVALUES.location,
    area: _area ? _area : DEFAULTVALUES.area,
    rooms: _rooms ? _rooms.map((room) => Number(room)) : DEFAULTVALUES.rooms,
    price: _price ? _price : DEFAULTVALUES.price,
    basement: _basement ? _basement : DEFAULTVALUES.basement,
    floors: _floors
      ? _floors.map((floor) => Number(floor))
      : DEFAULTVALUES.floors,
    delivery: _delivery ? _delivery : DEFAULTVALUES.delivery,
    balcony: _balcony ? _balcony : DEFAULTVALUES.balcony,
    terrace: _terrace ? _terrace : DEFAULTVALUES.terrace,
    green_terrace: _green_terrace
      ? _green_terrace
      : DEFAULTVALUES.green_terrace,
    has_parking_space: _has_parking_space
      ? _has_parking_space
      : DEFAULTVALUES.has_parking_space,
    closed: _closed ? _closed : DEFAULTVALUES.closed,
    order: _order ? _order : DEFAULTVALUES.order,
  };
}

export const locationFilter = (formValues) => (investment) => {
  if (Object.values(formValues.location)?.length === 0) return true;
  return Object.values(formValues.location).find(
    (l) =>
      l === investment.city ||
      l === investment.address_district ||
      l === investment.voivodeship ||
      l === investment.sm_location_city ||
      l === investment.sm_location_district ||
      l === investment.sm_location_voivodeship
  );
};
export const nameFilter = (formValues) => (investment) => {
  if (!formValues.text[0]) {
    return true;
  }
  const formText = formValues.text[0].toLowerCase();
  const investmentName = investment?.name?.toLowerCase();
  const developerName = investment?.developer_name?.toLowerCase();

  if (investmentName?.includes(formText) || developerName?.includes(formText)) {
    return true;
  }
};

export const typeFilter = (formValues) => (investment) => {
  if (Object.values(formValues.type)?.length === 0) return true;

  if (
    formValues.type.find((t) => t === "apartment") &&
    investment.have_apartment === true
  ) {
    return true;
  }

  if (
    formValues.type.find((t) => t === "house") &&
    investment.have_house === true
  ) {
    return true;
  }

  if (
    formValues.type.find((t) => t === "service premises") &&
    investment.have_office === true
  ) {
    return true;
  }
  if (
    formValues.type.find((t) => t === "office space") &&
    investment.have_office === true
  ) {
    return true;
  }
};

export const basementFilter = (formValues) => (investment) => {
  if (formValues.basement?.length === 0) return true;
  if (formValues.basement[0] === "true" && investment.has_basement === "1") {
    return true;
  }
  if (
    formValues.basement[0] === "true" &&
    investment.has_storage_room === "1"
  ) {
    return true;
  }
};

// export const storageFilter = (formValues) => (investment) => {
//   if (formValues.storage?.length === 0) return true;
//
//   if (
//       formValues.storage[0] === "true" &&
//       investment.storage === true
//   ) {
//     return true;
//   }
// };

export const closedFilter = (formValues) => (investment) => {
  if (formValues.closed?.length === 0) return true;

  if (formValues.closed[0] === "true" && investment.closed === true) {
    return true;
  }
};

export const deliveryFilter = (formValues) => (investment) => {
  if (formValues.delivery[0] === "") return true;

  if (investment.completion_quarter === formValues.delivery[0]) {
    return true;
  }
};

export const areaFilter = (formValues) => (investment) => {
  if (formValues.area[0] === "" && formValues.area[1] === "") return true;

  const filterFrom = Number(formValues.area[0]);
  const filterTo = Number(formValues.area[1]);

  const flatsFrom = Number(investment.area_from);
  const flatsTo = Number(investment.area_to);

  if (filterFrom === 0) {
    return filterTo >= flatsFrom;
  }
  if (filterTo === 0) {
    return filterFrom <= flatsTo;
  }

  return filterFrom <= flatsTo && filterTo >= flatsFrom;
};

export const priceFilter = (formValues) => (investment) => {
  if (formValues.price[0] === "" && formValues.price[1] === "") return true;

  let filterFrom = "";
  let filterTo = "";

  if (typeof formValues.price[0] === "string") {
    filterFrom = Number(formValues.price[0].replaceAll(" ", ""));
    filterTo = Number(formValues.price[1].replaceAll(" ", ""));
  } else {
    filterFrom = Number(formValues.price[0]);
    filterTo = Number(formValues.price[1]);
  }

  const flatsFrom = Number(investment.price_from);
  const flatsTo = Number(investment.price_to);

  if (filterFrom === 0) {
    return filterTo >= flatsFrom;
  }
  if (filterTo === 0) {
    return filterFrom <= flatsTo;
  }
  return filterFrom <= flatsTo && filterTo >= flatsFrom;
};
export const roomsFilter = (formValues) => (investment) => {
  if (formValues.rooms?.length === 0) return true;

  const flatsFrom = Number(investment.rooms_from);
  const flatsTo = Number(investment.rooms_to);

  const filterFrom = Number(formValues.rooms[0]);

  if (formValues.rooms?.length === 1)
    return flatsFrom <= filterFrom && flatsTo >= filterFrom;

  const filterTo = Number(formValues.rooms[1]);

  if (filterFrom === 0) {
    return filterTo >= flatsFrom;
  }
  if (filterTo === 0) {
    return filterFrom <= flatsTo;
  }
  return filterFrom <= flatsTo && filterTo >= flatsFrom;
};

export const floorsFilter = (formValues) => (investment) => {
  if (formValues.floors?.length === 0) return true;

  const flatsFrom = Number(investment.floor_from);
  const flatsTo = Number(investment.floor_to);

  const filterFrom = Number(formValues.floors[0]);

  if (formValues.floors?.length === 1)
    return flatsFrom <= filterFrom && flatsTo >= filterFrom;

  const filterTo = Number(formValues.floors[1]);

  if (filterFrom === 0) {
    return filterTo >= flatsFrom;
  }
  if (filterTo === 0) {
    return filterFrom <= flatsTo;
  }
  return filterFrom <= flatsTo && filterTo >= flatsFrom;
};

export function formatInvestmentData(investment) {
  return {
    uuid: investment.uuid,
    name: investment.name,
    number_of_offers: investment.number_of_offers,
    price_from: Number(investment.price_from),
    price_to: Number(investment.price_to),
    area_from: investment.area_from,
    area_to: investment.area_to,
    rooms_from: investment.rooms_from,
    rooms_to: investment.rooms_to,
    floor_from: investment.floor_from,
    floor_to: investment.floor_to,
    city: investment?.city,
    address_street: investment?.address_street,
    pictures: investment.pictures,
    basement: investment.basement,
    closed: investment.closed,
    storage: investment.storage,
    completion_quarter: investment.completion_quarter,
    location: {
      lat: investment.lat,
      lng: investment.long,
    },
    developer_name: investment.developer_name,
    completion_date: investment.completion_date,
    promoting_agency_offer: investment?.promoting_agency_offer,
  };
}

export const convertValuesToURL = (values) => {
  if (!values) return "";
  return Object.keys(values)
    .reduce((a, key) => {
      if (values[key] && values[key] === true) {
        return [...a, ...[`${key}=${values[key]}`]];
      }
      if (values[key] && values[key]?.length !== 0) {
        if (values[key][0] === "" && values.length === 1) {
          return a;
        }
        if (values[key]?.length === 2) {
          if (values[key][0] === "" && values[key][1] === "") {
            return a;
          }
        }
        return [...a, ...[`${key}=${values[key].join(",")}`]];
      }
      return a;
    }, [])
    .join("&");
};

export const areaFlatFilter = (formValues) => (flat) => {
  if (formValues.area[0] === "" && formValues.area[1] === "") return true;

  const filterFrom = Number(formValues.area[0]);
  const filterTo = Number(formValues.area[1]);

  const flatArea = Number(flat.area);

  if (filterFrom === 0) {
    return filterTo >= flatArea;
  }
  if (filterTo === 0) {
    return filterFrom <= flatArea;
  }
  return filterFrom <= flatArea && filterTo >= flatArea;
};

export const priceFlatFilter = (formValues) => (flat) => {
  if (formValues.price[0] === "" && formValues.price[1] === "") return true;

  const filterFrom = Number(formValues.price[0]);
  const filterTo = Number(formValues.price[1]);

  const flatPrice = Number(flat.price);

  if (filterFrom === 0) {
    return filterTo >= flatPrice;
  }
  if (filterTo === 0) {
    return filterFrom <= flatPrice;
  }
  return filterFrom <= flatPrice && filterTo >= flatPrice;
};
export const roomsFlatFilter = (formValues) => (flat) => {
  if (formValues.rooms?.length === 0) return true;

  const flatRooms = Number(flat.rooms);
  const filterFrom = Number(formValues.rooms[0]);
  const filterTo = Number(formValues.rooms[1]);

  if (formValues.rooms?.length === 1)
    return flatRooms <= filterFrom && flatRooms >= filterFrom;

  if (filterFrom === 0) {
    return filterTo >= flatRooms;
  }
  if (filterTo === 0) {
    return filterFrom <= flatRooms;
  }
  return filterFrom <= flatRooms && filterTo >= flatRooms;
};

export const floorsFlatFilter = (formValues) => (flat) => {
  if (formValues.floors?.length === 0) return true;

  const flatRooms = Number(flat.floor);
  const filterFrom = Number(formValues.floors[0]);
  const filterTo = Number(formValues.floors[1]);

  if (formValues.floors?.length === 1)
    return flatRooms <= filterFrom && flatRooms >= filterFrom;

  if (filterFrom === 0) {
    return filterTo >= flatRooms;
  }
  if (filterTo === 0) {
    return filterFrom <= flatRooms;
  }
  return filterFrom <= flatRooms && filterTo >= flatRooms;
};

export const typeFlatFilter = (formValues) => (investment) => {
  if (Object.values(formValues.type)?.length === 0) return true;
  return Object.values(formValues.type).find(
    (t) => t === investment.type.toLowerCase()
  );
};

export const priceFilterRealestate = (formValues) => (investment) => {
  if (formValues.price[0] === "" && formValues.price[1] === "") return true;

  let filterFrom = "";
  let filterTo = "";

  if (typeof formValues.price[0] === "string") {
    filterFrom = Number(formValues.price[0].replaceAll(" ", ""));
    filterTo = Number(formValues.price[1].replaceAll(" ", ""));
  } else {
    filterFrom = Number(formValues.price[0]);
    filterTo = Number(formValues.price[1]);
  }

  const flatsPrice = Number(investment.price);

  if (filterFrom === 0) {
    return filterTo >= flatsPrice;
  }
  if (filterTo === 0) {
    return filterFrom <= flatsPrice;
  }
  return filterFrom <= flatsPrice && filterTo >= flatsPrice;
};

export const roomsFilterRealestate = (formValues) => (investment) => {
  if (formValues.rooms?.length === 0) return true;

  const flatsRooms = Number(investment.rooms);

  const filterFrom = Number(formValues.rooms[0]);

  if (formValues.rooms?.length === 1)
    return flatsRooms <= filterFrom && flatsRooms >= filterFrom;

  const filterTo = Number(formValues.rooms[1]);

  if (filterFrom === 0) {
    return filterTo >= flatsRooms;
  }
  if (filterTo === 0) {
    return filterFrom <= flatsRooms;
  }
  return filterFrom <= flatsRooms && filterTo >= flatsRooms;
};

export const areaFilterRealestate = (formValues) => (investment) => {
  if (formValues.area[0] === "" && formValues.area[1] === "") return true;

  const filterFrom = Number(formValues.area[0]);
  const filterTo = Number(formValues.area[1]);

  const flatsArea = Number(investment.area);

  if (filterFrom === 0) {
    return filterTo >= flatsArea;
  }
  if (filterTo === 0) {
    return filterFrom <= flatsArea;
  }
  return filterFrom <= flatsArea && filterTo >= flatsArea;
};

export const typeFilterRealestate = (formValues) => (investment) => {
  if (Object.values(formValues.type)?.length === 0) return true;

  if (formValues.type.indexOf("apartment") !== -1) {
    if (investment.type === "apartment" || investment.type === "Apartament") {
      return true;
    }
  }
  if (formValues.type.indexOf("house") !== -1) {
    if (investment.type === "House") {
      return true;
    }
  }
  if (formValues.type.indexOf("service premises") !== -1) {
    if (
      investment.type === "Commercial" ||
      investment.type === "Office Space"
    ) {
      return true;
    }
  }
};

export const floorsFilterRealestate = (formValues) => (realestate) => {
  if (formValues.floors?.length === 0) return true;

  const floor = Number(realestate.floor);

  const filterFrom = Number(formValues.floors[0]);

  if (formValues.floors?.length === 1)
    return floor <= filterFrom && floor >= filterFrom;

  const filterTo = Number(formValues.floors[1]);

  if (filterFrom === 0) {
    return filterTo >= floor;
  }
  if (filterTo === 0) {
    return filterFrom <= floor;
  }
  return filterFrom <= floor && filterTo >= floor;
};

export const truncateString = (string, maxChars) => {
  return string?.length <= maxChars
    ? string
    : string.substring(0, maxChars) + "...";
};

const filterType = (formValues) => {
  const arr = [];
  formValues?.type?.forEach((el) => {
    if (el === "office space") {
      arr.push("office");
    } else {
      arr.push(el);
    }
  });

  return arr;
};
const selectValues = (data) => {
  if (data?.length === 0) return null;
  if (data) return true;
  if (!data) return null;
};
const selectValuesNullOrOne = (data) => {
  if (data?.length === 0) return null;
  if (data) return "1";
  if (!data) return null;
};

export const getParamsToApi = (filters) => {
  const params = {};
  if (!filters) return params;
  params.realestate_category = filterType(filters);

  if (filters?.price?.length > 0) {
    params.price_from = filters?.price[0]?.replaceAll(" ", "");
  }
  if (filters?.price?.length > 1) {
    params.price_to = filters?.price[1]?.replaceAll(" ", "");
  }

  if (filters?.area?.length > 0) {
    params.area_from = filters?.area[0];
  }
  if (filters?.area?.length > 1) {
    params.area_to = filters?.area[1];
  }
  if (filters?.rooms?.length > 0) {
    params.rooms_from = filters?.rooms[0];
    params.rooms_to = filters?.rooms[0];
  }
  if (filters?.rooms?.length > 1) {
    params.rooms_to = filters?.rooms[1] === 5 ? 99 : 0;
  }
  if (filters?.floors?.length > 0) {
    params.floor_from = filters?.floors[0];
    params.floor_to = filters?.floors[0];
  }
  if (filters?.floors?.length > 1) {
    params.floor_to = filters?.floors[1] === 9 ? 99 : filters?.floors[1];
  }

  if (filters?.location) {
    params.location = filters?.location;
  }

  if (filters?.delivery) {
    params.completion_quarter = filters?.delivery[0];
  }

  if (filters?.text) {
    params.name = filters?.text[0];
  }
  if (filters?.page?.length === 1) {
    params.page = filters?.page[0];
  }
  params.closed = selectValues(filters?.closed);
  params.basement = selectValues(filters?.basement);
  params.balcony = selectValuesNullOrOne(filters?.balcony);
  params.terrace = selectValuesNullOrOne(filters?.terrace);
  params.green_terrace = selectValuesNullOrOne(filters?.green_terrace);
  params.has_parking_space = selectValuesNullOrOne(filters?.has_parking_space);

  const cleanParams = {};

  for (const [key, value] of Object.entries(params)) {
    if (value && value !== "") cleanParams[key] = value;
    if ((key === "floor_from" || key === "floor_to") && value === 0)
      cleanParams[key] = value;
  }
  return cleanParams;
};
