import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Input, Message, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import UserServices from "../../../services/UserServices";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../components/Button";
import { useStoreState } from "easy-peasy";
import { actions } from "../../../store";

function EditProfile() {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [profileData, setProfileData] = useState(null);
  const { editUserModal } = useStoreState((state) => state.modals);

  useEffect(() => {
    setLoading(true);
    UserServices.getUserProfile()
      .then((res) => setProfileData(res))
      .finally(() => setLoading(false));
  }, [editUserModal]);

  const { city, information_category } = useStoreState(
    (state) => state.dictionaries.dictionariesList
  );

  const dropdownOptions = (arr) => {
    if (arr) {
      let optionsArr = Object.entries(arr).map(([id, name]) => {
        return { text: name, value: id };
      });
      return optionsArr.sort((optionOne, optionTwo) =>
        optionOne.text > optionTwo.text ? 1 : -1
      );
    } else return [];
  };

  const onSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();
    Object.entries(data)?.forEach(([key, value]) => {
      switch (key) {
        case "notification_query_accepted":
          formData.append(`${key}`, `${value === true ? "1" : "0"}`);
          break;
        case "notification_query_rejected":
          formData.append(`${key}`, `${value === true ? "1" : "0"}`);
          break;
        case "default_cities":
          data.default_cities?.forEach((id) => {
            dropdownOptions(city)?.forEach((city) => {
              if (city.value === id) {
                formData.append(`${key}[]`, city.value);
              }
            });
          });
          break;
        case "default_information_categories":
          data.default_information_categories?.forEach((id) => {
            dropdownOptions(information_category)?.forEach((category) => {
              if (category.value === id) {
                formData.append(`${key}[]`, category.value);
              }
            });
          });
          break;
        default:
          formData.append(`${key}`, `${value}`);
          break;
      }
    });
    formData.append("photo", file);
    UserServices.updateUserProfile(formData)
      .then((res) => {
        actions.auth.setUserDefaultCities(res.data.payload.default_cities);
        toast.success(`${t("Profile data has been successfully updated")}`);
      })
      .catch((err) =>
        toast.error(
          `${t("Could not change data, an error has occurred")}: ${
            err?.form?.error_messages?.photo?.fileSizeTooBig
              ? err.form.error_messages.photo.fileSizeTooBig
              : ""
          }`
        )
      )
      .finally(() => setLoading(false));
  };

  const { primaryRole } = useStoreState((state) => state.auth.user);

  const defaultOptions = (arr) => {
    let defaultValues = [];
    if (arr) {
      arr.map(({ id }) => {
        defaultValues.push(id);
      });

      return defaultValues;
    } else return null;
  };

  return (
    <Modal.Content>
      {profileData && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col lg:flex-row lg:justify-around">
            <div className="flex justify-center">
              <label className="h-72">
                {file ? (
                  <div
                    className="w-72 h-72 mt-4"
                    style={{
                      backgroundImage: `url(${URL.createObjectURL(file)})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="transition-all duration-500 hover:bg-primse-green bg-opacity-75 hover:bg-opacity-75 p-4 h-full group">
                      <div className="border text-white  text-xl lg:text-base text-center px-16 h-full flex items-center justify-center">
                        <strong className="opacity-0 transition-all delay-100 duration-500 transform translate-y-full group-hover:opacity-100 group-hover:translate-y-0">
                          {t("Choose a photo")}
                        </strong>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="w-72 h-72 mt-4"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_IMAGES_URL}${profileData.photo})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="transition-all duration-500 hover:bg-primse-green bg-opacity-75 hover:bg-opacity-75 p-4 h-full group">
                      <div className="border text-white  text-xl lg:text-base text-center px-16 h-full flex items-center justify-center">
                        <strong className="opacity-0 transition-all delay-100 duration-500 transform translate-y-full group-hover:opacity-100 group-hover:translate-y-0">
                          {t("Choose a photo")}
                        </strong>
                      </div>
                    </div>
                  </div>
                )}
                <input
                  onChange={(event) => setFile(event.target.files[0])}
                  type="file"
                  hidden={true}
                />
              </label>
            </div>
            <div className="flex flex-col">
              <div className="p-2 flex flex-col">
                <label className="font-bold p-2">{t("Facebook")}:</label>
                <Controller
                  name="facebook_url"
                  control={control}
                  defaultValue={profileData.facebook_url}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        className="w-full lg:w-96"
                        type="text"
                      />
                    );
                  }}
                />
              </div>
              <div className="p-2 flex flex-col">
                <label className="font-bold p-2">{t("Instagram")}:</label>
                <Controller
                  name="instagram_url"
                  control={control}
                  defaultValue={profileData.instagram_url}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        className="w-full lg:w-96"
                        type="text"
                      />
                    );
                  }}
                />
              </div>
              <div className="p-2 flex flex-col">
                <label className="font-bold p-2">{t("Twitter")}:</label>
                <Controller
                  name="twitter_url"
                  control={control}
                  defaultValue={profileData.twitter_url}
                  render={({ field }) => {
                    return (
                      <Input
                        {...field}
                        className="w-full lg:w-96"
                        type="text"
                      />
                    );
                  }}
                />
              </div>
              <div className="p-2 flex flex-col">
                <label className="font-bold p-2">{t("Description")}:</label>
                <Controller
                  name="description"
                  control={control}
                  defaultValue={profileData.description}
                  render={({ field }) => {
                    return (
                      <Input
                        className="w-full lg:w-96"
                        {...field}
                        type="text"
                      />
                    );
                  }}
                />
              </div>
              {(primaryRole === "Admin" ||
                primaryRole === "Top Admin" ||
                primaryRole === "Doradca") && (
                <div className="p-2 flex flex-col">
                  <label className="font-bold p-2">
                    {t("Default information categories")}:
                  </label>
                  <Controller
                    name="default_information_categories"
                    control={control}
                    defaultValue={defaultOptions(
                      profileData?.default_information_categories
                    )}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Dropdown
                          className="w-full lg:w-96"
                          value={value}
                          options={dropdownOptions(information_category)}
                          search={true}
                          multiple={true}
                          selection={true}
                          fluid={false}
                          onChange={(e, data) => {
                            onChange(data.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              )}
              {(primaryRole === "Admin" ||
                primaryRole === "Top Admin" ||
                primaryRole === "Doradca") && (
                <div className="p-2 flex flex-col">
                  <label className="font-bold p-2">
                    {t("Default cities")}:
                  </label>
                  <Controller
                    name="default_cities"
                    control={control}
                    defaultValue={defaultOptions(profileData.default_cities)}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Dropdown
                          className="w-full lg:w-96"
                          value={value}
                          options={dropdownOptions(city)}
                          search={true}
                          multiple={true}
                          selection={true}
                          fluid={false}
                          onChange={(e, data) => {
                            onChange(data.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              )}
              {(primaryRole === "Admin" ||
                primaryRole === "Top Admin" ||
                primaryRole === "Doradca") && (
                <div className="p-2 flex items-center justify-between w-full lg:w-96">
                  <label className="font-bold p-2 w-3/5">
                    {t("Notification of acceptance of the query")}
                  </label>

                  <Controller
                    name="notification_query_accepted"
                    control={control}
                    defaultValue={
                      profileData.notification_query_accepted === "1"
                    }
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Checkbox
                          className="w-2/5 flex justify-end"
                          defaultChecked={
                            profileData.notification_query_accepted === "1"
                          }
                          value={value}
                          toggle={true}
                          onChange={(_, e) => {
                            onChange(e.checked);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              )}
              {(primaryRole === "Admin" ||
                primaryRole === "Top Admin" ||
                primaryRole === "Doradca") && (
                <div className="p-2 flex items-center justify-between w-full lg:w-96">
                  <label className="font-bold p-2 w-3/5">
                    {t("Notification of rejection of the query")}
                  </label>
                  <Controller
                    name="notification_query_rejected"
                    control={control}
                    defaultValue={
                      profileData.notification_query_rejected === "1"
                    }
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Checkbox
                          className="w-2/5 flex justify-end"
                          defaultChecked={
                            profileData.notification_query_rejected === "1"
                          }
                          value={value}
                          toggle={true}
                          onChange={(_, e) => {
                            onChange(e.checked);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              )}
              <div className="p-2 2 mt-4 text-right">
                <Button
                  disabled={file && file.size > 2000000}
                  type="submit"
                  className="px2"
                >
                  {t("Save")}
                </Button>
              </div>
            </div>
          </div>

          {file && file.size > 2000000 && (
            <Message
              negative={true}
              content={t(
                "The selected file is too large, select a photo with a maximum size of 2 MB"
              )}
            />
          )}
        </form>
      )}
      {loading && <Loader />}
    </Modal.Content>
  );
}

export default EditProfile;
