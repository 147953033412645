import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import { useParams } from "react-router-dom";
import ClientsService from "../../../../../../services/ClientsService";
import Loader from "../../../../../../components/Loader";
import { toast } from "react-toastify";

const DeleteClientModal = ({ closedModal, setRefresh }) => {
  const [loaded, setLoaded] = useState(false);
  const { uuid } = useParams();

  const { t } = useTranslation();

  const deleteHandler = () => {
    setLoaded(true);
    ClientsService.deleteClient(uuid)
      .then(() => {
        toast.success(t("Successfully deleted client"));
        setRefresh(true);
        closedModal();
        setLoaded(false);
      })
      .catch(() => {
        setLoaded(false);
        toast.error(t("Client could not be deleted"));
      });
  };

  return (
    <Modal open="true" size="small" onClose={() => closedModal()}>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between items-center">
          <div>{t("Delete client")}</div>
          <AiFillCloseCircle
            onClick={() => {
              closedModal();
            }}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        <>
          <p>{t("Are you sure you want to delete the client")}? </p>

          <div className="w-full flex justify-end">
            <button
              className="m-2 text-xs lg:text-base uppercase font-bold text-white bg-primse-yellow py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
              onClick={() => {
                closedModal();
              }}
            >
              {t("Cancel")}
            </button>
            <button
              className="m-2 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
              onClick={() => {
                deleteHandler();
              }}
            >
              {t("Delete")}
            </button>
          </div>
        </>{" "}
      </Modal.Content>
      {loaded && <Loader />}
    </Modal>
  );
};

export default DeleteClientModal;
