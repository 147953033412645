import React, { useEffect, useState } from "react";
import { Input, Message, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { actions } from "../../../store";
import UserServices from "../../../services/UserServices";
import { Controller, useForm } from "react-hook-form";
import Button from "../../../components/Button";
import { validatePassword } from "../../../utils/regex";

function ChangePassword() {
  const { t } = useTranslation();
  const { handleSubmit, control, watch } = useForm();
  const [loading, setLoading] = useState(false);
  const [errorCurrentPassword, setErrorCurrentPassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    return () => {
      setLoading(false);
      setErrorPassword(false);
      setConfirmPasswordError(false);
      setErrorMessage("");
    };
  }, []);

  const onSubmit = (data) => {
    setErrorMessage("");
    setErrorPassword(false);
    setConfirmPasswordError(false);
    setErrorCurrentPassword(false);

    if (
      data?.currentPassword === undefined ||
      data?.currentPassword?.length === 0
    ) {
      toast.error(`${t("None of the fields can be empty")}`);
      return setErrorCurrentPassword(true);
    }

    const validPassword = validatePassword(data?.password);

    let validEmptyFields = true;
    if (data?.password !== undefined && data?.password?.length === 0) {
      validEmptyFields = false;
      setErrorPassword(true);
    }

    if (
      data?.confirmPassword !== undefined &&
      data?.confirmPassword?.length === 0
    ) {
      validEmptyFields = false;
      setConfirmPasswordError(true);
    }

    if (!validEmptyFields) {
      setErrorMessage("None of the fields can be empty");
      return;
    }

    if (!validPassword.success) {
      setErrorPassword(true);
      setErrorMessage(validPassword.message);
      return;
    }

    if (data?.confirmPassword !== data?.password) {
      setConfirmPasswordError(true);
      setErrorMessage(
        "The new password and confirm password fields must match"
      );
      return;
    }

    setLoading(true);
    UserServices.updatePassword(data)
      .then((res) => {
        if (res?.status === 400 || res?.response?.status === 400) {
          if (
            res?.response?.data?.form?.error_messages?.currentPassword
              ?.msgPassword &&
            res.response.data.form.error_messages.currentPassword
              .msgPassword === "Incorrect password"
          ) {
            setErrorCurrentPassword(true);
            toast.error(`${t("A wrong password was entered")}`);
          } else {
            setErrorPassword(true);
            toast.error(
              `${t("Could not change password, an error has occurred")}`
            );
          }

          return res?.status || res?.response?.status;
        } else {
          toast.success(`${t("Password has been successfully updated")}`);
          return res?.status || res?.response?.status;
        }
      })
      .then((status) => {
        if (status !== 400) {
          actions.modals.openEditUserModal(false);
        }
      })
      .catch((err) => {
        if (
          err?.response?.data?.form?.error_messages?.currentPassword
            ?.msgPassword &&
          err.response.data.form.error_messages.currentPassword.msgPassword ===
            "Incorrect password"
        ) {
          setErrorCurrentPassword(true);
          toast.error(`${t("A wrong password was entered")}`);
        } else {
          setErrorPassword(true);
          toast.error(
            `${t("Could not change password, an error has occurred")}`
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const sendEmailResetPassword = () => {
    setLoading(true);

    UserServices.resetPasswordSendEmail()
      .then(() => {
        toast.success(`${t("Password reminder email has been sent")}`);
      })

      .finally(() => setLoading(false));
  };

  return (
    <Modal.Content>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col lg:flex-row items-center lg:justify-around">
          <div className="flex flex-col">
            <div className="p-2 flex flex-col">
              <label className="font-bold p-2">{t("Current password")}:</label>
              <Controller
                name="currentPassword"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      size={"large"}
                      type="password"
                      error={
                        watch().currentPassword === undefined ||
                        watch().currentPassword?.length < 6 ||
                        errorCurrentPassword
                      }
                    />
                  );
                }}
              />
            </div>
            <div className="p-2 flex flex-col">
              <label className="font-bold p-2">{t("New password")}:</label>
              <Controller
                name="password"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      size={"large"}
                      type="password"
                      error={errorPassword}
                    />
                  );
                }}
              />
            </div>
            <div className="p-2 flex flex-col">
              <label className="font-bold p-2">{t("Confirm password")}:</label>
              <Controller
                name="confirmPassword"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      size={"large"}
                      type="password"
                      error={confirmPasswordError}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="p-2 2  text-center">
          <div className="flex justify-center p-2 mb-2">
            <p
              onClick={sendEmailResetPassword}
              className="font-bold mb-4 hover:text-primse-green transition-all cursor-pointer max-w-max"
            >
              {t("Remind password")}
            </p>
          </div>

          <Button type="submit" className="px2">
            {t("Save")}
          </Button>
        </div>
      </form>
      {loading && <Loader />}

      {errorMessage !== "" ? (
        <Message negative={true} content={t(errorMessage)} />
      ) : null}
    </Modal.Content>
  );
}

export default ChangePassword;
