import React, { useState, useEffect, useRef } from "react";
import { Input } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { X } from "react-feather";
import { BiSearchAlt2 } from "react-icons/all";
import ClientsService from "../../../../../services/ClientsService";

import { useQuery } from "react-query";
import Loader from "../../../../../components/Loader";

import RequestUserSearchElement from "./RequestUserSearchElement";
import BriefcaseContentService from "../../../../../services/BriefcaseContentService";
import { toast } from "react-toastify";

const RequestUserSearch = ({ setOpen, uuid, setClientUuid }) => {
  const { data } = useQuery("clients-briefcase", ClientsService.getClients);
  const [filteredData, setFilteredData] = useState([]);
  const [searchedSurname, setSearchedSurname] = useState("");
  const [searchedMobile, setSearchedMobile] = useState("");
  const [searchedEmail, setSearchedEmail] = useState("");
  const [searchClick, setSearchClick] = useState(false);

  const [refresh, setRefresh] = useState(true);
  const [lastListCLients, setLastListClients] = useState(null);
  const { t } = useTranslation();

  const toastId = useRef(null);

  useEffect(() => {
    if (data) {
      if (data.status === 200) {
        const listClients = data.data.payload.slice(-10);
        listClients.reverse();
        setLastListClients(listClients);
        setRefresh(false);
      } else {
        setLastListClients([]);
        setRefresh(false);
      }
    } else {
      setLastListClients(null);
      setRefresh(false);
    }
  }, [data]);

  const getBriefcase = async (uuidClient, uuid) => {
    return await BriefcaseContentService.getBriefcaseContent(uuidClient)().then(
      (res) => {
        const findRequest = res?.payload?.find(
          (el) => el.investment_uuid === uuid
        );
        if (findRequest && findRequest?.query_status) {
          toast.update(toastId.current, {
            render: `${t("Klient jest już zgłoszony na inwestycję")}`,
            type: toast.TYPE.ERROR,
            autoClose: 5000,
          });

          return false;
        } else {
          return true;
        }
      }
    );
  };

  const handleSelectClient = async (client) => {
    if (toastId.current) return;
    toastId.current = toast(
      t(`The conditions of the request are being loaded`),
      {
        type: toast.TYPE.WARNING,
      }
    );

    let next = true;

    if (client && uuid) {
      const dubel = await getBriefcase(client?.uuid, uuid);
      next = dubel;
    }
    if (next) {
      setOpen(false);
      setClientUuid(client?.uuid);
    }

    toastId.current = null;
  };

  const handleSearchSurname = (event) => {
    let value = event.target.value;
    if (value === "") {
      setSearchClick(false);
    }
    setSearchedSurname(value);
  };

  const handleSearchMobile = (event) => {
    let value = event.target.value;
    setSearchedMobile(value);
  };

  const handleSearchEmail = (event) => {
    let value = event.target.value;
    setSearchedEmail(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    ClientsService.getSearchedClient({
      last_name: searchedSurname,
      email: searchedEmail,
      phone: searchedMobile,
      assigned_to: "",
      created_from: "",
      created_to: "",
    })
      .then((res) => {
        setFilteredData(res.data.payload);
        setSearchClick(true);
      })
      .catch(() => {
        setSearchClick(true);
      });
  };

  const handleClearSearch = () => {
    setSearchedSurname("");
    setSearchedMobile("");
    setSearchedEmail("");
    setFilteredData([]);
    setSearchClick(false);
  };

  return (
    <>
      <div>
        <form
          className="flex flex-col lg:flex-row items-center"
          onSubmit={handleSubmit}
        >
          <Input
            placeholder={t("Client last name")}
            className="my-2 mx-2 "
            label={t("Minimum 3 characters")}
            value={searchedSurname}
            labelPosition={"left corner"}
            fluid={false}
            type="text"
            size={"large"}
            onChange={handleSearchSurname}
            error={0 < searchedSurname?.length && searchedSurname?.length < 3}
          />
          <Input
            placeholder={t("Phone number")}
            className="my-2 mx-2"
            value={searchedMobile}
            label={t("Minimum 6 digits")}
            labelPosition={"left corner"}
            fluid={false}
            type="text"
            size={"large"}
            onChange={handleSearchMobile}
            error={0 < searchedMobile?.length && searchedMobile?.length < 6}
          />
          <Input
            placeholder={t("E-mail")}
            className="my-2 mx-2"
            value={searchedEmail}
            label={t("Minimum 6 characters")}
            labelPosition={"left corner"}
            fluid={false}
            type="text"
            size={"large"}
            onChange={handleSearchEmail}
            error={0 < searchedEmail?.length && searchedEmail?.length < 6}
          />
          <button
            className="leading-5 flex items-center py-3 px-4 text-sm"
            type="button"
            onClick={handleClearSearch}
          >
            <X size={14} className="mr-2" />
            {t("Clear")}
          </button>

          <button
            className="leading-5 font-bold text-white bg-primse-green uppercase py-3 px-3 mx-2 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
            type="submit"
            disabled={
              searchedSurname?.length < 3 &&
              searchedMobile?.length < 6 &&
              searchedEmail?.length < 6
            }
          >
            <BiSearchAlt2 />
          </button>
        </form>
      </div>
      {!refresh && (
        <div style={{ paddingTop: "0" }}>
          {/*before search, slice data*/}
          {filteredData?.length === 0 && !searchClick && (
            <>
              {lastListCLients ? (
                <RequestUserSearchElement
                  data={lastListCLients}
                  handleSelectClient={handleSelectClient}
                />
              ) : (
                <Loader />
              )}
            </>
          )}
          {/*after search*/}
          {filteredData?.length !== 0 && searchClick && (
            <RequestUserSearchElement
              data={filteredData}
              handleSelectClient={handleSelectClient}
            />
          )}
          {filteredData?.length < 1 && searchClick && (
            <tbody
              className="text-sm flex flex-col items-center w-full justify-start"
              style={{ height: "50vh" }}
            >
              <p className="text-base p-8 m-0 pr-4">{t("No results found")} </p>
            </tbody>
          )}
        </div>
      )}
    </>
  );
};

export default RequestUserSearch;
