import { useState } from "react";
import { Menu } from "react-feather";

import PopupTrigger from "./components/PopupTrigger";
import ControlerEditModal from "./components/ControlerEditModal";
import DeleteClientModal from "./components/DeleteClientModal";

const EditModal = ({ setRefresh, status }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const closedModal = () => {
    setOpenEditModal(false);
  };
  const closedDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <div>
        {openEditModal && (
          <ControlerEditModal
            closedModal={closedModal}
            setRefresh={setRefresh}
          />
        )}
        {openDeleteModal && (
          <DeleteClientModal
            closedModal={closedDeleteModal}
            setRefresh={setRefresh}
          />
        )}
        {!openEditModal && !openDeleteModal && (
          <PopupTrigger
            openEditModal={setOpenEditModal}
            status={status}
            openDeleteModal={setOpenDeleteModal}
          >
            <Menu className="mx-2 text-primse-green cursor-pointer" size={24} />
          </PopupTrigger>
        )}
      </div>
    </>
  );
};

export default EditModal;
