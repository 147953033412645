import { parseThousands } from "../../../../utils/numbers";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import styles from "./FlatHover.module.css";
import investmentPhoto from "./noImage.jpeg";
import { useStoreState } from "easy-peasy";

const Image = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <>
      {!isLoaded && (
        <div className="top-0 left-0 z-40 m-0 p-0 flex items-center justify-center">
          <div className={styles.ldsRing}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <img
        onLoad={() => {
          setIsLoaded(true);
        }}
        className={props.className}
        style={{ opacity: isLoaded ? 1 : 0 }}
        alt={props.alt}
        src={props.src}
      />
    </>
  );
};

const SmallFlatHover = ({
  name,
  price_from,
  price_to,
  price,
  uuid,
  pictures,
}) => {
  const history = useHistory();
  const { mode } = useStoreState((state) => state.mode);

  function handleInvestmentDetails(uuid) {
    history.push(`/${!price ? "investment" : "realestate"}/${uuid}`);
  }
  if (!pictures) return null;

  return (
    <div
      className="marker-hover absolute  transform -translate-y-full -translate-x-1/2 -top-5 pb-6 z-10 cursor-pointer"
      onClick={() => handleInvestmentDetails(uuid)}
    >
      <div className="flex bg-white shadow-md p-1 whitespace-nowrap">
        <div className="w-20 max-h-12">
          {pictures[0]?.thumbnail ? (
            <Image
              src={`${process.env.REACT_APP_IMAGES_URL}${pictures[0].thumbnail}`}
              className="w-full h-full object-cover"
              alt="investment"
            />
          ) : (
            <Image
              src={investmentPhoto}
              className="w-full h-full object-cover"
              alt="investment"
            />
          )}
        </div>
        <div className="text-primse-gray-font mx-2  flex flex-col ">
          <div className="text-base font-bold">
            {mode !== "presentation" ? <strong>{name}</strong> : null}
          </div>

          <div className="mt-1 text-sm flex items-center">
            {price ? (
              parseThousands(price)
            ) : (
              <div>
                {parseThousands(price_from)} - {parseThousands(price_to)}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallFlatHover;
