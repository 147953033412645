import { useTranslation } from "react-i18next";
import cs from "classnames";

const InfoBoxDeveloper = ({ title, data }) => {
  const { t } = useTranslation();

  if (data === null) return null;

  return (
    <div className="px-5 py-6 border rounded-sm ">
      <div className="font-bold text-xl">{t(title)}</div>
      <div className="w-14 h-1 bg-primse-green" />
      <div className={cs(" flex items-center")}>
        {data?.logo && (
          <div className="w-1/4 pr-4 mt-6 h-full">
            <img
              src={`${process.env.REACT_APP_IMAGES_URL}/files/logo/${data?.logo}`}
              className="w-full h-full object-cover"
              alt="logo developer"
            />
          </div>
        )}
        <div>
          {data?.www?.display && data?.www?.value}
          {data?.developerName?.display && (
            <p className="pt-6">{data?.developerName?.value}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoBoxDeveloper;
