import { regexStringToNumberPrice } from "../../utils/numbers";

const getTax = (id, taxType) => {
  const filter = taxType.filter((el) => el.value * 1 === id * 1);
  if (filter?.length > 0) return filter[0].tax * 1;
  else return 1;
};

export const convertStringToNumber = (num) => {
  return num?.replace(/[^0-9.,]/g, "") * 1;
};

const calcTax = (num, tax) => {
  return regexStringToNumberPrice((convertStringToNumber(num) * 100) / tax);
};

export const calculationGrossSum = (control, name, setValue) => {
  const watchForm = control.fieldsRef.current;
  if (watchForm[name]) {
    let price = 0;
    if (watchForm?.realestate_is_paid?._f?.value) {
      price =
        price +
        convertStringToNumber(watchForm.realestate_price_gross._f.value);
    }
    if (watchForm?.parking_is_paid?._f?.value) {
      price =
        price + convertStringToNumber(watchForm.parking_price_gross._f.value);
    }
    if (watchForm?.parking_extra_1_is_paid?._f?.value) {
      price =
        price +
        convertStringToNumber(watchForm.parking_extra_1_price_gross._f.value);
    }
    if (watchForm?.parking_extra_2_is_paid?._f?.value) {
      price =
        price +
        convertStringToNumber(watchForm.parking_extra_2_price_gross._f.value);
    }

    if (watchForm?.storage_is_paid?._f?.value) {
      price =
        price + convertStringToNumber(watchForm.storage_price_gross._f.value);
    }
    if (watchForm?.storage_extra_1_is_paid?._f?.value) {
      price =
        price +
        convertStringToNumber(watchForm.storage_extra_1_price_gross._f.value);
    }
    if (watchForm?.storage_extra_2_is_paid?._f?.value) {
      price =
        price +
        convertStringToNumber(watchForm.storage_extra_2_price_gross._f.value);
    }
    setValue("total_price_gross", regexStringToNumberPrice(price));
    calculationCommission(control, name, setValue);
  }
};
export const calculationNetSum = (control, name, setValue) => {
  const watchForm = control.fieldsRef.current;
  if (watchForm[name]) {
    let priceNet = 0;
    if (watchForm?.realestate_is_paid?._f?.value) {
      priceNet =
        priceNet +
        convertStringToNumber(watchForm.realestate_price_net._f.value);
    }
    if (watchForm?.parking_is_paid?._f?.value) {
      priceNet =
        priceNet + convertStringToNumber(watchForm.parking_price_net._f.value);
    }
    if (watchForm?.parking_extra_1_is_paid?._f?.value) {
      priceNet =
        priceNet +
        convertStringToNumber(watchForm.parking_extra_1_price_net._f.value);
    }
    if (watchForm?.parking_extra_2_is_paid?._f?.value) {
      priceNet =
        priceNet +
        convertStringToNumber(watchForm.parking_extra_2_price_net._f.value);
    }

    if (watchForm?.storage_is_paid?._f?.value) {
      priceNet =
        priceNet + convertStringToNumber(watchForm.storage_price_net._f.value);
    }
    if (watchForm?.storage_extra_1_is_paid?._f?.value) {
      priceNet =
        priceNet +
        convertStringToNumber(watchForm.storage_extra_1_price_net._f.value);
    }
    if (watchForm?.storage_extra_2_is_paid?._f?.value) {
      priceNet =
        priceNet +
        convertStringToNumber(watchForm.storage_extra_2_price_net._f.value);
    }
    setValue("total_price_net", regexStringToNumberPrice(priceNet));
    calculationCommission(control, name, setValue);
  }
};
export const calculationNetValue = (control, name, setValue, taxType) => {
  const watchForm = control.fieldsRef.current;
  if (watchForm[name]) {
    let tax = 100;

    switch (name) {
      case "realestate_price_gross":
        tax = getTax(watchForm.realestate_tax_type_id._f.value * 1, taxType);

        setValue(
          "realestate_price_net",
          calcTax(watchForm.realestate_price_gross._f.value, tax)
        );
        break;
      case "parking_price_gross":
        tax = getTax(watchForm.parking_tax_type_id._f.value, taxType);
        setValue(
          "parking_price_net",
          calcTax(watchForm.parking_price_gross._f.value, tax)
        );
        break;
      case "parking_extra_1_price_gross":
        tax = getTax(watchForm.parking_extra_1_tax_type_id._f.value, taxType);
        setValue(
          "parking_extra_1_price_net",
          calcTax(watchForm.parking_extra_1_price_gross._f.value, tax)
        );
        break;
      case "parking_extra_2_price_gross":
        tax = getTax(watchForm.parking_extra_2_tax_type_id._f.value, taxType);
        setValue(
          "parking_extra_2_price_net",

          calcTax(watchForm.parking_extra_2_price_gross._f.value, tax)
        );
        break;
      case "storage_price_gross":
        tax = getTax(watchForm.storage_tax_type_id._f.value, taxType);
        setValue(
          "storage_price_net",
          calcTax(watchForm.storage_price_gross._f.value, tax)
        );
        break;
      case "storage_extra_1_price_gross":
        tax = getTax(watchForm.storage_extra_1_tax_type_id._f.value, taxType);
        setValue(
          "storage_extra_1_price_net",
          calcTax(watchForm.storage_extra_1_price_gross._f.value, tax)
        );
        break;
      case "storage_extra_2_price_gross":
        tax = getTax(watchForm.storage_extra_2_tax_type_id._f.value, taxType);
        setValue(
          "storage_extra_2_price_net",
          calcTax(watchForm.storage_extra_2_price_gross._f.value, tax)
        );
        break;

      default:
        break;
    }
  }
};

const calculationCommission = (control, name, setValue) => {
  const watchForm = control.fieldsRef.current;
  if (watchForm[name]) {
    const commissionPrimse = watchForm?.commission_total_percentage?._f?.value
      ? watchForm?.commission_total_percentage?._f?.value * 1
      : 0;
    // const BO_agreement_commission = watchForm?.BO_agreement_commission?._f
    //   ?.value
    //   ? watchForm?.BO_agreement_commission?._f?.value * 1
    //   : 0;
    // const BO_agreement_factoring_commission = watchForm
    //   ?.BO_agreement_factoring_commission?._f?.value
    //   ? watchForm?.BO_agreement_factoring_commission?._f?.value * 1
    //   : 0;

    const priceTotalNet = watchForm?.total_price_net?._f?.value
      ? convertStringToNumber(watchForm?.total_price_net?._f?.value)
      : 0;

    setValue(
      "commission_total_price_net",
      `${regexStringToNumberPrice(
        (commissionPrimse * 1 * priceTotalNet) / 100
      )}`
    );
    // if (watchForm?.commission_has_factoring?._f?.value === "1") {
    //   setValue(
    //     "commission_agency_price_net",
    //     `${regexStringToNumberPrice(
    //       (BO_agreement_factoring_commission * 1 * priceTotalNet) / 100
    //     )}`
    //   );
    // } else {
    //   setValue(
    //     "commission_agency_price_net",
    //     `${regexStringToNumberPrice(
    //       (BO_agreement_commission * 1 * priceTotalNet) / 100
    //     )}`
    //   );
    // }
  }
};

export const setTaxFactoring = (
  value,
  setValue,
  totalTax,
  factoringTax
  // control
) => {
  // const watchForm = control.fieldsRef.current;
  // const priceTotalNet = watchForm?.total_price_net?._f?.value
  //   ? convertStringToNumber(watchForm?.total_price_net?._f?.value)
  //   : 0;

  if (value === "1") {
    setValue("commission_agency_percentage", factoringTax);
    // setValue(
    //   "commission_agency_price_net",
    //   `${regexStringToNumberPrice((factoringTax * 1 * priceTotalNet) / 100)}`
    // );
  } else {
    setValue("commission_agency_percentage", totalTax);
    // setValue(
    //   "commission_agency_price_net",
    //   `${regexStringToNumberPrice((totalTax * 1 * priceTotalNet) / 100)}`
    // );
  }
};
