import React, { useState, useEffect } from 'react'
import { Modal } from 'semantic-ui-react'
import { BriefCase, BriefCaseWhite } from "../../Icons";
import { useTranslation } from "react-i18next";
import { useStoreState } from "easy-peasy";
import BriefcaseView from "../BriefcaseView";
import { actions } from "../../../store";
import BriefcaseModal from "../BriefcaseModal";


const BriefcaseComponent = () => {
    const { briefcaseClient, briefcaseModalOpen, briefcaseModalSelectClient } = useStoreState((state) => state.briefcase);
    const { t } = useTranslation();

    const [hovered, setHovered] = useState(false);
    const toggleHover = () => setHovered(!hovered);

    const selectModal = (modal) => {
        setActiveModal(modal)
    }
    const selectDefaultModal = () => {
        setActiveModal(<BriefcaseView selectModal={selectModal} selectDefaultModal={selectDefaultModal} setOpenFirstModal={actions.briefcase.setBriefcaseModal} />)
    }
    const [activeModal, setActiveModal] = useState(<BriefcaseView selectModal={selectModal} selectDefaultModal={selectDefaultModal} setOpenFirstModal={actions.briefcase.setBriefcaseModal} />)

    useEffect(() => {
        if (briefcaseModalSelectClient) {
            setActiveModal(<BriefcaseModal selectDefaultModal={selectDefaultModal} />)
        } else {
            setActiveModal(<BriefcaseView selectModal={selectModal} selectDefaultModal={selectDefaultModal} setOpenFirstModal={actions.briefcase.setBriefcaseModal} />)
        }
    }, [briefcaseModalOpen])

    return (
        <Modal
            open={briefcaseModalOpen}
            onClose={() => {
                actions.briefcase.setBriefcaseModal(false)
                actions.briefcase.setBriefcaseInfoPopup(false)
                actions.briefcase.setBriefcaseModalSelectClient(false)
                selectDefaultModal()
            }}
            onOpen={() => {
                actions.briefcase.setBriefcaseModal(true)
                actions.briefcase.setBriefcaseInfoPopup(false)
            }}
            size={"large"}
            trigger={<div className="px-2 py-4 flex cursor-pointer hover:text-primse-green" onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
                {/* <div className="mr-2.5 text-primse-green w-5">
                    <BriefCase />
                </div> */}
                <div className={"mr-2.5 text-primse-green w-7 h-7 flex items-center justify-center rounded-full cursor-pointer bg-primse-yellow"}>
                    <div className="w-4 flex items-center justify-center">
                        {!hovered && (<BriefCase/>)}
                        {hovered && (<BriefCaseWhite/>)}
                    </div>
                </div>
                <label className="font-normal cursor-pointer">{t("Briefcase")}: </label>
                {
                    briefcaseClient ?
                        <span className="font-bold">{briefcaseClient?.first_name} {briefcaseClient?.last_name}</span> :
                        <span className="font-bold">{t("Choose")}</span>
                }
            </div>}
        >
            {activeModal}
        </Modal>
    )
}

export default BriefcaseComponent;