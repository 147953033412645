import SingleInvestmentForm from "../SingleInvestmentForm";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const InvestmentsList = ({
  investmentsListModal,
  setFilesArr,
  filesArr,
  requiredError,
  handleError,
  setRequiredFiles = () => {},
}) => {
  const { t } = useTranslation();

  const [investments, setInvestments] = useState(investmentsListModal);
  const updateTimer = useRef(null);

  function setUpdate() {
    setInvestments(investmentsListModal);
    updateTimer.current = setTimeout(() => {
      setInvestments(investmentsListModal);
      updateTimer.current = null;
    }, 1000);
  }

  useEffect(() => {
    return () => {
      if (updateTimer.current) {
        clearTimeout(updateTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!updateTimer.current) setUpdate();
  }, [investmentsListModal]);

  return (
    <>
      {investments?.length === 1
        ? investments.map(({ investmentUuid, investmentInfo }) => (
            <>
              <SingleInvestmentForm
                investment_uuid={investmentUuid}
                investmentName={investmentInfo.investment_name}
                developerName={investmentInfo.developer_name}
                setFilesArr={setFilesArr}
                filesArr={filesArr}
                requiredError={requiredError}
                handleError={handleError}
                setRequiredFiles={setRequiredFiles}
              />
            </>
          ))
        : investments.map(
            ({ investmentUuid, investmentInfo }, index) =>
              investmentInfo.query_status === false && (
                <>
                  <div className="font-bold my-2">{`${t("Request")} ${
                    index + 1
                  }`}</div>
                  <SingleInvestmentForm
                    investment_uuid={investmentUuid}
                    investmentName={investmentInfo.investment_name}
                    developerName={investmentInfo.developer_name}
                    setFilesArr={setFilesArr}
                    requiredError={requiredError}
                    handleError={handleError}
                    setRequiredFiles={setRequiredFiles}
                  />
                </>
              )
          )}
    </>
  );
};

export default InvestmentsList;
