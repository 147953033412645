import cs from "classnames";
import { Link, NavLink } from "react-router-dom";
import { Popup } from "semantic-ui-react";
import { useState } from "react";

const PopupNavList = ({
  label,
  elements,
  exact,

  setShowMobileMenu,
  redirect,
}) => {
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <li className={cs("px-4 py-3 xl:py-0 relative nav hidden xl:block")}>
      <Popup
        trigger={
          redirect ? (
            <Link
              onClick={() => setShowMobileMenu(false)}
              to={elements[0].link}
              className="relative text-black hover:text-black  "
            >
              {label}
            </Link>
          ) : (
            <p className="relative text-black hover:text-black cursor-pointer ">
              {label}
            </p>
          )
        }
        position="bottom center"
        flowing
        hoverable
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onOpen={() => setOpenPopup(true)}
      >
        <div className={cs("bg-white  text-sm font-normal")}>
          {elements.map(({ link, label }) => (
            <NavLink
              to={link}
              key={`${label}_${link}`}
              exact={exact}
              activeClassName="active-nav"
              component={({ className, href }) => {
                return (
                  <li
                    className={cs(
                      "px-4 py-3 relative nav list-none",
                      className
                    )}
                  >
                    <Link
                      onClick={() => {
                        setOpenPopup(false);
                        setShowMobileMenu(false);
                      }}
                      to={href}
                      className="relative text-black hover:text-black"
                    >
                      {label}
                    </Link>
                  </li>
                );
              }}
            ></NavLink>
          ))}
        </div>
      </Popup>
    </li>
  );
};

export default PopupNavList;
