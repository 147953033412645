import { X } from "react-feather";

const Badge = ({ children, onRemove, Icon = null }) => {
  return (
    <div className="w-max flex items-center justify-between bg-primse-green rounded-2xl text-white py-1 px-3 text-sm">
      <div className="flex justify-center items-center">
        {Icon && <div className="mr-2">{Icon}</div>}
        <strong className="mr-2 whitespace-nowrap">{children}</strong>
      </div>
      {onRemove && (
        <X size={18} className="p-1 cursor-pointer" onClick={onRemove} />
      )}
    </div>
  );
};

export default Badge;
