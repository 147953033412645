import CartWithMinusIcon from "./CartWithMinus";
import CartWithPlusIcon from "./CartWithPlus";
import { ReactComponent as BriefcaseIconBlack } from './briefcaseIcon.svg';
import { ReactComponent as BriefcaseIconWhite } from './briefcaseWhiteIcon.svg';
import { ReactComponent as CartIconDark } from './cartIconDark.svg';
import { ReactComponent as CartIconWhite } from './cartWhiteIcon.svg';


export const BriefCasePlus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.37 25.76">
      <defs>
        <style>
          .cls-3{"{fill:#7eba27;}"}.cls-2
          {"{fill: #fff;stroke: #7eba27;stroke-linejoin:round;}"}
        </style>
      </defs>
      <g id="Warstwa_2" data-name="Warstwa 2">
        <g id="bold">
          <path
            className="cls-3"
            d="M17.92,7.24A1.19,1.19,0,0,1,16.73,6V4.25H12V6A1.2,1.2,0,1,1,9.56,6V4.25A2.39,2.39,0,0,1,12,1.86h4.78a2.4,2.4,0,0,1,2.39,2.39V6A1.2,1.2,0,0,1,17.92,7.24Z"
          />
          <path
            className="cls-3"
            d="M15.19,17.85a2.6,2.6,0,0,1-.85.14,2.51,2.51,0,0,1-.92-.17L0,13.35v9.12a3.29,3.29,0,0,0,3.29,3.29h22.1a3.29,3.29,0,0,0,3.29-3.29V13.35Z"
          />
          <path
            className="cls-3"
            d="M28.68,8.73v2.74L14.62,16.15a.69.69,0,0,1-.28.05.74.74,0,0,1-.29-.05L0,11.47V8.73A3.29,3.29,0,0,1,3.29,5.44h22.1A3.29,3.29,0,0,1,28.68,8.73Z"
          />
          <polygon
            className="cls-2"
            points="36.87 5.93 31.43 5.93 31.43 0.5 25.92 0.5 25.92 5.93 20.48 5.93 20.48 11.45 25.92 11.45 25.92 16.89 31.43 16.89 31.43 11.45 36.87 11.45 36.87 5.93"
          />
        </g>
      </g>
    </svg>
  );
};

export const BriefCasePlusWhite = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.37 25.76">
      <defs>
        <style>
          .cls-1111{"{fill:#fff;}"}.cls-1112
          {"{fill: #fff;stroke: #7eba27;stroke-linejoin:round;}"}
        </style>
      </defs>
      <g id="Warstwa_2" data-name="Warstwa 2">
        <g id="bold">
          <path
            className="cls-1111"
            d="M17.92,7.24A1.19,1.19,0,0,1,16.73,6V4.25H12V6A1.2,1.2,0,1,1,9.56,6V4.25A2.39,2.39,0,0,1,12,1.86h4.78a2.4,2.4,0,0,1,2.39,2.39V6A1.2,1.2,0,0,1,17.92,7.24Z"
          />
          <path
            className="cls-1111"
            d="M15.19,17.85a2.6,2.6,0,0,1-.85.14,2.51,2.51,0,0,1-.92-.17L0,13.35v9.12a3.29,3.29,0,0,0,3.29,3.29h22.1a3.29,3.29,0,0,0,3.29-3.29V13.35Z"
          />
          <path
            className="cls-1111"
            d="M28.68,8.73v2.74L14.62,16.15a.69.69,0,0,1-.28.05.74.74,0,0,1-.29-.05L0,11.47V8.73A3.29,3.29,0,0,1,3.29,5.44h22.1A3.29,3.29,0,0,1,28.68,8.73Z"
          />
          <polygon
            className="cls-1112"
            points="36.87 5.93 31.43 5.93 31.43 0.5 25.92 0.5 25.92 5.93 20.48 5.93 20.48 11.45 25.92 11.45 25.92 16.89 31.43 16.89 31.43 11.45 36.87 11.45 36.87 5.93"
          />
        </g>
      </g>
    </svg>
  );
};

export const BriefCaseMinus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.37 23.9">
      <defs>
        <style>
          .cls-111{"{fill:#7eba27;}"}.cls-112
          {"{fill: #fff;stroke: #7eba27;stroke-linejoin:round;}"}
        </style>
      </defs>
      <g id="Warstwa_2" data-name="Warstwa 2">
        <g id="bold">
          <path
            className="cls-111"
            d="M17.92,5.38a1.19,1.19,0,0,1-1.19-1.2V2.39H12V4.18a1.2,1.2,0,1,1-2.39,0V2.39A2.39,2.39,0,0,1,12,0h4.78a2.4,2.4,0,0,1,2.39,2.39V4.18A1.2,1.2,0,0,1,17.92,5.38Z"
          />
          <path
            className="cls-111"
            d="M15.19,16a2.6,2.6,0,0,1-.85.14,2.51,2.51,0,0,1-.92-.17L0,11.49v9.12A3.29,3.29,0,0,0,3.29,23.9h22.1a3.29,3.29,0,0,0,3.29-3.29V11.49Z"
          />
          <path
            className="cls-111"
            d="M28.68,6.87V9.61L14.62,14.29a.69.69,0,0,1-.28.05.74.74,0,0,1-.29-.05L0,9.61V6.87A3.29,3.29,0,0,1,3.29,3.58h22.1A3.29,3.29,0,0,1,28.68,6.87Z"
          />
          <polygon
            className="cls-112"
            points="36.87 4.08 31.43 4.08 25.92 4.08 20.48 4.08 20.48 9.59 25.92 9.59 31.43 9.59 36.87 9.59 36.87 4.08"
          />
        </g>
      </g>
    </svg>
  );
};

export const BriefCase = () => {
  return (
      <BriefcaseIconBlack />
    // <svg xmlns="http://www.w3.org/2000/svg" width="28.68" height="23.9" viewBox="0 0 28.68 23.9">
    //   <defs>
    //     <style>.cls-115{"{fill: #505050;}"}</style>
    //   </defs>
    //   <g id="Warstwa_2" data-name="Warstwa 2">
    //     <g id="bold">
    //       <path
    //         className="cls-115"
    //         d="M17.92,5.38a1.19,1.19,0,0,1-1.19-1.2V2.39H12V4.18a1.2,1.2,0,1,1-2.39,0V2.39A2.39,2.39,0,0,1,12,0h4.78a2.4,2.4,0,0,1,2.39,2.39V4.18A1.2,1.2,0,0,1,17.92,5.38Z"
    //       />
    //       <path
    //         className="cls-115"
    //         d="M15.19,16a2.6,2.6,0,0,1-.85.14,2.51,2.51,0,0,1-.92-.17L0,11.49v9.12A3.29,3.29,0,0,0,3.29,23.9h22.1a3.29,3.29,0,0,0,3.29-3.29V11.49Z"
    //       />
    //       <path
    //         className="cls-115"
    //         d="M28.68,6.87V9.61L14.62,14.29a.69.69,0,0,1-.28.05.74.74,0,0,1-.29-.05L0,9.61V6.87A3.29,3.29,0,0,1,3.29,3.58h22.1A3.29,3.29,0,0,1,28.68,6.87Z"
    //       />
    //     </g>
    //   </g>
    // </svg>
  );
};
export const BriefCaseWhite = () => {
  return (
      <BriefcaseIconWhite />
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.68 23.9">
    //   <defs>
    //     <style>.cls-111{"{fill: #ffffff;}"}</style>
    //   </defs>
    //   <g id="Warstwa_2" data-name="Warstwa 2">
    //     <g id="bold">
    //       <path
    //         className="cls-111"
    //         d="M17.92,5.38a1.19,1.19,0,0,1-1.19-1.2V2.39H12V4.18a1.2,1.2,0,1,1-2.39,0V2.39A2.39,2.39,0,0,1,12,0h4.78a2.4,2.4,0,0,1,2.39,2.39V4.18A1.2,1.2,0,0,1,17.92,5.38Z"
    //       />
    //       <path
    //         className="cls-111"
    //         d="M15.19,16a2.6,2.6,0,0,1-.85.14,2.51,2.51,0,0,1-.92-.17L0,11.49v9.12A3.29,3.29,0,0,0,3.29,23.9h22.1a3.29,3.29,0,0,0,3.29-3.29V11.49Z"
    //       />
    //       <path
    //         className="cls-111"
    //         d="M28.68,6.87V9.61L14.62,14.29a.69.69,0,0,1-.28.05.74.74,0,0,1-.29-.05L0,9.61V6.87A3.29,3.29,0,0,1,3.29,3.58h22.1A3.29,3.29,0,0,1,28.68,6.87Z"
    //       />
    //     </g>
    //   </g>
    // </svg>
  );
};

export const Cart = () => {
  return (
      <CartIconDark/>
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.19 23.9">
    //   <defs>
    //     <style>.cls-115{"{fill: #505050;}"}</style>
    //   </defs>
    //   <g id="Warstwa_2" data-name="Warstwa 2">
    //     <g id="bold">
    //       <path
    //         className="cls-115"
    //         d="M8.81,15.93h14.4a.8.8,0,0,0,.76-.58L27.16,4.2a.79.79,0,0,0-.77-1H7L6.41.62A.8.8,0,0,0,5.63,0H.8A.8.8,0,0,0,0,.8a.79.79,0,0,0,.8.79H5l2.88,13a2.39,2.39,0,0,0,.94,4.58h14.4a.8.8,0,0,0,0-1.6H8.81A.79.79,0,0,1,8,16.73.8.8,0,0,1,8.81,15.93Z"
    //       />
    //       <path
    //         className="cls-115"
    //         d="M8,21.51a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,8,21.51Z"
    //       />
    //       <path
    //         className="cls-115"
    //         d="M19.22,21.51a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,19.22,21.51Z"
    //       />
    //     </g>
    //   </g>
    // </svg>
  );
};
 

export const CartWhite = () => {
  return (
      <CartIconWhite/>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 27.19 23.9"
    // >
    //   <defs>
    //     <style>.cls-111{"{fill: #ffffff; }"}</style>
    //   </defs>
    //   <g id="Warstwa_2" data-name="Warstwa 2">
    //     <g id="bold">
    //       <path
    //         className="cls-111"
    //         d="M8.81,15.93h14.4a.8.8,0,0,0,.76-.58L27.16,4.2a.79.79,0,0,0-.77-1H7L6.41.62A.8.8,0,0,0,5.63,0H.8A.8.8,0,0,0,0,.8a.79.79,0,0,0,.8.79H5l2.88,13a2.39,2.39,0,0,0,.94,4.58h14.4a.8.8,0,0,0,0-1.6H8.81A.79.79,0,0,1,8,16.73.8.8,0,0,1,8.81,15.93Z"
    //       />
    //       <path
    //         className="cls-111"
    //         d="M8,21.51a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,8,21.51Z"
    //       />
    //       <path
    //         className="cls-111"
    //         d="M19.22,21.51a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,19.22,21.51Z"
    //       />
    //     </g>
    //   </g>
    // </svg>
  );
};
export const CartWhiteMap = () => {
  return (
     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.19 23.9">
       <defs>
         <style>.cls-111{"{fill: #ffffff; }"}</style>
       </defs>
       <g id="Warstwa_2" data-name="Warstwa 2">
         <g id="bold">
           <path
             className="cls-111"
             d="M8.81,15.93h14.4a.8.8,0,0,0,.76-.58L27.16,4.2a.79.79,0,0,0-.77-1H7L6.41.62A.8.8,0,0,0,5.63,0H.8A.8.8,0,0,0,0,.8a.79.79,0,0,0,.8.79H5l2.88,13a2.39,2.39,0,0,0,.94,4.58h14.4a.8.8,0,0,0,0-1.6H8.81A.79.79,0,0,1,8,16.73.8.8,0,0,1,8.81,15.93Z"
           />
          <path
             className="cls-111"
             d="M8,21.51a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,8,21.51Z"
           />
           <path
             className="cls-111"
             d="M19.22,21.51a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,19.22,21.51Z"
           />
         </g>
       </g>
     </svg>
  );
};

export const CartMinus = () => {
  return (
      <CartWithMinusIcon fill={"#7eba27"}/>
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.92 23.9">
    //   <defs>
    //     <style>
    //       .cls-111{"{fill: #7eba27;}"}.cls-112
    //       {"{fill: #fff;stroke: #7eba27;stroke-linejoin:round;}"}
    //     </style>
    //   </defs>
    //   <g id="Warstwa_2" data-name="Warstwa 2">
    //     <g id="bold">
    //       <path
    //         className="cls-111"
    //         d="M8.81,15.93h14.4a.8.8,0,0,0,.76-.58L27.16,4.2a.79.79,0,0,0-.77-1H7L6.41.62A.8.8,0,0,0,5.63,0H.8A.8.8,0,0,0,0,.8a.79.79,0,0,0,.8.79H5l2.88,13a2.39,2.39,0,0,0,.94,4.58h14.4a.8.8,0,0,0,0-1.6H8.81A.79.79,0,0,1,8,16.73.8.8,0,0,1,8.81,15.93Z"
    //       />
    //       <path
    //         className="cls-111"
    //         d="M8,21.51a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,8,21.51Z"
    //       />
    //       <path
    //         className="cls-111"
    //         d="M19.22,21.51a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,19.22,21.51Z"
    //       />
    //       <polygon
    //         className="cls-112"
    //         points="33.42 0.82 27.99 0.82 22.47 0.82 17.03 0.82 17.03 6.34 22.47 6.34 27.99 6.34 33.42 6.34 33.42 0.82"
    //       />
    //     </g>
    //   </g>
    // </svg>
  );
};

export const CartPlus = () => {
  return (
      <CartWithPlusIcon fill={"#7eba27"}/>
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.92 29.01">
    //   <defs>
    //     <style>
    //       .cls-111{"{fill: #7eba27;}"}.cls-112
    //       {"{fill: #fff;stroke: #7eba27;stroke-linejoin:round;}"}
    //     </style>
    //   </defs>
    //   <g id="Warstwa_2" data-name="Warstwa 2">
    //     <g id="bold">
    //       <path
    //         className="cls-111"
    //         d="M8.81,21h14.4a.79.79,0,0,0,.76-.58L27.16,9.31a.79.79,0,0,0-.77-1H7L6.41,5.73a.8.8,0,0,0-.78-.62H.8a.8.8,0,0,0-.8.8.79.79,0,0,0,.8.79H5l2.88,13a2.39,2.39,0,0,0,.94,4.58h14.4a.8.8,0,0,0,0-1.6H8.81A.79.79,0,0,1,8,21.84.8.8,0,0,1,8.81,21Z"
    //       />
    //       <path
    //         className="cls-111"
    //         d="M8,26.62a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,8,26.62Z"
    //       />
    //       <path
    //         className="cls-111"
    //         d="M19.22,26.62a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,19.22,26.62Z"
    //       />
    //       <polygon
    //         className="cls-112"
    //         points="33.42 5.93 27.99 5.93 27.99 0.5 22.47 0.5 22.47 5.93 17.03 5.93 17.03 11.45 22.47 11.45 22.47 16.89 27.99 16.89 27.99 11.45 33.42 11.45 33.42 5.93"
    //       />
    //     </g>
    //   </g>
    // </svg>
  );
};

export const CartPlusWhite = () => {
  return (
      <CartWithPlusIcon custom_color="#fff" />

    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.92 29.01">
    //   <defs>
    //     <style>
    //       .cls-111{"{fill: #fff;}"}.cls-112
    //       {"{fill: #fff;stroke: #7eba27;stroke-linejoin:round;}"}
    //     </style>
    //   </defs>
    //   <g id="Warstwa_2" data-name="Warstwa 2">
    //     <g id="bold">
    //       <path
    //         className="cls-111"
    //         d="M8.81,21h14.4a.79.79,0,0,0,.76-.58L27.16,9.31a.79.79,0,0,0-.77-1H7L6.41,5.73a.8.8,0,0,0-.78-.62H.8a.8.8,0,0,0-.8.8.79.79,0,0,0,.8.79H5l2.88,13a2.39,2.39,0,0,0,.94,4.58h14.4a.8.8,0,0,0,0-1.6H8.81A.79.79,0,0,1,8,21.84.8.8,0,0,1,8.81,21Z"
    //       />
    //       <path
    //         className="cls-111"
    //         d="M8,26.62a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,8,26.62Z"
    //       />
    //       <path
    //         className="cls-111"
    //         d="M19.22,26.62a2.39,2.39,0,1,0,2.39-2.39A2.4,2.4,0,0,0,19.22,26.62Z"
    //       />
    //       <polygon
    //         className="cls-112"
    //         points="33.42 5.93 27.99 5.93 27.99 0.5 22.47 0.5 22.47 5.93 17.03 5.93 17.03 11.45 22.47 11.45 22.47 16.89 27.99 16.89 27.99 11.45 33.42 11.45 33.42 5.93"
    //       />
    //     </g>
    //   </g>
    // </svg>
  );
};
export const MapPin = ({ width = 64, height = 64 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#7eba27"
      width={width}
      height={height}
      stroke="white"
      strokeWidth="2"
    >
      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
      <circle cx="12" cy="10" r="3"></circle>
    </svg>
  );
};
