import cs from "classnames";
import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import { useClickOutside } from "../../../../../utils/useClickOutside";
import FilterModal from "./FilterModal";
import styles from "./SearchComponents.module.css";
import { useTranslation } from "react-i18next";

export const ROOMS_NUM = 5;

const Room = ({ children, active, selected, ...props }) => (
  <li
    className={cs(
      {
        "bg-primse-green": !!active || !!selected,
        "text-white": !!active || !!selected,
        "opacity-80 ": !!active && !selected,
        "bg-opacity-40 ": !!selected,
      },
      "flex-1 text-center py-4 border-r border-t border-b rounded-sm first:border hover:bg-primse-green hover:text-white hover:opacity-100 transition-all cursor-pointer"
    )}
    {...props}
  >
    {children}
  </li>
);

const RoomsDropdown = ({ control, onClose }) => {
  const containerRef = useRef();
  const { t } = useTranslation();
  useClickOutside(containerRef, onClose);

  const onRoomCLicked = (selectedValues, value) => {
    if (selectedValues.includes(value)) {
      return selectedValues.filter((s) => s !== value);
    } else {
      const values = [...selectedValues, value].sort();
      if (values?.length === 1) {
        return [value];
      } else {
        return [values[0], values[values?.length - 1]];
      }
    }
  };

  return (
    <Controller
      control={control}
      name="rooms"
      render={({ field: { onChange, value: selectedValues } }) => {
        return (
          <div ref={containerRef}>
            <FilterModal
              title={t("Number of rooms")}
              onReset={() => {
                onChange([]);
              }}
            >
              <ul className={cs(styles.roomsContainer, "flex")}>
                {Array(ROOMS_NUM)
                  .fill()
                  .map((_, index) => {
                    const value = index + 1;
                    return (
                      <Room
                        key={index}
                        onClick={() =>
                          onChange(onRoomCLicked(selectedValues, value))
                        }
                        active={selectedValues.includes(value)}
                        selected={
                          selectedValues?.length !== 0 &&
                          value > selectedValues[0] &&
                          value < selectedValues[selectedValues?.length - 1]
                        }
                      >
                        {value}
                        {index === ROOMS_NUM - 1 && "+"}
                      </Room>
                    );
                  })}
              </ul>
            </FilterModal>
          </div>
        );
      }}
    />
  );
};

export default RoomsDropdown;
