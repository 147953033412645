import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import { Cart, CartWhite } from "../../Icons";
import { useTranslation } from "react-i18next";
import { useStoreState } from "easy-peasy";
import MyCartView from "../MyCartView";
import { actions } from "../../../store";
import { useHistory } from "react-router";

const MyCardViewComponent = () => {
  const { basketModalOpen, currentBasket } = useStoreState(
    (state) => state.baskets
  );
  const { t } = useTranslation();
  const history = useHistory();

  const [ishover, setishover] = useState(false);
  const istogglehover = () => setishover(!ishover);

  const handleClosedBasket = () => {
    if (history.location.pathname.slice(0, 8) === "/basket/") {
      history.push(history.location.pathname);
    }
  };

  const selectModal = (modal) => {
    setActiveModal(modal);
  };

  const selectDefaultModal = () => {
    setActiveModal(
      <MyCartView
        selectModal={selectModal}
        selectDefaultModal={selectDefaultModal}
        handleClosedBasket={handleClosedBasket}
      />
    );
  };
  const [activeModal, setActiveModal] = useState(
    <MyCartView
      selectModal={selectModal}
      selectDefaultModal={selectDefaultModal}
      handleClosedBasket={handleClosedBasket}
    />
  );

  return (
    <Modal
      open={basketModalOpen}
      onClose={() => {
        actions.baskets.setBasketModal(false);
        actions.baskets.setBasketInfoPopup(false);
        handleClosedBasket();
        selectDefaultModal();
      }}
      onOpen={() => {
        actions.baskets.setBasketInfoPopup(false);
        actions.baskets.setBasketModal(true);
      }}
      size={"large"}
      trigger={
        <div
          className="px-2 py-4 flex cursor-pointer hover:text-primse-green"
          onMouseEnter={istogglehover}
          onMouseLeave={istogglehover}
        >
          <div className="mr-2.5 bg-primse-green text-primse-green w-7 h-7 flex items-center justify-center rounded-full cursor-pointer">
            <div className="w-4 flex items-center justify-center">
              {!ishover && <Cart />}
              {ishover && <CartWhite />}
            </div>
          </div>
          {/* <div className="mr-2.5 bg-primse-gray absolute bottom-8 right-8 rounded-full w-5 cursor-pointer">
                    <div className="w-3">
                        <Cart/>
                    </div>
                </div> */}
          <label className="font-normal cursor-pointer">
            {t("My carts")}:{" "}
          </label>{" "}
          {currentBasket.name ? t(currentBasket.name) : t("Choose")}
        </div>
      }
    >
      {activeModal}
    </Modal>
  );
};

export default MyCardViewComponent;
