import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStoreState } from "easy-peasy";
import { Popup } from "semantic-ui-react";

import { editPrice } from "../../../../../utils/numbers";

import peopleSrc from "./assets/people@2x.png";
import dimensionsSrc from "./assets/dimensions@2x.png";
import investmentPhoto from "./noImage.jpeg";

import { CartPlusWhite } from "../../../../../components/Icons";
import {
  addFlatToBriefcase,
  addToBasket,
} from "../../../../../utils/customerInteraction";

const RealestateItem = ({ data }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const { mode } = useStoreState((state) => state.mode);
  const { briefcaseClient } = useStoreState((state) => state.briefcase);
  const { currentBasketUuid } = useStoreState((state) => state.baskets);

  const { t } = useTranslation();

  return (
    <div className="flex">
      <div className="flex flex-col ">
        <Link to={`/realestate/${data?.uuid}`} className="inline-block">
          {!isLoaded && (
            <div
              className="ui placeholder fluid"
              style={{ height: "167px", width: "278px" }}
            ></div>
          )}
          {data?.pictures !== null && data?.pictures?.[0]?.file ? (
            <img
              style={{
                opacity: isLoaded ? 1 : 0,
                height: "167px",
                width: "278px",
              }}
              onLoad={() => setIsLoaded(true)}
              src={`${process.env.REACT_APP_IMAGES_URL}${data?.pictures?.[0].file}`}
              className="rounded-t-sm "
              alt="realestate"
              data-link={`/realestate/${data?.uuid}`}
            />
          ) : (
            <img
              style={{
                opacity: isLoaded ? 1 : 0,
                height: "167px",
                width: "278px",
              }}
              onLoad={() => setIsLoaded(true)}
              src={investmentPhoto}
              className="rounded-t-sm "
              alt="realestate"
            />
          )}
        </Link>
        <Link
          to={`/realestate/${data?.uuid}`}
          className="inline-block w-full bg-primse-yellow text-sm font-bold text-primse-gray-font uppercase py-2 text-center rounded-b-sm hover:opacity-80 transition-all duration-200 hover:text-white"
        >
          <strong data-link={`/realestate/${data?.uuid}`}>
            {t("Details")}
          </strong>
        </Link>
      </div>
      <div className="text-primse-gray-font ml-5 mr-2 flex flex-col w-72 whitespace-nowrap font-medium">
        {mode !== "presentation" ? (
          <>
            <div className="text-xl font-bold">
              <strong>{data?.name}</strong>
            </div>
            <div className="text-sm">
              {[data?.sm_location_city, data?.sm_location_street]
                .filter((value) => value)
                .join(", ")}
            </div>
          </>
        ) : null}

        <div className="mt-4 text-sm  flex items-center justify-between">
          <div className="flex items-center">
            <img src={dimensionsSrc} width={20} height={20} alt="size icon" />
            <div className="ml-2.5">
              {data?.area} m<sup>2</sup>
            </div>
          </div>
          <Popup
            trigger={
              <div className="w-12 h-12 cursor-pointer bg-primse-green text-white flex items-center justify-center duration-300 hover:opacity-90">
                <div className="w-8">
                  <CartPlusWhite />
                </div>
              </div>
            }
            flowing
            open={openPopup}
            onOpen={() => setOpenPopup(true)}
            onClose={() => setOpenPopup(false)}
            on="click"
            position="left center"
            hideOnScroll
          >
            <div className="bg-white  text-sm font-normal">
              <div className="flex flex-col">
                <button
                  className="leading-5 my-2 font-bold text-black uppercase bg-primse-green py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                  onClick={(e) => {
                    addToBasket(e, data?.uuid, currentBasketUuid, data?.name);
                    setOpenPopup(false);
                  }}
                >
                  {t("Add to basket")}
                </button>
                <button
                  className="leading-5 my-2 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                  onClick={() => {
                    addFlatToBriefcase(data?.uuid, data?.name, briefcaseClient);
                    setOpenPopup(false);
                  }}
                >
                  {t("Add to client briefcase")}
                </button>
              </div>
            </div>
          </Popup>
        </div>
        <div className="mt-5 text-sm flex items-center">
          <img src={peopleSrc} width={20} height={20} alt="rooms icon" />
          <div className="ml-2.5">{data?.rooms}</div>
        </div>
        <div className="mt-5 bg-primse-mid-gray px-2 py-2 rounded-sm text-white text-xs font-bold uppercase text-center w-full">
          {t("Price")}
          <strong className="text-primse-yellow text-base px-2">
            {editPrice(data?.price)} PLN
          </strong>
        </div>
      </div>
    </div>
  );
};

export default RealestateItem;
