import { useTranslation } from "react-i18next";
import { FaSquare } from "react-icons/all";
import cs from "classnames";
import BriefcaseSearchItemMobile from "../BriefcaseSearchItemMobile";
import { actions } from "../../../../store";
import { toast } from "react-toastify";

const Td = ({ children, className }) => (
  <td className={cs("py-3 px-4 border-b", className)}>{children}</td>
);

const SearchElements = ({ data, selectDefaultModal, handleClientDetails }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="p-4 mt-6 border rounded-xl w-full flex flex-col lg:hidden">
        {data?.map((el) => (
          <BriefcaseSearchItemMobile
            key={el.uuid}
            data={el}
            selectDefaultModal={selectDefaultModal}
          />
        ))}
      </div>
      <table className="w-full hidden text-left lg:block">
        <thead className="font-bold uppercase text-xs flex w-full">
          <tr className="flex w-full">
            <th className="py-4 px-4 border-b w-1/12">ID</th>
            <th className="py-4 px-4 border-b w-2/12">{t("Creation date")}</th>
            <th className="py-4 px-4 border-b w-2/12">{t("Client")}</th>
            <th className="py-4 px-4 border-b w-1/12">{t("City")}</th>
            <th className="py-4 px-4 border-b w-1/12">{t("Phone")}</th>
            <th className="py-4 px-4 border-b w-3/12">{t("E-mail")}</th>
            <th className="py-4 px-4 border-b w-2/12"></th>
          </tr>
        </thead>
        <tbody
          className={cs(
            "text-sm flex flex-col items-center overflow-y-scroll w-full",
            data?.length < 8 ? "justify-start" : "justify-between"
          )}
          style={{ height: "50vh", display: "block" }}
        >
          {data.map((el) => (
            <tr
              key={el.uuid}
              className="flex w-full hover:bg-primse-light-green"
              onClick={() => handleClientDetails(el)}
            >
              <Td className="w-1/12">{el.id}</Td>
              <Td className="w-2/12">{el.created}</Td>
              <Td className="w-2/12">
                {`${el.last_name} ${el.first_name}`}
                {el.status === "1" && (
                  <div className="flex items-center">
                    <FaSquare className="text-primse-green pr-2" />
                    {t("StatusNew")}
                  </div>
                )}
                {el.status === "2" && (
                  <div className="flex items-center">
                    <FaSquare className="text-primse-green pr-2" />
                    {t("Interested")}
                  </div>
                )}
                {el.status === "3" && (
                  <div className="flex items-center">
                    <FaSquare className="text-primse-green pr-2" />
                    {t(`${el.status_name}`)}
                  </div>
                )}
                {el.status === "4" && (
                  <div className="flex items-center">
                    <FaSquare className="text-primse-green pr-2" />
                    {t(`${el.status_name}`)}
                  </div>
                )}

                {el.status === "6" && (
                  <div className="flex items-center">
                    <FaSquare className="text-primse-yellow pr-2" />
                    {t("StatusAgreement")}
                  </div>
                )}
                {el.status === "5" && (
                  <div className="flex items-center">
                    <FaSquare className="text-primse-yellow pr-2" />
                    {t("StatusInterested")}
                  </div>
                )}
              </Td>
              <Td className="w-1/12">Warszawa</Td>
              <Td className="w-1/12">{el.work_phone}</Td>
              <Td className="w-3/12">{el.work_email}</Td>
              <Td className="w-2/12">
                <div className="text-primse-green flex justify-center">
                  <button
                    className="leading-5 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
                    onClick={() => {
                      handleClientDetails(el);
                      try {
                        actions.briefcase.setBriefcaseModal(false);
                        actions.briefcase.setBriefcaseModalSelectClient(false);
                        toast.success(`${t("The briefcase has been changed")}`);
                      } catch (error) {
                        toast.error(`${error}`);
                      }
                    }}
                  >
                    {t("Choose")}
                  </button>
                </div>
              </Td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default SearchElements;
