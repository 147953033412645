import { Link } from "react-router-dom";
import dimensionsSrc from "./assets/dimensions@2x.png";
import peopleSrc from "./assets/people@2x.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BriefCasePlusWhite } from "../Icons";
import PopupTrigger from "../PopupTrigger";
import investmentPhoto from "./noImage.jpeg";
import { editCompletionDate } from "../../utils/searchUtils";
import { editPrice } from "../../utils/numbers";
import { useStoreState } from "easy-peasy";

const Investment = ({ investmentData, aftermarket = false }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { mode } = useStoreState((state) => state.mode);
  const { t } = useTranslation();

  const promotingAgencyOffer =
    investmentData?.promoting_agency_offer == "1" ? true : false;

  return (
    <div className="flex">
      <div className="flex flex-col ">
        <Link
          to={`/investment/${investmentData.uuid}`}
          className="inline-block"
        >
          {!isLoaded && (
            <div
              className="ui placeholder fluid"
              style={{ height: "167px", width: "278px" }}
            ></div>
          )}
          {investmentData?.pictures !== null &&
          investmentData?.pictures[0]?.thumbnail ? (
            <img
              style={{
                opacity: isLoaded ? 1 : 0,
                height: "167px",
                width: "278px",
              }}
              onLoad={() => setIsLoaded(true)}
              src={`${process.env.REACT_APP_IMAGES_URL}${investmentData.pictures[0].thumbnail}`}
              className="rounded-t-sm "
              alt="investment"
              data-link={`/investment/${investmentData.uuid}`}
            />
          ) : (
            <img
              style={{
                opacity: isLoaded ? 1 : 0,
                height: "167px",
                width: "278px",
              }}
              onLoad={() => setIsLoaded(true)}
              src={investmentPhoto}
              className="rounded-t-sm "
              alt="investment"
            />
          )}
        </Link>
        <Link
          to={`/investment/${investmentData.uuid}`}
          className="inline-block w-full bg-primse-yellow text-sm font-bold text-primse-gray-font uppercase py-2 text-center rounded-b-sm hover:opacity-80 transition-all duration-200 hover:text-white"
        >
          <strong data-link={`/investment/${investmentData.uuid}`}>
            {t("Details")}
          </strong>
        </Link>
      </div>
      <div className="text-primse-gray-font ml-5 mr-2 flex flex-col w-72 whitespace-nowrap font-medium">
        {mode !== "presentation" ? (
          <>
            <div className="text-xl font-bold">
              <strong>{investmentData.name}</strong>
            </div>
            {promotingAgencyOffer ? (
              <p className="text-sm mb-0 mt-2 uppercase">
                {t("Offer of promoting agency")}
              </p>
            ) : null}
            <div className="text-sm">
              {[investmentData.city, investmentData.address_street]
                .filter((value) => value)
                .join(", ")}
            </div>
          </>
        ) : null}
        <div className="text-sm">
          {t("Number of locals")}: {investmentData.number_of_offers}
        </div>
        {investmentData.completion_date && (
          <div className="text-sm">
            {t("Completion date")}:{" "}
            {editCompletionDate(investmentData.completion_date, t("Quarter"))}
          </div>
        )}
        <div className="mt-4 text-sm  flex items-center justify-between">
          <div className="flex items-center">
            <img src={dimensionsSrc} width={20} height={20} alt="size icon" />
            <div className="ml-2.5">
              {investmentData.area_from} m<sup>2</sup> {t("to")}{" "}
              {investmentData.area_to} m<sup>2</sup>
            </div>
          </div>
          {investmentData && (
            <PopupTrigger
              uuid={investmentData.uuid}
              name={investmentData.name}
              investmentData={investmentData}
              aftermarket={aftermarket}
              openRequestModal={true}
            >
              <div className="block max-h-0">
                <div className="bg-primse-green ml-3 rounded-sm flex justify-center items-center cursor-pointer transition-all duration-300 hover:opacity-80 w-12 h-12">
                  <div className="w-8">
                    <BriefCasePlusWhite />
                  </div>
                </div>
              </div>
            </PopupTrigger>
          )}
        </div>
        <div className="mt-5 text-sm flex items-center">
          <img src={peopleSrc} width={20} height={20} alt="rooms icon" />
          <div className="ml-2.5">
            {t("from")} {investmentData.rooms_from} {t("to")}{" "}
            {investmentData.rooms_to}
          </div>
        </div>
        <div className="mt-5 bg-primse-mid-gray px-2 py-2 rounded-sm text-white text-xs font-bold uppercase text-center w-full">
          {t("from")}
          <strong className="text-primse-yellow text-base px-2">
            {editPrice(investmentData.price_from)} PLN
          </strong>
          {t("to")}
          <strong className="text-primse-yellow text-base px-2">
            {editPrice(investmentData.price_to)} PLN
          </strong>
        </div>
      </div>
    </div>
  );
};

export default Investment;
