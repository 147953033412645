import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DescriptionRealestate = ({ text }) => {
  const { t } = useTranslation();

  return (
    <div className="my-4 mx-1 px-5 py-6 border rounded-sm">
      <h4 className="text-xl font-bold">{t("Property description")}</h4>
      <div className="w-14 h-1 bg-primse-green" />
      <div className="mt-7 text-lg">
        <p className="whitespace-pre-line" dangerouslySetInnerHTML={{
          __html: text ? text : "Brak opisu"
        }} />
      </div>
    </div>
  )
};

DescriptionRealestate.propTypes = {
  text: PropTypes.string,
};
DescriptionRealestate.defaultProps = {
  text: "Brak opisu",
};

export default DescriptionRealestate;
