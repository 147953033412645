import { useQuery } from "react-query";
import { Popup } from "semantic-ui-react";
import { User, Phone, Mail, MessageCircle } from "react-feather";

import ClientsService from "../../../../services/ClientsService";



const UserInfo = ({ uuid }) => {
    const { data } = useQuery(
        `client-${uuid}`,
        ClientsService.getClient(uuid)
    );
    return (
        <>
            {data ? (<div className="mx-2 flex flex-col items-start lg:flex-row lg:items-end justify-between">
                <div className="mb-4 lg:mb-0 lg:mx-4 flex items-end justify-between items-center">
                    <div className="mx-2 text-primse-green">
                        <User size={16} />
                    </div>
                    {[data?.client.first_name, data?.client.last_name]
                        .filter((value) => value)
                        .join(" ")}
                </div>
                {data.client.work_phone && (
                    <div className="mb-4 lg:mb-0 lg:mx-4 flex items-end justify-between items-center">
                        <div className="mx-2 text-primse-green">
                            <Phone size={16} />
                        </div>
                        {data?.client.work_phone}
                    </div>
                )}
                {data.client.work_email && (
                    <div className="mb-4 lg:mb-0 lg:mx-4 flex items-end justify-between items-center">

                        <div className="mx-2 text-primse-green">
                            <Mail size={16} />
                        </div>
                        {data?.client.work_email}
                    </div>
                )}
                <div className="mb-4 lg:mb-0 lg:mx-4 flex items-end justify-between items-center">
                    {data.client.description && (
                        <Popup
                            trigger={
                                <MessageCircle className="text-primse-green z-10 cursor-pointer" size={16} />
                            }
                            flowing
                            on="hover"
                            position="bottom center"
                        >
                            <div className="bg-white  text-sm font-normal">
                                {data.client.description}
                            </div>
                        </Popup>
                    )}
                </div>
            </div>) : null}
        </>

    )
}

export default UserInfo