import React from "react";
import { Controller } from "react-hook-form";
import { Input } from "semantic-ui-react";
import FilterModal from "./FilterModal";
import { preventFloat, preventNegative } from "../../../../../utils/numbers";
import { useTranslation } from "react-i18next";

const AreaDropdown = ({ control }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name="area"
      render={({ field: { onChange, value } }) => {
        const editFirstNumber = (e) => {
          if (e.nativeEvent.toString() === "[object Event]") {
            let number = e.target.value;

            const addFunction = () => {
              const type = Number(number) < Number(e.target.value) ? -5 : 5;

              onChange([Number(Number(e.target.value) + type), value[1]]);

              e.target.removeEventListener("click", addFunction);
            };

            e.target.addEventListener("click", addFunction);
          } else {
            onChange([Number(Number(e.target.value)), value[1]]);
          }
        };
        const editLastNumber = (e) => {
          if (e.nativeEvent.toString() === "[object Event]") {
            let number = e.target.value;

            const addFunction = () => {
              const type = Number(number) < Number(e.target.value) ? -5 : 5;

              onChange([value[0], Number(Number(e.target.value) + type)]);

              e.target.removeEventListener("click", addFunction);
            };

            e.target.addEventListener("click", addFunction);
          } else {
            onChange([value[0], Number(Number(e.target.value))]);
          }
        };

        return (
          <FilterModal
            title={t("Surface")}
            onReset={() => {
              onChange(["", ""]);
            }}
          >
            <div className="flex items-center">
              <Input
                placeholder={t("from")}
                type="number"
                min="0"
                // step="5"
                fluid
                defaultValue
                value={value[0]}
                className="flex-1"
                onChange={(e) => {
                  editFirstNumber(e);
                }}
                onKeyPress={(e) => {
                  preventNegative(e);
                  preventFloat(e);
                }}
              />
              <span className="mx-4">-</span>
              <Input
                placeholder={t("to")}
                type="number"
                min="0"
                // step="5"
                fluid
                value={value[1]}
                className="flex-1"
                onChange={(e) => editLastNumber(e)}
                onKeyPress={(e) => {
                  preventNegative(e);
                  preventFloat(e);
                }}
              />
            </div>
          </FilterModal>
        );
      }}
    />
  );
};

export default AreaDropdown;
