import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";
import ClientsService from "../../../../../../services/ClientsService";
import Loader from "../../../../../../components/Loader";

import { toast } from "react-toastify";

const EditClientContent = ({
  first_name,
  last_name,
  work_email,
  work_phone,
  description,
  uuid,
  closedModal,
  handleRefresh,
}) => {
  const [clientFirstName, setClientFirstName] = useState(first_name);
  const [clientLastName, setClientLastName] = useState(last_name);
  const [clientEmail, setClientEmail] = useState(work_email);
  const [clientPhone, setClientPhone] = useState(work_phone);
  const [clientComments, setClientComments] = useState(description);
  const [loading, setLoading] = useState(false);

  const [formFirstNameError, setFormFirstNameError] = useState(false);
  const [formLastNameError, setFormLastNameError] = useState(false);
  const [formEmailError, setFormEmailError] = useState(false);
  const [formPhoneError, setFormPhoneError] = useState(false);

  const { t } = useTranslation();

  const handleClientFirstName = (event) => {
    setFormFirstNameError(false);
    let value = event.target.value;
    setClientFirstName(value);
  };

  const handleClientLastName = (event) => {
    setFormLastNameError(false);
    let value = event.target.value;
    setClientLastName(value);
  };

  const handleClientEmail = (event) => {
    setFormEmailError(false);
    let value = event.target.value;
    setClientEmail(value);
  };

  const handleClientPhone = (event) => {
    setFormPhoneError(false);
    let value = event.target.value;
    setClientPhone(value);
  };
  const handleClientComments = (event) => {
    let value = event.target.value;
    setClientComments(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      clientFirstName !== "" &&
      clientLastName !== "" &&
      clientEmail !== "" &&
      clientPhone !== ""
    ) {
      setLoading(true);
      ClientsService.updateClient({
        first_name: clientFirstName,
        last_name: clientLastName,
        work_phone: clientPhone,
        work_email: clientEmail,
        description: clientComments,
        client_uuid: uuid,
      })
        .then(() => {
          toast.success(t("Successfully edited client"));
          setLoading(false);
          handleRefresh(true);
          closedModal();
        })
        .catch((err) => {
          const form = err.response.data.form.error_messages;
          if (form.work_phone) {
            setFormPhoneError(true);
            toast.error(t("Incorrect phone number"));
          }
          form.work_email && toast.error(t("Incorrect e-mail"));
          !form.work_phone &&
            !form.work_email &&
            toast.error(t("An error occurred while editing the data"));
          setLoading(false);
        });
    } else {
      const errors = [];
      if (clientFirstName === "") {
        setFormFirstNameError(true);
        errors.push("First name");
      }
      if (clientLastName === "") {
        setFormLastNameError(true);
        errors.push("Last name");
      }
      if (clientEmail === "") {
        setFormEmailError(true);
        errors.push("Email");
      }
      if (clientPhone === "") {
        setFormPhoneError(true);
        errors.push("Phone");
      }
      toast.error(
        <div>
          {t("None of the fields can be empty")}: <br />
          {errors.map((el, index) => (
            <div key={index}>
              - {t(el)} <br />
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <>
      <Form className="flex items-center flex-wrap" onSubmit={handleSubmit}>
        <div className="w-1/2 flex flex-col">
          <label className="font-bold px-2 pt-2">
            {t("First name")} <span className="text-red-500">*</span>
          </label>
          <Form.Input
            className="p-2"
            onChange={handleClientFirstName}
            value={clientFirstName}
            error={formFirstNameError}
          >
            <input placeholder={t("First name")} />
          </Form.Input>
        </div>
        <div className="w-1/2 flex flex-col">
          <label className="font-bold px-2 pt-2">
            {t("Last name")} <span className="text-red-500">*</span>
          </label>
          <Form.Input
            className="p-2"
            onChange={handleClientLastName}
            value={clientLastName}
            error={formLastNameError}
          >
            <input placeholder={t("Last name")} />
          </Form.Input>
        </div>
        <div className="w-1/2 flex flex-col">
          <label className="font-bold px-2 pt-2">
            {t("E-mail")} <span className="text-red-500">*</span>
          </label>
          <Form.Input
            className="p-2"
            onChange={handleClientEmail}
            value={clientEmail}
            error={formEmailError}
          >
            <input placeholder={t("E-mail")} />
          </Form.Input>
        </div>
        <div className="w-1/2 flex flex-col">
          <label className="font-bold px-2 pt-2">
            {t("Phone number")} <span className="text-red-500">*</span>
          </label>
          <Form.Input
            className="p-2"
            onChange={handleClientPhone}
            value={clientPhone}
            error={formPhoneError}
          >
            <input placeholder={t("Phone number")} />
          </Form.Input>
        </div>
        <Form.TextArea
          label={t("Comments")}
          placeholder={t("Comments")}
          value={clientComments}
          className="w-full p-2"
          onChange={handleClientComments}
        />
        <div className="w-full flex justify-end">
          <button
            className="m-2 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
            type="submit"
          >
            {t("Save")}
          </button>
        </div>
        {loading && <Loader />}
      </Form>
    </>
  );
};

export default EditClientContent;
