import { useStoreState } from "easy-peasy";

const useUserRole = () => {
  const userPrimaryRole = useStoreState(
    (state) => state.auth?.user?.primaryRole
  );
  const userEmployment = useStoreState((state) => state.auth?.user?.employment);

  const isAdmin = () => {
    if (userPrimaryRole === "Admin" || userPrimaryRole === "Top Admin") {
      return true;
    } else return false;
  };

  const isManager = () => {
    if (isAdmin()) return true;
    if (userPrimaryRole === "Manager") return true;
    else return false;
  };

  const isAgent = () => {
    if (isAdmin()) return true;
    if (userEmployment?.employment_type === "Agency") return true;
    else return false;
  };

  const isDeveloper = () => {
    if (isAdmin()) return true;
    if (userEmployment?.employment_type === "Deweloper") return true;
    else return false;
  };

  const isMetrohouse = () => {
    if (userEmployment?.agency_type === "Metrohouse office") return true;
    else return false;
  };

  const canSeeSecondaryMarket = () => {
    if (userEmployment?.secondary_market === true) return true;
    else return false;
  };

  return {
    isAdmin,
    isAgent,
    isDeveloper,
    isMetrohouse,
    isManager,
    canSeeSecondaryMarket,
  };
};

export default useUserRole;
