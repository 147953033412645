import { actions } from "../store";
import React from "react";
import i18n from "../i18n";
import cs from "classnames";

export const searchUtils = (basketsList, value) => {
  let result = {};
  result = basketsList.filter((data) => {
    return data.uuid?.search(value) !== -1;
  });

  actions.baskets.setCurrentBasket(result[0].uuid);
};

export const clearDuplicatesFromArray = (array) => {
  return Array.from(new Set(array.map((item) => JSON.stringify(item))))
    .map((string) => JSON.parse(string))
    .sort(function (a, b) {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    });
};

export const sortCreatedListDown = (a, b) => {
  const aDate = new Date(a.created || a.date_created);
  const bDate = new Date(b.created || b.date_created);
  if (bDate < aDate) {
    return -1;
  }
  if (bDate > aDate) {
    return 1;
  }
  return 0;
};

export const handleSortDataDown = (arr, setData) => {
  if (arr) {
    const sortArray = arr.sort(sortCreatedListDown);
    setData((prevState) => ({
      ...prevState,
      data: sortArray,
    }));
  }
};

export const Td = ({ children, className }) => (
  <td className={cs("py-2 px-2 border-b overflow-auto", className)}>
    {children}
  </td>
);

export const editCompletionDate = (el, translate) => {
  if (!el || el === null || el === undefined) return i18n.t("No data");
  const month = el.slice(5, 7);
  const year = el.slice(0, 4);

  const newEl = [];

  if (month < 4) {
    newEl[0] = "I";
  } else if (month >= 4 && month < 7) {
    newEl[0] = "II";
  } else if (month >= 7 && month < 10) {
    newEl[0] = "III";
  } else {
    newEl[0] = "IV";
  }

  newEl[1] = translate;
  newEl[2] = year;

  return newEl.join(" ");
};
