import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import {
  applicationStatusIcon,
  applicationStatusData,
  colorStatus,
} from "../../../../utils/statusUtils";

const RequestsItemMobile = ({
  data: {
    date,
    investment_name,
    developer_name,
    status,
    uuid,
    client_name_first,
    client_name_last,
    user_name_first,
    user_name_last,
    date_modified,
  },
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  function handleClientDetails(link) {
    history.push(`/request/${link}`);
  }

  return (
    <div
      onClick={() => handleClientDetails(uuid)}
      className="py-3 border-b flex flex-wrap"
    >
      <div className="flex w-full">
        <div className="w-10/12 font-bold text-lg">{`${client_name_last} ${client_name_first}`}</div>
      </div>
      <div>
        <div>
          <span className="font-bold">{t("Post date")}:</span>{" "}
          <Moment format="DD.MM.YYYY">{date}</Moment>
        </div>
        <div>
          <span className="font-bold">{t("Change status")}:</span>{" "}
          <Moment format="DD.MM.YYYY">
            {date_modified ? date_modified.slice(0, 19) : date}
          </Moment>
        </div>
        <div>
          <span className="font-bold">{t("Doradca")}:</span> {user_name_last}{" "}
          {user_name_first}
        </div>
        <div>
          <span className="font-bold">{t("The investment")}:</span>{" "}
          {investment_name}
        </div>
        <div>
          <span className="font-bold">{t("Developer")}:</span> {developer_name}
        </div>
        <div className="flex items-center">
          <span className="font-bold mr-1">{t("Status")}:</span>{" "}
          <span className="-mr-2">
            {status && applicationStatusIcon(status)}
          </span>
          <span className={cs("font-bold flex flex-col", colorStatus(status))}>
            {status && applicationStatusData(status)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default RequestsItemMobile;
