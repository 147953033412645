import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";

import Loader from "../../../components/Loader";
import TransactionsServices from "../../../services/TransactionsServices";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function SingleActionModal({
  accept,
  watchForm,
  buttonName = "Decline",
  text = "",
  transactionUuid,
  create = false,
  requestUuid,
  cancel,
  nextStatus = false,
  discard,
  routerPush = true,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const handleStatusChange = () => {
    setLoading(true);

    const options = {
      ...watchForm(),
    };

    if (cancel) {
      setOpen(false);
      if (routerPush) {
        history.push("/transactions");
      }
      return;
    }

    if (!create) {
      TransactionsServices.updateTransaction(transactionUuid, {
        ...options,
        realestate_id:
          options?.realestate_name === "other"
            ? ""
            : options?.realestate_id
            ? options?.realestate_id * 1
            : "",
        client_agreement_at: options?.client_agreement_at
          ? options?.client_agreement_at
          : "",
        parking_extra_1_tax_type_id: options?.parking_extra_1_tax_type_id * 1,
        parking_extra_2_tax_type_id: options?.parking_extra_2_tax_type_id * 1,
        parking_tax_type_id: options?.parking_tax_type_id * 1,
        realestate_tax_type_id: options?.realestate_tax_type_id * 1,
        storage_extra_1_tax_type_id: options?.storage_extra_1_tax_type_id * 1,
        storage_extra_2_tax_type_id: options?.storage_extra_2_tax_type_id * 1,
        storage_tax_type_id: options?.storage_tax_type_id * 1,
        parking_extra_1_is_paid: options?.parking_extra_1_is_paid ? 1 : 0,
        parking_extra_2_is_paid: options?.parking_extra_2_is_paid ? 1 : 0,
        parking_is_paid: options?.parking_is_paid ? 1 : 0,
        realestate_is_paid: options?.realestate_is_paid ? 1 : 0,
        storage_extra_1_is_paid: options?.storage_extra_1_is_paid ? 1 : 0,
        storage_extra_2_is_paid: options?.storage_extra_2_is_paid ? 1 : 0,
        storage_is_paid: options?.storage_is_paid ? 1 : 0,
        status_id: nextStatus
          ? `${options?.status_id * 1 + nextStatus}`
          : discard
          ? `${options?.status_id * 1 + discard}`
          : `${options?.status_id * 1}`,
      })
        .then(() => {
          toast.success(`${t("The transaction has been saved")}`);
          if (routerPush) {
            history.push("/transactions");
          }
        })
        .then(() => setOpen(false))
        .catch(() => toast.error(`${t("Wystąpił błąd podczas zapisu")}`))
        .finally(() => setLoading(false));
    }
    if (create) {
      const currentDate = new Date();
      TransactionsServices.createTransactions(
        requestUuid,
        {
          ...options,
          query_id: requestUuid,
          client_agreement_at: options?.client_agreement_at
            ? options?.client_agreement_at
            : `${currentDate.getFullYear()}-${
                currentDate.getMonth() + 1
              }-${currentDate.getDate()}`,
          realestate_id:
            options?.realestate_name === "other"
              ? ""
              : options?.realestate_id
              ? options?.realestate_id * 1
              : "",
          parking_extra_1_tax_type_id: options?.parking_extra_1_tax_type_id * 1,
          parking_extra_2_tax_type_id: options?.parking_extra_2_tax_type_id * 1,
          parking_tax_type_id: options?.parking_tax_type_id * 1,
          realestate_tax_type_id: options?.realestate_tax_type_id * 1,
          storage_extra_1_tax_type_id: options?.storage_extra_1_tax_type_id * 1,
          storage_extra_2_tax_type_id: options?.storage_extra_2_tax_type_id * 1,
          storage_tax_type_id: options?.storage_tax_type_id * 1,
          parking_extra_1_is_paid: options?.parking_extra_1_is_paid ? 1 : 0,
          parking_extra_2_is_paid: options?.parking_extra_2_is_paid ? 1 : 0,
          parking_is_paid: options?.parking_is_paid ? 1 : 0,
          realestate_is_paid: options?.realestate_is_paid ? 1 : 0,
          storage_extra_1_is_paid: options?.storage_extra_1_is_paid ? 1 : 0,
          storage_extra_2_is_paid: options?.storage_extra_2_is_paid ? 1 : 0,
          storage_is_paid: options?.storage_is_paid ? 1 : 0,

          // required
          invoice_tax_type_id: options?.invoice_tax_type_id
            ? options.invoice_tax_type_id
            : 1,
          invoice_payment_type_id: options?.invoice_payment_type_id
            ? options.invoice_payment_type_id
            : 1,

          developer_invoice_tax_type_id: options?.developer_invoice_tax_type_id
            ? options.developer_invoice_tax_type_id
            : 1,
          developer_invoice_payment_type_id: options?.developer_invoice_payment_type_id
            ? options.developer_invoice_payment_type_id
            : 1,

          status_id: nextStatus ? `2` : "1",
        }

        // status: `${accept ? "accept" : "reject"}`,
      )
        .then(() => {
          toast.success(`${t("The transaction has been added")}`);
          if (routerPush) {
            history.push("/transactions");
          }
        })
        .then(() => setOpen(false))
        .catch(() =>
          toast.error(`${t("An error occurred while adding a transaction")}`)
        )
        .finally(() => setLoading(false));
    }
  };

  return (
    <Modal
      open={open}
      trigger={
        accept ? (
          <button
            onClick={() => {
              if (
                // nextStatus === 1 &&
                !watchForm().client_reservation_agreement_at
              ) {
                return toast.error(
                  t(
                    "The date of signing Reservation agreement date field is missing"
                  )
                );
              }

              setOpen(true);
            }}
            className="leading-5 w-56 mx-2 font-bold text-white uppercase bg-primse-green py-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
          >
            {t(buttonName)}
          </button>
        ) : (
          <button
            onClick={() => {
              setOpen(true);
            }}
            className="leading-5 w-56 mx-2 font-bold text-black uppercase bg-primse-yellow py-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
          >
            {t(buttonName)}
          </button>
        )
      }
    >
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between">
          <h3 className="text-xl font-bold">{`${t(text)} `}</h3>
          <AiFillCloseCircle
            onClick={() => setOpen(false)}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>

      <Modal.Content>
        <div className="flex justify-between lg:justify-end">
          <button
            onClick={() => handleStatusChange()}
            className="leading-5 w-44 mx-2 font-bold text-white uppercase bg-primse-green py-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
          >
            {t("Confirm")}
          </button>
          <button
            onClick={() => setOpen(false)}
            className="leading-5 w-44 mx-2 font-bold text-black uppercase bg-primse-yellow py-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
          >
            {t("Cancel")}
          </button>
        </div>
      </Modal.Content>
      {loading && <Loader />}
    </Modal>
  );
}

export default SingleActionModal;
