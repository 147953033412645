import PropTypes from "prop-types";
import { Square, CheckSquare } from "react-feather";
import React, { useState } from "react";
import InvestmentItem from "./InvestmentItem";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import NewRequestModal from "../../../../modals/NewRequestModal";
import cs from "classnames";
import ButtonNavLink from "../../../../components/ButtonNavLink";
import { colorStatus } from "../../../../utils/statusUtils";
import { editCompletionDate } from "../../../../utils/searchUtils";
import { toast } from "react-toastify";

const BarsArrowDown = ({ onClick, showIcon = true }) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke={showIcon ? "#7eba27" : "rgba(0,0,0,0)"}
      className="w-8 h-8 text-primse-green"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25"
      />
    </svg>
  );
};
const BarsArrowUp = ({ onClick, showIcon = true }) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke={showIcon ? "#7eba27" : "rgba(0,0,0,0)"}
      className="w-8 h-8"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12"
      />
    </svg>
  );
};

const ClientInvestment = ({
  investmentData: {
    developer_name,
    investment_name,
    realestates,
    query_status,
    expire,
    query_uuid,
    investment_uuid,
    investment_status,
    investment_completion_date,
    investment_promoting_agency_offer,
  },
  setInvestmentsList,
  investmentsList,
  clientUuid,
  handleRefresh,
  handleRefreshData,
  realestateList,
  setRealestateList,
  handleDeleteInvestment,
  handleDeleteRealestate,
  enableSendRequest,
}) => {
  const [detailsClicked, setDetailsClicked] = useState(false);
  const [isChosen, setIsChosen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  function handleApplicationDetails(uuid) {
    history.push(`/request/${uuid}`);
  }

  const handleModalOpen = (value) => {
    setModalOpen(value);
  };

  const setDetailsAndChosen = (e) => {
    if (e.target.tagName === "CheckSquare" || e.target.tagName === "Square")
      setChosen();
    if (
      e.target.tagName === "DIV" ||
      e.target.tagName === "H3" ||
      e.target.tagName === "P"
    )
      setDetails();
  };

  const setDetails = () => {
    if (detailsClicked === false) {
      setDetailsClicked(true);
    } else setDetailsClicked(false);
  };

  const setChosen = () => {
    if (!isChosen) {
      setIsChosen(true);
      setInvestmentsList([
        ...investmentsList,
        {
          investment_uuid: investment_uuid,
          developer_name: developer_name,
          investment_name: investment_name,
          query_status: query_status,
          investment_promoting_agency_offer: investment_promoting_agency_offer,
        },
      ]);
    } else {
      setIsChosen(false);
      handleDeleteInvestment(investment_uuid);
      handleRefresh(true);
    }
  };

  const applicationStatusData = (status) => {
    let result = {};
    const statusArr = [
      { value: "Accepted", label: `${t("Accepted")}` },
      { value: "Awaiting", label: `${t("Awaiting")}` },
      {
        value: "Accepted conditionally",
        label: `${t("Accepted conditionally")}`,
      },
      {
        value: "Accepted duplicate",
        label: `${t("Accepted duplicate")}`,
      },
      { value: "Rejected", label: `${t("Rejected")}` },
      { value: "Expired", label: `${t("Expired")}` },
    ];
    statusArr.map((e) => {
      if (e.value === status) {
        result = e;
      }
    });
    return result;
  };

  return (
    <div className="border mb-6">
      <div
        className="py-2 px-2 my-2 mx-8 flex flex-col md:flex-row items-end justify-between items-center cursor-pointer"
        onClick={setDetailsAndChosen}
      >
        <div className="flex items-center md:items-center w-full md:w-auto justify-center">
          {realestates?.length !== 0 ? (
            <div className="mx-2 text-primse-green cursor-pointer">
              {detailsClicked === true ? (
                <BarsArrowUp size={40} onClick={setDetails} />
              ) : (
                <BarsArrowDown size={40} onClick={setDetails} />
              )}
            </div>
          ) : (
            <div className="mx-2 text-white cursor-pointer">
              <BarsArrowDown
                showIcon={realestates?.length > 0}
                size={40}
                onClick={setDetails}
              />
            </div>
          )}
          <div className="block mx-2 text-primse-green cursor-pointer">
            {isChosen === true ? (
              <CheckSquare size={22} onClick={setChosen} />
            ) : (
              <Square size={22} onClick={setChosen} />
            )}
          </div>
          <div className="md:mx-2 py-2 px-2 flex flex-col w-full md:w-auto items-center md:items-start text-center md: text-start">
            <h3 className="font-bold mb-1 hidden md:block">
              {investment_status === "On sale" ? (
                <ButtonNavLink
                  uuid={investment_uuid}
                  link={`/investment/${investment_uuid}`}
                  name={`${investment_name}`}
                />
              ) : (
                investment_name
              )}
            </h3>
            <h3 className="font-bold mb-1 block md:hidden">
              {`${investment_name}`}
            </h3>
            <p className="text-s mb-1">{developer_name}</p>
            {investment_completion_date && (
              <p className="text-s">
                {t("Completion date")}:{" "}
                {editCompletionDate(investment_completion_date, t("Quarter"))}
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="hidden lg:flex justify-between items-start md:items-center w-full ">
            {query_uuid ? (
              <div className="mx-2">
                <h3 className="text-xl font-bold">
                  {t("Request status")}:{" "}
                  <span
                    className={cs(
                      query_status === "Awaiting" && "text-yellow-400",
                      query_status === "Accepted conditionally" &&
                        "text-yellow-400",
                      query_status === "Accepted" && "text-primse-green",
                      query_status === "Rejected" && "text-red-500",
                      query_status === "Expired" && "text-red-500"
                    )}
                  >
                    {applicationStatusData(query_status).label}
                  </span>
                </h3>
              </div>
            ) : null}
            {query_uuid ? (
              <div className="mx-2">
                <h3 className="text-xl font-bold">
                  {t("Valid until")}:{" "}
                  <span className="text-primse-green">
                    {expire ? expire : `${t("No data")}`}
                  </span>
                </h3>
              </div>
            ) : null}{" "}
            <div className="mx-2">
              {query_uuid ? (
                <div className="">
                  <button
                    className="w-44 uppercase font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
                    onClick={() => handleApplicationDetails(query_uuid)}
                  >
                    {t("Details")}
                  </button>
                </div>
              ) : null}
              {!query_uuid ? (
                <div className=" w-full" onClick={(e) => e.stopPropagation()}>
                  <button
                    onClick={() => {
                      if (enableSendRequest?.error) {
                        toast.error(
                          `${t(
                            "No customer data completed"
                          )}: (${enableSendRequest?.fields
                            ?.map((err) => t(err))
                            ?.join(", ")})`
                        );
                      } else {
                        setModalOpen(!modalOpen);
                      }
                    }}
                    className="w-44 uppercase font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
                  >
                    {t("Report")}
                  </button>
                </div>
              ) : null}
              {realestates?.length > 0 ? (
                <button
                  onClick={() => {
                    if (enableSendRequest?.error) {
                      toast.error(
                        `${t(
                          "No customer data completed"
                        )}: (${enableSendRequest?.fields
                          ?.map((err) => t(err))
                          ?.join(", ")})`
                      );
                    } else {
                      setDetailsClicked((prev) => !prev);
                    }
                  }}
                  className="w-44 mt-2 uppercase font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
                >
                  {detailsClicked ? t("Hide premises") : t("Show premises")}
                </button>
              ) : null}
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start justify-between md:hidden w-full">
          {query_uuid ? (
            <div className="mx-2">
              <h3 className="text-base font-bold">
                {t("Request status")}:
                <span className={cs(colorStatus(query_status))}>
                  {applicationStatusData(query_status).label}
                </span>
              </h3>
            </div>
          ) : null}
          {query_uuid ? (
            <div className="mx-2 py-2 ">
              <h3 className="text-base font-bold">
                {t("Valid until")}:{" "}
                <span className="text-primse-green">
                  {expire ? expire : `${t("No data")}`}
                </span>
              </h3>
            </div>
          ) : null}
          {query_uuid ? (
            <div className="mx-0 md:mx-2 py-2 w-full md:w-80">
              <button
                className=" uppercase w-full font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
                onClick={() => handleApplicationDetails(query_uuid)}
              >
                {t("Details")}
              </button>
            </div>
          ) : null}
          {!query_uuid ? (
            <div
              className="mx-0 md:mx-2 py-2 w-full md:w-80"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={() => {
                  if (enableSendRequest?.error) {
                    toast.error(
                      `${t(
                        "No customer data completed"
                      )}: (${enableSendRequest?.fields
                        ?.map((err) => t(err))
                        ?.join(", ")})`
                    );
                  } else {
                    setModalOpen(!modalOpen);
                  }
                }}
                className=" uppercase  w-full font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
              >
                {t("Report")}
              </button>
            </div>
          ) : null}
          {realestates?.length > 0 ? (
            <button
              onClick={() => {
                if (enableSendRequest?.error) {
                  toast.error(
                    `${t(
                      "No customer data completed"
                    )}: (${enableSendRequest?.fields
                      ?.map((err) => t(err))
                      ?.join(", ")})`
                  );
                } else {
                  setDetailsClicked((prev) => !prev);
                }
              }}
              className=" uppercase  w-full font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
            >
              {t("Pokaz lokale")}
            </button>
          ) : null}
        </div>
      </div>
      {detailsClicked &&
        realestates.map((realeastete) => (
          <InvestmentItem
            key={realeastete.id}
            handleRefreshData={handleRefreshData}
            investmentItem={realeastete}
            briefcase_uuid={clientUuid}
            investment_name={investment_name}
            realestateList={realestateList}
            setRealestateList={setRealestateList}
            handleDeleteRealestate={handleDeleteRealestate}
            handleRefresh={handleRefresh}
            investment_status={investment_status}
          />
        ))}
      <NewRequestModal
        investment_uuid={investment_uuid}
        clientUuid={clientUuid}
        developerName={developer_name}
        investmentName={investment_name}
        modalOpen={modalOpen}
        setModalOpen={handleModalOpen}
        handleRefresh={handleRefresh}
      />
    </div>
  );
};

export default ClientInvestment;

ClientInvestment.propTypes = {
  name: PropTypes.string,
  company: PropTypes.string,
  investment_uuid: PropTypes.string,
};
