import { createStore, persist } from "easy-peasy";
import authStore from "./auth";
import briefcaseStore from "./briefcaseStore";
import basketStore from "./basketStore";
import modeStore from "./modeStore";
import loadingStore from "./loadingStore";
import modalsStore from "./modals";
import versionStore from "./versionStore";
import investmentsStoreData from "./investments";
import dictionariesStore from "./dictionaries";
import filtersStore from "./filtersStore";

const store = createStore({
  auth: persist(authStore, {
    deny: ["isLoginModalOpen"],
  }),
  briefcase: persist(briefcaseStore),
  baskets: persist(basketStore),
  mode: persist(modeStore),
  isLoading: loadingStore,
  modals: persist(modalsStore),
  version: versionStore,
  investments: persist(investmentsStoreData),
  dictionaries: persist(dictionariesStore),
  filters: persist(filtersStore, { storage: "localStorage" }),
});

export default store;

export const actions = store.getActions();
export const state = store.getState();
