import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { editPrice } from "../../../../utils/numbers";
import dimensionsSrc from "../../../../components/Investment/assets/dimensions@2x.png";
import peopleSrc from "../../../../components/Investment/assets/people@2x.png";
import investmentPhoto from "./noImage.jpeg";
import ButtonAddToBriefcase from "../../../Investemnt/components/ButtonAddToBriefcase";

const FlatInvestmentMobile = ({ investmentData }) => {

  const [isLoaded, setIsLoaded] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      {!isLoaded && (
        <div className="ui fluid placeholder w-72 h-80 m-2 mb-5"></div>
      )}
      {investmentData && (
        <div
          style={{ opacity: isLoaded ? 1 : 0 }}
          className="border  xl:w-72 m-2 mb-5  relative"
        >
          {investmentData?.pictures !== null &&
          investmentData?.pictures[0]?.thumbnail ? (
            <div className="relative">
              <div className="absolute top-0 right-0">
                <ButtonAddToBriefcase
                  type="icon"
                  uuid={investmentData.uuid}
                  name={investmentData.name}
                  investment={true}
                />
              </div>
              <img
                onLoad={() => setIsLoaded(true)}
                onClick={() =>
                  history.push(`/investment/${investmentData?.uuid}`)
                }
                className="w-full cursor-pointer"
                src={`${process.env.REACT_APP_IMAGES_URL}${investmentData?.pictures[0]?.thumbnail}`}
                alt="flat"
              />{" "}
            </div>
          ) : (
            <div className="relative">
              <div className="absolute top-0 right-0">
                <ButtonAddToBriefcase
                  type="icon"
                  uuid={investmentData.uuid}
                  name={investmentData.name}
                  investment={true}
                />
              </div>
              <img
                onLoad={() => setIsLoaded(true)}
                onClick={() =>
                  history.push(`/investment/${investmentData?.uuid}`)
                }
                className="w-full cursor-pointer"
                src={investmentPhoto}
                alt="flat"
              />
            </div>
          )}
          <div className="text-primse-gray-font mx-5 my-2 flex flex-col  whitespace-nowrap font-medium">
            <div className="text-xl font-bold">
              <strong>{investmentData.name}</strong>
            </div>
            <div className="text-sm">
              {[investmentData.city, investmentData.address_street]
                .filter((value) => value)
                .join(", ")}
            </div>
            <div className="text-sm">
              {t("Number of locals")}: {investmentData.number_of_offers}
            </div>
            <div className="text-primse-green mt-5">
              od {editPrice(investmentData.price_from)} PLN z m<sup>2</sup>
            </div>
            <div className="mt-5 text-sm flex items-center">
              <img src={dimensionsSrc} width={20} height={20} alt="size icon" />
              <div className="ml-2.5">
                {investmentData.area_from} m<sup>2</sup> {t("to")}{" "}
                {investmentData.area_to} m<sup>2</sup>
              </div>
            </div>
            <div className="mt-5 text-sm flex items-center">
              <img src={peopleSrc} width={20} height={20} alt="rooms icon" />
              <div className="ml-2.5">
                {t("from")} {investmentData.rooms_from} {t("to")}{" "}
                {investmentData.rooms_to}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FlatInvestmentMobile;
