import { action, computed } from "easy-peasy";

const briefcaseStore = {
  briefcaseClient: null,
  briefcaseModalOpen: false,
  briefcaseInfoPopup: false,
  briefcaseModalSelectClient: false,
  briefcaseChooseClientToRequest: false,
  briefcaseAddMobile: null,

  setBriefcaseClient: action((state, payload) => {
    state.briefcaseClient = payload;
  }),
  setBriefcaseInfoPopup: action((state, payload) => {
    state.briefcaseInfoPopup = payload;
  }),
  setBriefcaseModalSelectClient: action((state, payload) => {
    state.briefcaseModalSelectClient = payload;
  }),
  clearClient: action((state) => {
    state.briefcaseClient = null;
  }),
  setBriefcaseModal: action((state, setOpen) => {
    state.briefcaseModalOpen = setOpen;
  }),
  getBriefcaseClient: computed((state) => !!state.briefcaseClient),

  setBriefcaseChooseClientToRequest: action((state, setOpen) => {
    state.briefcaseChooseClientToRequest = setOpen;
  }),
  setBriefcaseAddMobile: action((state, setOpen) => {
    state.briefcaseAddMobile = setOpen;
  }),
};

export default briefcaseStore;
