import { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import cs from "classnames";

const ButtonNavLink = ({
  uuid,
  link,
  name,
  classNameProps,
  handleButtonSecondaryMarket = () => {},
}) => {
  const [linkPage, setLinkPage] = useState("");

  useEffect(() => {
    if (name === "Rynek wtórny") {
      setLinkPage("/realestates/?market=Secondary%20market");
    } else {
      setLinkPage(link);
    }
  }, []);

  return (
    <NavLink
      onClick={(e) => e.stopPropagation()}
      to={linkPage}
      key={uuid}
      exact
      activeClassName={name === "Rynek wtórny" ? "" : "active-nav"}
      component={({ className }) => {
        return (
          <li
            data-link={link}
            onClick={(e) => e.stopPropagation()}
            className={cs(
              "py-3 xl:py-0  -mb-1  nav max-w-max list-none  ",
              className
            )}
          >
            <Link
              onClick={(e) => {
                e.stopPropagation();
                if (name === "Rynek wtórny") {
                  handleButtonSecondaryMarket();
                }
              }}
              to={linkPage}
              className={cs("  text-black hover:text-black", classNameProps)}
            >
              <span data-link={link}>{name}</span>
            </Link>
          </li>
        );
      }}
    ></NavLink>
  );
};

export default ButtonNavLink;
