import axios from "../utils/axios";

const TransactionsServices = {
  getTransactions: async () => {
    return await axios
      .get(`/transaction-index`)
      .then((res) => res.data.payload);
  },
  getTransactionDetails: (uuid) => async () => {
    return await axios
      .get(`/transaction-view/${uuid}`)
      .then((res) => res.data.payload);
  },
  getTransactionHistory: (uuid) => async () => {
    return await axios
      .get(`/transaction-history/${uuid}`)
      .then((res) => res.data.payload);
  },

  createTransactions: async (uuid, data) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (typeof value === "number") {
        if (isNaN(value)) {
          //
        } else {
          formData.append(key, value);
        }
      } else {
        formData.append(key, value);
      }
    }
    formData.append("developer_invoice_document", new File([null], ""));
    formData.append("invoice_document", new File([null], ""));

    return await axios.post(`/transaction-create`, formData).then((res) => res);
  },
  updateTransaction: async (uuid, data) => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (typeof value === "number") {
        if (isNaN(value)) {
          //
        } else {
          formData.append(key, value);
        }
      } else {
        formData.append(key, value);
      }
    }

    return await axios
      .post(`/transaction-update/${uuid}`, formData)
      .then((res) => res)
      .catch((err) => err.response);
  },
};

export default TransactionsServices;
