import cs from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { ChevronDown, ChevronUp, X, MessageCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Dropdown, Input, Pagination, Popup } from "semantic-ui-react";
import Container from "../../../components/Container";
import { BiSearchAlt2 } from "react-icons/all";
import ClientsItemMobile from "./../ClientsItemMobile";
import ClientsModal from "./../ClientsModal";
import { Overlay } from "../../../components/Overlay";
import ClientsService from "../../../services/ClientsService";
import NewsSection from "../../../components/NewsSection";
import ButtonNavLink from "../../../components/ButtonNavLink";
import { useStoreActions, useStoreState } from "easy-peasy";
import PopupClients from "./PopupClients";
import {
  clearDuplicatesFromArray,
  handleSortDataDown,
  Td,
} from "../../../utils/searchUtils";

const ClientsView = ({ data, handleRefresh }) => {
  const [mouseHover, setMouseHover] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [searchedSurname, setSearchedSurname] = useState("");
  const [searchedMobile, setSearchedMobile] = useState("");
  const [searchedEmail, setSearchedEmail] = useState("");
  const [searchedOriginName, setSearchedOriginName] = useState("");
  const [searchedCreatedAfter, setSearchedCreatedAfter] = useState("");

  const [searchedCreatedBefore, setSearchedCreatedBefore] = useState("");
  const [sorted, setSorted] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const userAgencyType = useStoreState((state) => state.auth.user?.agencyType);
  const history = useHistory();
  const { t } = useTranslation();

  const setClientFilters = useStoreActions(
    (state) => state.filters.setClientFilter
  );
  const clientFilters = useStoreState((state) => state.filters.clientFilter);

  const { user } = useStoreState(
    (state) => state.dictionaries.dictionariesList
  );

  function handleClientDetails(link) {
    if (!mouseHover) {
      history.push(`/client/${link}`);
    }
  }

  // pagination

  const [pagination, setPagination] = useState({
    data: [],
    offset: 0,
    numberPerPage: 100,
    pageCount: 1,
    currentData: [],
  });

  useEffect(() => {
    if (clientFilters?.last_name) setSearchedSurname(clientFilters.last_name);
    if (clientFilters?.phone) setSearchedMobile(clientFilters.phone);
    if (clientFilters?.email) setSearchedEmail(clientFilters.email);
    if (clientFilters?.created_from)
      setSearchedCreatedAfter(clientFilters.created_from);
    if (clientFilters?.created_to)
      setSearchedCreatedBefore(clientFilters.created_to);
    if (clientFilters?.assigned_to)
      setSearchedOriginName(clientFilters.assigned_to);
  }, []);

  useEffect(() => {
    if (!sorted) {
      setPagination((prevState) => ({
        ...prevState,
        data: data?.payload.sort((a, b) =>
          new Date(a.created) < new Date(b.created) ? 1 : -1
        ),
      }));
    }
  }, [data?.payload]);

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      pageCount: Math.ceil(prevState.data?.length / prevState.numberPerPage),
      currentData: prevState.data.slice(
        pagination.offset,
        pagination.offset + pagination.numberPerPage
      ),
    }));
  }, [pagination.data, pagination.numberPerPage, pagination.offset]);

  useEffect(() => {
    handleSearch();
  }, [clientFilters]);

  const assignedToOptions = useMemo(() => {
    const arrayUsers = [];

    if (!user) return arrayUsers;

    for (const [key, value] of Object.entries(user)) {
      arrayUsers?.push({
        value: key,
        text: value,
      });
    }

    return clearDuplicatesFromArray(arrayUsers);
  }, [user]);

  const handlePageClick = (event, { activePage }) => {
    setActivePage(activePage);
    const offset = (activePage - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  const handleSearchSurname = (event, data) => {
    let value = data.value;
    setSearchedSurname(value);
  };

  const handleSearchEmail = (event, data) => {
    let value = data.value;
    setSearchedEmail(value);
  };

  const handleSearchMobile = (event, data) => {
    let value = data.value;
    setSearchedMobile(value);
  };

  const handleSearchSourceOfOrigin = (event, data) => {
    let value = data.value;
    setSearchedOriginName(value);
  };

  const handleSearchDateFrom = (event, data) => {
    let value = data.value;
    setSearchedCreatedAfter(value);
  };

  const handleSearchDateAfter = (event, data) => {
    let value = data.value;
    setSearchedCreatedBefore(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSorted(false);

    const handleNewValue = (e) => (e && e !== "" ? e : null);

    setClientFilters({
      last_name: handleNewValue(searchedSurname),
      email: handleNewValue(searchedEmail),
      phone: handleNewValue(searchedMobile),
      assigned_to: handleNewValue(searchedOriginName),
      created_from: handleNewValue(searchedCreatedAfter),
      created_to: handleNewValue(searchedCreatedBefore),
    });
  };

  const handleSearch = () => {
    ClientsService.getClients(clientFilters)
      .then((res) => {
        handleSortDataDown(res.data.payload, setPagination);
        setActivePage(1);
        setPagination((prevState) => ({
          ...prevState,
          offset: 0,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    setShowMobileMenu(false);
  };

  const handleClearSearch = () => {
    setSearchedSurname("");
    setSearchedOriginName("");
    setSearchedEmail("");
    setSearchedCreatedAfter("");
    setSearchedCreatedBefore("");
    setSearchedMobile("");
    handleSortDataDown(data?.payload, setPagination);
    setActivePage(1);
    setPagination((prevState) => ({
      ...prevState,
      offset: 0,
    }));
  };

  const handleSort = (event, dataEvent) => {
    if (pagination.data) {
      const newArr = [...pagination.data];

      if (dataEvent.value === "created_up") {
        newArr.sort((a, b) => {
          const aDate = new Date(a.created);
          const bDate = new Date(b.created);
          return aDate < bDate ? -1 : 1;
        });
        setPagination((prevState) => ({
          ...prevState,
          data: newArr,
        }));
      } else if (dataEvent.value === "created_down") {
        newArr.sort((a, b) => {
          const aDate = new Date(a.created);
          const bDate = new Date(b.created);
          return aDate > bDate ? -1 : 1;
        });
        setPagination((prevState) => ({
          ...prevState,
          data: newArr,
        }));
      } else {
        newArr.sort((a, b) => {
          return a[dataEvent.value] < b[dataEvent.value] ? -1 : 1;
        });
        setPagination((prevState) => ({
          ...prevState,
          data: newArr,
        }));
      }
      setSorted(true);
    }
  };

  const handleSortAsc = (arr) => {
    return arr.sort((a, b) => (a.text > b.text ? 1 : -1));
  };

  const handleDate = (date) => {
    if (!date) return false;
    const dateYesterday = new Date();
    dateYesterday.setDate(dateYesterday.getDate() - 1);
    const dateFromProps = new Date(date);
    if (dateYesterday < dateFromProps) {
      return true;
    } else return false;
  };

  return (
    <>
      <div style={{ minHeight: "calc(80vh + 1rem)" }}>
        {showMobileMenu && <Overlay onClose={() => setShowMobileMenu(false)} />}
        <Container className="lg:px-2">
          <header className="sticky top-20 py-4 lg:pt-4 lg:pb-0 lg:top-20  z-20 bg-white w-screen lg:w-full">
            <form
              className={cs(
                "flex flex-1 items-center  absolute w-screen lg:w-full bg-white flex-col lg:static lg:justify-start lg:flex-row lg:border-t-0 lg:py-0 transition-all transform  lg:transform -translate-y-0 duration-500",
                { "-translate-y-mobileClientSearchHeight": !showMobileMenu }
              )}
              onSubmit={handleSubmit}
            >
              <Input
                placeholder={t("Client last name")}
                className="w-56 lg:w-40 mx-2 h-12"
                fluid={false}
                type="text"
                value={searchedSurname}
                onChange={handleSearchSurname}
              />
              <Input
                placeholder={t("Phone number")}
                className="w-56 lg:w-40 mx-2 h-12"
                value={searchedMobile}
                onChange={handleSearchMobile}
                fluid={false}
                type="text"
              />
              <Input
                placeholder={t("E-mail")}
                className="w-56 lg:w-40 mx-2 h-12"
                fluid={false}
                type="text"
                value={searchedEmail}
                onChange={handleSearchEmail}
              />
              <Input
                label={t("Created from")}
                className="w-56 mx-2 h-12"
                labelPosition={"left corner"}
                size={"small"}
                value={searchedCreatedAfter}
                onChange={(event, data) => handleSearchDateFrom(event, data)}
                type="date"
              />
              <Input
                label={t("Created to")}
                className="w-56 mx-2 h-12"
                labelPosition={"left corner"}
                value={searchedCreatedBefore}
                size={"small"}
                onChange={(event, data) => handleSearchDateAfter(event, data)}
                type="date"
              />
              <Dropdown
                placeholder={t("Agent")}
                className="mx-2 h-12"
                style={{ display: "flex", alignItems: "center" }}
                search={true}
                selection={true}
                size={"small"}
                selectOnBlur={false}
                value={searchedOriginName}
                options={handleSortAsc(assignedToOptions)}
                onChange={(event, data) =>
                  handleSearchSourceOfOrigin(event, data)
                }
                clearable
              />
              <div className="mx-2">
                <button
                  className="flex items-center py-3 px-4"
                  onClick={handleClearSearch}
                  type="button"
                >
                  <X size={14} className="mr-2" />
                  {t("Clear")}
                </button>
              </div>
              <div className="mx-2">
                <button
                  className="font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                  type="submit"
                >
                  {t("Search")}
                </button>
              </div>
              <div
                className="lg:hidden flex justify-start py-2 my-2 sticky top-16 z-20 bg-white"
                onClick={() => setShowMobileMenu(!showMobileMenu)}
              >
                <div className="text-primse-green flex items-center">
                  {showMobileMenu && <ChevronUp size={24} />}
                  {!showMobileMenu && <ChevronDown size={24} />}
                  <BiSearchAlt2 size={24} />
                </div>
              </div>
            </form>
          </header>
          <div className="py-2 items-center justify-between hidden lg:flex">
            <div className="md:mx-2 md:my-0">
              <h3 className="text-xl font-bold">
                {t("Search results")}:{" "}
                <span className="text-primse-green">
                  {pagination.currentData?.length}
                </span>
              </h3>
            </div>
            <div className="flex items-center -ml-2">
              <div className="my-2 mx-2 md:my-0 flex  items-center hidden lg:inline-flex">
                <div>
                  <p className="mx-2 text-xs lg:text-base">{t("Sort by")}: </p>
                </div>
                <div className="text-xs lg:text-base my-2 md:mx-2 md:my-0">
                  <Dropdown
                    className="border-primse-green h-12"
                    style={{ display: "flex", alignItems: "center" }}
                    // placeholder={t("Choose")}
                    fluid={false}
                    selection={true}
                    defaultValue={"created_down"}
                    options={[
                      {
                        key: `${t("Last name")}`,
                        text: `${t("Last name")}`,
                        value: "last_name",
                      },
                      {
                        key: `${t("Creation date ascending")}`,
                        text: `${t("Creation date ascending")}`,
                        value: "created_up",
                      },
                      {
                        key: `${t("Creation date descending")}`,
                        text: `${t("Creation date descending")}`,
                        value: "created_down",
                      },
                    ]}
                    onChange={(event, data) => handleSort(event, data)}
                  />
                </div>
              </div>

              {userAgencyType !== "Metrohouse office" && (
                <div className="my-2 md:mx-2 md:my-0">
                  <ClientsModal
                    content={data?.payload}
                    handleRefresh={handleRefresh}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="p-4 mt-6 border rounded-xl w-full flex flex-col lg:hidden">
            {pagination.currentData?.map(
              ({
                uuid,
                last_name,
                first_name,
                created,
                work_phone,
                work_email,
                status_name,
                assigned_to_name,
                description,
              }) => (
                <ClientsItemMobile
                  key={uuid}
                  data={{
                    last_name: last_name,
                    first_name: first_name,
                    assigned_to_name: assigned_to_name,
                    created: created,
                    status_name: status_name,
                    uuid: uuid,
                    work_phone,
                    work_email,
                    description,
                  }}
                />
              )
            )}
          </div>
          <table className="w-full hidden lg:table">
            <thead className="bg-white font-bold uppercase z-20 text-xs">
              <tr className="border-b h-14">
                <th className="px-2 text-left sticky top-36 bg-white z-20">
                  {t("Client")}
                </th>
                {/* <th className="px-2 text-left sticky top-36 bg-white w-1/12">{t("First name")}</th> */}
                <th className="px-2 text-left sticky top-36 bg-white ">
                  {t("Created")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white ">
                  {t("Agent")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white ">
                  {t("Phone")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white">
                  {t("E-mail")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white "></th>
                <th className="px-2 text-left sticky top-36 bg-white ">
                  {t("Status")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white ">
                  {t("Requests")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white ">
                  {t("Comments")}
                </th>
                <th className="px-2 text-left sticky top-36 bg-white "></th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {pagination.currentData?.map(
                ({
                  uuid,
                  last_name,
                  first_name,
                  created,
                  work_phone,
                  work_email,
                  status_name,
                  description,
                  assigned_to_name,
                  request_last_change,
                  requests_count,
                }) => (
                  <tr
                    onClick={() => handleClientDetails(uuid)}
                    key={uuid}
                    className="border-b h-14 hover:bg-primse-light-green cursor-pointer "
                  >
                    <Td>
                      <ButtonNavLink
                        uuid={uuid}
                        link={`/client/${uuid}`}
                        name={`${last_name} ${first_name} `}
                        onMouseHover={setMouseHover}
                      />
                    </Td>
                    <Td>
                      <Moment format="DD.MM.YYYY">{created}</Moment>
                    </Td>
                    <Td>{assigned_to_name}</Td>
                    <Td>{work_phone ? work_phone : "brak"}</Td>
                    <Td>{work_email ? work_email : "brak"}</Td>
                    <td className="py-2 px-2 border-b text-2xl text-primse-green">
                      {handleDate(
                        request_last_change,
                        `${last_name} ${first_name} `
                      ) && "!"}
                    </td>
                    <td className="py-2 px-2 border-b">
                      {t(`${status_name}`)}
                    </td>

                    <Td>{requests_count}</Td>
                    <Td>
                      {description && (
                        <Popup
                          trigger={
                            <MessageCircle
                              className="text-primse-green z-10 cursor-pointer"
                              size={24}
                            />
                          }
                          flowing
                          on="hover"
                          position="top center"
                        >
                          <div className="bg-white  text-sm font-normal">
                            {description ? description : "-"}
                          </div>
                        </Popup>
                      )}
                    </Td>
                    <Td>
                      <div
                        className="text-primse-green m-auto cursor-pointer"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <PopupClients
                          handleClientDetails={handleClientDetails}
                          uuid={uuid}
                          status={status_name}
                          last_name={last_name}
                          first_name={first_name}
                          work_phone={work_phone}
                          work_email={work_email}
                          description={description}
                          handleRefresh={handleRefresh}
                        />
                      </div>
                    </Td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          {pagination.data?.length > 100 && (
            <div className="py-4">
              <Pagination
                activePage={activePage}
                totalPages={pagination.pageCount}
                onPageChange={handlePageClick}
              />
            </div>
          )}
        </Container>
      </div>
      <NewsSection />
    </>
  );
};

// Clients.propTypes = {};

// Clients.defaultProps = {};

export default ClientsView;
