import React from 'react'
import { useQuery } from "react-query";
import { Modal } from 'semantic-ui-react'
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import ClientsService from '../../../../../../services/ClientsService'
import EditClientContent from '../EditClientContent'

import { useParams } from 'react-router-dom';


const ControlerEditModal = ({ closedModal,setRefresh }) => {
    const { uuid } = useParams();

    const { data, isLoading } = useQuery(
        `client-${uuid}`,
        ClientsService.getClient(uuid)
    );

    const { first_name, last_name, work_email, work_phone, description} = data.client

    const { t } = useTranslation();
    return (
        <Modal open='true' onClose={() => closedModal()}>
            <Modal.Header style={{ background: "rgba(249, 250, 251, 1", border: "none" }}>
                <div className="flex justify-between items-center"  >
                    <div>{t("Edit customer details")}</div>
                    <AiFillCloseCircle onClick={() => {
                        closedModal()
                    }} className="text-primse-gray-font cursor-pointer w-8 h-8" />
                </div>
            </Modal.Header>
            {!isLoading && (
                <Modal.Content>
                    <EditClientContent setRefresh={setRefresh} first_name={first_name} last_name={last_name} work_email={work_email} work_phone={work_phone} description={description} uuid={uuid} closedModal={closedModal}/>
                </Modal.Content>
            )}
        </Modal>
    )
}

export default ControlerEditModal;
