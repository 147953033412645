import i18n from "../../i18n";
import { isArray } from "../../utils/functions";
import { ROOMS_NUM } from "./components/Flat/SearchComponents/RoomsDropdown";

export const getTypePlaceholder = (values) => {
  const value = values.type;
  if (value?.length === 0) return i18n.t("Type");

  if (isArray(value) && value?.length > 1) {
    return `${i18n.t("Selected")}: (${value?.length})`;
  }

  return Object.values(value)
    .map((v) => i18n.t(v))
    .join(", ");
};

export const getAreaPlaceholder = (values) => {
  const value = values.area;

  if (value[0] === "" && value[1] === "") return i18n.t("Surface");

  return `${value[0] ? `${i18n.t("from")} ${value[0]}` : ""}${
    value[1] ? ` ${i18n.t("to")} ${value[1]}` : ""
  }`;
};

export const getRoomsPlaceholder = (values) => {
  const value = values.rooms;

  if (value?.length === 0) return i18n.t("Number of rooms");
  if (value?.length === 1) {
    return `${i18n.t("Number of rooms")} ${value[0]}`;
  }
  const max = value[1] === ROOMS_NUM ? `${value[1]}+` : value[1];
  return `${i18n.t("Number of rooms")} ${value[0]} - ${max}`;
};

export const getPricePlaceholder = (values) => {
  const value = values.price;
  if (value[0] === "" && value[1] === "") return i18n.t("Price");

  return `${value[0] ? `${i18n.t("from")} ${value[0]} PLN` : ""}${
    value[1] ? ` ${i18n.t("to")} ${value[1]} PLN` : ""
  }`;
};

export const getAdvancedPlaceholder = () => {
  return i18n.t("Advanced search");
};
