import React, { useEffect } from "react";
import { Image, Modal } from "semantic-ui-react";
import cs from "classnames";
import { RiLayoutGridLine } from "react-icons/all";
import { X } from "react-feather";

function PlanModal({ link, name, mobile = false, classNameProps = "" }) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => (document.body.style.overflow = "unset");
  }, [open]);

  // const trackingPlan = () => {
  //   TrackingServices.trackingAdd({
  //     url: url,
  //     name: nameTracking,
  //     description: description,
  //   });
  // };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        mobile ? (
          <RiLayoutGridLine size="20" className="text-primse-green" />
        ) : (
          <p
            className={cs(
              "inline text-primse-green cursor-pointer",
              classNameProps
            )}
          >
            {name}
          </p>
        )
      }
    >
      <Modal.Content image className="responsive">
        <Image size="fullscreen" src={link} wrapped />

        <div className="absolute -top-10 right-0 xl:-right-10 z-20 cursor-pointer">
          <X color="white" size={32} onClick={() => setOpen(false)} />
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default PlanModal;
