import { Link, useHistory } from "react-router-dom";
import dimensionsSrc from "./assets/dimensions@2x.png";
import peopleSrc from "./assets/people@2x.png";
import investmentPhoto from "./assets/noImage.jpeg";
import { BriefCasePlusWhite } from "../../../../components/Icons";
import PopupTrigger from "../../../../components/PopupTrigger";
import ButtonNavLink from "../../../../components/ButtonNavLink";
import { useMemo, useState } from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { useStoreState } from "easy-peasy";
import { editCompletionDate } from "../../../../utils/searchUtils";
import { editPrice } from "../../../../utils/numbers";

const ImageInvestment = ({
  uuid,
  pictures,
  investmentData,
  userRole,
  promotion,
}) => {
  const { t } = useTranslation();

  const [isLoaded, setIsLoaded] = useState(false);
  const { mode } = useStoreState((state) => state.mode);

  const renderImage = useMemo(() => {
    if (pictures?.[0]?.thumbnail || pictures?.[0]?.file) {
      return `${process.env.REACT_APP_IMAGES_URL}${
        pictures[0].thumbnail || pictures[0].file
      }`;
    } else {
      return null;
    }
  }, [pictures]);

  return (
    <>
      {!isLoaded && (
        <div className="ui placeholder fluid " style={{ width: "300px" }}></div>
      )}
      <div
        className={cs(
          "flex flex-col block lg:hidden xl:block relative overflow-hidden",
          isLoaded ? " md:w-1/2 " : "w-0"
        )}
        style={{ opacity: isLoaded ? 1 : 0, width: isLoaded ? "300px" : "0px" }}
      >
        {investmentData && userRole !== "Deweloper" && (
          <div className="absolute top-0 right-0 hidden md:block">
            <PopupTrigger
              uuid={uuid}
              investmentData={investmentData}
              openRequestModal={true}
            >
              <div className="block  ">
                <div className="bg-primse-green ml-3 rounded-sm flex justify-center items-center cursor-pointer transition-all duration-300 hover:opacity-80 w-12 h-12">
                  <div className="w-8">
                    <BriefCasePlusWhite />
                  </div>
                </div>
              </div>
            </PopupTrigger>
          </div>
        )}
        <Link to={`/investment/${uuid}`} className="w-full">
          {promotion && mode !== "presentation" && (
            <div
              className="absolute top-4 h-10 w-52 text-primse-gray-font text-center text-xs font-bold bg-primse-yellow uppercase leading-10 transform -rotate-45"
              style={{ left: "-60px" }}
            >
              {t("Promotion")}
            </div>
          )}
          {renderImage ? (
            <img
              onLoad={() => setIsLoaded(true)}
              src={`${renderImage}`}
              className="rounded-t-sm cursor-pointer max-h-full object-cover w-full "
              alt="investment"
            />
          ) : (
            <img
              onLoad={() => setIsLoaded(true)}
              src={investmentPhoto}
              className="rounded-t-sm cursor-pointer max-h-full object-cover"
              alt="investment"
            />
          )}
        </Link>
        <Link
          to={`/investment/${uuid}`}
          className="inline-block w-full bg-primse-yellow text-sm font-bold text-primse-gray-font uppercase py-2 text-center rounded-b-sm hover:opacity-80 transition-all duration-200 hover:text-white"
        >
          {t("Details")}
        </Link>
      </div>
    </>
  );
};

const InvestmentsWrapper = ({
  uuid,
  name,
  price_from,
  price_to,
  area_from,
  area_to,
  rooms_from,
  rooms_to,
  number_of_offers,
  city,
  address_street,
  uuidDeleteFromBasket,
  pictures,
  developer_name,
  commission_rate,
  completion_date,
  promoting_agency_offer,
  promotion,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const investmentData = {
    name: name,
    developer_name: developer_name,
  };
  const isPromotion = promotion == "1" ? true : false;
  const { mode } = useStoreState((state) => state.mode);
  const userRole = useStoreState((state) => state.auth.user?.role);
  const promotingAgencyOffer = promoting_agency_offer == "1" ? true : false;

  return (
    <>
      {pictures ? (
        <div className="flex flex-col px-2 md:flex-row sm:px-6 md:px-5 xl:px-0">
          <ImageInvestment
            promotion={isPromotion}
            userRole={userRole}
            uuid={uuid}
            pictures={pictures}
            investmentData={investmentData}
            uuidDeleteFromBasket={uuidDeleteFromBasket}
          />
          <div className="text-primse-gray-font mx-5 my-2 flex flex-col font-medium w-1/2 md:w-full lg:w-1/2">
            {mode !== "presentation" ? (
              <div className="text-xl font-bold">
                <ButtonNavLink
                  classNameProps="text-primse-gray-font"
                  uuid={uuid}
                  link={`/investment/${uuid}`}
                  name={name}
                />
              </div>
            ) : null}
            {mode !== "presentation" && promotingAgencyOffer ? (
              <p className="text-sm mb-0 mt-2 uppercase">
                {t("Offer of promoting agency")}
              </p>
            ) : null}
            <div className="text-sm">
              {[city, address_street].filter((value) => value).join(", ")}
            </div>
            <div className="text-sm">
              {t("Number of locals")}: {number_of_offers}
            </div>
            {completion_date && (
              <div className="text-sm">
                {t("Completion date")}:{" "}
                {editCompletionDate(completion_date, t("Quarter"))}
              </div>
            )}
            {developer_name && mode !== "presentation" && (
              <div className="text-sm break-word ">
                {t("Developer")}: {developer_name}
              </div>
            )}
            {commission_rate && mode !== "presentation" && (
              <div className="text-sm break-word font-bold">
                {t("Commission rate")}: {commission_rate}%
              </div>
            )}
            <div className="mt-4 text-sm flex items-center">
              <img src={dimensionsSrc} width={20} height={20} alt="size icon" />
              <div className="ml-2.5">
                {area_from} m<sup>2</sup> {t("to")} {area_to} m<sup>2</sup>
              </div>
            </div>
            <div className="mt-4 text-sm flex items-center">
              <img src={peopleSrc} width={20} height={20} alt="rooms icon" />
              <div className="ml-2.5">
                {t("from")} {rooms_from} {t("to")} {rooms_to}
              </div>
            </div>
            <div
              onClick={() => history.push(`/investment/${uuid}`)}
              className="mt-5 bg-primse-mid-gray px-2 py-2 rounded-sm text-white text-xs font-bold uppercase text-center min-w-min cursor-pointer"
            >
              {t("from")}
              <p className="text-primse-yellow text-base px-2 inline">
                {editPrice(price_from)} PLN
              </p>
              {t("to")}
              <p className="text-primse-yellow text-base px-2 inline">
                {editPrice(price_to)} PLN
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="ui placeholder fluid h-40 "></div>
      )}
    </>
  );
};

export default InvestmentsWrapper;
