import { useTranslation } from "react-i18next";

import { useEffect } from "react";
import AuthService from "../../services/AuthServices";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { actions } from "../../store";

const LogoutComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    actions.isLoading.setIsLoading(true);
    AuthService.logOut(null)
      .then(() => {
        actions.briefcase.clearClient();
        actions.isLoading.setIsLoading(false);
        history.push("/");
      })
      .catch(() => {
        toast.error(
          `${t("Something went wrong, you could not log out of the system")}`
        );
        actions.isLoading.setIsLoading(false);
      });
  }, []);

  return <main className="mt-4"></main>;
};

export default LogoutComponent;
