import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import ClientsService from "../../services/ClientsService";
import Loader from "../../components/Loader";
import ClientInvestment from "./componenets/Investment";
import { useHistory } from "react-router-dom";
import { Dropdown, Popup } from "semantic-ui-react";
import BriefcaseContentService from "../../services/BriefcaseContentService";
import { useTranslation } from "react-i18next";
import EditModal from "./componenets/EditModal";
import NewsSection from "../../components/NewsSection";
import { toast } from "react-toastify";
import UserInfo from "./componenets/UserInfo";
import SecondaryMarketInvestment from "./componenets/SecondaryMarketInvestment";

import NewRequestMultiInvestmentModal from "../../modals/NewRequestMultiInvestmentModal";

import { actions } from "../../store";
import DocumentsServices from "../../services/DocumentsServices";
import MyOpinionModal from "../../modals/MyOpinionModal";
import BreadcrumbExample from "../../components/Breadcrumb";
import { useStoreState } from "easy-peasy";

const Client = () => {
  const { uuid } = useParams();

  const [refreshData, setRefreshData] = useState(false);

  useEffect(() => {
    setRefreshData(false);
  }, [refreshData]);

  return !refreshData ? (
    <ClientView uuid={uuid} setRefreshData={setRefreshData} />
  ) : (
    <Loader />
  );
};

const ClientView = ({ uuid, setRefreshData }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [refresh, setRefresh] = useState(false);
  const [investmentsList, setInvestmentsList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState(null);
  const [briefcaseInvestments, setBriefcaseInvestments] = useState([]);
  const [realestateList, setRealestateList] = useState({});
  const [
    realestateSecondaryMarketList,
    setRealestateSecondaryMarketList,
  ] = useState({});
  const [concatRealestateList, setConcatRealestateList] = useState({});
  const [actionOptions, setActionOptions] = useState([]);
  const [opinionModalOpen, setOpinionModalOpen] = useState(false);
  const [template, setTemplate] = useState("");
  const [loadingPDF, setLoadingPDF] = useState(false);

  const userAgencyType = useStoreState((state) => state.auth.user?.agencyType);

  const { data: templatesData } = useQuery(
    "templates",
    DocumentsServices.getPDFTemplates
  );

  const { data, isLoading } = useQuery(
    `client-${uuid}`,
    ClientsService.getClient(uuid)
  );

  const { data: briefcaseData } = useQuery(
    `briefcase-${uuid}`,
    BriefcaseContentService.getBriefcaseContent(uuid)
  );

  useEffect(() => {
    if (briefcaseData) setBriefcaseInvestments(briefcaseData.payload);
  }, [briefcaseData]);

  useEffect(() => {
    if (refresh === true) {
      BriefcaseContentService.getBriefcaseContent(uuid)()
        .then((res) => setBriefcaseInvestments(res.payload))
        .finally(() => setRefresh(false));
    }
  }, [refresh]);

  useEffect(() => {
    if (data) {
      actions.briefcase.setBriefcaseClient(data.client);
    }
  }, [uuid, data]);

  const handleRefresh = (value) => {
    setRefresh(value);
  };
  const handleRefreshData = (value) => {
    setRefreshData(value);
  };

  function isEmpty(obj) {
    return Object.keys(obj)?.length === 0;
  }

  function isLessThanTwo(obj) {
    return Object.keys(obj)?.length < 2;
  }

  const handleDeleteRealestate = (item_key) => {
    const newRealestateList = realestateList;
    delete newRealestateList[item_key];
    setRealestateList(newRealestateList);
  };
  const handleDeleteRealestateSecondaryMarket = (item_key) => {
    const newRealestateList = realestateSecondaryMarketList;
    delete newRealestateList[item_key];
    setRealestateSecondaryMarketList(newRealestateList);
  };

  const handleDeleteInvestment = (item_key) => {
    const newInvestmentsList = [...investmentsList];
    const index = newInvestmentsList
      .map((o) => o.investment_uuid)
      .indexOf(item_key);
    if (index > -1) {
      newInvestmentsList.splice(index, 1);
    }
    setInvestmentsList(newInvestmentsList);
  };

  const isOtherInvestment = (investmentsList, realestateList) => {
    let isOther = false;

    if (
      (investmentsList?.length === 0 &&
        Object.entries(realestateList)?.length === 0) ||
      investmentsList?.length > 1
    )
      return true;
    if (
      investmentsList?.length === 0 &&
      Object.entries(realestateList)?.length !== 0
    )
      return false;

    if (
      investmentsList?.length !== 0 &&
      Object.entries(realestateList)?.length === 0
    )
      return true;

    for (const [value] of Object.entries(realestateList)) {
      if (value.investment_name !== investmentsList[0].investment_name)
        isOther = true;
    }

    return isOther;
  };

  const hasObjectsWithProperty = (arr, key, value) => {
    let hasProperty = false;
    arr.forEach((obj) => {
      if (obj[key] === value) {
        hasProperty = true;
      }
    });

    return hasProperty;
  };

  useEffect(() => {
    const concatRealestates = {};

    for (const [key, value] of Object.entries(realestateSecondaryMarketList)) {
      concatRealestates[key] = value;
    }
    for (const [key, value] of Object.entries(realestateList)) {
      concatRealestates[key] = value;
    }

    setConcatRealestateList(concatRealestates);

    setActionOptions([
      {
        text: `${t("Report")}`,
        value: "report",
        disabled:
          (isEmpty(realestateList) && isEmpty(investmentsList)) ||
          hasObjectsWithProperty(
            investmentsList,
            "investment_promoting_agency_offer",
            "1"
          ),
        popupText: t("At least one investment or location must be selected"),
      },
      {
        text: `${t("Full offer - print")}`,
        value: "print",
        disabled: isEmpty(realestateList) && isEmpty(investmentsList),
        popupText: t("At least one investment or location must be selected"),
      },
      {
        text: `${t("Anonymous offer - print")}`,
        value: "anonymous",
        disabled: isOtherInvestment(investmentsList, concatRealestates),
        popupText: t("At least one location must be selected"),
        // ((isEmpty(realestateList) ||
        //   Object.keys(realestateList)?.length > 1) &&
        //   (isEmpty(investmentsList) ||
        //     Object.keys(investmentsList)?.length > 1)) ||
        // (Object.keys(investmentsList)?.length === 1 &&
        //   Object.keys(realestateList)?.length) === 1,
      },
      {
        text: `${t("Full offer - email")}`,
        value: "fullEmail",
        disabled: isEmpty(realestateList) && isEmpty(investmentsList),
        popupText: t("At least one investment or location must be selected"),
      },
      {
        text: `${t("Anonymous offer - email")} `,
        value: "anonymousEmail",
        disabled: isOtherInvestment(investmentsList, concatRealestates),
        popupText: t("At least one location must be selected"),
      },
      {
        text: `${t("Compare real estates")}`,
        value: "compareRealestates",
        disabled: isLessThanTwo(concatRealestates),
        popupText: t("At least two locations must be selected"),
      },
      {
        text: `${t("Compare investments")}`,
        value: "compareInvestments",
        disabled: isLessThanTwo(investmentsList),
        popupText: t("At least two investments must be selected"),
      },
      {
        text: `${t("Offer secondary market")}`,
        value: "OfferSecondaryMarket",
        disabled: isEmpty(realestateSecondaryMarketList),
        popupText: t(
          "At least two properties from the secondary market must be selected"
        ),
      },
    ]);

    handleRefresh(false);
  }, [
    t,
    investmentsList,
    realestateList,
    realestateSecondaryMarketList,
    refresh,
    briefcaseInvestments,
  ]);

  useEffect(() => {
    setAction(null);
  }, [
    investmentsList,
    realestateList,
    realestateSecondaryMarketList,
    briefcaseInvestments,
  ]);

  //TODO: dodać oferta anonimowa drukuj, tam akcje:

  const templates = useMemo(() => {
    if (!templatesData) return [];
    const templatesObjectSettings = {};

    for (const [key, value] of Object.entries(templatesData.settings)) {
      if (key.slice(-10) !== "_max_items") {
        if (key.search("sm_realestates") !== -1) {
          templatesObjectSettings[key] = {
            uuid: value,
            type: "Secondary Market Realestates",
          };
        } else {
          templatesObjectSettings[key] = {
            uuid: value,
            type:
              key.search("realestates") !== -1 ? "Realestates" : "Investments",
          };
        }
      }
    }
    return templatesObjectSettings;
  }, [t, templatesData]);

  const handleOptionChange = (event, data) => {
    let value = data.value;
    setAction(value);
  };

  const createPDFrealestates = (
    arr,
    templateUuid,
    text,
    arrInvestments,
    send = false
  ) => {
    const formData = new FormData();

    if (arr?.length !== 0) {
      Object.keys(arr).map((item) => {
        formData.append(["realestateUuids[]"], item);
      });
    }

    if (arrInvestments && arrInvestments?.length !== 0) {
      arrInvestments?.forEach((el) =>
        formData.append(["investmentUuids[]"], el.investment_uuid)
      );
    }
    formData.append("templateUuid", templateUuid);
    formData.append("custom_text", text);
    formData.append("client_uuid", data?.client?.uuid);

    if (!send) {
      return DocumentsServices.createPdf(formData)
        .then(() =>
          toast.success(`${t("Generated offers, download will start soon.")}`)
        )
        .catch(() => toast.error(`${t("Error, unable to generate offers.")}`));
    } else {
      return DocumentsServices.sendPdf(formData)
        .then(() => toast.success(`${t("Offer sent")}`))
        .catch(() => toast.error(`${t("Error, unable to generate offers.")}`));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const realestatesListLength = Object.keys(realestateList)?.length;
    const realestatesSecondaryMarketListLength = Object.keys(
      realestateSecondaryMarketList
    )?.length;
    const concatRealestatesListLength = Object.keys(concatRealestateList)
      ?.length;

    switch (true) {
      case (action === "print" &&
        !Object.values(realestateList).every(
          (element, index, arr) =>
            element.investment_name === arr[0].investment_name
        )) ||
        (realestatesListLength !== 0 &&
          realestatesSecondaryMarketListLength !== 0 &&
          action !== "compareRealestates"):
        toast.error(
          `${t("Please select the real estates belonging to one investment")}`
        );
        break;
      case action === "fullEmail" &&
        !Object.values(realestateList).every(
          (element, index, arr) =>
            element.investment_name === arr[0].investment_name
        ):
        toast.error(
          `${t("Please select the real estates belonging to one investment")}`
        );
        break;
      case action === "report" && Object.keys(investmentsList)?.length !== 0:
        {
          let toOpen = true;
          const array = [];
          Object.values(investmentsList)?.forEach((investment) => {
            if (investment.query_status) {
              array.push(`${investment.query_status}`);
            }
          });

          if (array?.length !== 0) {
            toast.error(`${t("Select only unreported investments")}`);
            toOpen = false;
          }
          setModalOpen(toOpen);
        }
        break;
      case action === "fullEmail" &&
        Object.keys(investmentsList)?.length !== 0 &&
        Object.keys(realestateSecondaryMarketList)?.length === 0 &&
        Object.keys(realestateList)?.length === 0:
        setLoadingPDF(true);
        createPDFrealestates(
          realestateList,
          templates["template_offer_investments"].uuid,
          null,
          investmentsList,
          true
        ).finally(() => setLoadingPDF(false));
        break;

      case action === "fullEmail" &&
        Object.keys(investmentsList)?.length !== 0 &&
        Object.keys(realestateList)?.length !== 0 &&
        !Object.values(realestateList).every(
          (element) =>
            element.investment_name ===
            Object.values(investmentsList)[0].investment_name
        ):
        toast.error(
          `${t(
            "Choose a maximum of one investment and any number of real estates belonging to it"
          )}`
        );
        break;
      case action === "fullEmail" &&
        Object.keys(investmentsList)?.length !== 0 &&
        Object.keys(realestateList)?.length !== 0 &&
        Object.keys(realestateList)?.length ===
          Object.keys(concatRealestateList)?.length &&
        Object.values(realestateList).every(
          (element) =>
            element.investment_name ===
            Object.values(investmentsList)[0].investment_name
        ):
        setLoadingPDF(true);
        createPDFrealestates(
          realestateList,
          templates["template_offer_realestates"].uuid,
          null,
          investmentsList,
          true
        ).finally(() => setLoadingPDF(false));
        break;
      case action === "fullEmail" &&
        Object.keys(realestateList)?.length !== 0 &&
        Object.keys(investmentsList)?.length === 0 &&
        realestatesListLength === concatRealestatesListLength:
        setLoadingPDF(true);
        createPDFrealestates(
          realestateList,
          templates["template_offer_realestates"].uuid,
          null,
          investmentsList,
          true
        ).finally(() => setLoadingPDF(false));

        break;

      case action === "OfferSecondaryMarket" &&
        Object.keys(investmentsList)?.length === 0 &&
        Object.keys(realestateList)?.length === 0 &&
        Object.keys(realestateSecondaryMarketList)?.length !== 0:
        setLoadingPDF(true);
        createPDFrealestates(
          realestateSecondaryMarketList,
          templates["template_offer_sm_realestates"].uuid,
          null,
          investmentsList
        ).finally(() => setLoadingPDF(false));

        break;

      case action === "print" &&
        Object.keys(investmentsList)?.length !== 0 &&
        Object.keys(realestateSecondaryMarketList)?.length === 0 &&
        Object.keys(realestateList)?.length === 0:
        setTemplate(templates["template_offer_investments"]);
        setOpinionModalOpen(true);
        break;

      case action === "print" &&
        Object.keys(investmentsList)?.length !== 0 &&
        Object.keys(realestateList)?.length !== 0 &&
        !Object.values(realestateList).every(
          (element) =>
            element.investment_name ===
            Object.values(investmentsList)[0].investment_name
        ):
        toast.error(
          `${t(
            "Choose a maximum of one investment and any number of real estates belonging to it"
          )}`
        );
        break;
      case action === "print" &&
        Object.keys(investmentsList)?.length !== 0 &&
        Object.keys(realestateList)?.length !== 0 &&
        Object.keys(realestateList)?.length ===
          Object.keys(concatRealestateList)?.length &&
        Object.values(realestateList).every(
          (element) =>
            element.investment_name ===
            Object.values(investmentsList)[0].investment_name
        ):
        setTemplate(templates["template_offer_realestates"]);

        setOpinionModalOpen(true);
        break;
      case action === "print" &&
        Object.keys(realestateList)?.length !== 0 &&
        Object.keys(investmentsList)?.length === 0 &&
        realestatesListLength === concatRealestatesListLength:
        setTemplate(templates["template_offer_realestates"]);
        setOpinionModalOpen(true);
        break;
      case action === null && Object.keys(investmentsList)?.length !== 0:
        toast.error(`${t("Choose action")}`);
        break;
      case action === "report" && Object.keys(investmentsList)?.length === 0:
        toast.error(`${t("Choose investment")}`);
        break;
      case action !== "report" &&
        action !== "print" &&
        action !== "fullEmail" &&
        action !== "compareInvestments" &&
        action !== "compareRealestates" &&
        action !== "anonymous" &&
        action !== "anonymousEmail" &&
        Object.keys(investmentsList)?.length === 0:
        toast.error(`${t("Choose investment and action")}`);
        break;
      case action === "compareRealestates" &&
        Object.keys(investmentsList)?.length !== 0:
        toast.error(`${t("Please select only the real estates")}`);
        break;
      case action === "compareRealestates" &&
        Object.keys(concatRealestateList)?.length > 6:
        toast.error(
          `${t("You can compare up to 6 real estates at the same time")}`
        );
        break;
      case action === "compareRealestates" &&
        !isLessThanTwo(concatRealestateList):
        setLoadingPDF(true);
        createPDFrealestates(
          concatRealestateList,
          templates["template_compare_realestates"].uuid,
          null,
          investmentsList
        ).finally(() => setLoadingPDF(false));
        break;
      case action === "compareRealestates" &&
        isLessThanTwo(concatRealestateList):
        toast.error(`${t("Please select at least two real estates")}`);
        break;
      case action === "compareInvestments" &&
        Object.keys(concatRealestateList)?.length !== 0:
        toast.error(`${t("Please select only investments")}`);
        break;
      case action === "compareInvestments" &&
        Object.keys(investmentsList)?.length > 6:
        toast.error(
          `${t("You can compare up to 6 investments at the same time")}`
        );
        break;
      case action === "compareInvestments" && !isLessThanTwo(investmentsList):
        setLoadingPDF(true);
        createPDFrealestates(
          realestateList,
          templates["template_compare_investments"].uuid,
          null,
          investmentsList
        ).finally(() => setLoadingPDF(false));
        break;
      case action === "compareInvestments" && isLessThanTwo(investmentsList):
        toast.error(`${t("Please select at least two investments")}`);
        break;

      case action === "anonymous" &&
        investmentsList?.length === 0 &&
        concatRealestatesListLength === 1:
        setLoadingPDF(true);
        createPDFrealestates(
          concatRealestateList,
          templates["template_anonymous_offer_realestates"].uuid,
          null,
          investmentsList
        ).finally(() => setLoadingPDF(false));
        break;
      case action === "anonymous" &&
        !isOtherInvestment(investmentsList, realestateList):
        setLoadingPDF(true);
        createPDFrealestates(
          concatRealestateList,
          templates["template_anonymous_offer_realestates"].uuid,
          null,
          investmentsList
        ).finally(() => setLoadingPDF(false));
        break;

      case action === "anonymousEmail" &&
        !isOtherInvestment(investmentsList, realestateList):
        setLoadingPDF(true);
        createPDFrealestates(
          concatRealestateList,
          templates["template_anonymous_offer_realestates"].uuid,
          null,
          investmentsList,
          true
        ).finally(() => setLoadingPDF(false));
        break;

      case action === "anonymousEmail" &&
        investmentsList?.length === 0 &&
        concatRealestatesListLength === 1:
        setLoadingPDF(true);
        createPDFrealestates(
          concatRealestateList,
          templates["template_anonymous_offer_realestates"].uuid,
          null,
          investmentsList,
          true
        ).finally(() => setLoadingPDF(false));
        break;
    }
  };

  // const BREADCRUMB_DATA = useMemo(() => {
  //   if (!data) return null;
  //   return [
  //     {
  //       name: "Main page",
  //       linkPage: "/",
  //     },
  //     {
  //       name: `Clients`,
  //       linkPage: `clients`,
  //     },
  //   ];
  // }, [data]);

  const enableSendRequest = useMemo(() => {
    let error = false;
    const fields = [];
    if (!data) return { error, fields };

    if (!data?.client?.first_name || data?.client?.first_name === "") {
      error = true;
      fields.push("First name");
    }
    if (!data?.client?.last_name || data?.client?.last_name === "") {
      error = true;
      fields.push("Last name");
    }
    if (!data?.client?.work_email || data?.client?.work_email === "") {
      error = true;
      fields.push("Email");
    }
    if (!data?.client?.work_phone || data?.client?.work_phone === "") {
      error = true;
      fields.push("Phone");
    }

    return { error, fields };
  }, [data]);

  return (
    <main className="mt-4">
      {isLoading || (loadingPDF && <Loader />)}
      {briefcaseInvestments && (
        <div>
          <div style={{ minHeight: "80vh" }}>
            <div className="md:hidden">
              <BreadcrumbExample returnPage={"Clients"} />
            </div>
            <div className="xl:px-8 ">
              <div className="container mx-auto min-w-full">
                <header className="flex items-end justify-between sticky top-20 bg-white pb-1 pt-4 z-10">
                  <div className="py-6 px-3 flex flex-col items-start lg:flex-row lg:items-center justify-between border w-full ">
                    {!refresh ? <UserInfo uuid={uuid} /> : <Loader />}

                    <div className="hidden lg:flex items-end justify-between items-center ">
                      {data?.client?.status_name === "New" && (
                        <div className="mx-4 flex items-end justify-between items-center">
                          <EditModal
                            setRefresh={setRefresh}
                            status={data?.client?.status_name}
                          />
                          Menu
                        </div>
                      )}
                      {data?.client?.status_name !== "New" &&
                        userAgencyType !== "Metrohouse office" && (
                          <div className="mx-4 flex items-end justify-between items-center">
                            <EditModal
                              setRefresh={setRefresh}
                              status={data?.client?.status_name}
                            />
                            Menu
                          </div>
                        )}
                    </div>
                  </div>
                </header>
                <main className="block sticky top-44 bg-white z-10">
                  <header>
                    <form
                      onSubmit={(e) => handleSubmit(e)}
                      className="py-6 px-7 lg:px-0 flex flex-col items-start lg:items-center lg:flex-row  "
                    >
                      <div className="mb-4 lg:mb-0 lg:mr-2 w-64">
                        <Dropdown
                          className="w-full"
                          placeholder={`${t("Choose")}`}
                          options={actionOptions}
                          fluid={false}
                          selection={true}
                          noResultsMessage={t("No results found")}
                          value={action}
                        >
                          <Dropdown.Menu>
                            {actionOptions?.map(
                              ({ text, value, disabled, label, popupText }) => {
                                return (
                                  <Popup
                                    key={value}
                                    trigger={
                                      <Dropdown.Item
                                        key={value}
                                        value={value}
                                        text={text}
                                        style={{
                                          color: disabled
                                            ? "rgba(0, 0, 0, 0.25)"
                                            : null,
                                        }}
                                        onClick={(e, d) => {
                                          if (!disabled) {
                                            handleOptionChange(e, d);
                                          }
                                        }}
                                        label={label}
                                      />
                                    }
                                    mouseEnterDelay={500}
                                    on="hover"
                                    position="right center"
                                  >
                                    <div className="bg-white text-sm font-normal text-center">
                                      {popupText}
                                    </div>
                                  </Popup>
                                );
                              }
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="mx-0 lg:mx-2">
                        <button
                          type="submit"
                          className="leading-5 uppercase font-bold text-white bg-primse-green py-3 px-10 rounded-sm transition-all hover:bg-primse-gray"
                        >
                          {t("Perform for selected")}
                        </button>
                      </div>
                    </form>
                  </header>
                </main>

                <main>
                  <div>
                    {briefcaseInvestments?.map((investment) => {
                      switch (investment.offer_kind_id) {
                        case "1":
                          return (
                            <ClientInvestment
                              handleRefreshData={handleRefreshData}
                              handleRefresh={handleRefresh}
                              key={investment.investment_uuid}
                              investmentData={investment}
                              clientUuid={uuid}
                              investmentsList={investmentsList}
                              setInvestmentsList={setInvestmentsList}
                              realestateList={realestateList}
                              setRealestateList={setRealestateList}
                              handleDeleteInvestment={handleDeleteInvestment}
                              handleDeleteRealestate={handleDeleteRealestate}
                              enableSendRequest={enableSendRequest}
                            ></ClientInvestment>
                          );
                      }
                    })}
                    {userAgencyType !== "Metrohouse office" &&
                      briefcaseInvestments?.map((investment, index) => {
                        switch (investment.offer_kind_id) {
                          case "2":
                            return (
                              <SecondaryMarketInvestment
                                key={index}
                                investmentData={investment}
                                clientUuid={uuid}
                                investmentsList={investmentsList}
                                setInvestmentsList={setInvestmentsList}
                                handleRefresh={handleRefresh}
                                handleRefreshData={handleRefreshData}
                                realestateList={realestateSecondaryMarketList}
                                setRealestateList={
                                  setRealestateSecondaryMarketList
                                }
                                handleDeleteRealestate={
                                  handleDeleteRealestateSecondaryMarket
                                }
                              />
                            );
                        }
                      })}

                    {briefcaseInvestments?.length === 0 && (
                      <div className="flex flex-col items-center">
                        <div className="uppercase font-medium text-center pt-4">
                          {t("There are no objects in the briefcase...")}
                        </div>
                        <button
                          className="leading-5 w-46 m-4 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
                          onClick={() => history.push("/")}
                        >
                          {t("Go to the search engine")}
                        </button>
                      </div>
                    )}
                  </div>
                </main>
              </div>
            </div>
          </div>
          <NewsSection />
        </div>
      )}
      {isLoading && <Loader />}
      <NewRequestMultiInvestmentModal
        investmentsData={investmentsList}
        clientUuid={uuid}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleRefresh={handleRefresh}
      />

      <MyOpinionModal
        setOpinionModalOpen={setOpinionModalOpen}
        opinionModalOpen={opinionModalOpen}
        createPDFrealestates={createPDFrealestates}
        realestateList={realestateList}
        investmentsList={investmentsList}
        template={template}
        realestateSecondaryMarketList={realestateSecondaryMarketList}
      />
    </main>
  );
};

export default Client;
