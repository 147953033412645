import React, { useState } from "react";
import { MessageCircle } from "react-feather";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Popup } from "semantic-ui-react";

import ButtonNavLink from "../../../components/ButtonNavLink";
import PopupClients from "./PopupClients";
import { Td } from "../../../utils/searchUtils";

import { useStoreState } from "easy-peasy";

const ListItem = ({
  data: {
    date_created,
    developer_name,
    investment_name,
    investment_uuid,
    realestate_name,
    realestate_uuid,
    status_name,
    subject_name,
    reply,
    uuid,
  },
  handleRefresh,
}) => {
  const [openReplyModal, setOpenReplyModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  const { t } = useTranslation();

  const userPrimaryRole = useStoreState(
    (state) => state.auth.user?.primaryRole
  );

  return (
    <tr
      key={uuid}
      className="border-b h-14 hover:bg-primse-light-green cursor-pointer "
      onClick={() => {
        if (userPrimaryRole === "Doradca") {
          setOpenDetailsModal(true);
        } else {
          if (status_name === "Waiting for developer answer") {
            setOpenReplyModal(true);
          } else {
            setOpenDetailsModal(true);
          }
        }
      }}
    >
      <Td>
        <Moment format="DD.MM.YYYY">{date_created}</Moment>
      </Td>

      <Td>{developer_name ? developer_name : t("No data")}</Td>
      <Td>{status_name ? t(status_name) : t("No data")}</Td>
      <Td>{subject_name ? subject_name : t("No data")}</Td>
      <Td>
        {reply && (
          <Popup
            trigger={
              <MessageCircle
                className="text-primse-green z-10 cursor-pointer "
                size={24}
              />
            }
            flowing
            on="hover"
            position="top center"
          >
            <div className="bg-white  text-sm font-normal">
              {reply ? reply : "-"}
            </div>
          </Popup>
        )}
      </Td>
      <Td>
        {investment_name ? (
          <ButtonNavLink
            uuid={investment_uuid}
            link={`/investment/${investment_uuid}`}
            name={investment_name}
          />
        ) : (
          t("No data")
        )}
      </Td>
      <Td>
        {realestate_name ? (
          <ButtonNavLink
            uuid={realestate_uuid}
            link={`/realestate/${realestate_uuid}`}
            name={realestate_name}
          />
        ) : (
          t("No data")
        )}
      </Td>

      <Td>
        <div
          className="text-primse-green m-auto cursor-pointer"
          onClick={(e) => e.stopPropagation()}
        >
          <PopupClients
            openReplyModal={openReplyModal}
            setOpenReplyModal={setOpenReplyModal}
            openDetailsModal={openDetailsModal}
            setOpenDetailsModal={setOpenDetailsModal}
            uuid={uuid}
            subject_name={subject_name}
            investment_name={investment_name}
            realestate_name={realestate_name}
            status_name={status_name}
            reply={reply}
            // work_phone={work_phone}
            // work_email={work_email}
            // description={description}
            handleRefresh={handleRefresh}
            userPrimaryRole={userPrimaryRole}
          />
        </div>
      </Td>
    </tr>
  );
};

export default ListItem;
