import cs from "classnames";

import { Link, NavLink } from "react-router-dom";

import { Popup } from "semantic-ui-react";
import PopupNavList from "./PopupNavList";
import { Fragment } from "react";

const NavHeader = ({
  items,
  setShowMobileMenu,
  openInvestmentPopupMenuMobile,
}) => {
  return (
    <ul className="flex items-center content-center flex-1 flex-col whitespace-pre xl:flex-row xl:static xl:top-0">
      {items.map(
        ({
          link,
          label,
          exact,
          list,
          elements,
          mobile,
          blank = false,
          display = true,
          redirect = true,
          displayWeb = true,
        }) => {
          if (!list && display) {
            return (
              <NavLink
                to={blank ? { pathname: link } : link}
                target={blank ? "_blank" : null}
                key={label}
                exact={exact}
                activeClassName="active-nav"
                component={({ className, href }) => {
                  return (
                    <li
                      className={cs(
                        "px-4 py-3 xl:py-0 relative nav",
                        { "hidden lg:block": !mobile },
                        { "block xl:hidden": !displayWeb && mobile },
                        className
                      )}
                    >
                      <Link
                        onClick={() => setShowMobileMenu(false)}
                        to={blank ? { pathname: href } : href}
                        className="relative text-black hover:text-black"
                        target={blank ? "_blank" : null}
                      >
                        {label}
                      </Link>
                    </li>
                  );
                }}
              ></NavLink>
            );
          }
          if (list && display) {
            return (
              <Fragment key={label}>
                <PopupNavList
                  label={label}
                  elements={elements}
                  exact={exact}
                  setShowMobileMenu={setShowMobileMenu}
                  redirect={redirect}
                />

                <li className={cs("px-4 py-3 xl:py-0 relative nav hidden")}>
                  <Popup
                    position="bottom center"
                    flowing
                    on="click"
                    open={openInvestmentPopupMenuMobile}
                    // onClose={() => setOpenInvestmentPopupMenuMobile(false)}
                    // onOpen={() => setOpenInvestmentPopupMenuMobile(true)}
                  ></Popup>
                </li>
              </Fragment>
            );
          }
        }
      )}
    </ul>
  );
};

export default NavHeader;
