import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox } from "semantic-ui-react";
import FilterModal from "./FilterModal";
import { useTranslation } from "react-i18next";

const TypeDropdown = ({ control }) => {
  const getValues = (checked, values, key) => {
    if (checked) {
      return [...values, key];
    } else {
      return values.filter((v) => v !== key);
    }
  };
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name="type"
      render={({ field: { onChange, value: values } }) => {
        return (
          <FilterModal
            title={t("Type")}
            onReset={() => {
              onChange([]);
            }}
          >
            <ul>
              <li className="mb-2">
                <Checkbox
                  label={t("flat")}
                  checked={values.includes("apartment")}
                  onChange={(_, e) => {
                    onChange(getValues(e.checked, values, "apartment"));
                  }}
                />
              </li>
              <li className="mb-2">
                <Checkbox
                  label={t("house")}
                  checked={values.includes("house")}
                  onChange={(_, e) => {
                    onChange(getValues(e.checked, values, "house"));
                  }}
                />
              </li>
              <li className="mb-2">
                <Checkbox
                  label={t("office space")}
                  checked={values.includes("office space")}
                  onChange={(_, e) => {
                    onChange(getValues(e.checked, values, "office space"));
                  }}
                />
              </li>
              <li className="mb-2">
                <Checkbox
                  label={t("plot")}
                  checked={values.includes("plot")}
                  onChange={(_, e) => {
                    onChange(getValues(e.checked, values, "plot"));
                  }}
                />
              </li>
              <li className="mb-2">
                <Checkbox
                  label={t("Investment apartment")}
                  checked={values.includes("investment_apartment")}
                  onChange={(_, e) => {
                    onChange(
                      getValues(e.checked, values, "investment_apartment")
                    );
                  }}
                />
              </li>
            </ul>
          </FilterModal>
        );
      }}
    />
  );
};

export default TypeDropdown;
