import Investment from "../Investment";
import React from "react";

const MapInvestment = ({investmentName, uuidDeleteFromBasket, ...restProps} ) => {

  return (
    <div className="py-5">
      <div className="flex">
        <Investment {...restProps} investmentName={investmentName} uuidDeleteFromBasket={uuidDeleteFromBasket}/>
      </div>
    </div>
  );
};

export default MapInvestment;
