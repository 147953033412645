import React, { useState } from "react";
import NewRequestModal from "../../../../modals/NewRequestModal";
import RequestUserComponent from "./RequestUserComponent";

const RequestUser = ({
  uuid,
  developer_name,
  investment_name,
  classNameButton,
}) => {
  const [clientUuid, setClientUuid] = useState(null);

  const handleModalOpen = () => {
    setClientUuid(null);
  };

  return (
    <>
      <RequestUserComponent
        uuid={uuid}
        setClientUuid={setClientUuid}
        classNameButton={classNameButton}
      />
      {clientUuid ? (
        <NewRequestModal
          investment_uuid={uuid}
          clientUuid={clientUuid}
          developerName={developer_name}
          investmentName={investment_name}
          modalOpen={clientUuid}
          setModalOpen={handleModalOpen}
          handleRefresh={() => {}}
        />
      ) : null}
    </>
  );
};

export default RequestUser;
