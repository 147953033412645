import React, { useEffect, useRef, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { Controller } from "react-hook-form";
import Badge from "../../../../../components/Badge";
import { useClickOutside } from "../../../../../utils/useClickOutside";

import "./style.css";
import cs from "classnames";
import { useTranslation } from "react-i18next";

export const DefaultValueInput = ({
  placeholder,
  value,
  Modal,
  control,
  setValue,
  completion_quarters,
}) => {
  const [showModal, setModal] = useState(false);
  const containerRef = useRef();

  useClickOutside(containerRef, () => setModal(false));
  return (
    <div ref={containerRef}>
      <div>
        <div
          className="flex items-center justify-between px-3 py-3 max-h-11 appearance-none w-full bg-white rounded-sm outline-none ring-1 focus:ring-1 ring-primse-green focus:ring-primse-green text-sm cursor-pointer "
          onClick={() => {
            setModal((prev) => !prev);
          }}
        >
          <span className="mr-3">
            {typeof placeholder === "string" ? placeholder : placeholder(value)}
          </span>
          {showModal && <ChevronUp />}
          {!showModal && <ChevronDown />}
        </div>
      </div>
      <div
        className={cs("relative", { block: showModal }, { hidden: !showModal })}
      >
        <Modal
          value={value}
          onReset={() => {}}
          control={control}
          onClose={() => setModal(false)}
          setValue={setValue}
          completion_quarters={completion_quarters}
        />
      </div>
    </div>
  );
};

export const DistrictValueInput = ({
  Modal,
  cities,
  control,
  resetQuery,
  handleSetQuery,
  watch,
}) => {
  const [showModal, setModal] = useState(false);
  const [query, setQuery] = useState("");
  const containerRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (resetQuery) {
      setQuery("");
      handleSetQuery(false);
    }
  }, [resetQuery]);

  useClickOutside(containerRef, () => setModal(false));
  return (
    <Controller
      control={control}
      name="location"
      render={({ field: { onChange, value: values } }) => {
        return (
          <div ref={containerRef}>
            <div className="flex items-center justify-between px-3 py-3 max-h-11 appearance-none w-full bg-white rounded-sm outline-none ring-1 focus:ring-1 ring-primse-green focus:ring-primse-green text-sm cursor-pointer ">
              {values?.length === 1 && <Badge>{values[0]}</Badge>}
              {values?.length > 1 && (
                <Badge onRemove={() => onChange([])}>{`+${values?.length} ${t(
                  "Location"
                )}`}</Badge>
              )}
              {values?.length === 0 ? (
                <div className="ml-3 block xl:block">
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={() => (
                      <input
                        className={cs(
                          "py-3 max-h-11 appearance-none bg-white rounded-sm outline-none ring-1 focus:ring-0 ring-transparent focus:ring-primse-green text-sm input-dropdown-search ",
                          values?.length > 0 ? "w-8" : "w-full"
                        )}
                        placeholder={
                          values?.length !== 0 ? null : t("Choose location")
                        }
                        value={query}
                        onFocus={() => setModal(true)}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    )}
                  />
                </div>
              ) : null}
              <div className="ml-3">
                {showModal && (
                  <ChevronUp
                    className="cursor-pointer"
                    onClick={() => {
                      setModal(false);
                    }}
                  />
                )}
                {!showModal && (
                  <ChevronDown
                    className="cursor-pointer"
                    onClick={() => {
                      setModal(true);
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className={cs(
                "relative",
                { block: showModal },
                { hidden: !showModal }
              )}
            >
              <Modal
                values={values.map((v) => v.toLowerCase())}
                options={cities}
                query={query.toLowerCase()}
                onOptionSelect={(value) => {
                  onChange([...values, value.name]);
                  setQuery("");
                }}
                onOptionRemove={(value) => {
                  onChange(
                    values.filter(
                      (v) => v.toLowerCase() !== value.toLowerCase()
                    )
                  );
                }}
                watch={watch}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export const DefaultValueInputText = ({ placeholder, control }) => {
  return (
    <Controller
      control={control}
      name="text"
      render={({ field }) => {
        return (
          <div>
            <div>
              <div className="flex items-center justify-between px-3 py-3 max-h-11 appearance-none w-full bg-white rounded-sm outline-none ring-1 focus:ring-1 ring-primse-green focus:ring-primse-green text-sm cursor-pointer ">
                <input
                  className="py-3 max-h-11 appearance-none w-full bg-white rounded-sm outline-none ring-1 focus:ring-0 ring-transparent focus:ring-primse-green text-sm input-dropdown-search "
                  placeholder={placeholder}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange([e.target.value]);
                  }}
                />
              </div>
            </div>
          </div>
        );
      }}
    ></Controller>
  );
};
