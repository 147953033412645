import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Form, FormDropdown, Modal, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import MyCartContent from "../MyCartContent";
import { actions } from "../../../store";
import { useStoreState } from "easy-peasy";
import NewBasket from "../NewBasket";
import EditBasket from "../EditBasket";
import DeleteBasket from "../DeleteBasket";
import cs from "classnames";
import { toast } from "react-toastify";

import { ArrowRight, Menu } from "react-feather";
import { searchUtils } from "../../../utils/searchUtils";

const MyCartView = ({
  selectModal,
  selectDefaultModal,
  handleClosedBasket,
}) => {
  const { basketsList, currentBasket } = useStoreState(
    (state) => state.baskets
  );
  const { t } = useTranslation();

  const basketsSuggestions = useMemo(() => {
    const arr = [];
    basketsList?.forEach(({ uuid, name }) => {
      arr.push({
        value: uuid,
        text: `${t(name)}`,
      });
    });

    arr.sort((a, b) => {
      return a.text < b.text ? -1 : 1;
    });

    return Array.from(
      new Set(arr.map((item) => JSON.stringify(item)))
    ).map((string) => JSON.parse(string));
  }, [t, basketsList]);

  const handleChangeBasket = (event, data) => {
    let value = data.value;
    try {
      searchUtils(basketsList, value);
      toast.success(`${t("The basket has been changed")}`);
    } catch (error) {
      toast.error(`${t("Error")}`);
    }
  };

  return (
    <>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex md:justify-between">
          <div
            className="flex flex-col md:flex-row items-start md:items-center justify-between md:justify-start  w-full"
            // style={{ alignItems: "center" }}
          >
            {t("Selected basket")}:
            <Form className="m-2">
              <FormDropdown
                defaultValue={currentBasket.uuid}
                placeholder={t("My carts")}
                className="w-1/2"
                search={true}
                selection={true}
                size={"small"}
                value={currentBasket.uuid}
                noResultsMessage={t("No results found")}
                options={basketsSuggestions}
                onChange={handleChangeBasket}
              />
            </Form>
            <div className="flex items-center ">
              {currentBasket.uuid !== undefined &&
              currentBasket.basketItems?.length !== 0 ? (
                <div>
                  {currentBasket.basketItems?.length === 1 &&
                    currentBasket.basketItems[0].realestate_uuid && (
                      <Link
                        to={`/basket/${currentBasket.uuid}`}
                        onClick={() => {
                          selectDefaultModal();
                          actions.baskets.setBasketModal(false);
                        }}
                        className="text-primse-gray-font hover:text-primse-green cursor-pointer inline"
                      >
                        <button
                          className={cs(
                            "m-2 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray "
                          )}
                          type="submit"
                        >
                          <div className="flex items-center">
                            {t("See basket on map")}
                            <ArrowRight
                              className="text-white ml-2 "
                              size={20}
                            />
                          </div>
                        </button>
                      </Link>
                    )}
                  {currentBasket.basketItems?.length > 1 && (
                    <Link
                      to={`/basket/${currentBasket.uuid}`}
                      onClick={() => {
                        selectDefaultModal();
                        actions.baskets.setBasketModal(false);
                      }}
                      className="text-primse-gray-font hover:text-primse-green cursor-pointer inline"
                    >
                      <button
                        className={cs(
                          "m-2 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray "
                        )}
                        type="submit"
                      >
                        <div className="flex items-center">
                          {t("See basket on map")}
                          <ArrowRight className="text-white ml-2 " size={20} />
                        </div>
                      </button>
                    </Link>
                  )}
                </div>
              ) : null}

              <Popup
                trigger={
                  <Menu
                    className="ml-2 text-primse-green z-10 cursor-pointer"
                    size={24}
                  />
                }
                flowing
                on="click"
                position="bottom center"
              >
                <div className="bg-white  text-sm font-normal">
                  <div className="border-b">
                    <div
                      onClick={() =>
                        selectModal(
                          <NewBasket selectDefaultModal={selectDefaultModal} />
                        )
                      }
                      className="p-3 cursor-pointer hover:text-primse-green"
                    >
                      {t("Add new cart")}
                    </div>
                  </div>
                  <div className="border-b">
                    <div
                      onClick={() =>
                        selectModal(
                          <EditBasket selectDefaultModal={selectDefaultModal} />
                        )
                      }
                      className="p-3 cursor-pointer hover:text-primse-green"
                    >
                      {t("Edit cart")}
                    </div>
                  </div>
                  <div>
                    <div
                      onClick={() =>
                        selectModal(
                          <DeleteBasket
                            selectDefaultModal={selectDefaultModal}
                          />
                        )
                      }
                      className="p-3 cursor-pointer hover:text-primse-green"
                    >
                      {t("Delete cart")}
                    </div>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
          <AiFillCloseCircle
            onClick={() => {
              actions.baskets.setBasketModal(false);
              handleClosedBasket();
            }}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      <MyCartContent display={true} />
    </>
  );
};

export default MyCartView;
