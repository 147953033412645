import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

const ClientsItemMobile = ({
  data: {
    last_name,
    first_name,
    assigned_to_name,
    created,
    status_name,
    uuid,
    work_phone,
    work_email,
    description,
  },
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  function handleClientDetails(link) {
    history.push(`/client/${link}`);
  }

  return (
    <div
      onClick={() => handleClientDetails(uuid)}
      className="py-3 border-b flex flex-wrap"
    >
      <div className="flex w-full">
        <div className="w-10/12 font-bold text-lg">
          {`${first_name} ${last_name}`}
        </div>
      </div>
      <div>
        <div>
          <span className="font-bold">{t("Created")}:</span>{" "}
          <Moment format="DD.MM.YYYY">{created}</Moment>
        </div>
        <div>
          <span className="font-bold">{t("Agent")}:</span> {assigned_to_name}
        </div>
        <div>
          <span className="font-bold">{t("Phone")}:</span>{" "}
          {work_phone ? work_phone : t("No data")}
        </div>
        <div>
          <span className="font-bold">E-mail:</span>{" "}
          {work_email ? work_email : t("No data")}
        </div>
        <div>
          <span className="font-bold">Status:</span> {t(status_name)}
        </div>
        <div>
          <span className="font-bold">{t("Requests")}:</span> 0
        </div>
        <div>
          <span className="font-bold">{t("Comments")}:</span>{" "}
          {description ? description : "-"}
        </div>
      </div>
    </div>
  );
};

// ClientsItemMobile.propTypes = {};

// ClientsItemMobile.defaultProps = {};

export default ClientsItemMobile;
