import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const MapContextMenu = ({ setShowContextMenu, url, contextMenuPosition }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    setPosition(contextMenuPosition);
  }, [contextMenuPosition]);

  return (
    <Link
      className="  bg-white opacity-0 fixed shadow-md z-30 h-full w-full"
      style={{
        top: position.y - 3,
        left: position.x - 3,
        height: "6px",
        width: "6px",
      }}
      to={`${url}`}
      onClick={() => {
        setShowContextMenu(false);
      }}
    />
  );
};

export default MapContextMenu;
