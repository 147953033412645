import { useQuery } from "react-query";
import RealestatesServices from "../../services/RealestatesServices";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import { editPrice } from "../../utils/numbers";
import PlanModal from "../PlanModal";

const Td = ({ children }) => <td className="px-2 py-2 border-b">{children}</td>;

const FlatListMobile = ({ uuid }) => {
  const { data, isLoading } = useQuery(
    `flats-${uuid}`,
    RealestatesServices.getFlatList(uuid, {}, true)
  );

  const { t } = useTranslation();

  return (
    <div className="my-4 mx-1 px-5 py-6 border rounded-sm ">
      <h4 className="font-bold text-xl">{t("Available apartments")}</h4>
      <div className="w-14 h-1 bg-primse-green" />
      <table className="my-4 w-full table table-fixed">
        <thead>
          <tr>
            <th className="py-2 border-b uppercase text-left w-1/8">
              {t("Plan")}
            </th>
            <th className="py-2 border-b uppercase text-left w-1/8">
              {t("Rooms")}
            </th>
            <th className="py-2 border-b uppercase text-left">
              {t("Flat area")}
            </th>
            <th className="py-2 border-b uppercase text-left">
              {t("Price")} PLN
            </th>
          </tr>
        </thead>
        <tbody className="text-sm">
          {data?.payload.map(({ rooms, area, price, plan }, index) => (
            <tr key={index}>
              <Td>
                <PlanModal
                  link={`${process.env.REACT_APP_IMAGES_URL}${plan}`}
                  name="Plan"
                  mobile={true}
                />
              </Td>
              <Td>{rooms}</Td>
              <Td>{area}</Td>
              <Td>{editPrice(price)}</Td>
            </tr>
          ))}
        </tbody>
        {isLoading && <Loader />}
      </table>
    </div>
  );
};

export default FlatListMobile;
