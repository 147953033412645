import React from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import EditClientContent from "../EditClientContent";

const ControlerEditModal = ({
  closedModal,
  handleRefresh,
  uuid,
  first_name,
  last_name,
  work_email,
  work_phone,
  description,
}) => {
  const { t } = useTranslation();
  return (
    <Modal open="true" onClose={() => closedModal()}>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between items-center">
          <div>{t("Edit customer details")}</div>
          <AiFillCloseCircle
            onClick={() => {
              closedModal();
            }}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>

      <Modal.Content>
        <EditClientContent
          handleRefresh={handleRefresh}
          first_name={first_name}
          last_name={last_name}
          work_email={work_email}
          work_phone={work_phone}
          description={description}
          uuid={uuid}
          closedModal={closedModal}
        />
      </Modal.Content>
    </Modal>
  );
};

export default ControlerEditModal;
