import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";
import Loader from "../../../../components/Loader";

import { toast } from "react-toastify";
import { useStoreState } from "easy-peasy";
import RealestatesServices from "../../../../services/RealestatesServices";
import QuestionsService from "../../../../services/QuestionsService";

const NewQuestionContent = ({
  subjects,
  handleClose,
  handleRefresh,
  addQuestionData,
}) => {
  const [loading, setLoading] = useState(false);

  const [realestatesList, setRealestatesList] = useState(null);

  const [errorSubject, setErrorSubject] = useState(false);
  const [errorInvestment, setErrorInvestment] = useState(false);
  const [errorRealestate, setErrorRealestate] = useState(false);

  const [selectSubject, setSelectSubjec] = useState(null);
  const [selectInvestment, setSelectInvestment] = useState(null);
  const [selectRealestate, setSelectRealestate] = useState(null);

  const { investment: investmentsList } = useStoreState(
    (state) => state.dictionaries.dictionariesList
  );

  useEffect(() => {
    if (addQuestionData) {
      setLoading(true);
      setSelectInvestment(addQuestionData.i_uuid);
      setSelectRealestate(addQuestionData.r_uuid);
      setTimeout(() => setLoading(false), 300);
    }
  }, [addQuestionData]);

  const { t } = useTranslation();

  useEffect(() => {
    if (selectInvestment) {
      setLoading(true);
      RealestatesServices.getFlatList(selectInvestment, {
        roomsFrom: "",
        roomsTo: "",
        priceFrom: "",
        priceTo: "",
        areaFrom: "",
        areaTo: "",
        perPage: 999,
      })().then((res) => {
        setRealestatesList(res.payload);
        setLoading(false);
      });
    }
  }, [selectInvestment]);

  const handleSubject = (value) => {
    setErrorSubject(false);
    setSelectSubjec(value);
  };
  const handleInvestment = (value) => {
    setErrorInvestment(false);
    setSelectRealestate(null);
    setSelectInvestment(value);
  };
  const handleRealestate = (value) => {
    setErrorRealestate(false);
    setSelectRealestate(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectSubject && selectRealestate && selectInvestment) {
      setLoading(true);

      QuestionsService.questionsCreate({
        subject_id: selectSubject,
        investment_uuid: selectInvestment,
        realestate_uuid: selectRealestate,
      })
        .then(() => {
          toast.success(t("Successfully added question"));
          setLoading(false);
          handleRefresh(true);
          handleClose();
        })
        .catch(() => {
          toast.error(t("Error"));
        });

      setLoading(false);
    } else {
      const errors = [];
      if (!selectSubject) {
        setErrorSubject(true);
        errors.push("Subject");
      }
      if (!selectInvestment) {
        setErrorInvestment(true);
        errors.push("Investment");
      }
      if (!selectRealestate) {
        setErrorRealestate(true);
        errors.push("Realestate name");
      }
      toast.error(
        <div>
          {t("None of the fields can be empty")}: <br />
          {errors.map((el, index) => (
            <div key={index}>
              - {t(el)} <br />
            </div>
          ))}
        </div>
      );
    }
  };

  const subjectsOptions = useMemo(() => {
    if (subjects) {
      const arr = [];
      subjects?.forEach(({ id, name }) =>
        arr.push({ key: id, value: id, text: name })
      );
      arr.sort((a, b) => (a.text > b.text ? 1 : -1));
      return arr;
    } else return [];
  }, [subjects]);

  const investmentsOptions = useMemo(() => {
    if (investmentsList) {
      const arr = [];
      investmentsList?.forEach(({ uuid, name }) =>
        arr.push({ key: uuid, value: uuid, text: name })
      );
      arr.sort((a, b) => (a.text > b.text ? 1 : -1));
      return arr;
    } else return [];
  }, [investmentsList]);

  const realestatesOptions = useMemo(() => {
    if (realestatesList) {
      const arr = [];
      realestatesList?.forEach(({ uuid, name }) =>
        arr.push({ key: uuid, value: uuid, text: name })
      );
      arr.sort((a, b) => (a.text > b.text ? 1 : -1));
      return arr;
    } else return [];
  }, [realestatesList]);

  return (
    <>
      <Form className="flex items-center flex-wrap" onSubmit={handleSubmit}>
        {!loading && (
          <>
            <Form.Dropdown
              error={errorSubject}
              label={t("Subject")}
              placeholder={t("Subject")}
              className="w-full p-2"
              fluid
              selection
              onChange={(e, data) => handleSubject(data.value)}
              options={subjectsOptions}
            />
            <Form.Dropdown
              error={errorInvestment}
              label={t("Investment")}
              placeholder={t("Investment")}
              className="w-full p-2"
              fluid
              selection
              onChange={(e, data) => handleInvestment(data.value)}
              defaultValue={selectInvestment}
              options={investmentsOptions}
            />
            <Form.Dropdown
              error={errorRealestate}
              disabled={!selectInvestment}
              label={t("Realestate name")}
              placeholder={t("Realestate name")}
              className="w-full p-2"
              fluid
              selection
              onChange={(e, data) => handleRealestate(data.value)}
              defaultValue={selectRealestate}
              options={realestatesOptions}
            />

            <div className="w-full flex justify-end">
              <button
                className="m-2 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
                type="submit"
              >
                {t("Send")}
              </button>
            </div>
          </>
        )}
        {loading && <Loader />}
      </Form>
    </>
  );
};

export default NewQuestionContent;
