import React, { useState } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import { useStoreState } from "easy-peasy";
import { actions } from "../../store";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import cs from "classnames";
import EditUserModalHeader from "./EditUserModalHeader";

function EditUserModal() {
  const { t } = useTranslation();
  const [editView, setEditView] = useState(true);

  const { editUserModal } = useStoreState((state) => state.modals);

  return (
    <Modal open={editUserModal} size="small">
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <EditUserModalHeader />
          <div className="flex flex-col lg:flex-row">
            <div className="text-center mx-2 my-2 lg:my-0">
              <button
                type="submit"
                className={cs(
                  "leading-5 w-full uppercase py-2 px-8 rounded-sm transition-all hover:bg-primse-gray",
                  { "bg-primse-green": editView },
                  { "bg-primse-yellow": !editView }
                )}
                onClick={() => setEditView(false)}
              >
                <span className="text-white font-bold whitespace-nowrap text-base">
                  {t("Change password")}
                </span>
              </button>
            </div>
            <div className="text-center mx-2">
              <button
                type="submit"
                className={cs(
                  "leading-5 w-full uppercase py-2 px-8 rounded-sm transition-all hover:bg-primse-gray",
                  { "bg-primse-yellow": editView },
                  { "bg-primse-green": !editView }
                )}
                onClick={() => setEditView(true)}
              >
                <span className="text-white font-bold whitespace-nowrap text-base">
                  {t("Edit profile")}
                </span>
              </button>
            </div>
          </div>
          <AiFillCloseCircle
            className="text-primse-gray-font cursor-pointer w-8 h-8 my-2 lg:my-0"
            onClick={() => actions.modals.openEditUserModal(false)}
          />
        </div>
      </Modal.Header>
      {editView ? <EditProfile /> : <ChangePassword />}
    </Modal>
  );
}

export default EditUserModal;
