import { useQuery } from "react-query";
import RequestsServices from "../../services/RequestsServices";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import RequestView from "./RequestView";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useMemo } from "react";
import InvestmentsService from "../../services/InvestmentsService";
import { useTranslation } from "react-i18next";

const Request = () => {
  const { uuid } = useParams();
  const { t } = useTranslation();
  const { data, isLoading } = useQuery(
    `request-${uuid}`,
    RequestsServices.getRequest(uuid)
  );
  const {
    data: dataInvestment,
  } = useQuery(
    `request-investment-${data?.investment_uuid}`,
    InvestmentsService.getInvestment(data?.investment_uuid),
    { enabled: data?.investment_uuid ? true : false }
  );

  const [applicationData, setApplicationData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [requestHistory, setRequestHistory] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (data) {
      RequestsServices.getRequestHistory(uuid).then((res) => {
        if (res?.data?.payload) {
          setRequestHistory(res?.data?.payload);
        }
        setApplicationData(data);
      });
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading) {
      if (!data) {
        toast.error(t("There was a problem downloading the data"));
        history.replace("/requests");
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (refresh === true) {
      RequestsServices.getRequest(uuid)()
        .then((res) => {
          RequestsServices.getRequestHistory(uuid).then((resHistory) => {
            setRequestHistory(resHistory.data.payload);
            setApplicationData(res);
          });
        })
        .finally(() => setRefresh(false));
    }
  }, [refresh]);

  const handleRefresh = (value) => {
    setRefresh(value);
  };

  const InfoData = useMemo(() => {
    if (!data || !dataInvestment) return [];

    const requestData = [
      { label: "First name and last name", value: data?.client_name },
      { label: "Phone", value: data?.client_phone },
      { label: "Email", value: data?.client_email },
      { label: "Investment name", value: data?.investment_name },
      {
        label: "Address",
        value: [dataInvestment?.city, dataInvestment?.address_street]
          .filter((el) => el !== undefined || el !== null)
          .join(", "),
      },
      { label: "Filing date", value: data?.status_date },
    ];

    const otherData = [
      { label: "First name and last name", value: data?.user_name },
    ];

    const investment = {
      promoting_agency_offer:
        dataInvestment?.promoting_agency_offer == "1" ? true : false,
    };

    return { data: requestData, otherData, investment };
  }, [data, dataInvestment]);

  return (
    <main className="mt-4">
      <div>
        {(!applicationData || isLoading) && <Loader />}
        {applicationData && (
          <RequestView
            requestHistory={requestHistory}
            applicationData={applicationData}
            requestUuid={uuid}
            handleRefresh={handleRefresh}
            infoData={InfoData}
          />
        )}
      </div>
    </main>
  );
};

export default Request;
