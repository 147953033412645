export const sortRealestates = (a, b) => {
  if (a.price && b.price) {
    return a.price - b.price;
  } else if (a.building && b.building) {
    if (a.building < b.building) {
      return -1;
    }
    if (a.building > b.building) {
      return 1;
    }
    return 0;
  } else if (a.building && !b.building) {
    return -1;
  } else if (!a.building && b.building) {
    return 1;
  } else {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }
};
