import { useState } from "react";
import { Menu } from "react-feather";
import { Form, Message, Modal, Table, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import cs from "classnames";
import Loader from "../../../components/Loader";
import PresentationService from "../../../services/PresentationService";
import { toast } from "react-toastify";
import DocumentsServices from "../../../services/DocumentsServices";
import ShowPresentation from "./components/ShowPresentation";

// import { useStoreState } from "easy-peasy";

const PresentationTable = ({
  handleRefresh,
  request_uuid,
  confirmation_document_created,
  confirmation_document_name,
  confirmation_document_uuid,
  client_id,
  investment_id,
}) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [error, setError] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState("");
  const [showPresentation, setShowPresentation] = useState(null);
  // const primaryRole = useStoreState((state) => state.auth.user?.primaryRole);

  const downloadFile = async () => {
    await DocumentsServices.getDocument({
      document_uuid: confirmation_document_uuid,
      file_name: `${client_id}_${investment_id}`,
    })
      .then(() => {
        toast.success(`${t("Document download started")}`);
      })
      .catch(() => toast.error(`${t("The document could not be downloaded")}`));
    setOpenPopup(false);
  };

  // const deleteFile = () => {
  //   setLoading(true);
  //   PresentationService.presentationRemove(request_uuid)
  //     .then(() => {
  //       toast.success(`${t("Presentation remove successfully")}`);
  //       handleRefresh(true);
  //     })
  //     .catch(() => {
  //       toast.error(`${t("Presentation could not be remove")}`);

  //       // setOpenModal(false);
  //       // handleRefresh(true);
  //     });

  //   setOpenPopup(false);
  // };

  const getType = (type) => {
    if (!type) return ".jpg";

    switch (type) {
      case "application/pdf":
        return ".pdf";
      case "image/jpeg":
        return ".jpg";
      case "image/png":
        return ".png";

      default:
        return ".jpg";
    }
  };
  const onSubmit = () => {
    const formData = new FormData();
    setLoading(true);
    formData.append(
      "name",
      subject ? `${subject}${getType(file?.type)}` : file?.name
    );
    formData.append("filename", file);

    if (file !== null) {
      PresentationService.presentationCreate(formData, request_uuid)
        .then(() => {
          toast.success(`${t("Presentation added successfully")}`);
          setFile(null);
          setSubject("");
          setOpenModal(false);
          handleRefresh(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error(`${t("Presentation could not be added")}`);
          setLoading(false);
        });
    } else {
      toast.error(`${t("To send query you must add the required files")}`);
      setLoading(false);
    }
  };

  const handleFile = (event) => {
    if (event?.target?.files?.length === 0) return;
    if (
      event?.target?.files[0]?.type !== "application/pdf" &&
      event?.target?.files[0]?.type !== "image/jpeg" &&
      event?.target?.files[0]?.type !== "image/png"
    ) {
      toast.error(`${t("Plik musi być w formacie .pdf / .jpg / .png")}`);
    } else {
      setFile(event?.target?.files[0]);
      setError(false);
    }
  };

  return (
    <>
      <Table celled structured textAlign="center">
        <Table.Body>
          <Table.Row>
            <Table.Cell>{t("Presentation confirmation")}</Table.Cell>

            <Table.Cell>
              {confirmation_document_name ? (
                confirmation_document_name
              ) : (
                <span
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  className="text-red-500 cursor-pointer"
                >
                  {t("No file")}
                </span>
              )}
            </Table.Cell>

            {confirmation_document_created && (
              <Table.Cell>{confirmation_document_created}</Table.Cell>
            )}

            <Table.Cell>
              <Popup
                trigger={
                  <Menu
                    className="text-primse-green z-10 cursor-pointer"
                    size={24}
                  />
                }
                flowing
                hoverable={true}
                on="click"
                open={openPopup}
                onOpen={() => setOpenPopup(true)}
                onClose={() => setOpenPopup(false)}
                position="bottom center"
              >
                {confirmation_document_uuid && (
                  <>
                    <div className="border-b">
                      <div
                        className="p-3 cursor-pointer hover:text-primse-green"
                        onClick={downloadFile}
                      >
                        <strong>{t("Download")}</strong>
                      </div>
                    </div>
                    <div className="border-b">
                      <div
                        className="p-3 cursor-pointer hover:text-primse-green"
                        onClick={() => {
                          setShowPresentation(true);
                          setOpenPopup(false);
                        }}
                      >
                        <strong>{t("Preview")}</strong>
                      </div>
                    </div>
                    {/* {primaryRole === "Admin" && (
                      <div className="border-b">
                        <div
                          className="p-3 cursor-pointer hover:text-primse-green"
                          onClick={deleteFile}
                        >
                          <strong>{t("Delete")}</strong>
                        </div>
                      </div>
                    )} */}
                  </>
                )}
                {!confirmation_document_uuid && (
                  <div
                    className="p-3 cursor-pointer hover:text-primse-green"
                    onClick={() => {
                      setOpenPopup(false);
                      setOpenModal(true);
                    }}
                  >
                    <strong>{t("Add")}</strong>
                  </div>
                )}
              </Popup>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <ShowPresentation
        setShowPresentation={setShowPresentation}
        showPresentation={showPresentation}
        confirmation_document_uuid={confirmation_document_uuid}
        downloadFile={downloadFile}
      />
      <Modal open={openModal}>
        <Modal.Header
          style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
        >
          <div className="flex justify-between">
            <h3 className="text-xl font-bold">{t("Update a request")}</h3>
            <AiFillCloseCircle
              onClick={() => setOpenModal(false)}
              className="text-primse-gray-font cursor-pointer w-8 h-8"
            />
          </div>
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Table celled structured textAlign="center">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="5">
                    {t("Required attachments")}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell colSpan="2" className="w-96">
                    {t("Description")}
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan="1" className="w-96">
                    {t("Name")}
                  </Table.HeaderCell>
                  <Table.HeaderCell>{t("Status")} </Table.HeaderCell>
                  <Table.HeaderCell className="w-44">
                    {t("File")} <span className="text-red-500">*</span>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row error={error}>
                  <Table.Cell colSpan="2">
                    <span className="font-bold lg:hidden">
                      {t("Description")}
                    </span>
                    {t("Reservation confirmation")}
                  </Table.Cell>
                  <Table.Cell className="w-96">
                    <input
                      placeholder={`${t("Name")}`}
                      className=" ml-4"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                  </Table.Cell>
                  <Table.Cell className="w-96">
                    <span>{file ? file.name : `${t("No file")}`}</span>
                  </Table.Cell>
                  <Table.Cell className="w-44">
                    <Form.Field className="flex justify-center">
                      <label className="w-40 uppercase font-bold text-white bg-primse-green py-3 px-5 rounded-sm transition-all  hover:bg-primse-gray   cursor-pointer">
                        {t("Choose")}
                        <input
                          type="file"
                          accept=".jpeg,.jpg,.png,.pdf"
                          onChange={(event) => {
                            handleFile(event);
                          }}
                          hidden
                        />
                      </label>
                    </Form.Field>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <div className="p-4 flex justify-end">
              <button
                className="mx-2 w-40 uppercase font-bold text-white bg-primse-yellow py-3 px-5 rounded-sm transition-all hover:bg-primse-gray"
                onClick={() => setOpenModal(false)}
              >
                {t("Close")}
              </button>
              <button
                disabled={error}
                onClick={(e) => onSubmit(e)}
                className={cs(
                  "mx-2 w-40 uppercase font-bold text-white py-3 px-5 rounded-sm",
                  {
                    "bg-primse-green transition-all hover:bg-primse-gray": !error,
                  },
                  { "bg-primse-gray cursor-default": error }
                )}
              >
                {t("Send")}
              </button>
            </div>
            {error && (
              <Message
                negative={true}
                content={t(
                  "Fill in the required conditions to submit the request"
                )}
              />
            )}
          </Form>
          {loading && <Loader />}
        </Modal.Content>
      </Modal>
    </>
  );
};

export default PresentationTable;
