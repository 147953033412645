import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "semantic-ui-react";
import Container from "../../../components/Container";
import QuestionsItemMobile from "./../QuestionsItemMobile";

import { Overlay } from "../../../components/Overlay";
import NewsSection from "../../../components/NewsSection";
import { useLocation } from "react-router-dom";
import NewQuestionModal from "../NewQuestionModal";

import ListItem from "./ListItem";
import useUserRole from "../../../hooks/useUserRole";

const QuestionsView = ({ data, handleRefresh }) => {
  const [activePage, setActivePage] = useState(1);

  const [sorted] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const [addQuestionData, setAddQuestionData] = useState(null);
  const { isAgent } = useUserRole();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location?.search && location?.search !== "") {
      const object = {};
      const allQuery = location?.search.slice(1).split("&");
      allQuery?.forEach((el) => {
        const elQuery = el.split("=");
        if (elQuery?.length === 2) {
          object[elQuery[0]] = elQuery[1];
        }
      });
      if (object?.r_uuid && object.i_uuid) {
        setAddQuestionData(object);
      }
    }
    return () => setAddQuestionData(null);
  }, [location.search]);

  const [pagination, setPagination] = useState({
    data: [],
    offset: 0,
    numberPerPage: 100,
    pageCount: 1,
    currentData: [],
  });

  useEffect(() => {
    if (!sorted) {
      setPagination((prevState) => ({
        ...prevState,
        data: data?.payload.sort((a, b) =>
          new Date(a.date_created) < new Date(b.date_created) ? 1 : -1
        ),
      }));
    }
  }, [data?.payload]);

  useEffect(() => {
    setPagination((prevState) => ({
      ...prevState,
      pageCount: Math.ceil(prevState.data?.length / prevState.numberPerPage),
      currentData: prevState.data.slice(
        pagination.offset,
        pagination.offset + pagination.numberPerPage
      ),
    }));
  }, [pagination.data, pagination.numberPerPage, pagination.offset]);

  const handlePageClick = (event, { activePage }) => {
    setActivePage(activePage);
    const offset = (activePage - 1) * pagination.numberPerPage;
    setPagination({ ...pagination, offset });
  };

  return (
    <>
      <div style={{ minHeight: "calc(80vh + 1rem)" }}>
        {showMobileMenu && <Overlay onClose={() => setShowMobileMenu(false)} />}
        <Container className="lg:px-2">
          <div className="py-2 items-center justify-between hidden lg:flex">
            <div className="md:mx-2 md:my-0">
              <h3 className="text-xl font-bold">
                {t("Search results")}:{" "}
                <span className="text-primse-green">
                  {pagination.currentData?.length}
                </span>
              </h3>
            </div>
            <div className="flex items-center -ml-2">
              <div className="my-2 mx-2 md:my-0 flex  items-center hidden lg:inline-flex">
                {isAgent() && (
                  <div className="my-2 md:mx-2 md:my-0">
                    <NewQuestionModal
                      handleRefresh={handleRefresh}
                      addQuestionData={addQuestionData}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="p-4 mt-6 border rounded-xl w-full flex flex-col lg:hidden">
            {pagination.currentData?.map(
              ({
                date_created,
                developer_name,
                investment_name,
                investment_uuid,
                realestate_name,
                realestate_uuid,
                status_name,
                subject_name,
                reply,

                uuid,
              }) => (
                <QuestionsItemMobile
                  key={uuid}
                  data={{
                    date_created,
                    developer_name,
                    investment_name,
                    investment_uuid,
                    realestate_name,
                    realestate_uuid,
                    status_name,
                    subject_name,
                    reply,

                    uuid,
                  }}
                />
              )
            )}
          </div>
          <table className="w-full hidden lg:table">
            <thead className="bg-white font-bold uppercase z-20 text-xs">
              <tr className="border-b h-14">
                <th className="px-2 text-left sticky top-20 bg-white ">
                  {t("Created")}
                </th>
                <th className="px-2 text-left sticky top-20 bg-white ">
                  {t("Developer")}
                </th>
                <th className="px-2 text-left sticky top-20 bg-white ">
                  {t("Status")}
                </th>
                <th className="px-2 text-left sticky top-20 bg-white ">
                  {t("Subject")}
                </th>
                <th className="px-2 text-left sticky top-20 bg-white ">
                  {t("Reply")}
                </th>
                <th className="px-2 text-left sticky top-20 bg-white ">
                  {t("Investment")}
                </th>
                <th className="px-2 text-left sticky top-20 bg-white">
                  {t("Realestate name")}
                </th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {pagination.currentData?.map((data) => (
                <ListItem
                  key={data.uuid}
                  data={data}
                  handleRefresh={handleRefresh}
                />
              ))}
            </tbody>
          </table>
          {pagination.data?.length > 100 && (
            <div className="py-4">
              <Pagination
                activePage={activePage}
                totalPages={pagination.pageCount}
                onPageChange={handlePageClick}
              />
            </div>
          )}
        </Container>
      </div>
      <NewsSection />
    </>
  );
};

export default QuestionsView;
