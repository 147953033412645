import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Dropdown, Input } from "semantic-ui-react";
import { X } from "react-feather";
import cs from "classnames";

import ReportsServices from "../../services/ReportsServices";
import DictionariesServices from "../../services/DictionariesServices";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";

const Th = ({ children, rowspan, colspan, className }) => (
  <th
    className={cs(
      "px-1 text-center z-20 bg-white  border-primse-green",
      className
    )}
    rowSpan={rowspan && rowspan}
    colSpan={colspan && colspan}
  >
    {children}
  </th>
);
const Td = ({ children, className }) => (
  <th
    className={cs("py-2 px-2 border-b overflow-auto font-normal  ", className)}
  >
    {children}
  </th>
);

const ReportAgency = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [reportsData, setReportsData] = useState([]);
  const [reportsDataTotal, setReportsDataTotal] = useState({});
  const [totalAgencies, setTotalAgencies] = useState([]);
  const [totalUsers, setTotalUsers] = useState([]);
  const [totalCities, setTotalCities] = useState([]);

  const [selectedCity, setSelectedCity] = useState("");
  const [selectedAgency, setSelectedAgency] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserStatus, setSelectedUserStatus] = useState("");
  const [selectedDataFrom, setSelectedDataFrom] = useState("");
  const [selectedDataTo, setSelectedDataTo] = useState("");

  const [loaded, setLoaded] = useState(false);

  const branch = useStoreState(
    (state) => state.dictionaries.dictionariesList.branch
  );

  const setDictionaries = useStoreActions(
    (actions) => actions.dictionaries.setDictionaries
  );

  const totalUsersStatus = [
    { text: t("Active"), value: 1 },
    { text: t("Inactive"), value: 0 },
  ];

  useEffect(() => {
    setLoaded(false);
    const formData = new FormData();

    const date = new Date();

    const firstDay = new Date(date.getFullYear(), date.getMonth(), 2)
      .toISOString("yyyy-MM-dd")
      .slice(0, 10);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1)
      .toISOString("yyyy-MM-dd")
      .slice(0, 10);

    setSelectedDataFrom(firstDay);
    setSelectedDataTo(lastDay);

    formData.append(["date_from"], firstDay);
    formData.append(["date_to"], lastDay);
    ReportsServices.reportAgency(null, formData).then((res) => {
      setData(res);
    });
  }, []);

  const fixDate = (date) => {
    const d = new Date(date * 1000);

    const fixDate = (date) => {
      if (date < 10) return "0" + date;
      else return date;
    };

    const datestring =
      fixDate(d.getDate()) +
      "-" +
      fixDate(d.getMonth() + 1) +
      "-" +
      d.getFullYear();

    return datestring;
  };

  const setDataFn = (data) => {
    if (data?.agencies_total?.length !== 0) {
      setReportsDataTotal(data?.agencies_total?.users);
    }
    const arr = [];

    if (!data?.agencies?.users) {
      setReportsData(arr);
      setLoaded(true);
      return;
    }

    for (const [key, value] of Object.entries(data?.agencies?.users)) {
      arr.push({
        id: key,
        user_first_name: value.user.user_first_name
          ? value.user.user_first_name
          : "",
        user_last_name: value.user.user_last_name
          ? value.user.user_last_name
          : "",
        agency_city: value.user.agency_city ? value.user.agency_city : "",
        agency_name: value.user.agency_name ? value.user.agency_name : "",
        agency_id: value.user.agency_id ? value.user.agency_id : "",
        agency_supervisor_first_name: value.user.agency_supervisor_first_name
          ? value.user.agency_supervisor_first_name
          : "",
        agency_supervisor_last_name: value.user.agency_supervisor_last_name
          ? value.user.agency_supervisor_last_name
          : "",
        user_created: value.user.user_created ? value.user.user_created : "",
        login_count: value.login_count ? value.login_count : 0,
        investment_view: value.investment_view ? value.investment_view : 0,
        query_accepted: value.query_accepted ? value.query_accepted : 0,
        query_accepted_conditionally: value.query_accepted_conditionally
          ? value.query_accepted_conditionally
          : 0,
        query_awaiting: value.query_awaiting ? value.query_awaiting : 0,
        query_confirmed: value.query_confirmed ? value.query_confirmed : 0,
        query_count: value.query_count ? value.query_count : 0,
        query_expired: value.query_expired ? value.query_expired : 0,
        query_rejected: value.query_rejected ? value.query_rejected : 0,
        query_rejected_percent: value.query_rejected_percent
          ? value.query_rejected_percent
          : 0,

        transaction_open: value.transaction_open ? value.transaction_open : 0,
        total_transaction_value: value.total_transaction_value
          ? value.total_transaction_value
          : 0,
        average_transaction_value: value.average_transaction_value
          ? value.average_transaction_value
          : 0,
        transaction_settled: value.transaction_settled
          ? value.transaction_settled
          : 0,
        total_commission_agency_price_net: value.total_commission_agency_price_net
          ? value.total_commission_agency_price_net
          : 0,
      });
    }

    setReportsData(arr);

    setLoaded(true);
  };

  useEffect(() => {
    prepareReportsData();
  }, [data]);

  const prepareReportsData = async () => {
    setLoaded(false);
    if (data) {
      if (data?.agencies_total?.length !== 0) {
        setReportsDataTotal(data?.agencies_total?.users);
      }

      const arr = [];

      const arrAgencies = [];
      const arrUsers = [];
      const arrCities = [];

      if (!data?.agencies?.users) return setLoaded(true);

      let branchFromApi = null;

      if (!branch) {
        const dictionaries = await DictionariesServices.getDictionaries();
        if (dictionaries) {
          setDictionaries(dictionaries);
          branchFromApi = dictionaries.branch;
        }
      }

      const branchData = branch || branchFromApi;

      if (branchData) {
        for (const [key, value] of Object.entries(branchData)) {
          arrAgencies.push({
            key: key,
            value: key,
            text: value,
          });
        }
      }

      for (const [key, value] of Object.entries(data?.agencies?.users)) {
        if (value?.user?.user_first_name && value?.user?.user_last_name) {
          arrUsers.push({
            key: key,
            value: key,
            text: value.user.user_last_name + " " + value.user.user_first_name,
          });
        }
        if (value?.user?.agency_city) {
          arrCities.push({
            key: value.user.agency_city,
            value: value.user.agency_city,
            text: value.user.agency_city,
          });
        }

        arr.push({
          id: key,
          user_first_name: value.user.user_first_name
            ? value.user.user_first_name
            : "",
          user_last_name: value.user.user_last_name
            ? value.user.user_last_name
            : "",
          agency_city: value.user.agency_city ? value.user.agency_city : "",
          agency_name: value.user.agency_name ? value.user.agency_name : "",
          agency_supervisor_first_name: value.user.agency_supervisor_first_name
            ? value.user.agency_supervisor_first_name
            : "",
          agency_supervisor_last_name: value.user.agency_supervisor_last_name
            ? value.user.agency_supervisor_last_name
            : "",
          user_created: value.user.user_created ? value.user.user_created : "",
          login_count: value.login_count ? value.login_count : 0,
          investment_view: value.investment_view ? value.investment_view : 0,
          query_accepted: value.query_accepted ? value.query_accepted : 0,
          query_accepted_conditionally: value.query_accepted_conditionally
            ? value.query_accepted_conditionally
            : 0,
          query_awaiting: value.query_awaiting ? value.query_awaiting : 0,
          query_confirmed: value.query_confirmed ? value.query_confirmed : 0,
          query_count: value.query_count ? value.query_count : 0,
          query_expired: value.query_expired ? value.query_expired : 0,
          query_rejected: value.query_rejected ? value.query_rejected : 0,
          query_rejected_percent: value.query_rejected_percent
            ? value.query_rejected_percent
            : 0,

          transaction_open: value.transaction_open ? value.transaction_open : 0,
          total_transaction_value: value.total_transaction_value
            ? value.total_transaction_value
            : 0,
          average_transaction_value: value.average_transaction_value
            ? value.average_transaction_value
            : 0,
          transaction_settled: value.transaction_settled
            ? value.transaction_settled
            : 0,
          total_commission_agency_price_net: value.total_commission_agency_price_net
            ? value.total_commission_agency_price_net
            : 0,
        });
      }
      const sortFunction = (a, b) => {
        if (a.text.toLowerCase() < b.text.toLowerCase()) {
          return -1;
        }
        if (a.text.toLowerCase() > b.text.toLowerCase()) {
          return 1;
        }
        return 0;
      };

      const setUniqe = (arr, value) => {
        return arr
          .filter(
            (thing, index, self) =>
              index === self.findIndex((t) => t[value] === thing[value])
          )
          .sort(sortFunction);
      };

      setReportsData(arr);
      setTotalAgencies(setUniqe(arrAgencies, "text"));
      setTotalUsers(setUniqe(arrUsers, "text"));
      setTotalCities(setUniqe(arrCities, "text"));

      setLoaded(true);
    }

    setLoaded(true);
  };

  const handleClearSearch = () => {
    setSelectedCity("");
    setSelectedAgency("");
    setSelectedUser("");
    setSelectedUserStatus("");
    setSelectedDataFrom("");
    setSelectedDataTo("");
    setLoaded(false);
    ReportsServices.reportAgency().then((res) => {
      setData(res);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (selectedCity !== "") {
      formData.append(["city"], selectedCity);
    }
    if (selectedAgency !== "") {
      formData.append(["agency"], selectedAgency);
    }
    if (selectedUser !== "") {
      formData.append(["user"], selectedUser);
    }
    if (selectedUserStatus !== "") {
      formData.append(["user_status"], selectedUserStatus);
    }
    if (selectedDataTo !== "") {
      formData.append(["date_to"], selectedDataTo);
    }
    if (selectedDataFrom !== "") {
      formData.append(["date_from"], selectedDataFrom);
    }
    setLoaded(false);
    ReportsServices.reportAgency(null, formData).then((res) => {
      setDataFn(res);
    });
  };

  return (
    <main>
      <div style={{ minHeight: "calc(80vh + 1rem)" }}>
        {!loaded && <Loader />}

        <Container>
          <header className="sticky top-20 py-4 lg:pt-0 lg:pb-2 lg:top-20  z-20 bg-white w-screen lg:w-full">
            <form
              onSubmit={handleSubmit}
              className={cs(
                "flex flex-col items-center  absolute w-screen lg:w-full bg-white lg:static lg:justify-start lg:border-t-0 lg:py-0 transition-all transform  lg:transform -translate-y-0 duration-500"
                // { "-translate-y-mobileClientSearchHeight": !showMobileMenu }
              )}
            >
              <div className="w-full  flex flex-col lg:flex-row items-center lg:justify-start flex-wrap">
                <Input
                  className="w-56 mx-2 mb-2 h-12 lg:mt-4"
                  label={`${t("Post date")} ${t("from")}`}
                  labelPosition={"left corner"}
                  fluid={true}
                  type="date"
                  size={"small"}
                  onChange={(e, data) => setSelectedDataFrom(data.value)}
                  value={selectedDataFrom}
                />
                <Input
                  className="w-56 mx-2 mb-2 h-12 lg:mt-4"
                  label={`${t("Post date")} ${t("to")}`}
                  labelPosition={"left corner"}
                  fluid={true}
                  type="date"
                  size={"small"}
                  onChange={(e, data) => setSelectedDataTo(data.value)}
                  value={selectedDataTo}
                />
                <Dropdown
                  className="h-12 mx-2  mb-2 lg:mt-4"
                  placeholder={t("Agency")}
                  fluid={false}
                  search={true}
                  selection={true}
                  onChange={(e, data) => setSelectedAgency(data.value)}
                  options={totalAgencies}
                  value={selectedAgency}
                  clearable
                  selectOnBlur={false}
                />

                <Dropdown
                  className="h-12 mx-2 my-2   lg:mt-4"
                  placeholder={t("User")}
                  fluid={false}
                  search={true}
                  selection={true}
                  onChange={(e, data) => setSelectedUser(data.value)}
                  options={totalUsers}
                  value={selectedUser}
                  noResultsMessage={t("No results found")}
                  clearable
                  selectOnBlur={false}
                />
                <Dropdown
                  className="h-12 mx-2 my-2   lg:mt-4"
                  placeholder={t("User status")}
                  fluid={false}
                  selection={true}
                  onChange={(e, data) => setSelectedUserStatus(data.value)}
                  options={totalUsersStatus}
                  value={selectedUserStatus}
                  noResultsMessage={t("No results found")}
                  clearable
                  selectOnBlur={false}
                />

                <div className="hidden lg:flex  mb-2 lg:mt-4 ">
                  <Dropdown
                    className="h-12 mx-2"
                    placeholder={t("City")}
                    fluid={false}
                    search={true}
                    selection={true}
                    onChange={(e, data) => setSelectedCity(data.value)}
                    options={totalCities}
                    value={selectedCity}
                    noResultsMessage={t("No results found")}
                    clearable
                    selectOnBlur={false}
                  />
                </div>
                <div className="mx-2 justify-center items-center">
                  <button
                    className="leading-5 flex items-center py-3 px-4"
                    type="button"
                    onClick={handleClearSearch}
                  >
                    <X size={14} className="mr-2" />
                    {t("Clear")}
                  </button>
                </div>
                <div className="mx-2">
                  <button
                    className="leading-5 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                    type="submit"
                    // onClick={() => setShowMobileMenu(false)}
                  >
                    {t("Search")}
                  </button>
                </div>
              </div>
            </form>
          </header>
          <div className="hidden lg:flex w-full items-center justify-center lg:justify-start">
            <div className="mx-2 mb-4">
              <h3 className="text-xl font-bold">
                {t("Search results")}:{" "}
                <span className="text-primse-green">{reportsData?.length}</span>
              </h3>
            </div>
          </div>
          {loaded && reportsData?.length !== 0 && (
            <table
              className="w-full hidden text-center lg:block border-separate"
              style={{ borderSpacing: 0 }}
            >
              <thead
                className="uppercase w-full sticky top-40 pt-8 "
                // className="uppercase text-xs w-full"
                style={{ fontSize: "0.7rem", lineFeight: "0.75rem" }}
              >
                <tr className="">
                  {/* <Th rowspan="2" className="border-t border-r border-b border-l">id</Th> */}
                  <Th
                    rowspan="2"
                    className="border-t border-r border-b border-l"
                  >
                    id
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("User")}
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    Region
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Agency")}
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Agency supervisor")}
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Account created")}
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Login count")}
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Investment views")}
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Offers")}
                  </Th>
                  <Th
                    colspan="8"
                    className="text-center border-t border-r border-b"
                  >
                    {t("Requests")}
                  </Th>
                  <Th rowspan="2" className="border-t border-r border-b">
                    {t("Presentations")}
                  </Th>
                  <Th
                    colspan="4"
                    className="text-center border-t border-r border-b"
                  >
                    {t("Transactions")}
                  </Th>
                </tr>
                <tr>
                  <Th className="border-r border-b"> {t("Accepted")}</Th>
                  <Th className="border-r border-b">
                    {t("Accepted conditionally")}
                  </Th>
                  <Th className="border-r border-b"> {t("Awaiting")}</Th>
                  <Th className="border-r border-b">{t("All")}</Th>
                  <Th className="border-r border-b">{t("Rejected")}</Th>
                  <Th className="border-r border-b">{t("Rejected")} (%)</Th>
                  <Th className="border-r border-b">
                    {" "}
                    {t("Average response time")}
                  </Th>
                  <Th className="border-r border-b">
                    {t("Average query time")}
                  </Th>
                  <Th className="border-r border-b">
                    {" "}
                    {t("Number of transactions")}
                  </Th>
                  <Th className="border-r border-b">
                    {t("Total transaction value")}
                  </Th>
                  <Th className="border-r border-b">
                    {t("Average transaction value (PLN)")}
                  </Th>
                  <Th className="border-r border-b">
                    {" "}
                    {t("Value of commissions (PLN)")}
                  </Th>
                </tr>
              </thead>
              {/* <tbody className="text-sm"> */}
              <tbody style={{ fontSize: "0.70rem", lineFeight: "0.75rem" }}>
                <tr className="border-b h-14 hover:bg-primse-light-green cursor-pointer ">
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td>{t("Total")}</Td>
                  <Td>
                    {reportsDataTotal.login_count
                      ? reportsDataTotal.login_count
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.investment_view
                      ? reportsDataTotal.investment_view
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.offer_count
                      ? reportsDataTotal.offer_count
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.query_accepted
                      ? reportsDataTotal.query_accepted
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.query_accepted_conditionally
                      ? reportsDataTotal.query_accepted_conditionally
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.query_awaiting
                      ? reportsDataTotal.query_awaiting
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.query_count
                      ? reportsDataTotal.query_count
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.query_rejected
                      ? reportsDataTotal.query_rejected
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal?.query_rejected_percent
                      ? Number(reportsDataTotal.query_rejected_percent).toFixed(
                          2
                        )
                      : 0}{" "}
                    %
                  </Td>

                  <Td>{t("No data")}</Td>
                  <Td>{t("No data")}</Td>

                  <Td>
                    {reportsDataTotal.query_confirmed
                      ? reportsDataTotal.query_confirmed
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.transaction_settled
                      ? reportsDataTotal.transaction_settled
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.total_transaction_value
                      ? reportsDataTotal.total_transaction_value
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.average_transaction_value
                      ? reportsDataTotal.average_transaction_value
                      : 0}
                  </Td>
                  <Td>
                    {reportsDataTotal.total_commission_agency_price_net
                      ? reportsDataTotal.total_commission_agency_price_net
                      : 0}
                  </Td>
                </tr>

                {reportsData.map((data) => {
                  return (
                    <tr
                      key={data.id}
                      className="border-b h-14 hover:bg-primse-light-green cursor-pointer "
                    >
                      <Td>{data.id}</Td>
                      <Td>
                        {data.user_last_name} {data.user_first_name}
                      </Td>
                      <Td>{data.agency_city}</Td>
                      <Td>{data.agency_name}</Td>
                      <Td>
                        {data?.agency_supervisor_last_name}{" "}
                        {data?.agency_supervisor_first_name}
                      </Td>
                      <Td>{fixDate(data.user_created)}</Td>
                      <Td>{data.login_count}</Td>
                      <Td>{data.investment_view}</Td>
                      <Td>{data.query_count}</Td>
                      <Td>{data.query_accepted}</Td>
                      <Td>{data.query_accepted_conditionally}</Td>
                      <Td>{data.query_awaiting}</Td>
                      <Td>{data.query_count}</Td>
                      <Td>{data.query_rejected}</Td>
                      <Td>
                        {Number(data.query_rejected_percent).toFixed(2)} %
                      </Td>
                      <Td>{t("No data")}</Td>
                      <Td>{t("No data")}</Td>
                      <Td>{data.query_confirmed}</Td>
                      <Td>{data.transaction_settled}</Td>
                      <Td>{data.total_transaction_value}</Td>
                      <Td>{data.average_transaction_value}</Td>
                      <Td>{data.total_commission_agency_price_net}</Td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Container>
      </div>
      <Footer />
    </main>
  );
};

export default ReportAgency;
