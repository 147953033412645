import { StoreProvider, useStoreRehydrated, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import ModalLogin from "./modals/LoginModal";
import ProblemModal from "./modals/ProblemModal";
import Routing from "./routing";
import store, { actions } from "./store";

import Loader from "./components/Loader";
import EditUserModal from "./modals/EditUserModal";
import AuthServices from "./services/AuthServices";
// import IdleTimer from "./IdleTimer";
// import AuthService from "./services/AuthServices";
import axios from "axios";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
});

const App = () => {
  const token = localStorage.getItem("token");
  // const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedAuth, setIsLoadedAuth] = useState(!token);
  const isRehydrated = useStoreRehydrated();
  // const { user } = useStoreState((state) => state.auth);

  useEffect(() => {
    const el = document.querySelector(".loader-container");
    el.remove();
  }, []);

  useEffect(() => {
    if (token) {
      AuthServices.getUserInfo().finally(() => setIsLoadedAuth(true));
    }
    // console.log(token);
    // if (token === null) {
    //   actions.auth.setUser(null);
    //   actions.briefcase.clearClient();
    //   AuthService.logOut(null);
    // }
    actions.modals.openRequestModal(false);
  }, []);

  // useEffect(() => {
  //   if (isRehydrated) {
  //     console.log("Error 2");
  //     axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  //     AuthServices.getUserInfo()
  //         .finally(() => setIsLoaded(true))
  //   }
  // }, [isRehydrated, token]);

  // useEffect(() => {
  //   if (token) {
  //     const timer = new IdleTimer({
  //       timeout: 3600,
  //       onTimeout: () => {
  //         setIsTimeout(true);
  //         AuthService.logOut(null);
  //       },
  //       onExpired: () => {
  //         setIsTimeout(true);
  //         AuthService.logOut(null);
  //       },
  //     });
  //     return () => {
  //       timer.cleanUp();
  //     };
  //   }
  // }, [token]);

  useEffect(() => {
    axios.get("/gitInfo.json").then((res) => {
      actions.version.setVersion(res.data);
    });
  }, []);

  const { isLoading: appIsLoading } = useStoreState((state) => state.isLoading);

  if (!isLoadedAuth) return <Loader />;

  const gtmVirtualPageView = (rest) => {
    window.dataLayer?.push({
      event: "VirtualPageView",
      ...rest,
    });
  };

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);

      const mainDataLayer = {
        url: pathname,
      };

      window.onload = () => window.dataLayer?.push({ ...mainDataLayer });

      gtmVirtualPageView(mainDataLayer);
    }, [pathname]);

    return null;
  }
  if (!isRehydrated) return null;
  return (
    <Router>
      <div className="text-primse-gray" id="contentWebsite">
        <Header />
        <ScrollToTop />
        <div id="HelloWorld">
          <Routing />
        </div>
        <EditUserModal />
        <ModalLogin />
        <ProblemModal />
      </div>
      {appIsLoading && <Loader />}
    </Router>
  );
};

const AppConfig = () => (
  <StoreProvider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />

      {process.env.NODE_ENV === "development" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </QueryClientProvider>
    <ToastContainer />
  </StoreProvider>
);

export default AppConfig;
