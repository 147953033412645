import React, { useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/all";
import { editPrice } from "../../../../utils/numbers";
import RealestateItem from "./RealestateItem";

const MapRealestateView = ({
  onClosedMarker,
  setActiveScrollMap = () => {},
  data,
}) => {
  useEffect(() => {
    return () => {
      setActiveScrollMap(true);
    };
  }, []);

  return (
    <div
      onMouseEnter={() => {
        setActiveScrollMap(false);
      }}
      onMouseLeave={() => {
        setActiveScrollMap(true);
      }}
      className="p-5 bg-white absolute bottom-11 -left-80 shadow-md z-10 "
    >
      {/* {isLoading && <Loader position="absolute" opacity="bg-opacity-100" />} */}
      <div className="flex">
        <div className="absolute right-5 top-5" data-id="exitModal">
          <AiFillCloseCircle
            onClick={() => {
              setActiveScrollMap(true);
              onClosedMarker();
            }}
            className="text-primse-gray-font cursor-pointer w-6 h-6"
            data-id="exitModal"
          />
        </div>

        <RealestateItem data={data} editPrice={editPrice} />
      </div>
    </div>
  );
};

export default MapRealestateView;
