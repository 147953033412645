import axios from "../utils/axios";

const RealestatesServices = {
  getRealestates: ({
    price_from,
    price_to,
    area_from,
    area_to,
    location,
    rooms_from,
    rooms_to,
    floor_from,
    floor_to,
    completion_quarter,
    closed,
    basement,
    balcony,
    terrace,
    green_terrace,
    has_parking_space,
    realestate_category,
  }) => async () => {
    return await axios
      .get("/realestate-sm-index", {
        params: {
          perPage: -1,

          realestate_category: realestate_category
            ? `${realestate_category}`
            : null,
          price_from: price_from
            ? `${Number(price_from.replaceAll(" ", ""))}`
            : null,
          price_to: price_to ? `${Number(price_to.replaceAll(" ", ""))}` : null,
          area_from: area_from ? `${area_from}` : null,
          area_to: area_to ? `${area_to}` : null,
          location: location ? location : null,

          rooms_from: rooms_from ? `${rooms_from}` : null,
          rooms_to: rooms_to ? `${rooms_to}` : null,
          floor_from:
            floor_from || typeof floor_from === "number"
              ? `${floor_from}`
              : null,
          floor_to:
            floor_to || typeof floor_to === "number" ? `${floor_to}` : null,

          completion_quarter: completion_quarter
            ? `${completion_quarter}`
            : null,
          closed_area: closed ? `${closed}` : null,
          basement: basement ? `${basement}` : null,
          balcony: balcony ? "1" : null,
          terrace: terrace ? "1" : null,
          green_terrace: green_terrace ? "1" : null,
          has_parking_space: has_parking_space ? "1" : null,
        },
      })
      .then((res) => res.data);
  },
  getRealestate: (uuid) => async () => {
    return await axios
      .get(`/realestate-view/${uuid}`)
      .then((res) => res.data.payload);
  },
  getFlatList: (
    uuid,
    { roomsFrom, roomsTo, priceFrom, priceTo, areaFrom, areaTo, perPage },
    onlyEnable = false
  ) => async () => {
    return await axios
      .get(`/realestate-index/${uuid}`, {
        params: {
          rooms_from: roomsFrom,
          rooms_to: roomsTo,
          price_from: priceFrom ? priceFrom?.replaceAll(" ", "") : null,
          price_to: priceTo ? priceTo?.replaceAll(" ", "") : null,
          area_from: areaFrom,
          area_to: areaTo,
          perPage,
          realestate_status_id: onlyEnable ? "1" : null,
        },
      })
      .then((res) => res.data);
  },

  getSelectRealestates: ({
    page,
    realestate_category,
    location,
    area_from,
    area_to,
    rooms_from,
    rooms_to,
    price_from,
    price_to,
    search_secondary,
    search_primary,
    order,
    investment_name,
    floor_from,
    floor_to,
    completion_quarter,
    closed,
    basement,
    balcony,
    terrace,
    green_terrace,
    has_parking_space,
    name,
    onlyEnable = false,
  }) => async () => {
    return await axios
      .get("/realestate-list", {
        params: {
          realestate_category: realestate_category,
          page: page,
          location: location,
          area_from: area_from ? `${area_from}` : null,
          area_to: area_to ? `${area_to}` : null,
          rooms_from: rooms_from ? `${rooms_from}` : null,
          rooms_to: rooms_to ? `${rooms_to}` : null,
          price_from: price_from ? `${price_from}` : null,
          price_to: price_to ? `${price_to}` : null,
          search_secondary: search_secondary ? true : null,
          search_primary: search_primary ? true : null,
          order: order ? `${order}` : null,
          investment_name: investment_name ? `${investment_name}` : null,
          name: name ? `${name}` : null,
          floor_from:
            floor_from || typeof floor_from === "number"
              ? `${floor_from}`
              : null,
          floor_to:
            floor_to || typeof floor_to === "number" ? `${floor_to}` : null,
          completion_quarter: completion_quarter
            ? `${completion_quarter}`
            : null,
          closed_area: closed ? `${closed}` : null,
          basement: basement ? `${basement}` : null,
          balcony: balcony ? "1" : null,
          terrace: terrace ? "1" : null,
          green_terrace: green_terrace ? "1" : null,
          has_parking_space: has_parking_space ? "1" : null,
          realestate_status_id: onlyEnable ? "1" : null,
        },
      })
      .then((res) => res.data);
  },
};

export default RealestatesServices;
