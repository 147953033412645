import axios from "../utils/axios";

const QuestionsService = {
  getQuestions: async () => {
    return await axios
      .get("/questions-index", {
        params: {
          perPage: 10000,
        },
      })
      .then((res) => {
        return res;
      });
  },
  getQuestionsSubject: async () => {
    return await axios
      .get("/questions-subject-index", {
        params: {
          perPage: 10000,
        },
      })
      .then((res) => {
        return res;
      });
  },
  getQuestionsStatus: async () => {
    return await axios
      .get("/questions-status-index", {
        params: {
          perPage: 10000,
        },
      })
      .then((res) => {
        return res;
      });
  },

  questionsCreate: async ({ subject_id, investment_uuid, realestate_uuid }) => {
    return await axios.post("/questions-create", {
      subject_id: `${subject_id}`,
      investment_uuid: `${investment_uuid}`,
      realestate_uuid: `${realestate_uuid}`,
    });
  },
  questionsReply: async ({ question_uuid, reply }) => {
    return await axios.post("/questions-reply", {
      question_uuid: `${question_uuid}`,
      reply: `${reply}`,
    });
  },
};

export default QuestionsService;
