import { Link } from "react-router-dom";
import dimensionsSrc from "./assets/dimensions@2x.png";
import peopleSrc from "./assets/people@2x.png";
import { BriefCasePlusWhite } from "../../../../components/Icons";
import PopupTrigger from "../../../../components/PopupTrigger";
import ButtonNavLink from "../../../../components/ButtonNavLink";
import { useState } from "react";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { editPrice } from "../../../../utils/numbers";
import { editCompletionDate } from "../../../../utils/searchUtils";

const ImageInvestment = ({
  uuid,
  uuidDeleteFromBasket,
  name,
  investment_pictures,
  handleRefreshBasket,
  currentBasketMore,
  investment_status,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {!isLoaded && (
        <div className="ui placeholder" style={{ width: "320px" }}></div>
      )}

      <div
        className={cs(
          "flex flex-col block lg:hidden xl:block relative",
          isLoaded ? "w-full md:w-1/2" : "w-0"
        )}
        style={{ opacity: isLoaded ? 1 : 0 }}
      >
        <div className="absolute top-0 right-0">
          <PopupTrigger
            aftermarket={false}
            uuid={uuid}
            investmentData={name}
            typeButton="deleteBasket"
            uuidDeleteFromBasket={uuidDeleteFromBasket}
            handleRefreshBasket={handleRefreshBasket}
            currentBasketMore={currentBasketMore}
          >
            <div className="block max-h-0">
              <div className="bg-primse-green ml-3 rounded-sm flex justify-center items-center cursor-pointer transition-all duration-300 hover:opacity-80 w-12 h-12">
                <div className="w-8">
                  <BriefCasePlusWhite />
                </div>
              </div>
            </div>
          </PopupTrigger>
        </div>
        {investment_status === "On sale" ? (
          <div>
            <Link to={`/investment/${uuid}`} className="w-full">
              <img
                onLoad={() => setIsLoaded(true)}
                src={`${process.env.REACT_APP_IMAGES_URL}${investment_pictures[0]}`}
                className="rounded-t-sm cursor-pointer"
                alt="investment"
              />
            </Link>
            <Link
              to={`/investment/${uuid}`}
              className="inline-block w-full bg-primse-yellow text-sm font-bold text-primse-gray-font uppercase py-2 text-center rounded-b-sm hover:opacity-80 transition-all duration-200 hover:text-white"
            >
              {t("Details")}
            </Link>
          </div>
        ) : (
          <img
            onLoad={() => setIsLoaded(true)}
            src={`${process.env.REACT_APP_IMAGES_URL}${investment_pictures[0]}`}
            className="rounded-t-sm "
            alt="investment"
          />
        )}
      </div>
    </>
  );
};

const Investment = ({
  uuid,
  investmentName,
  investment_price_from,
  investment_price_to,
  investment_area_from,
  investment_area_to,
  investment_rooms_from,
  investment_rooms_to,
  number_of_offers,
  investment_city,
  address_street,
  uuidDeleteFromBasket,
  investment_pictures,
  handleRefreshBasket,
  currentBasketMore,
  investment_status,
  completion_date,
}) => {
  //To jest inwestycja poniżej mapy!
  const { t } = useTranslation();

  return (
    <>
      <div className="flex  flex-col md:flex-row  px-2 sm:px-6 md:px-5 xl:px-0">
        <ImageInvestment
          uuid={uuid}
          uuidDeleteFromBasket={uuidDeleteFromBasket}
          name={investmentName}
          investment_pictures={investment_pictures}
          handleRefreshBasket={handleRefreshBasket}
          currentBasketMore={currentBasketMore}
          investment_status={investment_status}
        />
        <div className="text-primse-gray-font mx-5 my-2 flex flex-col  whitespace-nowrap font-medium w-1/2 md:w-full lg:w-1/2">
          <div className="text-xl font-bold">
            {investment_status === "On sale" ? (
              <ButtonNavLink
                classNameProps="text-primse-gray-font"
                uuid={uuid}
                link={`/investment/${uuid}`}
                name={investmentName}
              />
            ) : (
              investmentName
            )}
          </div>
          <div className="text-sm">
            {[investment_city, address_street]
              .filter((value) => value)
              .join(", ")}
          </div>
          <div className="text-sm">
            {t("Number of locals")}: {number_of_offers}
          </div>
          {completion_date && (
            <div className="text-sm">
              {t("Completion date")}:{" "}
              {editCompletionDate(completion_date, t("Quarter"))}
            </div>
          )}
          <div className="mt-5 text-sm flex items-center">
            <img src={dimensionsSrc} width={20} height={20} alt="size icon" />
            <div className="ml-2.5">
              {investment_area_from} m<sup>2</sup> {t("to")}{" "}
              {investment_area_to} m<sup>2</sup>
            </div>
          </div>
          <div className="mt-5 text-sm flex items-center">
            <img src={peopleSrc} width={20} height={20} alt="rooms icon" />
            <div className="ml-2.5">
              {t("from")} {investment_rooms_from} {t("to")}{" "}
              {investment_rooms_to}
            </div>
          </div>
          <div className="mt-5 text-sm flex items-center">
            <div>
              {editPrice(investment_price_from)} PLN -{" "}
              {editPrice(investment_price_to)} PLN
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Investment;
