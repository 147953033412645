import React from "react";
import { Minus, Plus } from "react-feather";

const MapControls = ({ handlePlusClicked, handleMinusClicked }) => {
  return (
    <div className="bg-primse-green absolute bottom-5 left-3">
      <div
        className="w-10 h-10 flex items-center justify-center text-4xl text-white cursor-pointer hover:opacity-60"
        onClick={handlePlusClicked}
      >
        <Plus />
      </div>
      <div className="border" />
      <div
        className="w-10 h-10 flex items-center justify-center text-4xl text-white cursor-pointer hover:opacity-60"
        onClick={handleMinusClicked}
      >
        <Minus />
      </div>
    </div>
  );
};

export default MapControls;
