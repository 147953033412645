import { Link, NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import NewsServices from "../../services/NewsServices";

import { useStoreState } from "easy-peasy";
import Footer from "../Footer";

const NewsSection = () => {
  const { data, isLoading } = useQuery("news_components", () =>
    NewsServices.getNews({ order: "active_date,desc", perPage: 3 })
  );

  const { t } = useTranslation();
  const userRole = useStoreState((state) => state.auth.user?.role);
  const { mode } = useStoreState((state) => state.mode);

  if (mode === "presentation") return <div className="py-4"></div>;

  return (
    <>
      {!isLoading && data?.length > 0 && userRole !== "Deweloper" && (
        <>
          <div className="sm:px-8 mx-auto min-w-full pb-4 hidden lg:block">
            <div className="px-2 py-4 border-t border-b">
              <div>
                <h2 className="text-xl font-bold pb-1 text-primse-green uppercase">
                  {t("Latest news")}:
                </h2>
              </div>
              <div className="flex flex-col xl:flex-row">
                {data.map(({ subject, uuid }, index) => (
                  <div key={uuid} className="flex">
                    <NavLink
                      to={`/news-info/${uuid}`}
                      key={index}
                      exact
                      activeClassName="active-nav"
                      component={({ className }) => {
                        return (
                          <li
                            className={cs(
                              "py-3 xl:py-0 relative nav list-none",
                              className
                            )}
                          >
                            <Link
                              to={`/news-info/${uuid}`}
                              className="relative text-black hover:text-black"
                            >
                              <p className="cursor-pointer">{subject}</p>
                            </Link>
                          </li>
                        );
                      }}
                    ></NavLink>
                    {index < data?.length - 1 && (
                      <p className="text-primse-green px-2 hidden xl:block">
                        /
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default NewsSection;
