import { action } from "easy-peasy";

const modeStore = {
    mode: "work",
    setMode: action((state, payload) => {
        state.mode = payload;
    }),
}


export default modeStore;