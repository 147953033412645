import PropTypes from "prop-types";
import PopupTrigger from "../../../../../components/PopupTrigger";
import { Menu } from "react-feather";
import ButtonNavLink from "../../../../../components/ButtonNavLink";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { editPrice } from "../../../../../utils/numbers";

const checkType = (type) => {
  let flatType = "";
  const typeArr = [
    {
      Label: "apartment",
      Value: "Mieszkanie",
    },
    {
      Label: "House",
      Value: "Dom",
    },
    {
      Label: "Apartament",
      Value: "Mieszkanie",
    },
    {
      Label: "Garage",
      Value: "Garaż",
    },
    {
      Label: "Parking place",
      Value: "Miejsce parkingowe",
    },
    {
      Label: "Storage unit",
      Value: "Komórka lokatorska",
    },
    {
      Label: "Share.",
      Value: "Udział",
    },
    {
      Label: "Parking place ground",
      Value: "Miejsce parkingowe naziemne",
    },
    {
      Label: "Parking place underground",
      Value: "Miejsce parkingowe podziemne",
    },
    {
      Label: "Plot",
      Value: "plot",
    },
    {
      Label: "Investment apartment",
      Value: "Investment apartment",
    },
  ];

  typeArr?.forEach((el) => {
    if (type === el.Label) {
      flatType = el.Value;
    }
  });
  return flatType;
};

const FlatListView = ({
  flats,
  uuidDeleteFromBasket,
  investment_uuid,
  investment_name,
  handleRefreshBasket,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      <tr key={flats.id} className="flex w-full">
        <td className="py-3 px-4 border-b w-1/12">
          {flats.investment_status === "On sale" ? (
            <ButtonNavLink
              uuid={flats.uuid}
              link={`/realestate/${flats.uuid}`}
              name={`${flats?.building ? `${flats?.building} - ` : " "}${
                flats?.name
              }`}
            />
          ) : (
            flats.name
          )}
        </td>

        <td className="py-3 px-4 border-b w-1/12">{t(flats.status)}</td>
        <td className="py-3 px-4 border-b w-2/12">
          {flats.price && flats?.ask_for_price !== "1" ? (
            `${editPrice(flats.price)} PLN`
          ) : (
            <span
              className="cursor-pointer"
              onClick={() => {
                history.push(
                  `/questions?r_uuid=${flats?.uuid}&i_uuid=${flats?.investment_uuid}`
                );
              }}
            >
              {t("Ask about price")}
            </span>
          )}
        </td>
        <td className="py-3 px-4 border-b w-1/12 whitespace-nowrap">
          {flats.pricemkw && flats?.ask_for_price !== "1" ? (
            `${parseInt(flats.pricemkw)} PLN`
          ) : (
            <span
              className="cursor-pointer"
              onClick={() => {
                history.push(
                  `/questions?r_uuid=${flats?.uuid}&i_uuid=${flats?.investment_uuid}`
                );
              }}
            >
              {t("Ask about price")}
            </span>
          )}
          {flats.pricemkw && <sup>2</sup>}
        </td>
        <td className="py-3 px-4 border-b w-2/12">
          {flats.area ? `${flats.area} m` : t("No data")}
          {flats.area && <sup>2</sup>}
        </td>
        <td className="py-3 px-4 border-b w-1/12">
          {flats.rooms ? flats.rooms : t("not applicable")}
        </td>
        <td className="py-3 px-4 border-b w-1/12">{flats.floor}</td>
        <td className="py-3 px-4 border-b w-1/12">
          {flats.type && t(checkType(flats.type))}
        </td>
        <td className="py-3 px-4 border-b w-1/12">
          <PopupTrigger
            aftermarket={false}
            uuid={investment_uuid}
            investmentData={investment_name}
            typeButton="deleteBasket"
            uuidDeleteFromBasket={uuidDeleteFromBasket}
            handleRefreshBasket={handleRefreshBasket}
          >
            <div className="mr-2.5 text-primse-green relative w-8 cursor-pointer">
              <Menu
                className="ml-2 text-primse-green z-10 cursor-pointer"
                size={24}
              />
            </div>
          </PopupTrigger>
        </td>
      </tr>
    </>
  );
};

FlatListView.propTypes = {
  flats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      uuid: PropTypes.string,
      name: PropTypes.string,
      price: PropTypes.string,
      pricemkw: PropTypes.string,
      area: PropTypes.string,
      rooms: PropTypes.string,
      floor: PropTypes.string,
      status: PropTypes.string,
      investment_id: PropTypes.string,
      investment_uuid: PropTypes.string,
      investment_city: PropTypes.string,
      investment_lat: PropTypes.string,
      investment_long: PropTypes.string,
      plan: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};

export default FlatListView;
