import { useTranslation } from "react-i18next";
import {
  CartPlusWhite,
  BriefCasePlusWhite,
} from "../../../../components/Icons";
import { useStoreState } from "easy-peasy";
import React from "react";
import { useHistory } from "react-router-dom";
import { editPrice } from "../../../../utils/numbers";
import { toast } from "react-toastify";
import { actions } from "../../../../store";
import BriefcaseContentService from "../../../../services/BriefcaseContentService";
import PopupTrigger from "../../../../components/PopupTrigger";

const FlatBasket = ({
  realestateData,
  uuidDeleteFromBasket,
  realestate_name,
  handleRefresh,
}) => {
  return realestateData ? (
    <FlatBasketComponent
      realestateData={realestateData}
      uuidDeleteFromBasket={uuidDeleteFromBasket}
      realestate_name={realestate_name}
      handleRefresh={handleRefresh}
    />
  ) : (
    <div className="ui fluid placeholder  h-80 m-2 mb-5"></div>
  );
};

const FlatBasketComponent = ({
  realestateData: {
    investment_pictures,
    realestate_picture_sm,
    realestate_price_mkw,
    realestate_floor,
    realestate_area,
    investment_city,
    investment_district,
    investment_uuid,
    realestate_price,
    realestate_rooms,
    realestate_uuid,
  },
  uuidDeleteFromBasket,
  realestate_name,
  handleRefresh,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { briefcaseClient } = useStoreState((state) => state.briefcase);

  const addInvestmentToBriefcase = (object_uuid, investment_name) => {
    if (briefcaseClient) {
      BriefcaseContentService.addRealestate({
        contact: briefcaseClient.uuid,
        object_uuid: object_uuid,
        object_type: "realestate",
      })
        .then(() => {
          toast.success(
            `${t("The investment")} ${investment_name} ${t(
              "has been successfully added to the briefcase"
            )}`
          );
        })
        .catch((err) => {
          if (err?.response.data?.isDuplicated) {
            toast.error(`${t("The investment is already in the briefcase")}`);
          } else
            toast.error(
              `${t("The investment could not be added to briefcase")}`
            );
        });
    } else {
      toast.warning(
        <div className="text-base">
          {t("Choose client briefcase")}
          <button
            className="my-2 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
            onClick={() => {
              actions.briefcase.setBriefcaseModalSelectClient(true);
              actions.briefcase.setBriefcaseModal(true);
            }}
          >
            {t("Choose briefcase")}
          </button>
        </div>
      );
    }
  };

  if (realestate_name) {
    return (
      <div className="border m-2 mb-5  relative">
        <div className="w-12 h-12 absolute bg-primse-green text-white flex items-center justify-center">
          <div
            onClick={() =>
              addInvestmentToBriefcase(realestate_uuid, realestate_name)
            }
            className="w-8 cursor-pointer"
          >
            <CartPlusWhite />
          </div>
          <div className="absolute top-0 right-0">
            <PopupTrigger
              aftermarket={false}
              uuid={investment_uuid}
              investmentData={realestate_name}
              typeButton="deleteBasket"
              uuidDeleteFromBasket={uuidDeleteFromBasket}
              handleRefreshBasket={handleRefresh}
            >
              <div className="block max-h-0">
                <div className="bg-primse-green ml-3 rounded-sm flex justify-center items-center cursor-pointer transition-all duration-300 hover:opacity-80 w-12 h-12">
                  <div className="w-8">
                    <BriefCasePlusWhite />
                  </div>
                </div>
              </div>
            </PopupTrigger>
          </div>
        </div>
        {investment_pictures && investment_pictures?.length > 0 && (
          <img
            onClick={() => history.push(`/realestate/${realestate_uuid}`)}
            className="w-full cursor-pointer"
            src={
              realestate_picture_sm
                ? `${process.env.REACT_APP_IMAGES_URL}${realestate_picture_sm}`
                : `${process.env.REACT_APP_IMAGES_URL}${investment_pictures[0]}`
            }
            alt="flat"
          />
        )}
        {investment_pictures && investment_pictures?.length === 0 && (
          <img
            onClick={() => history.push(`/realestate/${realestate_uuid}`)}
            className="w-full cursor-pointer"
            src={`${process.env.REACT_APP_IMAGES_URL}/files/investments/a4d45b27c23fe41889af62351f7b826e.jpeg`}
            alt="flat"
          />
        )}
        <div className="p-4 px-3">
          <div>
            <strong>
              {[investment_city, investment_district]
                .filter((value) => value)
                .join(", ")}
            </strong>
          </div>
          {realestate_price_mkw ? (
            <div className="text-primse-green">
              {editPrice(realestate_price)} PLN /{" "}
              {editPrice(realestate_price_mkw)} PLN z m<sup>2</sup>
            </div>
          ) : (
            <div className="text-primse-green">
              {editPrice(realestate_price)} PLN
            </div>
          )}
          <div className="flex justify-between mt-2 text-xs">
            <div>
              <strong className="">{t("Surface")}</strong>
              {realestate_area ? (
                <div>
                  {editPrice(realestate_area)} m<sup>2</sup>
                </div>
              ) : (
                <div>{t("No data")}</div>
              )}
            </div>

            <div>
              <strong className="">{t("Rooms number")}:</strong>
              <div>{realestate_rooms}</div>
            </div>
            <div>
              <strong className="">{t("Floor")}:</strong>
              {realestate_floor ? (
                <div>{realestate_floor}</div>
              ) : (
                <div>{t("No data")}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default FlatBasket;
