import { useState, useMemo } from "react";

import { useParams } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import PopupBriefcaseTrigger from "./components/PopupBriefcaseTrigger";
import SelectBriefcaseModal from "./components/SelectBriefcaseModal";
import TriggerBriefcase from "./components/TriggerBriefcase";
import { BriefCaseWhite } from "../../../../components/Icons";
import cs from "classnames";

const BriefcasePanel = () => {
  return <BriefcasePanelButton />;
};

const BriefcasePanelButton = () => {
  const [openSelectBasket, setOpenSelectBasket] = useState(false);

  const { uuid } = useParams();

  const { briefcaseModalOpen, briefcaseClient } = useStoreState(
    (state) => state.briefcase
  );

  const closedAllModal = () => {
    setOpenSelectBasket(false);
  };

  const selectMargin = useMemo(() => {
    return briefcaseClient ? "mt-4 w-8" : "mt-0 w-12";
  }, [briefcaseClient]);

  return (
    <div>
      {openSelectBasket && (
        <SelectBriefcaseModal
          openBasket={openSelectBasket}
          setOpenBasket={closedAllModal}
        />
      )}

      {!briefcaseModalOpen && !openSelectBasket && (
        <PopupBriefcaseTrigger
          uuid={uuid}
          type="map-basket"
          setOpenSelectBasket={setOpenSelectBasket}
          briefcaseClient={briefcaseClient}
        >
          <div className="bg-primse-green absolute bottom-8 right-8 rounded-full h-24 w-24 flex flex-col items-center justify-center cursor-pointer">
            {briefcaseClient && (
              <TriggerBriefcase
                briefcaseClient={briefcaseClient}
                type="number"
              />
            )}
            <div className={cs(selectMargin)}>
              <BriefCaseWhite />
            </div>
            {briefcaseClient && (
              <TriggerBriefcase briefcaseClient={briefcaseClient} type="name" />
            )}
          </div>
        </PopupBriefcaseTrigger>
      )}
    </div>
  );
};

export default BriefcasePanel;
