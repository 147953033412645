import ButtonNavLink from "../../../../components/ButtonNavLink";
import { Popup } from "semantic-ui-react";
import { CheckSquare, Menu, XSquare } from "react-feather";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ListSingleActionModal from "../ListSingleActionModal";
import { useStoreState } from "easy-peasy";
import {
  applicationStatusIcon,
  applicationStatusData,
} from "../../../../utils/statusUtils";
import cs from "classnames";
import { daysLeft } from "../../../../utils/numbers";

const Td = ({ children }) => <td className="py-2 px-2 border-b">{children}</td>;

const RequestsSingleItem = ({
  uuid,
  id,
  date,
  client_uuid,
  client_name_last,
  client_name_first,
  client_phone,
  client_email,
  investment_uuid,
  developer_name,
  all_filled,
  status,
  user_name_last,
  user_name_first,
  date_modified,
  investment_name,
  handleRefresh,
  investment_status,
  decision_deadline,
}) => {
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [declineModalOpen, setDeclineModalOpen] = useState(false);

  const userRole = useStoreState((state) => state.auth.user?.role);

  const handleConfirmModal = (value) => {
    setConfirmModalOpen(value);
  };

  const handleDeclineModal = (value) => {
    setDeclineModalOpen(value);
  };

  let history = useHistory();

  function handleClientDetails(link) {
    history.push(`/request/${link}`);
  }

  return (
    <tr
      onClick={() => {
        handleClientDetails(uuid);
      }}
      key={uuid}
      className={cs(
        "border-b h-14 hover:bg-primse-light-green cursor-pointer",
        {
          "bg-red-500 bg-opacity-10":
            decision_deadline && daysLeft(decision_deadline) <= 1,
        }
      )}
    >
      <Td>{id}</Td>
      <Td>{date}</Td>
      {userRole !== "Deweloper" ? (
        <Td>
          {client_uuid ? (
            <ButtonNavLink
              uuid={client_uuid}
              link={`/client/${client_uuid}`}
              name={`${client_name_last} ${client_name_first}`}
            />
          ) : (
            `${client_name_last} ${client_name_first}`
          )}
        </Td>
      ) : (
        <Td>
          {client_name_last} {client_name_first}
        </Td>
      )}

      <Td>
        {user_name_last} {user_name_first}
      </Td>
      <Td>{client_phone}</Td>
      <Td>{client_email}</Td>

      <Td>
        {investment_status === "On sale" ? (
          <ButtonNavLink
            uuid={investment_uuid}
            link={`/investment/${investment_uuid}`}
            name={investment_name}
          />
        ) : (
          <td className="flex py-4  items-center">{investment_name}</td>
        )}
      </Td>
      <Td>{developer_name}</Td>
      <td className="flex py-4 px-2 items-center">
        {status && applicationStatusIcon(status)}
        {status && applicationStatusData(status)}
      </td>
      <Td>
        <div className="flex justify-center">
          <div className="mr-2.5 text-primse-green">
            {all_filled ? (
              <div className="mr-2.5 text-primse-green">
                <CheckSquare />
              </div>
            ) : (
              <div className="mr-2.5 text-yellow-400">
                <XSquare />
              </div>
            )}
          </div>
        </div>
      </Td>
      <Td>{date_modified ? date_modified.slice(0, 19) : date}</Td>
      <Td>
        {decision_deadline ? (
          <>
            {`${decision_deadline}, ${t("Left")}:`}{" "}
            <div className="font-bold">
              {daysLeft(decision_deadline) > 0
                ? `${daysLeft(decision_deadline)} ${t("Days")}`
                : `0 ${t("Days")}`}
            </div>
          </>
        ) : (
          ""
        )}
      </Td>
      <Td>
        <div
          className="text-primse-green m-auto cursor-pointer"
          onClick={(e) => e.stopPropagation()}
        >
          <Popup
            trigger={<Menu className="text-primse-green  " size={24} />}
            flowing
            on="click"
            hoverable
            closeOnEscape
            position="bottom center"
          >
            <div className="bg-white  text-sm font-normal">
              <div className="border-b">
                <div
                  className="p-3 cursor-pointer hover:text-primse-green"
                  onClick={() => handleClientDetails(uuid)}
                >
                  <strong>{t("Details")}</strong>
                </div>
              </div>
              {status === "Awaiting" && userRole !== "Agency" && (
                <div className="border-b">
                  <div
                    className="p-3 cursor-pointer hover:text-primse-green"
                    onClick={() => {
                      setConfirmModalOpen(true);
                      setDeclineModalOpen(false);
                    }}
                  >
                    <strong>{t("Accept")}</strong>
                  </div>
                </div>
              )}
              {status === "Awaiting" && userRole !== "Agency" && (
                <div className="border-b">
                  <div
                    className="p-3 cursor-pointer hover:text-primse-green"
                    onClick={() => {
                      setConfirmModalOpen(false);
                      setDeclineModalOpen(true);
                    }}
                  >
                    <strong>{t("Decline")}</strong>
                  </div>
                </div>
              )}
            </div>
          </Popup>
          <ListSingleActionModal
            handleModalClose={handleConfirmModal}
            setModalOpen={confirmModalOpen}
            accept={true}
            requestUuid={uuid}
            handleRefresh={handleRefresh}
          />
          <ListSingleActionModal
            handleModalClose={handleDeclineModal}
            setModalOpen={declineModalOpen}
            accept={false}
            requestUuid={uuid}
            handleRefresh={handleRefresh}
          />
        </div>
      </Td>
    </tr>
  );
};

export default RequestsSingleItem;
