import { useQuery } from "react-query";
import RealestatesServices from "../../../../services/RealestatesServices";
import FlatListView from "./FlatListView";

const FlatList = ({
  investment_uuid,
  investment_name,
  uuidDeleteFromBasket,
  realestate_uuid,
  handleRefreshBasket,
}) => {
  const { data, isLoading } = useQuery(
    `flats-${realestate_uuid}`,
    RealestatesServices.getRealestate(realestate_uuid)
  );

  if (isLoading)
    return (
      <div
        className="ui placeholder fluid"
        style={{ width: "100%", height: "100px", margin: "0" }}
      ></div>
    );
  else
    return data ? (
      <FlatListView
        flats={data}
        uuidDeleteFromBasket={uuidDeleteFromBasket}
        investment_uuid={investment_uuid}
        investment_name={investment_name}
        handleRefreshBasket={handleRefreshBasket}
      />
    ) : null;
};

export default FlatList;
