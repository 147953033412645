import { action } from "easy-peasy";

const dictionariesStore = {
    dictionariesList: {},

    setDictionaries: action((state, payload) => {
        state.dictionariesList = payload;
    }),

};

export default dictionariesStore;