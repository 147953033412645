import { Popup, Table } from "semantic-ui-react";
import { Menu } from "react-feather";
import cs from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DocumentsServices from "../../../services/DocumentsServices";
import { toast } from "react-toastify";
import UpdateRequestModal from "../../../modals/UpdateRequestModal";
import { useStoreState } from "easy-peasy";
import HistoryTermsModal from "../Modals/HistoryTermsModal";

export const RequestDocumentTable = ({
  rowData: { text, deadline_days, deadline, doc_name, doc_uuid, term_uuid },
  required,
  requestUuid,
  handleRefresh,
  requestStatus,
  termsItems,
  PresentationCardText,
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [popupMenuOpen, setPopupMenuOpen] = useState(false);
  const [modalTermsOpen, setModalTermsOpen] = useState(false);
  const userRole = useStoreState((state) => state.auth.user?.role);
  const primaryRole = useStoreState((state) => state.auth.user?.primaryRole);
  const userAgencyType = useStoreState((state) => state.auth.user?.agencyType);

  const employment_type = useStoreState(
    (state) => state.auth.user?.employment?.employment_type
  );

  const showButtonAddPresentation = () => {
    if (primaryRole === "Admin") return true;
    if (primaryRole === "Top Admin") return true;
    if (userRole === "Deweloper") return false;
    if (requestStatus === "Awaiting" && employment_type === "Agency")
      return true;
    if (
      (requestStatus === "Accepted" ||
        requestStatus === "Accepted conditionally") &&
      employment_type === "Agency" &&
      PresentationCardText === text
    )
      return true;

    return false;
  };

  const showButton = () => {
    if (primaryRole === "Admin") return true;
    if (primaryRole === "Top Admin") return true;

    if (requestStatus === "Awaiting" && employment_type === "Agency")
      return true;
    if (
      (requestStatus === "Accepted" ||
        requestStatus === "Accepted conditionally") &&
      userAgencyType === "Metrohouse office" &&
      employment_type === "Agency"
    )
      return true;
    if (showButtonAddPresentation()) return true;
    return false;
  };

  return (
    <Table.Row className="border-solid border-primse-green border-t">
      <Table.Cell>{text}</Table.Cell>
      {!required && (
        <Table.Cell>
          {deadline_days === 1
            ? `${deadline_days} ${t("Day")}`
            : `${deadline_days} ${t("Days")}`}
        </Table.Cell>
      )}
      <Table.Cell>
        {doc_uuid ? (
          doc_name
        ) : (
          <span
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
            className="text-red-500 cursor-pointer"
          >
            {t("No file")}
          </span>
        )}
      </Table.Cell>
      <Table.Cell>{deadline}</Table.Cell>

      {userRole === "Deweloper" && doc_uuid && (
        <Table.Cell>
          <Popup
            open={popupMenuOpen}
            trigger={
              <Menu
                className="text-primse-green z-10 cursor-pointer"
                size={24}
              />
            }
            onOpen={() => setPopupMenuOpen(true)}
            onClose={() => setPopupMenuOpen(false)}
            flowing
            hoverable={true}
            on="click"
            position="bottom center"
          >
            <div className={cs("bg-white  text-sm font-normal")}>
              {doc_uuid && (
                <div className="border-b">
                  <div
                    className="p-3 cursor-pointer hover:text-primse-green"
                    onClick={() => {
                      DocumentsServices.getDocument({
                        document_uuid: doc_uuid,
                        file_name: doc_name,
                      })
                        .then(() => {
                          toast.success(`${t("Document download started")}`);
                          setPopupMenuOpen(false);
                        })
                        .catch(() =>
                          toast.error(
                            `${t("The document could not be downloaded")}`
                          )
                        );
                    }}
                  >
                    <strong>{t("Download")}</strong>
                  </div>
                </div>
              )}
            </div>
          </Popup>

          <UpdateRequestModal
            text={text}
            request_uuid={requestUuid}
            term_uuid={term_uuid}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            handleRefresh={handleRefresh}
          />
        </Table.Cell>
      )}
      {userRole !== "Deweloper" && (showButton() || doc_uuid) && (
        <Table.Cell>
          <Popup
            open={popupMenuOpen}
            trigger={
              <Menu
                className="text-primse-green z-10 cursor-pointer"
                size={24}
              />
            }
            onOpen={() => setPopupMenuOpen(true)}
            onClose={() => setPopupMenuOpen(false)}
            flowing
            hoverable={true}
            on="click"
            position="bottom center"
          >
            <div className={cs("bg-white  text-sm font-normal")}>
              {doc_uuid && (
                <div className="border-b">
                  <div
                    className="p-3 cursor-pointer hover:text-primse-green"
                    onClick={() => {
                      DocumentsServices.getDocument({
                        document_uuid: doc_uuid,
                        file_name: doc_name,
                      })
                        .then(() => {
                          toast.success(`${t("Document download started")}`);
                          setPopupMenuOpen(false);
                        })
                        .catch(() =>
                          toast.error(
                            `${t("The document could not be downloaded")}`
                          )
                        );
                    }}
                  >
                    <strong>{t("Download")}</strong>
                  </div>
                </div>
              )}
              {showButton() &&
                (PresentationCardText === text
                  ? termsItems?.length < 3
                  : true) && (
                  <div className="border-b">
                    <div
                      onClick={() => {
                        setModalOpen(!modalOpen);
                        setPopupMenuOpen(false);
                      }}
                      className="p-3 cursor-pointer hover:text-primse-green"
                    >
                      <strong>{t("Add")}</strong>
                    </div>
                  </div>
                )}
              {PresentationCardText === text && termsItems?.length > 0 && (
                <div className="border-b">
                  <div
                    onClick={() => {
                      setModalTermsOpen(true);
                      setPopupMenuOpen(false);
                    }}
                    className="p-3 cursor-pointer hover:text-primse-green"
                  >
                    <strong>{t("Change history")}</strong>
                  </div>
                </div>
              )}
            </div>
          </Popup>
          {termsItems?.length > 0 && (
            <HistoryTermsModal
              items={termsItems}
              modalOpen={modalTermsOpen}
              setModalOpen={setModalTermsOpen}
            />
          )}
          <UpdateRequestModal
            text={text}
            request_uuid={requestUuid}
            term_uuid={term_uuid}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            handleRefresh={handleRefresh}
          />
        </Table.Cell>
      )}
    </Table.Row>
  );
};
