import cs from "classnames";
import styles from "./Marker.module.css";

import { MapPin } from "../../../../components/Icons";

const PineMarker = () => {
  return (
    <div className={cs("cursor-default", styles.marker)}>
      <div
        style={{
          height: "64px",
          width: "64px",
          transform: " translate(-50%, -100%)",
        }}
        className={
          "  flex flex-col items-center justify-center  transition-all duration-500 hover:opacity-90  "
        }
      >
        <MapPin height="64" width="64" />
      </div>
    </div>
  );
};

export default PineMarker;
