import ReactHtmlParser from "react-html-parser";
import { Link, NavLink } from "react-router-dom";
import cs from "classnames";
import Container from "../../../components/Container";
import BreadcrumbExample from "../../../components/Breadcrumb";
import NewsSection from "../../../components/NewsSection";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import NewsServices from "../../../services/NewsServices";
import { toast } from "react-toastify";

const NewsDetailsView = ({
  newsDetails: {
    payload: {
      subject,
      description,
      files,
      active_date,
      short_text,
      investments,
    },
  },
}) => {
  const { t } = useTranslation();

  const filesInGroupType = useMemo(() => {
    const newFiles = files.reduce((acc, file) => {
      const { type } = file;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(file);
      return acc;
    }, {});

    let mainImage = null;

    // Banners / Images
    if (newFiles?.Banner?.length > 0) {
      mainImage = newFiles?.Banner[0];
    } else if (newFiles?.Image?.length > 0) {
      mainImage = newFiles?.Image[0];
    }

    return { ...newFiles, mainImage };
  }, [files]);

  const downloadFile = async (id, name) => {
    const idToast = toast.success(t("Document download started"), {
      autoClose: false,
    });
    const res = await NewsServices.downloadFile(id, name);

    if (res.success) {
      toast.update(idToast, {
        render: `${t("Document download started")}`,
        type: "success",
        isLoading: false,
        autoClose: true,
      });
    } else {
      toast.update(idToast, {
        render: `${t("The document could not be downloaded")}`,
        type: "error",
        isLoading: false,
        autoClose: true,
      });
    }
  };

  return (
    <div>
      <div
        style={{
          minHeight: "calc(80vh + 1rem)",
          maxWidth: "1200px",
          margin: "0 auto",
          marginBottom: "10px",
        }}
      >
        <BreadcrumbExample pages={[]} returnPage={"News"} />
        <Container>
          <div className="pb-6 pt-6 border-b border-primse-green mb-10 w-max pr-10">
            <h2 className="text-4xl font-bold text-grey-600">{subject}</h2>
          </div>
          {filesInGroupType?.mainImage && (
            <div className="">
              <img
                src={`${process.env.REACT_APP_IMAGES_URL}${filesInGroupType?.mainImage?.url}`}
                alt={filesInGroupType?.mainImage?.name}
                className="object-cover h-72 w-full object-cover"
              />
            </div>
          )}
          <div className="py-6">
            <p>{short_text}</p>
            <div>{ReactHtmlParser(description)}</div>
          </div>
          {investments?.length > 0 && (
            <div>
              <h3 className="text-xl font-bold text-primse-green mt-4 mb-2">
                Link do inwestycji:
              </h3>
              {investments?.map((el) => (
                <NavLink
                  to={`/investment/${el?.uuid}`}
                  key={el?.uuid}
                  exact
                  activeClassName="active-nav"
                  component={({ className }) => {
                    return (
                      <li
                        className={cs(
                          "py-2 xl:py-0 relative nav list-none w-max",
                          className
                        )}
                      >
                        <Link
                          to={`/investment/${el?.uuid}`}
                          className="relative text-black hover:text-black"
                        >
                          <p className="cursor-pointer text-lg my-1">
                            {el?.name}
                          </p>
                        </Link>
                      </li>
                    );
                  }}
                ></NavLink>
              ))}
            </div>
          )}
          {filesInGroupType?.Attachment &&
          filesInGroupType?.Attachment?.length !== 0 ? (
            <div>
              <h3 className="text-xl font-bold text-primse-green pt-4 mb-2">
                {t("Attachments")}:
              </h3>
              {filesInGroupType?.Attachment?.map((el) => (
                <li
                  key={el?.id}
                  className={cs("py-3 xl:py-0 relative nav list-none w-max")}
                  onClick={() => {
                    downloadFile(el?.id, el?.name);
                  }}
                >
                  <div className="relative text-black hover:text-primse-green">
                    <p className="cursor-pointer text-lg my-2">{el?.name}</p>
                  </div>
                </li>
              ))}
            </div>
          ) : null}

          <div className="py-4 pb-6 border-t border-primse-green mt-6 w-max pr-10">
            <h2 className="text-base text-gray-400 mt-4 py-2 pr-16">
              {active_date}
            </h2>
          </div>
        </Container>
      </div>
      <NewsSection />
    </div>
  );
};

export default NewsDetailsView;
