import { useQuery } from "react-query";
import Investment from "../../../../components/Investment";
import Loader from "../../../../components/Loader";
import { BriefCasePlus } from "../../../../components/Icons";
import RealestatesServices from "../../../../services/RealestatesServices";
import { useStoreState } from "easy-peasy";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import {
  parseURLtoFormValues,
  priceFilterRealestate,
  roomsFilterRealestate,
  areaFilterRealestate,
  floorsFlatFilter,
} from "../../searchUtils";
import { useLocation } from "react-router";
import { Popup } from "semantic-ui-react";
import {
  addToBasket,
  addFlatToBriefcase,
} from "../../../../utils/customerInteraction";
import ButtonNavLink from "../../../../components/ButtonNavLink";
import PlanModal from "../../../../components/PlanModal";
import { sortRealestates } from "../../../../utils/sortUtils";
import cs from "classnames";
import { editPrice } from "../../../../utils/numbers";

const MapInvestment = ({
  onClosedMarker,
  setActiveScrollMap,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [loading] = useState(false);
  const [loadedData, setLoadedData] = useState(false);
  const [selectRealestates, setSelectRealestates] = useState([]);
  const location = useLocation();
  const formValues = parseURLtoFormValues(location.search);

  const { data: flatsData, isLoading: flatsLoading } = useQuery(
    ["flats", restProps.uuid],
    RealestatesServices.getFlatList(
      restProps.uuid,
      {
        roomsFrom: formValues.rooms[0],
        roomsTo: formValues.rooms[1]
          ? formValues.rooms[1]
          : formValues.rooms[0],
        priceFrom: formValues.price[0],
        priceTo: formValues.price[1],
        areaFrom: formValues.area[0],
        areaTo: formValues.area[1],
        perPage: 999,
      },
      true
    ),

    {
      cacheTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const filterInvestments = (formValues) => {
    if (!flatsData) return null;
    setLoadedData(false);

    const filterData = flatsData.payload
      // .filter(typeFilterRealestate(formValues))
      .filter(areaFilterRealestate(formValues))
      .filter(roomsFilterRealestate(formValues))
      .filter(priceFilterRealestate(formValues))
      .filter(floorsFlatFilter(formValues))
      .sort(sortRealestates);

    setSelectRealestates(filterData);

    setLoadedData(true);
  };

  useEffect(() => {
    const formValues = parseURLtoFormValues(location.search);
    filterInvestments(formValues);
  }, [flatsData, location.search]);

  const { briefcaseClient } = useStoreState((state) => state.briefcase);
  const { currentBasketUuid } = useStoreState((state) => state.baskets);

  const isLoading = flatsLoading || loading;

  return (
    <div
      onMouseEnter={() => {
        setActiveScrollMap(false);
      }}
      onMouseLeave={() => {
        setActiveScrollMap(true);
      }}
      className="p-5 bg-white absolute bottom-11 -left-80 shadow-md z-10 "
    >
      {isLoading && <Loader position="absolute" opacity="bg-opacity-100" />}
      <div className="flex">
        <div className="absolute right-5 top-5" data-id="exitModal">
          <AiFillCloseCircle
            onClick={() => {
              setActiveScrollMap(true);
              onClosedMarker();
            }}
            className="text-primse-gray-font cursor-pointer w-6 h-6"
            data-id="exitModal"
          />
        </div>

        <Investment investmentData={restProps} editPrice={editPrice} />
      </div>
      <div className="-mt-7">
        <div className="mt-7 max-h-40 overflow-auto">
          <table className="w-full text-sm">
            {isLoading && (
              <div className="h-40 relative">
                <Loader position="absolute" />
              </div>
            )}
            {!isLoading && loadedData && (
              <tbody className="text-primse-gray-font text-sm">
                {selectRealestates?.map((flat) => (
                  <tr
                    key={`${flat.uuid}`}
                    className="transition-all duration-300 hover:bg-primse-light-green cursor-pointer"
                  >
                    <td
                      // data-link={`/realestate/${flat.uuid}`}
                      className="py-2 pl-2 border-b"
                    >
                      <ButtonNavLink
                        uuid={flat.uuid}
                        link={`/realestate/${flat.uuid}`}
                        name={`${
                          flat?.building ? `${flat?.building} - ` : " "
                        }${flat?.name}`}
                      />
                    </td>
                    <td className="py-2 border-b">
                      <strong
                        className={cs(
                          flat?.is_in_promotion === "1" && "text-red-600"
                        )}
                        data-link={`/questions?r_uuid=${flat.uuid}&i_uuid=${flat.investment_uuid}`}
                      >
                        {flat?.price && flat?.ask_for_price !== "1"
                          ? `${editPrice(flat.price)} PLN`
                          : t("Ask about price")}{" "}
                      </strong>{" "}
                      {flat.pricemkw && flat?.ask_for_price !== "1" && (
                        <span className="opacity-50">
                          ({flat.pricemkw} PLN/m2)
                        </span>
                      )}
                    </td>
                    <td className="py-2 border-b">
                      {flat.area && (
                        <>
                          {flat.area} m<sup>2</sup>
                        </>
                      )}
                    </td>

                    <td className="py-2 border-b">
                      {flat.rooms &&
                        `${flat.rooms} ${
                          flat.rooms > 1
                            ? t("Number-rooms").toLowerCase()
                            : t("Room").toLowerCase()
                        }`}
                    </td>

                    <td className="py-2 border-b">
                      {flat.floor &&
                        `${
                          flat.floor < 1
                            ? t("Ground floor").toLowerCase()
                            : flat.floor + " " + t("Floor").toLowerCase()
                        }`}
                    </td>
                    <td className="py-2 border-b">
                      {flat.plan && (
                        <PlanModal
                          link={`${process.env.REACT_APP_IMAGES_URL}${flat.plan}`}
                          name="Plan"
                        />
                      )}
                    </td>
                    <td className="py-2 border-b">
                      <div className="duration-300 hover:opacity-80">
                        <Popup
                          trigger={
                            <div className="cursor-pointer text-white flex items-center justify-center duration-300 hover:opacity-90">
                              <div className="w-8 ">
                                <BriefCasePlus className="text-primse-green " />
                              </div>
                            </div>
                          }
                          flowing
                          on="click"
                          position="right center"
                        >
                          <div className="bg-white  text-sm font-normal">
                            <div className="flex flex-col">
                              <button
                                className="leading-5 my-2 font-bold text-black uppercase bg-primse-green py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                                onClick={(e) => {
                                  addToBasket(
                                    e,
                                    flat.uuid,
                                    currentBasketUuid,
                                    flat.name
                                  );
                                }}
                              >
                                {t("Add to basket")}
                              </button>
                              <button
                                className="leading-5 my-2 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                                onClick={() => {
                                  addFlatToBriefcase(
                                    flat.uuid,
                                    flat.name,
                                    briefcaseClient
                                  );
                                }}
                              >
                                {t("Add to client briefcase")}
                              </button>
                            </div>
                          </div>
                        </Popup>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default MapInvestment;
