import propTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";

const Infobox = ({ title, data }) => {
  const { t } = useTranslation();

  if (data === null) return null;

  return (
    <div className="px-5 py-6 border rounded-sm ">
      <div className="font-bold text-xl">{t(title)}</div>
      <div className="w-14 h-1 bg-primse-green" />
      <ul className="mt-6">
        {data.map(({ label, value, display = true }, index) => {
          if (
            value === null ||
            value === "null" ||
            value === "undefined" ||
            value === "" ||
            display === false
          ) {
            return null;
          } else {
            return (
              <li className="flex leading-8" key={index}>
                <div className="overflow-ellipsis overflow-hidden whitespace-pre-line">
                  {label && !Array.isArray(value) && (
                    <strong className="mr-1 font-bold">{t(label)}:</strong>
                  )}
                  {typeof value === "string" && !Array.isArray(value) && (
                    <span>
                      {label === "Price list updated" ? (
                        <Moment format="DD.MM.YYYY HH:mm">{value}</Moment>
                      ) : (
                        t(value)
                      )}
                    </span>
                  )}
                  {typeof value === "object" && !Array.isArray(value) && (
                    <>
                      {value?.props?.children[0] === null && null}
                      {value?.props?.children[0] !== null && (
                        <span>{value}</span>
                      )}
                    </>
                  )}
                  {Array.isArray(value) && value?.length !== 0 && (
                    <div key={value}>
                      <strong className="mr-1 font-bold">{t(label)}:</strong>

                      {value.map((el, i) => {
                        if (i === value?.length - 1) {
                          return <span>{t(el.name)}</span>;
                        } else {
                          return <span>{`${t(el.name)}, `}</span>;
                        }
                      })}
                    </div>
                  )}
                </div>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

Infobox.propTypes = {
  title: propTypes.string,
  data: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string,
      value: propTypes.oneOfType([propTypes.element, propTypes.string]),
    })
  ),
};

Infobox.defaultProps = {
  title: "Brak danych",
  data: [],
};

export default Infobox;
