import { useState, useMemo, useEffect } from "react";
import { useQuery } from "react-query";
import { Element, Link as SmoothLink } from "react-scroll";
import RealestatesServices from "../../services/RealestatesServices";
import cs from "classnames";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Infobox from "../../components/InfoBox";
import Loader from "../../components/Loader";
import { CartWhite, MapPin } from "../../components/Icons";
import DescriptionRealestate from "./components/DescriptionRealestate";
import GelleryRealestate from "./components/GelleryRealestate";
import PopupTrigger from "../../components/PopupTrigger";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BreadcrumbExample from "../../components/Breadcrumb";
import NewsSection from "../../components/NewsSection";
import AgentInformation from "../../components/AgentInformation";
import { Modal } from "semantic-ui-react";
import TrackingServices from "../../services/TrackingServices";
import { useStoreState, useStoreActions } from "easy-peasy";
import { ArrowRight, X } from "react-feather";
import { actions } from "../../store";
import PriceComponent from "./components/PriceComponent";
import DictionariesServices from "../../services/DictionariesServices";
import Documents from "./components/Documents";
import ReportRealestate from "./ReportRealestate";
import useUserRole from "../../hooks/useUserRole";
import InvestmentsService from "../../services/InvestmentsService";
import MapGoogle from "../../components/MapGoogle";
import RequestBriefcaseUser from "../Investemnt/components/RequestUser/RequestBriefcaseUser";

const Realestate = () => {
  const history = useHistory();
  const { uuid } = useParams();
  const { t } = useTranslation();
  const { data, isLoading } = useQuery(
    `flats-${uuid}`,
    RealestatesServices.getRealestate(uuid)
  );
  const { isAgent } = useUserRole();
  const setProblemModalStatus = useStoreActions(
    (state) => state.modals.setProblemModalStatus
  );
  const [investmentData, setInvestmentData] = useState(null);
  const [isLoadingInvestment, setIsLoadingInvestment] = useState(false);
  const { mode } = useStoreState((state) => state.mode);
  const [showModalMap, setShowModalMap] = useState(false);
  const [showContactData, setShowContactData] = useState(false);
  const { data: dictionariesList } = useQuery(
    "dictionaries",
    DictionariesServices.getDictionaries
  );

  const map = useMemo(() => {
    if (!data?.static_map && !data?.static_map_presentation) return null;

    if (mode === "presentation") {
      return data?.static_map_presentation || data?.static_map;
    } else {
      return data?.static_map;
    }
  }, [data?.static_map, data?.static_map_presentation, mode]);

  useEffect(() => {
    if (data?.investment_uuid) {
      getInvestmentData(data?.investment_uuid);
    }
  }, [data?.investment_uuid]);

  const getInvestmentData = async (uuid) => {
    setIsLoadingInvestment(true);
    const investmentData = await InvestmentsService.getInvestment(uuid)();
    if (investmentData?.uuid) {
      setInvestmentData(investmentData);
    }
    setIsLoadingInvestment(false);
  };

  useEffect(() => {
    if (dictionariesList) {
      actions.dictionaries.setDictionaries(dictionariesList);
    }
  }, [dictionariesList]);
  const userRole = useStoreState((state) => state.auth.user?.role);

  const tracking = (realestate_uuid, identifier) => {
    TrackingServices.trackingAdd({
      identifier: identifier,
      url: `/realestate/${realestate_uuid}`,
      name: `Realestate - contact`,
    });
  };

  const getAttribute = (data, type, variant = "boolean") => {
    if (variant === "boolean") {
      if (!data || !type) return "No";

      const filter = data.filter((el) => el.name === type);
      if (filter?.length > 0) return filter[0].value === "1" ? "Yes" : "No";

      return "No";
    } else if (variant === "number") {
      if (!data || !type) return null;

      const filter = data.filter((el) => el.name === type);
      if (filter?.length > 0 && filter[0]?.value && filter[0]?.value !== "")
        return (
          <div className="inline">
            {filter[0].value} m<sup>2</sup>
          </div>
        );
      return null;
    }

    return null;
  };

  const REALESTATE_NAV = [
    {
      label: "Gallery",
      to: "gallery",
      offset: -70,
      mobile: true,
      deskop: true,
    },
    {
      label: "Description",
      to: "description",
      offset: -70 - 60,
      mobile: true,
      deskop: true,
    },
    {
      label: "Details",
      to: "details",
      offset: -70 - 60,
      mobile: false,
      deskop: true,
    },
    {
      label: "Map",
      to: "map",
      offset: -70 - 60,
      mobile: true,
      deskop: true,
    },
    {
      label: t("Documents"),
      to: "docs",
      offset: -70 - 60,
      mobile: false,
      deskop: true,
    },
  ];

  if (mode !== "presentation")
    REALESTATE_NAV.splice(4, 0, {
      label: "Information for the agent",
      to: "agent",
      offset: -70 - 60,
      mobile: false,
      deskop: true,
    });

  const getPropertyData = useMemo(() => {
    if (!data) return null;
    return [
      { label: "Offer type", value: `${data.sm_offer_type}` },
      { label: "Building number", value: `${data?.building}` },
      { label: "Status", value: `${data.status}` },
      { label: "Property type", value: `${data.type}` },
      { label: "Country", value: `${data.sm_location_country}` },
      { label: "Voivodeship", value: `${data.sm_location_voivodeship}` },
      { label: "City", value: `${data.sm_location_city}` },
      { label: "District", value: `${data.sm_location_district}` },
      { label: "Street", value: `${data.sm_location_street}` },
      {
        label: "Surface",
        value: (
          <div className="inline">
            {data.area} m<sup>2</sup>
          </div>
        ),
      },
      { label: "Floor", value: `${data.floor}` },
      { label: "Rooms", value: `${data.rooms}` },
      { label: "Kitchen", value: `${data.sm_kitchen_type}` },
      { label: "Balcony", value: getAttribute(data?.attributes, "balcony") },
      { label: "Terrace", value: getAttribute(data?.attributes, "terrace") },
      { label: "Type ownership", value: `${data.sm_ownership_form}` },
      { label: "Build technology", value: `${data.sm_build_technology}` },
      {
        label: "Amount of rent",
        value: `${
          data.minimal_price_gross ? `${data.minimal_price_gross} PLN` : null
        }`,
      },
      { label: "Heating", value: `${data.sm_heating_type}` },
      {
        label: "Elevator",
        value: data?.investment_is_elevators === "1" ? "Yes" : "No",
      },

      { label: "Parking", value: `${data.sm_parking_type}` },
      { label: "Build year", value: `${data.sm_build_year}` },
      { label: "Building state", value: `${data.sm_building_state}` },
      { label: "Building type", value: `${data.sm_building_type}` },
      { label: "Floor type", value: `${data.sm_floor_type}` },
      { label: "Standard finishing", value: `${data.sm_standard_finishing}` },
      { label: "Wall Type", value: `${data.sm_wall_type}` },
      { label: "Window type", value: `${data.sm_window_type}` },
      { label: "Stage", value: `${data.stage}` },
      { label: "General characteristic", value: data.general_characteristic },
      { label: "Media", value: data.media },
      { label: "Public transport", value: data.public_transport },
      { label: "Security type", value: data.security_type },
      { label: "View", value: data.view_at },
      { label: "Equipment", value: data.equipment },
      { label: "Extras", value: data.extras },
      {
        label: "Plot area",
        value: getAttribute(data?.attributes, "plot_area", "number"),
      },
    ];
  }, [data]);
  const getContactData = useMemo(() => {
    if (!data) return null;
    if (!data.contact_name && !data.contact_phone && !data.contact_email)
      return null;
    return [
      { label: "First name and last name", value: `${data.contact_name}` },
      { label: "Phone", value: `${data.contact_phone}` },
      { label: "Email", value: `${data.contact_email}` },
      {
        label: "Commission percent %",
        value: `${data.commission_percent}`,
      },
    ];
  }, [data]);

  const BREADCRUMB_DATA = useMemo(() => {
    if (!data) return null;
    const arr = [
      {
        name: "Main page",
        linkPage: "/",
      },
      {
        name: `${data.type}`,
        linkPage: `?type=${data?.type?.toLowerCase()}`,
      },
      {
        name: data.is_primary ? data.investment_city : data.sm_location_city,
        linkPage: data.is_primary
          ? `?location=${data.investment_city}`
          : `?location=${data.sm_location_city}`,
      },
      {
        name: data.is_primary
          ? data.address_district
          : data.sm_location_district,
        linkPage: data.address_district
          ? `?location=${data.address_district}`
          : `?location=${data.sm_location_district}`,
      },
    ];

    if (mode !== "presentation")
      arr.push({
        name: data.is_primary ? data.investment_name : null,
        linkPage: data.is_primary ? `investment/${data.investment_uuid}` : null,
      });

    arr.push({
      name: data.is_primary ? null : `${t("Aftermarket")}`,
      linkPage: data.is_primary
        ? null
        : `realestates/?type=apartment,house&market=Secondary%20market`,
    });

    return arr;
  }, [data, mode]);

  const promotingAgencyOffer =
    investmentData?.promoting_agency_offer == "1" ? true : false;

  return (
    <main className="mt-4">
      {(isLoading || isLoadingInvestment) && <Loader />}
      {!isLoading && !isLoadingInvestment && data && (
        <div>
          <div className="-mb-1">
            <BreadcrumbExample
              pages={BREADCRUMB_DATA}
              returnPage={"/"}
              current={[
                `${data?.building ? `${data?.building}  - ` : ""}${data?.name}`,
              ]
                .filter((value) => value)
                .join(" ")}
            />
          </div>
          <Container>
            <header className="px-0 py-1 flex items-end justify-between">
              <div>
                <h2 className="text-3xl font-bold m-0">
                  {[
                    mode !== "presentation" ? data.investment_name : null,
                    `${
                      data.building ? `${data.building} - ` : " "
                    }${`${data.name}`}`,
                  ]
                    .filter((value) => value)
                    .join(", ")}
                </h2>
                {mode !== "presentation" && promotingAgencyOffer ? (
                  <p className="text-lg mb-0 uppercase">
                    {t("Offer of promoting agency")}
                  </p>
                ) : null}
                <h3 className="text-lg m-0 font-normal">
                  {[
                    data.sm_location_city,
                    data.sm_location_district,
                    data.sm_location_street,
                  ]
                    .filter((value) => value)
                    .join(", ")}
                </h3>
              </div>{" "}
              <div className="flex gap-x-5">
                <ReportRealestate
                  is_primary={data?.is_primary}
                  realestateUuid={data?.uuid}
                />
                <div>
                  <button
                    className={cs(
                      "leading-5 uppercase bg-primse-mid-gray py-5 px-8 rounded-sm transition-all hover:bg-primse-gray",
                      "hidden lg:flex lg:justify-center lg:py-5 lg:px-5 items-center"
                    )}
                    onClick={() => {
                      setProblemModalStatus("investment");
                      setTimeout(() => {
                        actions.modals.openProblemModal(true);
                      }, 0);
                    }}
                  >
                    <span className="text-white font-bold whitespace-nowrap">
                      {t("Ask Primse")}
                    </span>
                  </button>
                </div>
                {isAgent() && investmentData?.uuid ? (
                  <RequestBriefcaseUser
                    uuid={investmentData?.uuid}
                    developer_name={investmentData?.developer_name}
                    investment_name={investmentData?.name}
                    data={investmentData}
                  />
                ) : null}
                {userRole !== "Deweloper" && (
                  <div>
                    <PopupTrigger
                      uuid={data.uuid}
                      investmentData={data}
                      aftermarket={true}
                      openRequestModal={true}
                      hiddenRequestButton={true}
                    >
                      <Button
                        className="hidden lg:flex lg:justify-center items-center lg:py-4 lg:px-5 "
                        Icon={CartWhite}
                      >
                        {t("Add to basket")}{" "}
                      </Button>
                    </PopupTrigger>
                  </div>
                )}{" "}
              </div>
            </header>
          </Container>

          <Element name="gallery">
            <GelleryRealestate
              data_secondary={data}
              static_map={map}
              images={data.files}
              setShowModalMap={setShowModalMap}
              investmentData={investmentData}
            />
          </Element>

          <div className="bg-primse-yellow sticky top-headerHeigh z-10">
            <Container>
              <ul className="flex -mx-1 lg:-mx-5 h-investmentNavHeight items-center font-medium">
                {REALESTATE_NAV.map(
                  ({ label, to, offset, mobile, deskop }, index) => (
                    <li
                      className={cs(
                        "px-3 lg:px-7 cursor-pointer",
                        mobile && deskop === false && "block lg:hidden",
                        mobile === false && deskop && "hidden lg:block"
                      )}
                      key={index}
                    >
                      <SmoothLink
                        className="text-primse-gray hover:text-white"
                        to={to}
                        smooth={true}
                        offset={offset}
                      >
                        {t(label)}
                      </SmoothLink>
                    </li>
                  )
                )}
              </ul>
            </Container>
          </div>
          <Container className="flex flex-col xl:flex-row">
            <div className="xl:w-9/12 xl:pr-7 mt-4 ">
              <Element name="description">
                <DescriptionRealestate text={data.description} />
              </Element>

              {/* Sekcja details zostaje ukryta do czasu otrzymania danych od klienta */}
              {/* <Element name="details" className="hidden lg:inline-block w-full">
                <DetailsRealestate
                  list={[
                    {
                      label: "Quisque eget eros eu nunc viverra",
                      value:
                        "Mauris scelerisque congue commodo. Suspendisse vestibulum est lorem.",
                    },
                    {
                      label: "Vestibulum eu urna sit amet",
                      value:
                        "Cras bibendum ullamcorper tortor, non dapibus ipsum mollis non.",
                    },
                    {
                      label: "Morbi scelerisque mauris et felis",
                      value:
                        "Nam et vehicula tortor, sit amet auctor augue. Lorem ipsum dolor sit amet, consectetur adipiscing.",
                    },
                  ]}
                />
              </Element> */}
              <Element name="map" className="px-1 pb-8">
                {map ? (
                  <div
                    className="relative mb-4 cursor-pointer"
                    style={{ height: "400px" }}
                    index={1}
                    onClick={() => setShowModalMap(true)}
                  >
                    <div
                      key={2}
                      className="bg-no-repeat bg-cover bg-center w-full h-full"
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_IMAGES_URL}${map})`,
                      }}
                    />
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                      <MapPin />
                    </div>
                    <button
                      className="leading-5 font-bold uppercase bg-primse-green py-4 px-6 rounded-sm transition-all text-white cursor-pointer hover:opacity-80 absolute bottom-3 right-3"
                      onClick={() => setShowModalMap(true)}
                    >
                      <div className="flex items-center">
                        <MapPin height="24" width="24" />
                        <span className="ml-2">{t("See on map")} </span>
                      </div>
                    </button>
                  </div>
                ) : null}
              </Element>
              {mode !== "presentation" ? (
                <Element name="agent">
                  <AgentInformation text={data?.information_for_agent} />
                </Element>
              ) : null}
              {data.files &&
                data.files !== null &&
                Array.isArray(data.files) &&
                data.files?.length !== 0 && (
                  <Element className="w-full hidden lg:block" name="docs">
                    <Documents files={data.files} />
                  </Element>
                )}
            </div>
            <aside className="mt-8 order-first xl:order-1 xl:w-3/12">
              <PriceComponent
                price={data?.price}
                pricemkw={data?.pricemkw}
                ask_for_price={data?.ask_for_price}
                is_in_promotion={data?.is_in_promotion}
                promotion_price={data?.promotion_price}
                r_uuid={data?.uuid}
                i_uuid={data?.investment_uuid}
              />

              <div className="mb-7">
                <Infobox title={t("Property data")} data={getPropertyData} />
              </div>
              {data.sm_offer_kind === "secondary" &&
                getContactData &&
                !showContactData && (
                  <div
                    onClick={() => {
                      tracking(uuid, data.id);
                      setShowContactData(true);
                    }}
                    className="bg-primse-green py-5 px-8 mb-8 rounded-sm text-white flex flex-col items-center transition-all cursor-pointer hover:text-primse-gray-font"
                  >
                    <p className="text-2xl font-bold mb-0 text-center ">
                      {t("View contact details")}
                    </p>
                  </div>
                )}
              {showContactData && (
                <div className="mb-7">
                  <Infobox title="Dane kontaktowe" data={getContactData} />
                </div>
              )}
            </aside>
          </Container>
          <NewsSection />

          <Modal
            onClose={() => setShowModalMap(false)}
            onOpen={() => setShowModalMap(true)}
            open={showModalMap}
            size="large"
            style={{ height: "80vh" }}
          >
            <div className="absolute -top-10 right-0 xl:-right-10 z-20 cursor-pointer">
              <X
                color="white"
                size={32}
                onClick={() => setShowModalMap(false)}
              />
            </div>
            <MapGoogle
              markersInvestment={[
                {
                  location: {
                    lat: parseFloat(
                      data.sm_latitude === null
                        ? data.investment_lat
                        : data.sm_latitude
                    ),
                    lng: parseFloat(
                      data.sm_longitude === null
                        ? data.investment_long
                        : data.sm_longitude
                    ),
                  },
                },
              ]}
              markersRealestate={[]}
              defaultCenter={{
                lat: parseFloat(
                  data.sm_latitude === null
                    ? data.investment_lat
                    : data.sm_latitude
                ),
                lng: parseFloat(
                  data.sm_longitude === null
                    ? data.investment_long
                    : data.sm_longitude
                ),
              }}
              pineComponent={true}
            />
          </Modal>
        </div>
      )}
      {!isLoading && data === null && (
        <div className=" flex flex-col items-center mt-20">
          <div className="uppercase font-medium text-center pt-4">
            {t("Real estate not found")}
          </div>
          <button
            className="leading-5 w-46 m-4 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-2 px-5 rounded-sm transition-all hover:bg-primse-gray"
            onClick={() => history.push(`/`)}
          >
            <div className="flex">
              {t("Go to the search engine")}
              <ArrowRight className="text-white ml-2 " size={20} />
            </div>
          </button>
        </div>
      )}
    </main>
  );
};

export default Realestate;
