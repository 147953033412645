import { useTranslation } from "react-i18next";
import { checkType } from "../../../../../utils/checkType";
import { editPrice } from "../../../../../utils/numbers";
import { useHistory } from "react-router";

const FlatListViewMobile = ({ flats }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      {flats?.length !== 0 ? (
        flats.map((el) => (
          <div
            key={el.realestate_uuid}
            className="py-3 px-2 border-b flex flex-col md:hidden"
            onClick={() => history.push(`/realestate/${el.realestate_uuid}`)}
          >
            <div>
              <span className="font-bold"> {t("Realestate name")}: </span>
              {el.name}
            </div>
            <div>
              <span className="font-bold"> {t("Investment name")}: </span>
              {el.investment_name}
            </div>

            <div>
              <span className="font-bold"> {t("Status")}: </span>
              {t(el.status)}
            </div>
            <div>
              <span className="font-bold"> {t("Price")}: </span>
              {el.price && el?.ask_for_price !== "1" ? (
                `${editPrice(el.price)} PLN`
              ) : (
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    history.push(
                      `/questions?r_uuid=${el.uuid}&i_uuid=${el.investment_uuid}`
                    );
                  }}
                >
                  {t("Ask about price")}
                </span>
              )}
            </div>

            <div>
              <span className="font-bold">
                {t("Price per m")}
                <sup>2</sup>:{" "}
              </span>
              {el.pricemkw && el?.ask_for_price !== "1" ? (
                `${parseInt(el.pricemkw)} PLN`
              ) : (
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    history.push(
                      `/questions?r_uuid=${el.uuid}&i_uuid=${el.investment_uuid}`
                    );
                  }}
                >
                  {t("Ask about price")}
                </span>
              )}
              {el.pricemkw && <sup style={{ zIndex: "-1" }}>2</sup>}
            </div>
            <div>
              <span className="font-bold"> {t("Surface")}: </span>{" "}
              {el.area ? `${el.area} m` : t("brak danych")}
              {el.area && <sup style={{ zIndex: "-1" }}>2</sup>}
            </div>

            <div>
              <span className="font-bold"> {t("Rooms")}: </span>
              {el.rooms ? el.rooms : t("not applicable")}
            </div>
            <div>
              <span className="font-bold"> {t("Floor")}: </span>
              {el.floor}
            </div>
            <div>
              <span className="font-bold"> {t("Type")}: </span>
              {el.type && t(checkType(el.type))}
            </div>
          </div>
        ))
      ) : (
        <div className="text-sm w-full py-8 px-4 ">
          {t("There are no matching premises in the investment")}
        </div>
      )}
    </>
  );
};

export default FlatListViewMobile;
