import PropTypes from "prop-types";
import * as RModal from "react-modal";
import {AiFillCloseCircle} from "react-icons/all";
import React from "react";

const customStyles = {
  overlay: {
    zIndex: 1000,
    background: 'rgba(0, 0, 0, 0.5)'
  },
  content: {
    padding: 0,
    borderRadius: 2,
    border: "none",
    background: "white",
    // background: "red",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

RModal.setAppElement("#modal");

const Modal = ({ isOpen, handleClose, children }) => {
  return (
    <RModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      //   appElement="#modal"
    >
      <header className="flex justify-end transition-all p-4 hover:opacity-25">
        <AiFillCloseCircle   onClick={handleClose} className="text-primse-gray-font cursor-pointer w-8 h-8" />
      </header>
      {children}
    </RModal>
  );
};

Modal.propTypes = {
  Content: PropTypes.any,
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default Modal;
