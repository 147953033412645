import React, { useState } from "react";
import { useStoreState } from "easy-peasy";
import NewRequestModal from "../../../../../modals/NewRequestModal";
import RequestUser from "..";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import BriefcaseContentService from "../../../../../services/BriefcaseContentService";
import { toast } from "react-toastify";

const RequestBriefcaseUser = ({
  uuid,
  developer_name,
  investment_name,
  data,
  classNameButton,
}) => {
  const { briefcaseClient } = useStoreState((state) => state.briefcase);
  const [open, setOpen] = useState(false);
  const handleModalOpen = () => {
    setOpen(false);
  };
  const { t } = useTranslation();

  const getBriefcase = async (uuidClient, uuid) => {
    return await BriefcaseContentService.getBriefcaseContent(uuidClient)().then(
      (res) => {
        console.log(res?.payload);
        const findRequest = res?.payload?.find(
          (el) => el.investment_uuid === uuid
        );
        if (findRequest && findRequest?.query_status) {
          toast.error(
            t("Client has already been registered for the investment")
          );
        } else {
          setOpen(true);
        }
      }
    );
  };

  return (
    <>
      {!briefcaseClient ? (
        <RequestUser
          uuid={uuid}
          developer_name={developer_name}
          investment_name={investment_name}
          classNameButton={classNameButton}
        />
      ) : (
        <>
          <button
            className={cs(
              "leading-5 uppercase bg-primse-green py-5 px-8 rounded-sm transition-all hover:bg-primse-gray",
              "hidden lg:flex lg:justify-center lg:py-5 lg:px-5 items-center",
              classNameButton
            )}
            onClick={() => getBriefcase(briefcaseClient.uuid, uuid)}
          >
            <span className="text-white font-bold whitespace-nowrap">
              {t("Report a customer for this investment")}
            </span>
          </button>
          <NewRequestModal
            investment_uuid={uuid}
            clientUuid={briefcaseClient.uuid}
            developerName={data?.developer_name}
            investmentName={data?.name}
            modalOpen={open}
            setModalOpen={handleModalOpen}
            handleRefresh={() => {}}
          />
        </>
      )}
    </>
  );
};

export default RequestBriefcaseUser;
