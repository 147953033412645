

import { Popup } from "semantic-ui-react";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { actions } from '../../../../../../store'

 
const PopupTrigger = ({ children, uuid, setOpenBasket, setOpenSelectBasket, basketName }) => {
    return <PopupView uuid={uuid} setOpenBasket={setOpenBasket} setOpenSelectBasket={setOpenSelectBasket} basketName={basketName}>{children}</PopupView>
}

const PopupView = ({ children, setOpenSelectBasket, basketName}) => {
 

    const { t } = useTranslation()

    return (
        <Popup
            trigger={children}
            flowing
            on="click"
            position={"left center"}
        >
            <div className={cs("bg-white  text-sm font-normal")}>
                <p className="  text-center  my-1 ">{t("Cart name")}: <strong>{t(basketName)}</strong></p>
                <div className="flex flex-col">
                    <button
                        className="leading-5 my-2 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                        onClick={() => {
                            setOpenSelectBasket(true)
                        }}
                    >
                        {t("Switch basket")}
                    </button>
                    <button
                        className="leading-5 my-2 font-bold text-white uppercase bg-primse-green py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                        onClick={() => {
                            actions.baskets.setBasketModal(true)
                        }}
                    >
                        {t("Open basket")}
                    </button>
                </div>
            </div>

        </Popup>

    )
}

export default PopupTrigger