import axios from "../utils/axios";

const LocationsServices = {
  getLocations: (
    locations_arr,
    marketTypes,
    withSecondaryMarket = false
  ) => async () => {
    const formData = new FormData();
    locations_arr?.forEach((el) => {
      if (el === "service premises" || el === "office space") {
        formData.append("type[]", "office");
      } else {
        formData.append("type[]", el);
      }
    });

    formData.append("perPage", -1);
    if (withSecondaryMarket) {
      if (!marketTypes || marketTypes?.length === 0) {
        formData.append("with_existing_investments", true);
        formData.append("with_existing_realestates", true);
      } else {
        marketTypes?.forEach((el) => {
          if (el === "Primary market") {
            formData.append("with_existing_investments", true);
          } else if (el === "Secondary market") {
            formData.append("with_existing_realestates", true);
          }
        });
      }
    }

    return await axios
      .post(`/locations-index`, formData)
      .then((res) => res.data.payload);
  },
};

export default LocationsServices;
