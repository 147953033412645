import axios from "../utils/axios";
import { actions } from "../store";

const BasketsService = {
  addBasket: async ({ name }) => {
    return await axios.post("/basket-create", {
      name: `${name}`,
    });
  },
  getBaskets: async () => {
    return await axios.get("/basket-index").then((res) => {
      if (res.data) {
        actions.baskets.setAllBaskets(res.data.payload);
        return res.data.payload;
      } else return null;
    });
  },
  getBasket: (basket_uuid) => async () => {
    if (basket_uuid) {
      return await axios.get(`/basket-view/${basket_uuid}`).then((res) => {
        const { basket_items } = res.data.payload;
        actions.baskets.setBasketItems({
          basketId: basket_uuid,
          basketItemsList: basket_items,
        });
        return res.data.payload;
      });
    }
  },
  updateBasket: async ({ basket_uuid, name }) => {
    return await axios
      .post(`/basket-update/${basket_uuid}`, {
        name,
      })
      .then((res) => res.data.payload);
  },
  removeBasket: async ({ basket_uuid }) => {
    return await axios
      .post(`/basket-remove/${basket_uuid}`)
      .then((res) => {
        if (res?.data?.payload) return res?.data?.payload;
        else return res;
      })
      .catch((error) => error);
  },
  removeItemFromBasket: async ({ basket_item_uuid }) => {
    return await axios
      .post(`/basket-removefrom/${basket_item_uuid}`)
      .then((res) => {
        return res;
      });
  },
  addToBasket: async ({ basket_uuid, investment_uuid }) => {
    // const promise1 = await axios.post(`/basket-addto/${basket_uuid}`, {
    //   investment_uuid: `${investment_uuid}`,
    // });
    // const promise3 = await axios.get(`/basket-view/${basket_uuid}`);

    // return await Promise.all([promise1, promise3]).then(function (res) {
    //   actions.baskets.setBasketItems({
    //     basketId: basket_uuid,
    //     basketItemsList: res[1].data.payload.basket_items,
    //   });
    //   return res[0];
    // });
    const token = localStorage.getItem("token");
    return await axios
      .post(`/basket-addto/${basket_uuid}`, {
        investment_uuid: `${investment_uuid}`,
      })
      .then(async (resAddTo) => {
        return await axios
          .get(`/basket-view/${basket_uuid}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            actions.baskets.setBasketItems({
              basketId: basket_uuid,
              basketItemsList: res.data.payload.basket_items,
            });
            return resAddTo;
          });
      })
      .catch((err) => err);
  },
  //   addRealestateToBasket: async ({ basket_uuid, realestate_uuid }) => {
  //     return await axios.post(`/basket-addto/${basket_uuid}`, {
  //       realestate_uuid: `${realestate_uuid}`,
  //     });
  //   },
  addRealestateToBasket: async ({ basket_uuid, realestate_uuid }) => {
    const token = localStorage.getItem("token");
    return await axios
      .post(`/basket-addto/${basket_uuid}`, {
        realestate_uuid: `${realestate_uuid}`,
      })
      .then(async (resAddTo) => {
        return await axios
          .get(`/basket-view/${basket_uuid}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            actions.baskets.setBasketItems({
              basketId: basket_uuid,
              basketItemsList: res.data.payload.basket_items,
            });
            return resAddTo;
          })
          .catch((err) => err);
      })
      .catch((err) => err);
  },
  // addRealestateToBasket: async ({ basket_uuid, realestate_uuid }) => {
  //   const promise1 = await axios.post(`/basket-addto/${basket_uuid}`, {
  //     realestate_uuid: `${realestate_uuid}`,
  //   });
  //   const promise2 = await axios.get(`/basket-view/${basket_uuid}`);

  //   return await Promise.all([promise1, promise2]).then(function (res) {
  //     actions.baskets.setBasketItems({
  //       basketId: basket_uuid,
  //       basketItemsList: res[1].data.payload.basket_items,
  //     });

  //     return res[0];
  //   });
  // },
};

export default BasketsService;
