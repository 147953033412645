import { useHistory } from "react-router-dom";
import { Popup } from "semantic-ui-react";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import { actions } from '../../../../../../store'


const PopupBriefcaseTrigger = ({ children, uuid, setOpenBasket, setOpenSelectBasket, briefcaseClient }) => {
    return <PopupBriefcaseView
        uuid={uuid}
        setOpenBasket={setOpenBasket}
        setOpenSelectBasket={setOpenSelectBasket}
        briefcaseClient={briefcaseClient}
    >{children}</PopupBriefcaseView>
}

const PopupBriefcaseView = ({ children, setOpenSelectBasket, briefcaseClient }) => {


    const history = useHistory()
    function handleClientDetails(link) {
        history.push(`/client/${link}`);
    }

    const { t } = useTranslation()

    return (
        <Popup
            trigger={children}
            flowing
            on="click"
            position={"left center"}
        >
            <div className={cs("bg-white  text-sm font-normal")}>
                {briefcaseClient ? (
                    <p className="text-center  my-1 ">{t("Briefcase")}: <strong>{briefcaseClient?.first_name} {briefcaseClient?.last_name}</strong></p>
                ) : (
                    <p className="text-center  my-1 ">{t("No briefcase has been selected")}</p>
                )}

                <div className="flex flex-col">
                    <button
                        className="my-2 font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                        onClick={() => {
                            setOpenSelectBasket(true)
                        }}
                    >
                        {briefcaseClient ? t("Switch briefcase") : t("Choose briefcase")}

                    </button>
                    {briefcaseClient && (
                        <button
                            className="my-2 font-bold text-white uppercase bg-primse-green py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                            onClick={() => {
                                actions.briefcase.setBriefcaseModal(true)
                            }}
                        >
                            {t("Open briefcase")}
                        </button>
                    )}
                    {briefcaseClient && (
                        <button
                            className="my-2 font-bold text-white uppercase bg-primse-gray py-3 px-14 rounded-sm transition-all hover:bg-primse-green hover:text-white"
                            onClick={() => {
                                handleClientDetails(briefcaseClient?.uuid)
                            }}
                        >
                            {t("Przejdź do karty klienta")}
                        </button>
                    )}

                </div>
            </div>

        </Popup>

    )
}

export default PopupBriefcaseTrigger