import { useState } from "react";
import PopupTrigger from "./components/PopupTrigger";
import ControlerEditModal from "./components/ControlerEditModal";
import DeleteClientModal from "./components/DeleteClientModal";
import { Menu } from "react-feather";

const PopupClients = (rest) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const closedModal = () => {
    setOpenEditModal(false);
  };
  const closedDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <div>
        {openEditModal && (
          <ControlerEditModal closedModal={closedModal} {...rest} />
        )}
        {openDeleteModal && (
          <DeleteClientModal closedModal={closedDeleteModal} {...rest} />
        )}
        {!openEditModal && !openDeleteModal && (
          <PopupTrigger
            openEditModal={setOpenEditModal}
            openDeleteModal={setOpenDeleteModal}
            {...rest}
          >
            <Menu className="mx-2 text-primse-green cursor-pointer" size={24} />
          </PopupTrigger>
        )}
      </div>
    </>
  );
};

export default PopupClients;
