import React, { useState, useEffect } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";
import NewQuestionContent from "./NewQuestionContent";

import QuestionsService from "../../../services/QuestionsService";
import Loader from "../../../components/Loader";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";

const NewQuestionModal = ({ handleRefresh, addQuestionData }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [subjects, setSubjects] = useState(null);
  function handleClose() {
    history.replace("questions");

    setOpen(false);
  }

  useEffect(() => {
    if (addQuestionData) setOpen(true);
  }, [addQuestionData]);

  const { data } = useQuery(
    "subjectsQuestions",
    QuestionsService.getQuestionsSubject,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      setSubjects(data?.data?.payload);
    }
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={() => handleClose(false)}
      onOpen={() => setOpen(true)}
      size={"large"}
      trigger={
        <button className="leading-5 text-xs lg:text-base uppercase font-bold text-white bg-primse-green py-3 px-10 rounded-sm transition-all hover:bg-primse-gray">
          {t("Add a question")}
        </button>
      }
    >
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between">
          <div>{t("Add a question")}</div>

          <AiFillCloseCircle
            onClick={() => handleClose(false)}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      {subjects !== null ? (
        <Modal.Content>
          <NewQuestionContent
            addQuestionData={addQuestionData}
            subjects={subjects}
            handleClose={handleClose}
            handleRefresh={handleRefresh}
          />
        </Modal.Content>
      ) : (
        <Loader />
      )}
    </Modal>
  );
};

export default NewQuestionModal;
