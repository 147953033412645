import { useStoreState } from "easy-peasy";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import Loader from "../../components/Loader";
import ClientsService from "../../services/ClientsService";
import ClientsView from "./ClientsView";

const Clients = () => {
  const [refresh, setRefresh] = useState(false);
  const [clientList, setClientList] = useState(null);
  const clientFilters = useStoreState((state) => state.filters.clientFilter);

  const { data } = useQuery(
    "clients",
    () => ClientsService.getClients(clientFilters),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      if (data.status === 200) {
        setClientList(data.data);
      } else {
        setClientList({ payload: [] });
      }
    }
  }, [data]);

  useEffect(() => {
    if (refresh === true) {
      setClientList(null);
      ClientsService.getClients()
        .then((res) => {
          if (res.status === 200) {
            setClientList(res.data);
          } else {
            setClientList({ payload: [] });
          }
        })
        .finally(() => setRefresh(false));
    }
  }, [refresh]);

  const handleRefresh = (value) => {
    setRefresh(value);
  };

  return (
    <main className="py-0">
      {clientList !== null && !refresh && (
        <ClientsView data={clientList} handleRefresh={handleRefresh} />
      )}
      {(clientList === null && <Loader />) || (refresh && <Loader />)}
    </main>
  );
};

// Clients.propTypes = {};

// Clients.defaultProps = {};

export default Clients;
