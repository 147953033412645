export const centerToMarkers = (
  mapRef,
  markers,
  defaultCenter,
  defaultZoom
) => {
  const map = mapRef.current;
  if (markers?.length === 0) {
    map.setCenter(defaultCenter);
    map.setZoom(defaultZoom);
    return;
  }
  const bounds = new window.google.maps.LatLngBounds();

  markers.map(({ location: { lat, lng } }) =>
    bounds.extend(new window.google.maps.LatLng(lat, lng))
  );
  map.fitBounds(bounds);
};
