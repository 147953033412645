import cs from "classnames";
import { useEffect, useMemo, useState } from "react";
import Container from "../../components/Container";
import MapInvestment from "./components/MapInvestment";
import styles from "../Search/Search.module.css";
import FlatList from "./components/FlatList";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import MapBasket from "./components/MapBasket";
import NewsSection from "../../components/NewsSection";
import FlatBasket from "./components/FlatBasket";
import { useStoreState } from "easy-peasy";

const BasketContainer = ({
  currentBasket,
  EmptyBasketComponent,
  handleRefresh,
}) => {
  const [loadedData, setLoadedData] = useState(false);

  const location = useLocation();

  const { t } = useTranslation();

  const userAgencyType = useStoreState((state) => state.auth.user?.agencyType);

  const getBasketItem = () => {
    setLoadedData(true);
  };

  const filterInvestment = useMemo(() => {
    const filter = currentBasket.basketItems
      .filter((investment) => investment.investment_name === "Rynek wtórny")
      .map((data) => data);

    const filterElementToMap = filter
      ?.filter(
        ({ realestate_latitude, realestate_longitude }) =>
          realestate_latitude && realestate_longitude
      )
      .map((el) => ({
        ...el,
        location: {
          lat: el?.realestate_latitude,
          lng: el?.realestate_longitude,
        },
        price: el?.realestate_price,
        name: el?.realestate_name,
        uuid: el?.realestate_uuid,
        sm_location_city: el?.realestate_city,
        sm_location_street: el?.realestate_street,
        area: el?.realestate_area,
        rooms: el?.realestate_rooms,

        pictures: [
          {
            file: el?.realestate_picture,
            thumbnail: el?.realestate_picture_sm,
          },
        ],
      }));

    return { data: filter, dataMap: filterElementToMap };
  }, [location.key]);

  const filterInvestmentLength = useMemo(() => {
    const filter = currentBasket.basketItems
      .filter((investment) => investment.investment_name === "Rynek wtórny")
      .filter((investment) => investment.realestate_name)
      .map((data) => data);

    return filter?.length;
  }, [location.key]);

  const filterCurrentBasket = useMemo(() => {
    const mapInvestment = currentBasket.basketItems.filter(
      ({ is_secondary_market }) => !is_secondary_market
    );

    const arrItems = [];

    mapInvestment?.forEach((el) => {
      const filter = arrItems.filter(
        ({ investment_id }) => investment_id === el.investment_id
      );

      if (filter?.length === 0) {
        arrItems.push({
          investment_uuid: el.investment_uuid,
          investment_id: el.investment_id,
          investment_name: el.investment_name,
          investment_city: el.investment_city,
          investment_district: el.investment_district,
          investment_pictures: el.investment_pictures,
          investment_price_from: el.investment_price_from,
          investment_price_to: el.investment_price_to,
          investment_area_from: el.investment_area_from,
          investment_area_to: el.investment_area_to,
          investment_rooms_from: el.investment_rooms_from,
          investment_rooms_to: el.investment_rooms_to,
          investment_status: el.investment_status,
          developer_name: el.developer_name,
          number_of_offers: el.number_of_offers,
          address_street: el.address_street,
          uuid: el.uuid,
          items: [],
          completion_date: el.investment_completion_date,
        });
      }
    });

    mapInvestment?.forEach((el) => {
      const filter = arrItems
        .map(({ investment_id }) => {
          if (investment_id === el.investment_id && el.realestate_name) {
            return el;
          } else return null;
        })
        .filter((el) => el !== null);

      function checkIndex(element) {
        return element.investment_id === el.investment_id;
      }
      const index = arrItems.findIndex(checkIndex);
      if (filter?.length !== 0) {
        arrItems[index].items = [...arrItems[index].items, { ...filter }];
      }
    });
    return arrItems;
  }, [location.key, currentBasket.basketItems]);

  useEffect(() => {
    setLoadedData(false);
    setTimeout(getBasketItem, 0);
  }, [location.key]);

  return currentBasket.basketItems?.length !== 0 ? (
    <main className={cs("pt-4", styles.mainContainer)}>
      <Container className="h-full flex flex-col justify-between">
        <div className="flex mt-4 h-full flex-col xl:flex-row">
          <div
            className={cs(
              " mb-3 xl:mb-0 xl:pr-3 hidden lg:block lg:w-9/12 xl:w-5/6",
              styles.mapContainer,
              userAgencyType === "Metrohouse office" && "lg:w-full xl:w-full "
            )}
          >
            {loadedData ? (
              <MapBasket
                currentBasket={currentBasket}
                markersRealestate={filterInvestment?.dataMap}
              />
            ) : null}
          </div>
          {userAgencyType !== "Metrohouse office" && (
            <div className={cs("lg:w-3/12 xl:w-1/6 flex xl:flex-col ")}>
              <div
                className={cs(
                  "xl:pr-2 -mb-5 w-full h-full flex flex-wrap xl:block overflow-auto"
                )}
              >
                {filterInvestment?.data?.length < 1 ? (
                  <strong>
                    {t(
                      "There are no properties from this investment in the basket"
                    )}{" "}
                  </strong>
                ) : (
                  <>
                    <div className="flex p-4 px-2">
                      <strong>{t("Secondary market")}: </strong>
                      <span className="text-primse-green">
                        <strong>{filterInvestmentLength}</strong>
                      </span>
                    </div>
                    {filterInvestment?.data?.map((realestate) => (
                      <div
                        key={realestate.investment_uuid}
                        className="w-full sm:w-1/2 sm:p-4 md:w-1/3 md:p-3 lg:p-0 lg:w-full"
                      >
                        <FlatBasket
                          key={realestate.investment_uuid}
                          realestateData={realestate}
                          realestate_name={realestate.realestate_name}
                          uuidDeleteFromBasket={realestate.uuid}
                          handleRefresh={handleRefresh}
                        />
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </Container>
      <div className="xl:px-8 xl:mt-8 ">
        {filterCurrentBasket.map(
          ({ investment_uuid, investment_name, items, uuid, ...restProps }) => (
            <div
              key={`${investment_uuid}-${investment_name}`}
              className="flex justify-between "
            >
              <div className="w-full lg:w-3/12 xl:w-4/12">
                <MapInvestment
                  uuid={investment_uuid}
                  investmentName={investment_name}
                  uuidDeleteFromBasket={uuid}
                  handleRefreshBasket={handleRefresh}
                  {...restProps}
                  currentBasketMore={items?.length !== 0 ? true : false}
                />
              </div>
              <div className="hidden lg:block lg:w-9/12 xl:w-8/12 py-5">
                {items?.length !== 0 ? (
                  <div id="flats">
                    <table className="text-left w-full max-h-40 py-5">
                      <thead className="font-bold uppercase text-xs flex w-full">
                        <tr className="flex w-full mb-4">
                          <th className="py-4 px-4 border-b w-1/12">
                            {t("Name")}
                          </th>
                          <th className="py-4 px-4 border-b w-1/12">
                            {t("Status")}
                          </th>

                          <th className="py-4 px-4 border-b w-2/12">
                            {t("Price")}
                          </th>
                          <th className="py-4 px-4 border-b w-1/12 whitespace-nowrap">
                            {t("Price per m")}
                            <sup>2</sup>
                          </th>
                          <th className="py-4 px-4 border-b w-2/12">
                            {t("Surface")}
                          </th>
                          <th className="py-4 px-4 border-b w-1/12 ">
                            {t("Rooms")}
                          </th>
                          <th className="py-4 px-4 border-b w-1/12">
                            {t("Floor")}
                          </th>
                          <th className="py-4 px-4 border-b w-1/12">
                            {t("Type")}
                          </th>
                          <th className="py-4 px-4 border-b w-1/12"></th>
                        </tr>
                      </thead>
                      <tbody className="text-sm flex flex-col items-center justify-between w-full max-h-40 overflow-auto ">
                        {items.map((el) => (
                          <FlatList
                            key={el[0].uuid}
                            investment_uuid={investment_uuid}
                            investment_name={investment_name}
                            realestate_uuid={el[0].realestate_uuid}
                            uuidDeleteFromBasket={el[0].uuid}
                            handleRefreshBasket={handleRefresh}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="text-sm w-full py-8 px-4 ">
                    {t(
                      "There are no properties from this investment in the basket"
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        )}
      </div>
      <NewsSection />
    </main>
  ) : (
    <EmptyBasketComponent />
  );
};

export default BasketContainer;
