import axios from "../utils/axios";

const getExtension = (type) => {
  if (!type) return null;
  switch (type) {
    case "application/pdf":
      return "pdf";
    case "image/jpeg":
      return "jpg";
    case "image/png":
      return "png";

    default:
      return "txt";
  }
};

const selectExtension = (extension) => {
  if (
    extension === "jpg" ||
    extension === "png" ||
    extension === "ico" ||
    extension === "pdf" ||
    extension === "txt"
  )
    return true;
  else false;
};

const DocumentsServices = {
  getDocument: async ({ document_uuid, file_name, extension }) => {
    return await axios
      .get(`/download-document/${document_uuid}`, { responseType: "blob" })
      .then((res) => {
        let fileNameExtension;
        if (extension) {
          fileNameExtension =
            file_name.indexOf(extension) === -1
              ? `${file_name}.${extension}`
              : file_name;
        } else {
          const sliceName = file_name.slice(-3);

          if (selectExtension(sliceName)) {
            fileNameExtension = file_name.slice(0, file_name.length - 4);
          } else {
            fileNameExtension = file_name;
          }
        }

        if (!extension && getExtension(res?.data?.type)) {
          fileNameExtension += `.${getExtension(res?.data?.type)}`;
        }

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileNameExtension);
        document.body.appendChild(link);
        link.click();
      });
  },
  showDocument: async ({ document_uuid }) => {
    return await axios
      .get(`/download-document/${document_uuid}`, { responseType: "blob" })
      .then((res) => {
        const blob = new Blob([res?.data]);
        const url = window.URL.createObjectURL(blob);
        return { url, extension: getExtension(res?.data?.type) };
      });
  },
  getPdf: async ({ document_uuid, file_name }) => {
    const link = document_uuid.slice(5);
    return await axios.get(link, { responseType: "blob" }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file_name}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  },
  getFile: async ({ document_id, file_name, extension }) => {
    return await axios
      .get(`/file-download/${document_id}`, { responseType: "blob" })
      .then((res) => {
        let fileNameExtension;
        if (extension) {
          fileNameExtension =
            file_name.indexOf(extension) === -1
              ? `${file_name}${extension}`
              : file_name;
        } else {
          const sliceName = file_name.slice(-3);

          if (selectExtension(sliceName)) {
            fileNameExtension = file_name.slice(0, file_name.length - 4);
          } else {
            fileNameExtension = file_name;
          }
        }

        if (!extension && getExtension(res?.data?.type)) {
          fileNameExtension += `.${getExtension(res?.data?.type)}`;
        }

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileNameExtension);
        document.body.appendChild(link);
        link.click();
      });
  },

  createPdf: async (formData) => {
    return await axios
      .post("/pdf-offer-generate", formData, { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/pdf" })
        );
        const link = document.createElement("a");
        const today = new Date();

        link.href = url;
        link.setAttribute(
          "download",
          `lista_lokali-${today.toLocaleDateString("en-US")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      });
  },
  sendPdf: async (formData) => {
    return await axios
      .post("/send-pdf", formData, { responseType: "blob" })
      .then((res) => {
        return res;
      });
  },
  getPDFTemplates: async () => {
    return await axios.get("/pdf-settings").then((res) => res.data.payload);
  },
  pdfProvisionsGenerate: async () => {
    return await axios
      .get("/pdf-provisions-generate", { responseType: "blob" })
      .then((res) => {
        const today = new Date();
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `offer-${today.toLocaleDateString("en-US")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      });
  },
};

export default DocumentsServices;
