import { useState, useMemo } from "react";
import { Input } from "semantic-ui-react";
import { Modal } from "semantic-ui-react";
import { AiFillCloseCircle, BiSearchAlt2 } from "react-icons/all";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import SearchBriefcaseElement from "./SearchBriefcaseElement";
import ClientsService from "../../../../../../services/ClientsService";

import Loader from "../../../../../Loader";
import { actions } from "../../../../../../store";

const SelectBriefcaseModal = ({ setOpenBasket }) => {
  const { t } = useTranslation();

  const { data } = useQuery("clients", ClientsService.getClients);
  const [filteredData, setFilteredData] = useState([]);
  const [searchedSurname, setSearchedSurname] = useState("");
  const [searchedMobile, setSearchedMobile] = useState("");
  const [searchedEmail, setSearchedEmail] = useState("");

  const handleLastClients = useMemo(() => {
    if (data) {
      const listClients = data?.data?.payload?.slice(-10);
      if (!listClients) return [];
      listClients.reverse();
      return listClients;
    } else return null;
  }, [data]);

  function handleClientDetails(client) {
    actions.briefcase.setBriefcaseClient(client);
    setOpenBasket();
  }

  const handleSearchSurname = (event) => {
    let value = event.target.value;
    setSearchedSurname(value);
  };

  const handleSearchMobile = (event) => {
    let value = event.target.value;
    setSearchedMobile(value);
  };

  const handleSearchEmail = (event) => {
    let value = event.target.value;
    setSearchedEmail(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    ClientsService.getSearchedClient({
      last_name: searchedSurname,
      email: searchedEmail,
      phone: searchedMobile,
      assigned_to: "",
      created_from: "",
      created_to: "",
    })
      .then((res) => {
        setFilteredData(res.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClearSearch = () => {
    setSearchedSurname("");
    setSearchedMobile("");
    setSearchedEmail("");
    setFilteredData([]);
  };

  return (
    <Modal open="open" size={"large"} onClose={() => setOpenBasket()}>
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between items-center">
          <div className="flex  items-center">{t("Switch briefcase")}</div>
          <AiFillCloseCircle
            onClick={() => setOpenBasket()}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        <>
          <div>
            <form
              className="flex flex-col lg:flex-row items-center"
              onSubmit={handleSubmit}
            >
              <Input
                placeholder={t("Client last name")}
                className="my-2 mx-2 "
                label={t("Minimum 3 characters")}
                value={searchedSurname}
                labelPosition={"left corner"}
                fluid={false}
                type="text"
                size={"large"}
                onChange={handleSearchSurname}
                error={
                  0 < searchedSurname?.length && searchedSurname?.length < 3
                }
              />
              <Input
                placeholder={t("Phone number")}
                className="my-2 mx-2"
                value={searchedMobile}
                label={t("Minimum 6 digits")}
                labelPosition={"left corner"}
                fluid={false}
                type="text"
                size={"large"}
                onChange={handleSearchMobile}
                error={0 < searchedMobile?.length && searchedMobile?.length < 6}
              />
              <Input
                placeholder={t("E-mail")}
                className="my-2 mx-2"
                value={searchedEmail}
                label={t("Minimum 6 characters")}
                labelPosition={"left corner"}
                fluid={false}
                type="text"
                size={"large"}
                onChange={handleSearchEmail}
                error={0 < searchedEmail?.length && searchedEmail?.length < 6}
              />
              <button
                className="leading-5 flex items-center py-3 px-4 text-sm"
                type="button"
                onClick={handleClearSearch}
              >
                <X size={14} className="mr-2" />
                {t("Clear")}
              </button>

              <button
                className="leading-5 font-bold text-white bg-primse-green uppercase py-3 px-3 mx-2 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                type="submit"
                disabled={
                  searchedSurname?.length < 3 &&
                  searchedMobile?.length < 6 &&
                  searchedEmail?.length < 6
                }
              >
                <BiSearchAlt2 />
              </button>
            </form>
          </div>
          <div style={{ paddingTop: "0" }}>
            {filteredData?.length < 1 ? (
              <>
                {handleLastClients ? (
                  <SearchBriefcaseElement
                    data={handleLastClients}
                    handleClientDetails={handleClientDetails}
                  />
                ) : (
                  <Loader />
                )}
              </>
            ) : (
              <SearchBriefcaseElement
                data={filteredData}
                handleClientDetails={handleClientDetails}
              />
            )}
          </div>
        </>
      </Modal.Content>
    </Modal>
  );
};

export default SelectBriefcaseModal;
