import React from "react";
import { Form, FormDropdown, Modal } from "semantic-ui-react";
import { AiFillCloseCircle } from "react-icons/all";
import { useTranslation } from "react-i18next";
import { useStoreState } from "easy-peasy";
import { searchUtils } from "../../../../../../utils/searchUtils";
import { toast } from "react-toastify";

const SelectModal = ({ openBasket, setOpenBasket }) => {
  const { t } = useTranslation();

  const { basketsList, currentBasket } = useStoreState(
    (state) => state.baskets
  );

  const basketsSuggestions = () => {
    const arr = [];
    basketsList?.forEach(({ uuid, name }) => {
      arr.push({
        value: uuid,
        text: `${name}`,
      });
    });
    return Array.from(
      new Set(arr.map((item) => JSON.stringify(item)))
    ).map((string) => JSON.parse(string));
  };

  const handleChangeBasket = (event, data) => {
    let value = data.value;
    try {
      searchUtils(basketsList, value);
      toast.success(`${t("The basket has been changed")}`);
    } catch (error) {
      toast.error(`${error}`);
    }
    setOpenBasket();
  };

  return (
    <Modal
      open={openBasket}
      onClose={() => {
        setOpenBasket();
      }}
      size={"tiny"}
    >
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div
          className="flex justify-between items-center"
          style={{ alignItems: "center" }}
        >
          <div className="flex items-center">
            <div className="mr-2">{t("Switch basket")}:</div>
            <Form className="m-2 flex">
              <FormDropdown
                defaultValue={currentBasket.uuid}
                placeholder={t("My carts")}
                className="w-1/2"
                search={true}
                selection={true}
                size={"small"}
                options={basketsSuggestions()}
                onChange={(e, data) => handleChangeBasket(e, data)}
              />
            </Form>
          </div>
          <div className="flex justify-between">
            <AiFillCloseCircle
              onClick={() => {
                setOpenBasket();
              }}
              className="text-primse-gray-font cursor-pointer w-8 h-8"
            />
          </div>
        </div>
      </Modal.Header>
    </Modal>
  );
};

export default SelectModal;
