import React from 'react'
import BriefcaseComponent from './BriefcaseComponent';

const Briefcase = () => {

    return <>
        <BriefcaseComponent />
    </>

}

export default Briefcase