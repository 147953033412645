import { useEffect } from "react";
import { Menu, Square, CheckSquare } from "react-feather";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import Moment from "react-moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Popup } from "semantic-ui-react";

import BriefcaseContentService from "../../../../../services/BriefcaseContentService";
import { toast } from "react-toastify";
import { editPrice } from "../../../../../utils/numbers";
import DetailsInvestments from "../../../../../components/DetailsInvestments"; 
import WarningModal from "../../../../../modals/WarningModal";
import ButtonNavLink from "../../../../../components/ButtonNavLink";

const InvestmentItem = ({
  investmentItem: {
    id,
    name,
    floor,
    rooms,
    price,
    area,
    created,
    item_uuid,
    plan_url,
  },
  SecondaryMarket = false,
  briefcase_uuid,
  investment_name,
  handleRefreshData,
  realestateList,
  setRealestateList,
  handleDeleteRealestate,
  handleRefresh,
  investment_status,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isChosen, setIsChosen] = useState(false);
  const [isOpenPopupButton, setIsOpenPopupButton] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

 
  useEffect(() => {
    for (const [key] of Object.entries(realestateList)) {
      if (key === id) {
        setIsChosen(true);
      }
    }
  }, []);

  const removeRealestate = () => {
    BriefcaseContentService.removeRealestate({
      briefcase_uuid: briefcase_uuid,
      item_uuid: item_uuid,
    })
      .then(() => {
        toast.success(
          `${t("The real estate")} ${name} ${t(
            "has been successfully remove from the briefcase"
          )}`
        );
        setOpenWarningModal(false);
        handleRefreshData(true);
      })
      .catch((err) => {
        if (err.response.status === 410) {
          setOpenWarningModal(false);
          handleRefreshData(true);
          toast.success(
            `${t("The real estate")} ${name} ${t(
              "has been successfully remove from the briefcase"
            )}`
          );
        } else {
          toast.error(`${err}`);
        }
      });
  };

  const setChosen = () => {
    if (isChosen === false) {
      setIsChosen(true);
      setRealestateList((prevState) => {
        return {
          ...prevState,
          [id]: {
            realestate_name: name,
            investment_name: investment_name,
          },
        };
      });
    } else if (isChosen === true) {
      setIsChosen(false);
      handleDeleteRealestate(id);
      handleRefresh(true);
    }
  };

  // const trackingPlan = (url, nameRealestate, uuidRealestate) => {
  //   TrackingServices.trackingAdd({
  //     url: url,
  //     name: `Realestate - download plan`,
  //     description: `Lokal: ${nameRealestate}, uuid Lokalu: ${uuidRealestate}`,
  //   });
  // };

  return (
    <div className="flex-col">
      <div className="py-2 px-2 my-4 mx-8 flex flex-wrap lg:flex-row justify-between border-t items-center">
        <div className="ml-16 mr-2 text-primse-green cursor-pointer inline-block">
          {isChosen === true ? (
            <CheckSquare size={18} onClick={setChosen} />
          ) : (
            <Square size={18} onClick={setChosen} />
          )}
        </div>
        <div className="mx-2 py-2 px-2 flex-col items-start">
          <h3 className="font-bold text-base">{t("Number")}</h3>

          <p className="text-xs mt-4 w-32">
            {name ? (
              <ButtonNavLink
                uuid={id}
                link={`/realestate/${id}`}
                name={`${name}`}
              />
            ) : (
              `${t("No data")}`
            )}
          </p>
        </div>
        <div className="mx-2 py-2 px-2 flex-col items-start">
          <h3 className="font-bold text-base">{t("Area")}</h3>
          <p className="text-xs mt-4 w-32">
            {area ? area : 0}
            <sup>2</sup>
          </p>
        </div>
        <div className="mx-2 py-2 px-2 flex-col items-start">
          <h3 className="font-bold text-base">{t("Rooms number")}</h3>
          <p className="text-xs mt-4 w-32">
            {rooms ? rooms : `${t("No data")}`}
          </p>
        </div>
        <div className="mx-2 py-2 px-2 flex-col items-start">
          <h3 className="font-bold text-base">{t("Floor")}</h3>
          <p className="text-xs mt-4 w-32">
            {floor ? floor : `${t("No data")}`}
          </p>
        </div>
        <div className="mx-2 py-2 px-2 flex-col items-start">
          <h3 className="font-bold text-base">{t("Price")}</h3>
          <p className="text-xs mt-4 w-32">
            {price ? `${editPrice(price)} PLN` : `${t("No data")}`}
          </p>
        </div>

        <div className="mx-2 py-2 px-2 flex-col items-start">
          <h3 className="font-bold text-base">{t("Apartment plan")}</h3>
          {plan_url ? (
            <DetailsInvestments
              PDFLink={null}
              PDFName={name}
              planLink={plan_url}
              uuid={item_uuid}
              pathname={history.location.pathname}
              classNameProps="text-xs"
            />
          ) : (
            <p className="text-xs mt-4  ">{t("No plan")}</p>
          )}
        </div>

        <div className="mx-2 py-2 px-2 flex-col items-start">
          <h3 className="font-bold text-base">{t("Offered")}</h3>
          <p className="text-xs mt-4 w-32">
            <Moment format="DD.MM.YYYY">{created}</Moment>
          </p>
        </div>
        {SecondaryMarket ? (
          <Popup
            trigger={
              <div className="hidden lg:inline-block ml-2 mr-16 text-primse-green cursor-pointer">
                <Menu />
              </div>
            }
            flowing
            open={isOpenPopupButton}
            onOpen={() => setIsOpenPopupButton(true)}
            onClose={() => {
              setIsOpenPopupButton(false);
            }}
            on="click"
            position={"bottom center"}
          >
            <div className="bg-white  text-sm font-normal flex flex-col ">
              <div
                onClick={() => {
                  setIsOpenPopupButton(false);
                  setOpenWarningModal(true);
                }}
                className="p-3 cursor-pointer border-b hover:text-primse-green"
              >
                <strong>{t("Remove the premises from the briefcase")}</strong>
              </div>

              <div
                onClick={() => history.push(`/realestate/${id}`)}
                className="p-3 cursor-pointer border-b hover:text-primse-green"
              >
                <strong>{t("Details of the premises")}</strong>
              </div>
            </div>
          </Popup>
        ) : (
          <Popup
            trigger={
              <div className="hidden lg:inline-block ml-2 mr-16 text-primse-green cursor-pointer">
                <Menu />
              </div>
            }
            flowing
            open={isOpenPopupButton}
            onOpen={() => setIsOpenPopupButton(true)}
            onClose={() => {
              setIsOpenPopupButton(false);
            }}
            on="click"
            position={"bottom center"}
          >
            <div className="bg-white  text-sm font-normal flex flex-col ">
              <div
                onClick={() => {
                  setIsOpenPopupButton(false);
                  setOpenWarningModal(true);
                }}
                className="p-3 cursor-pointer border-b hover:text-primse-green"
              >
                <strong>{t("Remove the premises from the briefcase")}</strong>
              </div>
              {investment_status === "On sale" && (
                <div
                  onClick={() => history.push(`/realestate/${id}`)}
                  className="p-3 cursor-pointer border-b hover:text-primse-green"
                >
                  <strong>{t("Details of the premises")}</strong>
                </div>
              )}
            </div>
          </Popup>
        )}
      </div>
      <WarningModal
        removeRealestate={removeRealestate}
        open={openWarningModal}
        setOpen={setOpenWarningModal}
      />
    </div>
  );
};

InvestmentItem.propTypes = {
  name: PropTypes.string,
  floor: PropTypes.string,
  rooms: PropTypes.string,
  price: PropTypes.string,
  area: PropTypes.string,
  created: PropTypes.string,
};

export default InvestmentItem;
