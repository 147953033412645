const SI_SYMBOL = ["", "k", "M"];

export const parseThousands = (number) => {
  // what tier? (determines SI symbol)
  const _tier = (Math.log10(Math.abs(number)) / 3) | 0;
  const tier = _tier > 2 ? 2 : _tier;

  // if zero, we don't need a suffix
  if (tier === 0) return number;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
};

export const preventNegative = (e) => {
  if (e.code === "Minus") {
    e.preventDefault();
  }
};

export const preventFloat = (e) => {
  if (e.code === "Period") {
    e.preventDefault();
  }
  if (e.code === "Comma") {
    e.preventDefault();
  }
};

export const editPrice = (price) => {
  const roundedPrice = Math.round(price);
  const parsePrice = parseInt(roundedPrice);
  const result = parsePrice.toLocaleString();
  return result;
};

export const daysLeft = (decisionDeadline) => {
  if (!decisionDeadline) {
    return 0;
  }
  const today = new Date();
  const deadline = new Date(decisionDeadline);
  const difference = deadline.getTime() - today.getTime();

  return Math.ceil(difference / (1000 * 3600 * 24));
};

export const regexStringToNumberPrice = (input, blur = true) => {
  if (!input || input === undefined) return "0.00";
  const inputReplice = input
    .toString()
    .replace(/[,]/g, ".")
    .replace(/[^0-9.]/g, "");
  const parts = inputReplice.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  if (blur && !parts[1]) {
    parts.push("00");
  }
  if (blur && parts[1]?.length === 1) {
    parts[1] = parts[1] + "0";
  }
  if (blur && parts[1]?.length > 2) {
    parts[1] = parts[1].slice(0, 2);
  }
  if (blur) {
    parts.length = 2;
  }

  return parts.join(".");
};
