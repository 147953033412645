import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import RealestatesServices from "../../../../services/RealestatesServices";
import FlatListView from "./FlatListView";
import Loader from "../../../../components/Loader";

const FlatList = ({
  formValues,
  page,
  setAllPageCount,
  handleButtonSecondaryMarket,
  sortRealestates,
  setItemsInPagination,
  offsetTopTable,
  canRenderSecondaryMarket = () => {},
}) => {
  const [selectRealestates, setSelectRealestates] = useState(null);
  const [loadedData, setLoadedData] = useState(false);

  const filterType = (formValues) => {
    const arr = [];
    formValues.type?.forEach((el) => {
      if (el === "service premises") {
        arr.push("office");
      } else {
        arr.push(el);
      }
    });

    return arr;
  };

  const { data } = useQuery(
    [`realestates-select-${page}`, formValues],
    RealestatesServices.getSelectRealestates({
      page,
      realestate_category: filterType(formValues),
      location: formValues.location,
      area_from: formValues.area[0],
      area_to: formValues.area[1],
      rooms_from: formValues.rooms[0],
      rooms_to:
        formValues.rooms?.length === 2
          ? formValues.rooms[1]
          : formValues.rooms[0],
      price_from: formValues.price[0]?.replaceAll(" ", ""),
      price_to: formValues.price[1]?.replaceAll(" ", ""),
      search_primary:
        formValues.market[0] === "Primary market" ||
        formValues.market[1] === "Primary market" ||
        !canRenderSecondaryMarket(),
      search_secondary:
        formValues.market[0] === "Secondary market" ||
        formValues.market[1] === "Secondary market",
      order: formValues.order
        ? `${formValues.order[0]},${formValues.order[1]}`
        : null,
      name: formValues.text[0],
      floor_from: formValues.floors[0],
      floor_to:
        formValues.floors?.length === 2
          ? formValues.floors[1]
          : formValues.floors[0],
      completion_quarter: formValues.delivery[0],
      closed: formValues.closed[0],
      basement: formValues.basement[0],
      balcony: formValues.balcony[0],
      terrace: formValues.terrace[0],
      green_terrace: formValues.green_terrace[0],
      has_parking_space: formValues.has_parking_space[0],
      onlyEnable: true,
    })
  );

  const filterInvestments = () => {
    if (!data) return null;

    if (data.payload) {
      setLoadedData(false);
      setAllPageCount(data.paginator?.totalItems, data.paginator.itemsPerPage);
      setItemsInPagination(data.paginator?.totalItems);
      setSelectRealestates(data.payload);
      setLoadedData(true);
    }
  };

  useEffect(() => {
    // const formValues = parseURLtoFormValues(location.search);
    setLoadedData(false);
    setSelectRealestates(null);

    filterInvestments();
  }, [formValues, data]);

  return loadedData && selectRealestates !== null ? (
    <FlatListView
      flats={selectRealestates}
      handleButtonSecondaryMarket={handleButtonSecondaryMarket}
      offsetTopTable={offsetTopTable}
      sortRealestates={sortRealestates}
      formValues={formValues}
    />
  ) : (
    <Loader />
  );
};

export default FlatList;
