import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AiFillCloseCircle } from "react-icons/all";

import Loader from "../../../components/Loader";
import TransactionsServices from "../../../services/TransactionsServices";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
  TransactionDropdown,
  TransactionInput,
  TransactionInputFiles,
} from "../../Transactions/components/TransactionInputs";

const ComponentWithLable = ({ text = "", children }) => {
  return (
    <div className="flex items-center  ">
      <div className="w-4/12" style={{}}>
        {text}
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
};

function AddInvoiceModal({
  watchForm,
  uuid,
  role = "developer",
  handleRefresh,
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, watch, setValue } = useForm();
  const [selectFile, setSelectFile] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      setValue("invoice_price_net", watchForm().commission_agency_price_net);
    }
  }, [open]);

  const taxType = useMemo(() => {
    return [
      { value: "1", text: "VAT 23%", tax: 123 },
      { value: "2", text: "VAT 8%", tax: 108 },
      { value: "3", text: t("Without VAT"), tax: 100 },
      { value: "4", text: "VAT 0", tax: 100 },
      { value: "5", text: "VAT 8/23", tax: 123 },
    ];
  }, [t]);

  const handleStatusChange = () => {
    setLoading(true);
    const options = {
      ...watch(),
    };
    if (role === "developer") {
      options.developer_invoice_status_id = "1";
    } else {
      options.invoice_status_id = "1";
    }

    TransactionsServices.updateTransaction(uuid, {
      ...options,
    })
      .then((res) => {
        if (res.status === 200) {
          handleRefresh();
          toast.success(`${t("The invoice has been added")}`);
          setOpen(false);
        } else {
          toast.error(`${t("Wystąpił błąd podczas dodawania faktury")}`);
        }
      })
      .catch(() =>
        toast.error(`${t("Wystąpił błąd podczas dodawania faktury")}`)
      )
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={open}
      size="small"
      trigger={
        <button
          onClick={() => setOpen(true)}
          className="leading-5 w-56   font-bold text-white uppercase bg-primse-green py-3 rounded-sm transition-all hover:bg-primse-gray  "
        >
          {t("Upload the invoice")}
        </button>
      }
    >
      <Modal.Header
        style={{ background: "rgba(249, 250, 251, 1", border: "none" }}
      >
        <div className="flex justify-between">
          <h3 className="text-xl font-bold">{`
          ${
            role === "developer"
              ? t("Developer invoice")
              : t("Invoice for PRIMSE.COM from the agent")
          } `}</h3>
          <AiFillCloseCircle
            onClick={() => setOpen(false)}
            className="text-primse-gray-font cursor-pointer w-8 h-8"
          />
        </div>
      </Modal.Header>

      <Modal.Content>
        <div className="flex flex-col  px-8">
          <ComponentWithLable text={t("Invoice number")}>
            <TransactionInput
              control={control}
              name={
                role === "developer"
                  ? "developer_invoice_number"
                  : "invoice_number"
              }
              type="text"
            />
          </ComponentWithLable>

          <ComponentWithLable text={t("Net amount")}>
            <TransactionInput
              defaultValue={
                role === "developer"
                  ? watchForm().commission_total_price_net
                  : watchForm().commission_agency_price_net
              }
              control={control}
              name={
                role === "developer"
                  ? "developer_invoice_price_net"
                  : "invoice_price_net"
              }
              type="text"
              reqex
            />
          </ComponentWithLable>
          <ComponentWithLable text="Stawka VAT">
            <TransactionDropdown
              control={control}
              name={
                role === "developer"
                  ? "developer_invoice_tax_type_id"
                  : "invoice_tax_type_id"
              }
              options={taxType}
            />
          </ComponentWithLable>
          <ComponentWithLable text="Sposób zapłaty">
            <TransactionDropdown
              control={control}
              name={
                role === "developer"
                  ? "developer_invoice_payment_type_id"
                  : "invoice_payment_type_id"
              }
              options={[
                { text: t("Transfer to a bank account"), value: "1" },
                { text: t("Cash payment"), value: "2" },
              ]}
            />
          </ComponentWithLable>
          <ComponentWithLable text="Data wystawienia">
            <TransactionInput
              control={control}
              name={
                role === "developer"
                  ? "developer_invoice_issued_at"
                  : "invoice_issued_at"
              }
              type="date"
            />
          </ComponentWithLable>
          <ComponentWithLable text="Załącznik">
            <div className="flex my-2" style={{ paddingLeft: "7px" }}>
              <TransactionInputFiles
                control={control}
                setSelectFile={setSelectFile}
                name={
                  role === "developer"
                    ? "developer_invoice_document"
                    : "invoice_document"
                }
              />
              {selectFile?.name && (
                <p className="pl-10 flex items-center">
                  {role === "developer"
                    ? watch().developer_invoice_issued_at
                    : watch().invoice_document?.name}
                </p>
              )}
            </div>
          </ComponentWithLable>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className="flex justify-between lg:justify-end">
          <button
            onClick={() => handleStatusChange()}
            className="leading-5 w-44 mx-2 font-bold text-white uppercase bg-primse-green py-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
          >
            {t("Upload")}
          </button>
          <button
            onClick={() => setOpen(false)}
            className="leading-5 w-44 mx-2 font-bold text-black uppercase bg-primse-yellow py-3 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
          >
            {t("Cancel")}
          </button>
        </div>
      </Modal.Actions>
      {loading && <Loader />}
    </Modal>
  );
}

export default AddInvoiceModal;
