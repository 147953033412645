import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import SwiperCore, { A11y, Navigation, Pagination } from "swiper";
import { MapPin } from "../Icons";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Modal from "../Modal";
import styles from "./Gallery.module.css";
import ReactPlayer from "react-player/youtube";
import Button from "../Button";
import { useTranslation } from "react-i18next";
import { useStoreState } from "easy-peasy";

SwiperCore.use([Navigation, Pagination, A11y]);

const ImageGallery = ({ height, index, src, setShowModal }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      <div
        className="transition-all hover:opacity-80 cursor-pointer "
        key={index}
        onClick={() => setShowModal(index)}
      >
        {!isLoaded && (
          <div
            className="ui placeholder fluid"
            style={{ height: height }}
          ></div>
        )}
        <img
          onLoad={() => setIsLoaded(true)}
          className="w-full object-cover"
          style={{
            height: height,
            opacity: isLoaded ? 1 : 0,
            display: isLoaded ? "inline" : "none",
          }}
          src={`${process.env.REACT_APP_IMAGES_URL}${src.file}`}
          alt="flat"
        />
      </div>
    </>
  );
};

const Gallery = ({
  images,
  data,
  setShowModalMap,
  static_map,
  data_secondary = null,
}) => {
  const [showModal, setShowModal] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(null);
  const { t } = useTranslation();
  const { mode } = useStoreState((state) => state.mode);
  const mainImages = images?.slice(0, 1);
  const restImages = images?.slice(1, 5);

  useEffect(() => {
    if (showModal !== null) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => (document.body.style.overflow = "unset");
  }, [showModal]);

  const videoGallery = useMemo(() => {
    if (mode === "presentation") return [];
    const array = [data.yt_video_0, data.yt_video_1, data.yt_video_2];

    const filterArr = array.filter((el) => {
      if (el !== null) {
        if (el.startsWith("https://www.youtube.com")) return el;
      }
    });

    return filterArr;
  }, [data, mode]);

  const renderImage = (_images, height) =>
    _images.map((src, index = 1) => (
      <ImageGallery
        key={index}
        height={height}
        index={index}
        src={src}
        setShowModal={setShowModal}
      />
    ));
  if (images === null || images?.length === 0)
    return <div className="pt-12"></div>;

  const ytLink = (link) => {
    const href = window.location.href;
    const pathname = window.location.pathname;

    const newLink = `${link}?showinfo=0&enablejsapi=1&origin=${href.slice(
      0,
      href?.length - pathname?.length
    )}`;

    return newLink;
  };

  return (
    <div className="pt-0 md:pt-4">
      {images?.length < 2 && (
        <div
          className="hidden lg:grid grid-flow-col h-galleryHeight"
          style={{ minHeight: "420px" }}
        >
          {(data?.lat || data_secondary?.sm_latitude) &&
            (data?.long || data_secondary?.sm_longitude) && (
              <div
                className="relative transition-all hover:opacity-80 cursor-pointer"
                index={1}
                onClick={() => setShowModalMap(true)}
              >
                <div
                  key={2}
                  className="bg-no-repeat bg-cover bg-center w-full h-full"
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_IMAGES_URL}${static_map})`,
                  }}
                />

                <div
                  style={{ top: "-32px" }}
                  className="absolute left-0 w-full h-full flex items-center justify-center "
                >
                  <MapPin />
                </div>
              </div>
            )}
          {renderImage(mainImages, "420px")}
        </div>
      )}
      {images?.length >= 2 && images?.length < 4 && (
        <div className="hidden lg:grid grid-cols-3 h-galleryHeight">
          {(data?.lat || data_secondary?.sm_latitude) &&
            (data?.long || data_secondary?.sm_longitude) && (
              <div
                className="relative transition-all hover:opacity-80 cursor-pointer"
                index={1}
                onClick={() => setShowModalMap(true)}
              >
                <div
                  key={2}
                  className="bg-no-repeat bg-cover bg-center w-full h-full"
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_IMAGES_URL}${static_map})`,
                  }}
                />

                <div
                  style={{ top: "-32px" }}
                  className="absolute left-0 w-full h-full flex items-center justify-center "
                >
                  <MapPin />
                </div>
              </div>
            )}
          {renderImage(mainImages, "420px")}

          {videoGallery?.length > 0 ? (
            <div className="grid">
              <div className="flex items-center justify-center" key={3}>
                <ReactPlayer
                  height="210px"
                  width="100%"
                  style={{ pointerEvents: "none" }}
                  url={ytLink(videoGallery)}
                  playing={false}
                  muted
                  config={{
                    file: {
                      attributes: {
                        muted: true,
                        controls: false,
                      },
                    },
                  }}
                />
                <Button
                  onClick={() => setShowVideoModal(true)}
                  className="z-20 absolute"
                >
                  {t("Zoom in")}
                </Button>
              </div>
              {renderImage(restImages.slice(0, 1), "210px")}
            </div>
          ) : (
            <div className="grid">{renderImage(restImages, "210px")}</div>
          )}
        </div>
      )}
      {images?.length === 4 && (
        <div className="hidden lg:grid grid-cols-3 h-galleryHeight">
          {(data?.lat || data_secondary?.sm_latitude) &&
            (data?.long || data_secondary?.sm_longitude) && (
              <div
                className="relative transition-all hover:opacity-80 cursor-pointer"
                index={1}
                onClick={() => setShowModalMap(true)}
              >
                <div
                  key={2}
                  className="bg-no-repeat bg-cover bg-center w-full h-full"
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_IMAGES_URL}${static_map})`,
                  }}
                />

                <div
                  style={{ top: "-32px" }}
                  className="absolute left-0 w-full h-full flex items-center justify-center "
                >
                  <MapPin />
                </div>
              </div>
            )}
          {renderImage(mainImages, "420px")}
          <div className="grid">
            {videoGallery?.length > 0 ? (
              <div className="grid grid-cols-2">
                <div className="flex items-center justify-center" key={3}>
                  <ReactPlayer
                    height="210px"
                    width="100%"
                    style={{ pointerEvents: "none" }}
                    url={ytLink(videoGallery)}
                    playing={false}
                    controls={false}
                    muted
                    config={{
                      file: {
                        attributes: {
                          muted: true,
                          controls: false,
                        },
                      },
                    }}
                  />
                  <Button
                    onClick={() => setShowVideoModal(true)}
                    className="z-20 absolute"
                  >
                    {t("Zoom in")}
                  </Button>
                </div>
                {renderImage(restImages.slice(0, 1), "210px")}
              </div>
            ) : (
              <div className="grid grid-cols-2">
                {renderImage(restImages.slice(0, 2), "210px")}
              </div>
            )}
            <div className="grid grid-cols-1">
              {renderImage(restImages.slice(2), "210px")}
            </div>
          </div>
        </div>
      )}

      {images?.length > 4 && (
        <div className="hidden lg:grid grid-cols-3 h-galleryHeight">
          {(data?.lat || data_secondary?.sm_latitude) &&
            (data?.long || data_secondary?.sm_longitude) && (
              <div
                className="relative transition-all hover:opacity-80 cursor-pointer overflow-hidden"
                index={1}
                onClick={() => setShowModalMap(true)}
              >
                <div
                  key={2}
                  className="bg-no-repeat bg-cover bg-center w-full h-full"
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_IMAGES_URL}${static_map})`,
                  }}
                />
                <div
                  style={{ top: "-32px" }}
                  className="absolute left-0 w-full h-full flex items-center justify-center "
                >
                  <MapPin />
                </div>
              </div>
            )}
          {renderImage(mainImages, "420px")}
          {videoGallery?.length > 0 ? (
            <div className="grid grid-cols-2">
              <div className="flex items-center justify-center" key={3}>
                <ReactPlayer
                  height="210px"
                  width="100%"
                  style={{ pointerEvents: "none" }}
                  url={ytLink(videoGallery)}
                  playing={false}
                  controls={false}
                  muted
                  config={{
                    file: {
                      attributes: {
                        muted: true,
                        controls: false,
                      },
                    },
                  }}
                />
                <Button
                  onClick={() => setShowVideoModal(true)}
                  className="z-20 absolute"
                >
                  {t("Zoom in")}
                </Button>
              </div>
              {renderImage(restImages.slice(0, 3), "210px")}
            </div>
          ) : (
            <div className="grid grid-cols-2">
              {renderImage(restImages, "210px")}
            </div>
          )}
        </div>
      )}
      <Swiper
        className="lg:hidden pb-4"
        slidesPerView={1}
        navigation
        loop={true}
        pagination={{ clickable: true }}
        initialSlide={showModal}
      >
        {images.map((src, index) => (
          <SwiperSlide key={index}>
            <img
              src={`${process.env.REACT_APP_IMAGES_URL}${src.file}`}
              width="100%"
              height="100%"
              alt={`investment ${index}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <Modal isOpen={showModal !== null} handleClose={() => setShowModal(null)}>
        <div className={styles.modalContainer}>
          <div style={{ height: "80vh", marginBottom: "4rem" }}>
            <Swiper
              style={{ height: "100%" }}
              autoHeight={true}
              centeredSlides={true}
              slidesPerView={1}
              navigation
              loop={true}
              pagination={{ clickable: true }}
              initialSlide={showModal}
              preloadImage={false}
              lazy={true}
            >
              {images.map((src, index) => (
                <SwiperSlide key={index}>
                  <div className="grid h-full">
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "80vh",
                        margin: "auto",
                      }}
                      src={`${process.env.REACT_APP_IMAGES_URL}${src.file}`}
                      alt={`investment ${index}`}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showVideoModal !== null}
        handleClose={() => setShowVideoModal(null)}
      >
        <div className={styles.modalContainer}>
          <div style={{ height: "80vh", marginBottom: "4rem" }}>
            <Swiper
              style={{ height: "100%" }}
              autoHeight={true}
              centeredSlides={true}
              slidesPerView={1}
              navigation={videoGallery?.length > 1}
              loop={true}
              pagination={{ clickable: true }}
              initialSlide={showModal}
              preloadImage={false}
              lazy={true}
            >
              {videoGallery.map((src, index) => (
                <SwiperSlide key={index}>
                  <div className="grid h-full">
                    <ReactPlayer
                      width="1280px"
                      height="75vh"
                      url={src}
                      muted
                      controls={true}
                      config={{
                        file: {
                          attributes: {
                            muted: true,
                            related: false,
                          },
                        },
                      }}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </Modal>
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.array,
};

export default Gallery;
