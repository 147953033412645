import { useMemo } from "react";
import { Link } from "react-router-dom";

const Recomendation = ({ uuid, banner_text, files, subject }) => {
  const filesInGroupType = useMemo(() => {
    const newFiles = files.reduce((acc, file) => {
      const { type } = file;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(file);
      return acc;
    }, {});

    let mainImage = null;

    // Banners / Images
    if (newFiles?.Banner?.length > 0) {
      mainImage = newFiles?.Banner[0];
    } else if (newFiles?.Image?.length > 0) {
      mainImage = newFiles?.Image[0];
    }

    return { ...newFiles, mainImage };
  }, [files]);

  return (
    <Link to={`news-info/${uuid}`}>
      <div
        className="  mb-2  w-full lg:w-72 h-52 bg-cover bg-center overflow-hidden mx-auto"
        style={{
          backgroundImage:
            files?.length !== 0 &&
            `url(${process.env.REACT_APP_IMAGES_URL}${filesInGroupType?.mainImage?.url})`,
        }}
      >
        <>
          <div className="hidden md:block transition-all duration-500 hover:bg-primse-green bg-opacity-75 hover:bg-opacity-75 p-4 h-full group">
            <div className="border text-white  text-xl lg:text-base text-center px-16 h-full flex items-center justify-center">
              <strong className="opacity-0 transition-all delay-100 duration-500 transform translate-y-full group-hover:opacity-100 group-hover:translate-y-0">
                {banner_text === null ? subject : banner_text}
              </strong>
            </div>
          </div>
        </>
        <div className="md:hidden bg-primse-green  bg-opacity-75 p-4 h-full group">
          <div className="border text-white  text-xl text-center px-16 h-full flex items-center justify-center">
            <strong className="">
              {banner_text === null ? subject : banner_text}
            </strong>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Recomendation;
