import { ChevronRight, ChevronDown } from "react-feather";
import React, { useState } from "react";
import InvestmentItem from "../Investment/InvestmentItem";
import NewRequestModal from "../../../../modals/NewRequestModal";
import ButtonNavLink from "../../../../components/ButtonNavLink";

const SecondaryMarketInvestment = ({
  investmentData: {
    developer_name,
    investment_name,
    realestates,
    investment_uuid,
  },

  clientUuid,
  handleRefreshData,
  realestateList,
  setRealestateList,
  handleDeleteRealestate,
  handleRefresh,
}) => {
  const [detailsClicked, setDetailsClicked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const setDetails = () => {
    if (detailsClicked === false) {
      setDetailsClicked(true);
    } else setDetailsClicked(false);
  };

  // const setChosen = () => {
  //   if (isChosen === false) {
  //     setIsChosen(true);
  //   } else setIsChosen(false);
  // };

  // const setChosenOnly = (e) => {
  //   if (e.target.tagName === "CheckSquare" || e.target.tagName === "Square")
  //     setChosen();
  // };

  return (
    <>
      {realestates?.length !== 0 && (
        <div className="border mb-6">
          <div
            className="py-2 px-2 my-2 mx-8 flex items-end justify-between items-center cursor-pointer"
            // onClick={setChosenOnly}
          >
            <div className="flex items-center">
              {realestates?.length !== 0 ? (
                <div className="mx-2 text-primse-green cursor-pointer">
                  {detailsClicked === true ? (
                    <ChevronDown size={22} onClick={setDetails} />
                  ) : (
                    <ChevronRight size={22} onClick={setDetails} />
                  )}
                </div>
              ) : (
                <div className="mx-2 text-white cursor-pointer">
                  <ChevronRight size={22} onClick={setDetails} />
                </div>
              )}
              <div className="mx-6 text-primse-green">
                {/*{isChosen === true ? (*/}
                {/*  <CheckSquare size={22} onClick={setChosen} />*/}
                {/*) : (*/}
                {/*  <Square size={22} onClick={setChosen} />*/}
                {/*)}*/}
              </div>
              <div className="mx-2 py-2 px-2 flex flex-col items-start">
                <h3 className="font-bold mb-1">
                  <ButtonNavLink
                    uuid={investment_uuid}
                    link={`realestates/?type=apartment,house&market=Secondary%20market`}
                    name={`${investment_name}`}
                  />
                </h3>
              </div>
            </div>
          </div>

          {detailsClicked &&
            realestates.map((realeastete) => (
              <InvestmentItem
                handleRefreshData={handleRefreshData}
                key={realeastete.id}
                investmentItem={realeastete}
                briefcase_uuid={clientUuid}
                investment_name={investment_name}
                SecondaryMarket={true}
                realestateList={realestateList}
                setRealestateList={setRealestateList}
                handleDeleteRealestate={handleDeleteRealestate}
                handleRefresh={handleRefresh}
              />
            ))}
          <NewRequestModal
            investment_uuid={investment_uuid}
            clientUuid={clientUuid}
            developerName={developer_name}
            investmentName={investment_name}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
          />
        </div>
      )}
    </>
  );
};

export default SecondaryMarketInvestment;
